import React from 'react'

const Previous4 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.87" height="17.87" viewBox="0 0 17.87 17.87">
      <path
        d="M9.935 18.87a8.935 8.935 0 1 1 8.935-8.935 8.935 8.935 0 0 1-8.935 8.935zm0-16.246a7.311 7.311 0 1 0 7.311 7.311 7.311 7.311 0 0 0-7.311-7.31zM8.311 9.935l4.874 3.249v-6.5zm-2.437 3.249H7.5v-6.5H5.874z"
        transform="translate(-1 -1)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Previous4

import React from 'react'

const Playback7 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 13.703 13.68"
    >
      <path
        data-name="Path 1151"
        d="M57.767 20.342c0-.274-.16-.342-.251-.342h-5.883a.424.424 0 0 0-.433.433v.821c0 .365.228.456.342.456h4.1c.16.023.433.137.433.593v3.876a.406.406 0 0 0 .433.365h.912a.382.382 0 0 0 .365-.433v-5.769z"
        transform="translate(-44.086 -20)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1152"
        d="M42.167 35.942c.023-.274-.16-.342-.251-.342h-5.883a.424.424 0 0 0-.433.433v.821c0 .365.228.456.342.456h4.1c.16.023.433.137.433.593v3.876a.406.406 0 0 0 .433.365h.912a.382.382 0 0 0 .365-.433v-5.769z"
        transform="translate(-32.043 -32.043)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1153"
        d="M20.958 57.5c.046.046.274.228.593-.091l2.964-2.964c.091-.091.342-.274.342.114v2.918a.406.406 0 0 0 .433.365h.91a.382.382 0 0 0 .365-.433v-5.767a.3.3 0 0 0-.251-.342h-5.881a.424.424 0 0 0-.433.433v.821c0 .365.228.456.342.456h2.736c.114 0 .456.023.137.342l-2.85 2.9s-.342.319-.046.593z"
        transform="translate(-20 -44.163)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Playback7

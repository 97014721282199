import React from 'react'

const RadioButtonUnchecked = ({ color = '#fff', imgClass = '' }) => {
  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      className={imgClass}
    >
      <path
        data-name="Path 902"
        d="M8.564 15.127a6.564 6.564 0 1 1 6.564-6.564 6.564 6.564 0 0 1-6.564 6.564zm0-11.815a5.251 5.251 0 1 0 5.251 5.251 5.251 5.251 0 0 0-5.251-5.25z"
        transform="translate(-.687 -.687)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default RadioButtonUnchecked

import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { getImage } from '../../../utils/helper'
import { AppContext } from '../../../context/app'

const HeaderBar = ({ playerData, contentInfo, showDisplayIcons }) => {
  let data = playerData?.header_bar?.data
  const { accountObj } = useContext(AppContext)

  const positionClass = {
    CENTER_LEFT: 'justify-start',
    CENTER: 'justify-center',
    CENTER_RIGHT: 'justify-end',
  }

  return (
    <div
      style={{
        height: '46px',
        backgroundColor: `${data.background}60`,
        padding: '0px 30px',
        display: data.show_header_bar ? 'block' : 'none',
        color: data.text_color,
        opacity: showDisplayIcons ? '1' : '0',
        transition: 'opacity 0.25s ease-in',
      }}
      className={`w-full absolute top-0 `}
    >
      <div className={`flex ${positionClass[data.position]} h-full items-center`}>
        <div className="flex items-center">
          {data?.show_censorship && !isEmpty(contentInfo?.content_rating) ? (
            <div style={{}} className="flex items-center justify-center pr-4">
              {contentInfo?.content_rating?.icon?.s3_key && (
                <div className="mr-1">
                  <img
                    src={getImage(
                      accountObj?.cf_domain_name,
                      contentInfo?.content_rating?.icon?.s3_key,
                    )}
                    className="w-6 h-6 "
                    alt="Rating"
                  />
                </div>
              )}
              <p className="text-sm font-semibold">{contentInfo?.content_rating?.name}</p>
            </div>
          ) : null}
          {data?.show_title ? (
            <p className="font-bold lg:text-2xl text-xl line-clamp-1 ">{contentInfo?.title}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default HeaderBar

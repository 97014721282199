import React from 'react'

const Subtitle1 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 18.884 15.347"
    >
      <path
        data-name="Path 1015"
        d="M2 11.074c0-3.335 0-5 1.036-6.038S5.739 4 9.074 4h3.537c3.335 0 5 0 6.038 1.036s1.036 2.7 1.036 6.038 0 5-1.036 6.038-2.7 1.036-6.038 1.036H9.074c-3.335 0-5 0-6.038-1.036S2 14.408 2 11.074z"
        style={{ fill: 'none', stroke: color, strokeWidth: '1.2px' }}
        transform="translate(-1.4 -3.4)"
      />
      <path
        data-name="Path 1016"
        d="M9.537 16H6"
        transform="translate(-1.863 -4.789)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
      <path
        data-name="Path 1017"
        d="M14 13h3.537"
        transform="translate(-2.789 -4.442)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
      <path
        data-name="Path 1018"
        d="M13.826 16H12.5"
        transform="translate(-2.616 -4.789)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
      <path
        data-name="Path 1019"
        d="M9.5 13h1.768"
        transform="translate(-2.268 -4.442)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
      <path
        data-name="Path 1020"
        d="M17.826 16H16.5"
        transform="translate(-3.079 -4.789)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
      <path
        data-name="Path 1021"
        d="M6 13h.884"
        transform="translate(-1.863 -4.442)"
        style={{ strokeLinecap: 'round', fill: 'none', stroke: color, strokeWidth: '1.2px' }}
      />
    </svg>
  )
}

export default Subtitle1

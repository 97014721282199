import React from 'react'

const FiltersCheck = ({ bgColor = '#0ff', tickColor = '#0f1721', styles }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={styles} viewBox="0 0 17 17">
      <g data-name="Group 561" transform="translate(-1068 -454)">
        <circle
          data-name="Ellipse 52"
          cx="8.5"
          cy="8.5"
          r="8.5"
          transform="translate(1068 454)"
          style={{ fill: bgColor }}
        />
        <path
          data-name="tick-sign-svgrepo-com (1)"
          d="m9.586 33.286-5.657 5.656-2.2-2.2L0 38.473 3.929 42.4l7.387-7.386z"
          transform="translate(1070.842 424.438)"
          style={{ fill: tickColor }}
        />
      </g>
    </svg>
  )
}

export default FiltersCheck

import React from 'react'

const FullScreen3 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 12.697 12.697"
        >
          <g data-name="Group 1093">
            <path
              d="M14.7 8.349v5.714a.635.635 0 0 1-.635.635H2.635A.635.635 0 0 1 2 14.062V2.635A.635.635 0 0 1 2.635 2h5.714a.635.635 0 0 1 0 1.27H3.27v10.158h10.158V8.349a.635.635 0 1 1 1.27 0z"
              transform="translate(-2 -2)"
              style={{ fill: color }}
            />
            <path
              data-name="external-link-svgrepo-com"
              d="M11.392 8.935a.629.629 0 0 0 .243.049h3.174a.635.635 0 0 0 0-1.27h-1.642l4.63-4.63a.635.635 0 1 0-.9-.9l-4.63 4.63v-1.64a.635.635 0 0 0-1.27 0v3.175a.637.637 0 0 0 .392.586z"
              transform="translate(-5.286 -2)"
              style={{ fill: color }}
            />
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 12.697 12.697"
        >
          <path
            d="M14.7 8.349v5.714a.635.635 0 0 1-.635.635H2.635A.635.635 0 0 1 2 14.062V2.635A.635.635 0 0 1 2.635 2h5.714a.635.635 0 0 1 0 1.27H3.27v10.158h10.158V8.349a.635.635 0 1 1 1.27 0zm-.392-6.3A.629.629 0 0 0 14.062 2h-3.174a.635.635 0 0 0 0 1.27h1.642L7.9 7.9a.635.635 0 1 0 .9.9l4.63-4.63v1.639a.635.635 0 1 0 1.27 0V2.635a.637.637 0 0 0-.392-.586z"
            transform="translate(-2 -2)"
            style={{ fill: color }}
          />
        </svg>
      )}
    </div>
  )
}

export default FullScreen3

import React from 'react'

const Backward3 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.117"
      height="18.117"
      viewBox="0 0 18.117 18.117"
    >
      <path
        d="M37.059 46.117A9.059 9.059 0 1 0 28 37.059a9.059 9.059 0 0 0 9.059 9.059zm0-17.392a8.334 8.334 0 1 1-8.334 8.334 8.334 8.334 0 0 1 8.334-8.334zM36.5 37.36l4.348 2.9a.362.362 0 0 0 .563-.3v-5.8a.362.362 0 0 0-.563-.3l-4.348 2.9a.362.362 0 0 0 0 .6zm4.187-2.523v4.443l-3.333-2.222zm-9.26 2.523 4.348 2.9a.362.362 0 0 0 .563-.3v-5.8a.362.362 0 0 0-.563-.3l-4.348 2.9a.362.362 0 0 0 0 .6zm4.187-2.523v4.443l-3.333-2.222z"
        transform="translate(-28 -28)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Backward3

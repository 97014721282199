import React from 'react'

const StartAgainIcon = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <g id="Group_776" data-name="Group 776" transform="translate(-490 -262)">
        <g id="circle-play-next-svgrepo-com" transform="translate(523.932 295.933) rotate(180)">
          <rect
            id="icon-bound"
            width="34"
            height="34"
            fill="none"
            transform="translate(-0.068 -0.068)"
          />
          <path
            id="Path_878"
            data-name="Path 878"
            fill={color}
            d="M10.6,23.328l8.483-6.362L10.6,10.6Zm8.483-6.362v6.362h4.242V10.6H19.087Z"
          />
        </g>
        <g id="Complete" transform="translate(489 261)">
          <g id="Circle" transform="translate(2 2)">
            <circle id="Circle-2" data-name="Circle" style={pathStyle} cx="16" cy="16" r="16" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StartAgainIcon

import React, { useContext } from 'react'
import EclipseSignIn from './eclipseSignIn'
import Login from '../loginSignup/login'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import EliteEdgeSignIn from './eliteEdgeSignIn'
import SceneCenterSignIn from './sceneCenterSignin'

const LoginTemplate = () => {
  const { loginTemplateData, loginTemplateLoaded } = useContext(TemplateContext)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      eclipseSignIn: <EclipseSignIn {...pageProps} />,
      eliteEdgeSignIn: <EliteEdgeSignIn {...pageProps} />,
      sceneCenterSignIn: <SceneCenterSignIn {...pageProps} />,
      playmakersSignIn: <SceneCenterSignIn {...pageProps} />,
      crescendoSignIn: <SceneCenterSignIn {...pageProps} />,
    }

    return componentObj[key]
  }

  return (
    <div className="w-full h-full">
      {loginTemplateLoaded ? (
        !isEmpty(loginTemplateData) ? (
          getPage(loginTemplateData.template_key, loginTemplateData.data)
        ) : (
          <Login />
        )
      ) : null}
    </div>
  )
}

export default LoginTemplate

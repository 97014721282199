import React, { useContext } from 'react'
import { withTheme } from 'theming'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'

const ChannelAbout = (props) => {
  const { description = '', category = [], theme } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isCustomTemplate } = useContext(AppContext)

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  return (
    <div className="">
      <div className="mt-10 md:text-lg text-sm " style={{ color: '#cecece' }}>
        <p className={'text-lg'}>{description}</p>

        <div
          className="description-details md:flex-row flex-col gap-y-3 md:flex-wrap"
          style={{
            display: 'flex',
            // gap: '12px 0',
            marginTop: '2vw',
          }}
        >
          <div className={'flex md:w-fit'}>
            <p
              style={{ color: isCustomTemplate ? theme.c4 : '#fff', marginBlockEnd: '0' }}
              className={'w-fit '}
            >
              {t('category')}
            </p>
            <div
              style={{
                color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)',
                marginBlockEnd: '0',
              }}
              className={'w-fit px-4 flex flex-wrap'}
            >
              {[...category].map((item, index) => (
                <p
                  key={index}
                  onClick={() => handleCategoryClick(item._id, item.name)}
                  className={'cursor-pointer hover:underline'}
                  style={{ marginBlock: '0' }}
                >
                  {index !== 0 && <span>{' , '}</span>}
                  {item.name}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ChannelAbout)

import React from 'react'

const Setting1 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 19.551 19.551">
      <path
        data-name="Path 1096"
        d="M10.255 4.188a1.894 1.894 0 0 1-2.542.987 1.914 1.914 0 0 0-2.538 2.538 1.894 1.894 0 0 1-.987 2.542 1.89 1.89 0 0 0 0 3.49 1.894 1.894 0 0 1 .987 2.542 1.914 1.914 0 0 0 2.538 2.538 1.9 1.9 0 0 1 2.542.987 1.89 1.89 0 0 0 3.49 0 1.894 1.894 0 0 1 2.542-.987 1.914 1.914 0 0 0 2.538-2.538 1.894 1.894 0 0 1 .987-2.542 1.89 1.89 0 0 0 0-3.49 1.9 1.9 0 0 1-.987-2.542 1.914 1.914 0 0 0-2.538-2.538 1.894 1.894 0 0 1-2.542-.987 1.89 1.89 0 0 0-3.49 0z"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: color,
          strokeWidth: '1.6px',
        }}
        transform="translate(-2.225 -2.225)"
      />
      <path
        data-name="Path 1097"
        d="M15 12a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"
        style={{ fill: 'none', stroke: color, strokeWidth: '1.6px' }}
        transform="translate(-2.225 -2.225)"
      />
    </svg>
  )
}

export default Setting1

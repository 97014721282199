import React, { useEffect, useState } from 'react'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import IntentMarketingForm from './intentMarketingForm'

const TemplateCustomForms = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  theme,
  onAdd,
  formData,
}) => {
  const { data, id } = componentData
  const { isLogin, setOpenLoginModal, CUSTOM_FORM, setLoader, raiseToast } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')

  let templateKey = data?.form_metadata?.template_key || ''

  const [{ data: formSubmitData, loading: formSubmitLoading }, formSubmitCall] = useAxios(
    {
      url: CUSTOM_FORM,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(formSubmitLoading)
  }, [formSubmitLoading])

  useEffect(() => {
    if (formSubmitData && formSubmitData.success) {
      if (!isLogin) {
        let submittedFormArr = localStorage.getItem('submittedFormIDs')
          ? JSON.parse(localStorage.getItem('submittedFormIDs'))
          : []

        submittedFormArr.push(`${formSubmitData.data.custom_form}`)
        localStorage.setItem('submittedFormIDs', JSON.stringify(submittedFormArr))
      }
      raiseToast('success', 'Your response has been submitted!')
    }
  }, [formSubmitData])

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const formSubmit = async (currentForm, payload) => {
    if (currentForm.is_login_required && !isLogin) {
      setOpenLoginModal(true)
    }
    let formRes = await formSubmitCall({
      data: {
        form_id: currentForm?._id,
        form_data: {
          fields: payload,
        },
      },
    })

    return formRes?.data?.success
  }

  const getForm = () => {
    let formObj = formData?.find((info) => info?._id === data?._id)
    if (!templateKey || !formObj) return

    let pageProps = {
      data: formObj,
      onFormSubmit: formSubmit,
      isEdit,
    }
    let componentObj = {
      intentMarketingForm: <IntentMarketingForm {...pageProps} />,
    }

    return componentObj[templateKey]
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative w-full h-full`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}

      {getForm()}

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default TemplateCustomForms

import React, { useContext } from 'react'
import { getPaymentGatewayLogo, getReleaseDate } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const ViewTransactionDetailModal = ({ onCancel, data, theme }) => {
  const { t } = useTranslation()
  const { isCustomTemplate } = useContext(AppContext)
  return (
    <div
      style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none inset-0"
    >
      <div className="relative w-[94%] lg:w-[905px] my-2 mx-auto">
        <div
          style={{
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
            backgroundImage: 'linear-gradient(to right, #000 -14%, #00224a 55%)',
          }}
          className="shadow-lg relative flex flex-col md:w-full p-5 "
        >
          <div
            style={{
              color: '#fff',
            }}
            className=" justify-center md:text-lg lg:p-5 rounded-xl"
          >
            <div
              style={{ color: '#fff' }}
              className="md:text-2xl lg:text-[28px] text-lg font-semibold flex justify-center mb-12"
            >
              <h4>{t('transaction_details_caps')}</h4>
            </div>
            <div className="flex justify-between text-xs mt-2 md:mt-6 ">
              <p className="w-[90px] lg:w-auto mt-1 lg:mt-0">{t('transaction_ID')}</p>
              <p
                style={{ wordBreak: 'break-word' }}
                className={'text-sm w-[219px] lg:w-auto'}
                title={data.transaction_id}
              >
                {data.transaction_id}
              </p>
            </div>
            <div className="flex justify-between text-xs mt-2  md:mt-6">
              <p>{t('transaction_date')}</p>
              <p>{getReleaseDate(data.captured_date)}</p>
            </div>
            <div className="flex justify-between text-xs mt-2  md:mt-6">
              <p>{t('payment_gateway')}</p>
              <div
                style={{ width: '85px', height: '14px' }}
                className="bg-white rounded flex justify-center items-center"
              >
                <img
                  src={getPaymentGatewayLogo(data.payment_gateway)}
                  alt={'stripe'}
                  className="h-full"
                />
              </div>
            </div>
            <div
              className="flex justify-between text-xs mt-2  md:mt-6 pb-2.5"
              style={{ borderBottom: '0.5px solid #707070' }}
            >
              <p>{t('order_details')}</p>
            </div>

            {[...data?.subscriptions].map((info, index) => (
              <div
                style={{ color: '#b5b5b5' }}
                className="flex justify-between text-xs mt-2  md:mt-6"
                key={index}
              >
                <p>{info.subscription_name}</p>
                <p>
                  {parseFloat(info?.subscription_amount).toFixed(2)}
                  <span className="ml-1">{data.currency}</span>
                </p>
              </div>
            ))}

            {data?.discount_amount !== 0 && (
              <div
                style={{ color: '#b5b5b5' }}
                className="flex justify-between text-xs mt-2  md:mt-6"
              >
                <p>Coupon Discount</p>
                <p>{`- ${parseFloat(data?.discount_amount).toFixed(2)} ${data.currency}`}</p>
              </div>
            )}

            {data?.tax_amount !== 0 && (
              <div
                style={{ color: '#b5b5b5' }}
                className="flex justify-between text-xs mt-2  md:mt-6"
              >
                <p>{t('Tax')} </p>
                <p>{`${parseFloat(data?.tax_amount).toFixed(2)} ${data.currency}`}</p>
              </div>
            )}

            <div
              className="flex justify-between text-xs mt-2  md:mt-6 pt-2.5"
              style={{ borderTop: '0.5px solid #707070' }}
            >
              <p>{t('total_amount_paid')}</p>
              <p>{`${parseFloat(data?.transaction_amount).toFixed(2)}  ${data.currency}`}</p>
            </div>
            <div className="mt-4 md:mt-10 flex justify-center md:text-lg font-bold">
              <button
                style={{
                  backgroundColor: '#bbfa33',
                  color: '#0d0d0d',
                  width: '190px',
                }}
                className=" py-1 rounded focus:outline-none"
                onClick={onCancel}
              >
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ViewTransactionDetailModal)

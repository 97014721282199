import React from 'react'

const Setting3 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 20.01 18.878">
      <path
        data-name="setting-2-svgrepo-com (1)"
        d="M20.1 9.221c-1.81 0-2.55-1.28-1.65-2.85a1.9 1.9 0 0 0-.7-2.59l-1.73-.99a1.669 1.669 0 0 0-2.28.6l-.11.19c-.9 1.57-2.38 1.57-3.29 0l-.11-.19a1.641 1.641 0 0 0-2.26-.6l-1.73.99a1.908 1.908 0 0 0-.7 2.6c.91 1.56.17 2.84-1.64 2.84a1.906 1.906 0 0 0-1.9 1.9v1.76a1.906 1.906 0 0 0 1.9 1.9c1.81 0 2.55 1.28 1.64 2.85a1.9 1.9 0 0 0 .7 2.59l1.73.99a1.669 1.669 0 0 0 2.28-.6l.11-.19c.9-1.57 2.38-1.57 3.29 0l.11.19a1.669 1.669 0 0 0 2.28.6l1.73-.99a1.9 1.9 0 0 0 .7-2.59c-.91-1.57-.17-2.85 1.64-2.85a1.906 1.906 0 0 0 1.9-1.9v-1.76a1.921 1.921 0 0 0-1.91-1.9zm-8.1 6.03A3.25 3.25 0 1 1 15.25 12 3.256 3.256 0 0 1 12 15.251z"
        transform="translate(-2 -2.563)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Setting3

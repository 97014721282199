import React, { useContext } from 'react'
import { withTheme } from 'theming'
import { getReleaseDate, isEmpty } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'
import { createCustomDefinedFilter } from '../../utils/filterPayloadCreator'
import { encodeURI } from 'js-base64'

const PlaylistAbout = (props) => {
  const {
    venues = [],
    description = '',
    releaseDate = '',
    artists = [],
    category = [],
    sub_category = [],
    custom_metadata = [],
    theme,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isCustomTemplate } = useContext(AppContext)

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleSubCategory = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleCustomMetadata = (id, value, name) => {
    value = value.trim()
    const encoded = encodeURI(JSON.stringify(createCustomDefinedFilter(id, name, value)))
    navigate(`/content/filter/${encodeURI(value)}-${encodeURI(name)}?filters=${encoded}`)
  }

  return (
    <div>
      <div
        className="mt-10 2xl:text-lg  lg:text-base sm:text-sm text-xs"
        style={{ color: '#cecece' }}
      >
        <p className={releaseDate ? '' : 'pb-6'}>{description}</p>
        {releaseDate && (
          <div className={'flex mt-2 pb-6'}>
            <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={' font-semibold'}>
              {t('release_date')} :
            </p>
            <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={'ml-2'}>
              {getReleaseDate(releaseDate)}
            </p>
          </div>
        )}
        <div
          className="description-details md:flex-row flex-col gap-y-3 md:flex-wrap"
          style={{
            display: 'flex',
            // gap: '12px 0',
          }}
        >
          <div className={'flex md:w-1/2'}>
            <p
              style={{ color: isCustomTemplate ? theme.c4 : '#fff', marginBlockEnd: '0' }}
              className={'lg:w-2/12 w-5/12'}
            >
              {t('category')}
            </p>
            <div
              style={{
                color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)',
                marginBlockEnd: '0',
              }}
              className={'w-7/12 lg:w-10/12 flex flex-wrap'}
            >
              {[...category].map((item, index) => (
                <p
                  key={index}
                  onClick={() => handleCategoryClick(item.id, item.name)}
                  className={'cursor-pointer hover:underline'}
                  style={{ marginBlock: '0' }}
                >
                  {index !== 0 && <span>{' , '}</span>}
                  {item.name}
                </p>
              ))}
            </div>
          </div>
          {!isEmpty(sub_category) && (
            <div className={'flex md:w-1/2'}>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'lg:w-2/12 w-5/12'}
              >
                {t('genre')}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                className={'w-7/12 lg:w-10/12 flex flex-wrap'}
              >
                {sub_category.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => handleSubCategory(item.id, item.name)}
                    className={'cursor-pointer hover:underline'}
                  >
                    {index !== 0 && <span>{' , '}</span>}
                    {item.name}
                  </p>
                ))}
              </div>
            </div>
          )}
          {Object.keys(artists).map((artist, index) => (
            <div className={'flex md:w-1/2'} key={index}>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'lg:w-2/12 w-5/12'}
              >
                {artist}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                className={'w-7/12 lg:w-10/12 flex flex-wrap'}
              >
                {artists[artist].map((item, index) => (
                  <p
                    key={index}
                    onClick={() => navigate(`/artist/detail/${item.id}`)}
                    className={'cursor-pointer hover:underline'}
                  >
                    {index !== 0 && <span>{' , '}</span>}
                    {item.name}
                  </p>
                ))}
              </div>
            </div>
          ))}

          {!isEmpty(venues) && (
            <div className={'flex md:w-1/2'}>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'lg:w-2/12 w-5/12'}
              >
                {t('venue')}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                className={' w-7/12 lg:w-10/12 flex flex-wrap'}
              >
                {venues.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => navigate(`/venue/detail/${item.id}`)}
                    className={'cursor-pointer hover:underline'}
                  >
                    {index !== 0 && <span>{' , '}</span>}
                    {item.name}
                  </p>
                ))}
              </div>
            </div>
          )}
          {!isEmpty(custom_metadata) &&
            custom_metadata.map((item, index) => (
              <div className={'flex md:w-1/2'} key={index}>
                <p
                  style={{
                    color: isCustomTemplate ? theme.c4 : '#fff',
                    marginBlock: '0',
                    height: 'min-content',
                  }}
                  className={'lg:w-2/12 w-5/12'}
                >
                  {item.name}
                </p>
                <div
                  style={{
                    color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)',
                    height: 'min-content',
                  }}
                  className={'w-7/12 lg:w-10/12 flex flex-wrap justify-start items-start'}
                >
                  {item.value.split(',').map((obj, index) => (
                    <p
                      key={index}
                      onClick={() => handleCustomMetadata(item.id, obj, item.name)}
                      className={'cursor-pointer  hover:underline'}
                      style={{ marginBlock: '0', background: 'transparent' }}
                    >
                      {index !== 0 && <span>{' , '}</span>}
                      {obj}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default withTheme(PlaylistAbout)

import React from 'react'

const BackIconImg = ({ color = '#fff', imgClass = '', onClick = () => {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.117"
      height="20.771"
      viewBox="0 0 12.117 20.771"
      className={imgClass}
      onClick={onClick}
    >
      <path
        d="M17.61 19.61a1.731 1.731 0 0 1-2.448 0l-8.655-8.655a1.731 1.731 0 1 1 2.448-2.448l7.431 7.431 7.431-7.431a1.731 1.731 0 1 1 2.448 2.448z"
        transform="rotate(90 13.059 7.059)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default BackIconImg

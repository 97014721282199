import React from 'react'

const RadioButtonChecked = ({ color = '#bbfa33', imgClass = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      className={imgClass}
    >
      <g data-name="Layer 2">
        <path
          data-name="Path 900"
          d="M8.564 2a6.564 6.564 0 1 0 6.564 6.564A6.564 6.564 0 0 0 8.564 2zm0 11.815a5.251 5.251 0 1 1 5.251-5.251 5.251 5.251 0 0 1-5.251 5.251z"
          transform="translate(-.687 -.764)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 901"
          d="M10.282 7a3.282 3.282 0 1 0 3.282 3.282A3.282 3.282 0 0 0 10.282 7z"
          transform="translate(-2.405 -2.482)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default RadioButtonChecked

import React from 'react'

const FiltersLogo = ({ color = '#0ff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.587"
      height="16.559"
      viewBox="0 0 16.587 16.559"
    >
      <path
        data-name="Layer 15"
        d="M3.395 5.786H6.2a2.37 2.37 0 0 0 4.668 0h8.329a.395.395 0 0 0 0-.79h-8.334A2.37 2.37 0 0 0 6.2 5H3.4a.395.395 0 0 0 0 .79zm5.134-1.975a1.58 1.58 0 1 1-1.58 1.58 1.58 1.58 0 0 1 1.58-1.58zm10.663 7.109h-2.8a2.37 2.37 0 0 0-4.668 0H3.395a.395.395 0 0 0 0 .79h8.329a2.37 2.37 0 0 0 4.668 0h2.8a.395.395 0 0 0 0-.79zm-5.134 1.975a1.58 1.58 0 1 1 1.58-1.58 1.58 1.58 0 0 1-1.58 1.58zm5.134 3.949h-8.329a2.37 2.37 0 0 0-4.668 0h-2.8a.395.395 0 0 0 0 .79H6.2a2.37 2.37 0 0 0 4.668 0h8.329a.395.395 0 0 0 0-.79zM8.529 18.819a1.58 1.58 0 1 1 1.58-1.58 1.58 1.58 0 0 1-1.58 1.58z"
        transform="translate(-3 -3.035)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default FiltersLogo

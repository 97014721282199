import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { isEmpty, PAGE_SIZE } from '../../utils/helper'
import { useParams } from 'react-router'
import useAxios from 'axios-hooks'
import ContentListComponent from './contentListComponent'
import { CONTENT_FILTERING } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { decode } from 'js-base64'

let totalRecords = 0
let currentPage = 1
let apiHit = false
const ContentListing = () => {
  const title = decode(useParams().filterName.split('-')[0])
  const [searchParams] = useSearchParams()
  const { setLoader } = useContext(AppContext)
  const [dataList, setDataList] = useState([])

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  const hitAPI = (page) => {
    const filters = searchParams.get('filters')
    if (!isEmpty(filters)) {
      const payload = JSON.parse(decode(filters))
      payload['page_size'] = PAGE_SIZE
      payload['page'] = page
      apiHit = true
      contentFilterCall({
        data: payload,
      })
    }
  }

  useEffect(() => {
    hitAPI(1)
    currentPage = 1
    setDataList([])
    totalRecords = 0
  }, [title])

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList((dataList) => [...dataList, ...contentList.data])
      totalRecords = contentList.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    setLoader(contentListLoading)
  }, [contentListLoading])

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [title])

  const infiniteScroll = () => {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.7 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1
        hitAPI(currentPage)
      }
    }
  }

  return <ContentListComponent title={title} data={dataList} />
}

export default ContentListing

import React from 'react'

const PlayPause6 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 11.779 16.5"
        >
          <path
            data-name="Path 1044"
            d="M0 0h4.772v16.5H0V0zm7.007 0h4.772v16.5H7.007V0z"
            style={{ fill: primaryColor, fillRule: 'evenodd' }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 13.068 17.416"
        >
          <path
            data-name="Path 1045"
            d="M13.068 8.642 0 17.416V0z"
            style={{ fill: primaryColor, fillRule: 'evenodd' }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause6

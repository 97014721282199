import React from 'react'

const Subtitle2 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 18.843 15.074"
    >
      <path
        data-name="subtitles-svgrepo-com (1)"
        d="M2 11.537C2 7.984 2 6.208 3.1 5.1S5.984 4 9.537 4h3.769c3.553 0 5.33 0 6.433 1.1s1.1 2.88 1.1 6.433 0 5.33-1.1 6.433-2.88 1.1-6.433 1.1H9.537c-3.553 0-5.33 0-6.433-1.1S2 15.09 2 11.537zM5.769 14.6a.707.707 0 0 0 0 1.413h3.768a.707.707 0 0 0 0-1.413zm1.649-2.12a.707.707 0 0 0-.707-.707h-.942a.707.707 0 0 0 0 1.413h.942a.707.707 0 0 0 .706-.707zm3.533-.707a.707.707 0 0 1 0 1.413H9.066a.707.707 0 0 1 0-1.413zm6.831.707a.707.707 0 0 0-.707-.707h-3.769a.707.707 0 0 0 0 1.413h3.769a.707.707 0 0 0 .706-.707zm-5.89 2.12a.707.707 0 0 0 0 1.413h1.413a.707.707 0 0 0 0-1.413zm3.062.707a.707.707 0 0 1 .707-.707h1.413a.707.707 0 0 1 0 1.413h-1.413a.707.707 0 0 1-.707-.707z"
        transform="translate(-2 -4)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Subtitle2

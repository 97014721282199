import React from 'react'

const Resolution2 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 24 24"
    >
      <path
        data-name="Path 1119"
        d="M21.513 13H20v4.539h1.513a.756.756 0 0 0 .756-.756v-3.027a.756.756 0 0 0-.756-.756z"
        transform="translate(-4.871 -2.903)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1120"
        d="M21.668 6H3.513A1.513 1.513 0 0 0 2 7.513v12.1a1.513 1.513 0 0 0 1.513 1.513h18.155a1.513 1.513 0 0 0 1.513-1.513v-12.1A1.513 1.513 0 0 0 21.668 6zm-9.834 11.347h-1.513v-3.026H8.052v3.026H6.539V9.782h1.513v3.026h2.269V9.782h1.513zm7.566-2.27a2.269 2.269 0 0 1-2.269 2.269H14.1V9.782h3.026a2.269 2.269 0 0 1 2.274 2.27z"
        transform="translate(-.487 -1.198)"
        style={{ fill: color }}
      />
      <path data-name="<Transparent Rectangle>" style={{ fill: 'none' }} d="M0 0h24v24H0z" />
    </svg>
  )
}

export default Resolution2

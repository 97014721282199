import React from 'react'

const Backward7 = ({ color = '#323232' }) => {
  return (
    <svg
      data-name="Group 1163"
      xmlns="http://www.w3.org/2000/svg"
      width="20.533"
      height="20.533"
      viewBox="0 0 20.533 20.533"
    >
      <path
        d="M10.164 7.821a.716.716 0 0 1 .406.646v6.685a.716.716 0 0 1-1.433 0V9.957l-1.224.979a.716.716 0 0 1-.895-1.119l2.388-1.91a.716.716 0 0 1 .758-.086zm3.749 1.362a.955.955 0 0 0-.955.955v3.342a.955.955 0 0 0 1.91 0v-3.342a.955.955 0 0 0-.955-.955zm-2.388.955a2.388 2.388 0 0 1 4.775 0v3.342a2.388 2.388 0 0 1-4.775 0z"
        transform="translate(-1.258 -1.542)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
      <path
        data-name="rewind-10-seconds-forward-svgrepo-com"
        d="M21.784 11.517A10.267 10.267 0 0 0 11.517 1.25a.716.716 0 0 0-.559 1.164L12.867 4.8a.716.716 0 0 0 1.119-.895l-.862-1.078a8.832 8.832 0 1 1-5.14.589A.716.716 0 1 0 7.41 2.1a10.268 10.268 0 1 0 14.374 9.412z"
        transform="translate(-1.25 -1.25)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Backward7

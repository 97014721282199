import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { ARTIST_LISTING, CONTENT_FILTERING, GET_CATEGORY, VENUE_LISTING } from '../../utils/apiUrls'
import { useParams } from 'react-router'
import { groupBy, isEmpty } from 'lodash'
import Dropdown from '../../components/filterDropdown/filterDropdown'
import { getImage } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import VenuePlaceholder from '../../assets/venue-placeholder.png'
import ShowMoreText from 'react-show-more-text'
import ReviewsListing from '../../components/reviewRating'
import RatingReviewWidget from '../../components/reviewRating/ratingReviewWidget'
import { withTheme } from 'theming'
import VenueWrapper from './_venueDetailStyle'
import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'

const VenueDetail = ({ theme }) => {
  const { t } = useTranslation()
  const venueId = useParams().venueId
  const { setLoader, accountObj, modulePermissions, isCustomTemplate, isBrowser } =
    useContext(AppContext)
  const [venueDetail, setVenueDetail] = useState({})
  const [venueContents, setVenueContent] = useState({})
  const [genreData, setGenreData] = useState([])
  const [contentPayload, setContentPayload] = useState({
    ...createSystemDefinedFilter('venues', [venueId]),
    order_by: 'created_date',
    reverse: true,
    page_size: '100',
    page: '1',
  })

  const [{ data: artistList, loading: artistListLoading }] = useAxios({
    url: ARTIST_LISTING,
    method: 'get',
  })

  const [{ data: venueData, loading: venueDataLoading }, venueDetailCall] = useAxios({
    url: `${VENUE_LISTING}/detail/${venueId}`,
    method: 'get',
  })

  const [{ data: categoryRes, loading: categoryLoading }] = useAxios({
    url: GET_CATEGORY,
    method: 'get',
  })

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(venueDataLoading || artistListLoading || contentListLoading || categoryLoading)
  }, [venueDataLoading, artistListLoading, contentListLoading, categoryLoading])

  useEffect(() => {
    contentFilterCall({
      data: contentPayload,
    })
  }, [contentPayload])

  useEffect(() => {
    if (venueData && venueData.success) {
      setVenueDetail(venueData.data)
    }
  }, [venueData])

  useEffect(() => {
    if (categoryRes && categoryRes.success) {
      setGenreData(categoryRes.data.filter((obj) => obj.type === 'sub_category'))
    }
  }, [categoryRes])

  useEffect(() => {
    if (contentList && contentList.success) {
      setVenueContent(groupBy(contentList.data, (content) => content.category[0].name))
    }
  }, [contentList])

  const handleFilterChange = (filterPayload) => {
    setContentPayload({ ...contentPayload, filters: filterPayload })
  }
  return (
    <div>
      <VenueWrapper theme={theme}>
        <>
          <div className="flex flex-col md:flex">
            {isBrowser ? (
              <div className="venue-detail-image md:visible">
                <div className="venue-detail-image-div">
                  <CustomImage
                    imageUrl={getImage(accountObj?.cf_domain_name, venueDetail?.thumbnail)}
                    loaderClass="content-loader"
                    imgAlt={'content'}
                    errorPlaceholder={VenuePlaceholder}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center">
                <CustomImage
                  imageUrl={getImage(accountObj?.cf_domain_name, venueDetail?.thumbnail)}
                  imgAlt={'content'}
                  imgClass={`object-fill full-img-content  w-screen`}
                  loaderClass="full-img-content"
                  errorPlaceholder={VenuePlaceholder}
                />
              </div>
            )}

            <div
              className="mt-15 xl:mt-20 z-10 xl:absolute pl-5 md:pl-10 xl:pl-20 w-11/12"
              style={{ color: '#e3e2e2' }}
            >
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'text-3xl md:text-4xl font-semibold'}
              >
                {venueDetail?.name}
              </p>
              {modulePermissions?.rating && (
                <RatingReviewWidget
                  value={venueDetail.average_rating}
                  ratingCount={venueDetail.review_count}
                />
              )}
              <ShowMoreText
                lines={4}
                more="Show more"
                less="Show less"
                className={`mt-5 xl:w-3/5 w-full text-lg mr-5 description-color`}
                anchorClass={`text-sm description-see-more-color`}
                expanded={false}
                truncatedEndingComponent={'... '}
              >
                {venueDetail?.description}
              </ShowMoreText>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="mt-2 md:mt-4 md:w-2/5 text-lg mr-5 line-clamp-4"
              >
                {venueDetail?.address}
              </p>
              <div className={'md:w-3/5  mt-3 '}>
                {venueDetail.custom_metadata &&
                  venueDetail.custom_metadata.map((item, index) => (
                    <div className={'flex'} key={index}>
                      <p
                        style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                        className={' font-semibold w-4/12 md:w-2/12'}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                        className={' font-semibold w-8/12 md:w-10/12'}
                      >
                        {item.value}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
        <div className="w-full mt-2">
          <div className="w-full px-5 md:px-10 xl:px-20">
            <div
              style={{
                color: isCustomTemplate ? theme.c4 : '#fff',
                borderColor: isCustomTemplate ? theme.c4 : '',
              }}
              className="w-full py-2 flex justify-end  border-b-2 "
            >
              <div className="flex justify-between md:mr-5">
                {!isEmpty(artistList?.data) && (
                  <Dropdown
                    title={t('artist')}
                    options={artistList?.data}
                    filterKey={'artist'}
                    filterFunction={handleFilterChange}
                    payload={contentPayload.filters}
                  />
                )}
                {!isEmpty(genreData) && (
                  <Dropdown
                    title={t('genre')}
                    options={genreData}
                    filterKey={'sub_category'}
                    filterFunction={handleFilterChange}
                    payload={contentPayload.filters}
                  />
                )}
              </div>
              {!isEmpty(genreData) || !isEmpty(artistList?.data) ? (
                <button
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="px-5 rounded-lg md:px-2"
                  onClick={() =>
                    handleFilterChange({
                      venue: [`${venueId}`],
                      artist: [],
                      sub_category: [],
                    })
                  }
                >
                  {t('clear')}
                </button>
              ) : null}
            </div>
          </div>
          <div className="mt-10">
            {!isEmpty(venueContents) &&
              Object.keys(venueContents).map((key, index) => {
                let data = {
                  id: '0330d0e-7523-14e5-784-da18542fd05',
                  component_key: 'newDefaultSwimlane',
                  category: 'card_carousel',
                  config: {},
                  data: {
                    name: key,
                    type: 'STATIC',
                    content_data: venueContents[key].map((info) => info.content_id),
                    auto_payload: [],
                    order_by: '',
                    reverse: '',
                    style_config: {},
                  },
                }
                return (
                  <div className={'md:mt-2 lg:mt-10 2xl:mt-16'} key={index}>
                    <NewDefaultSwimlane
                      componentData={data}
                      modifyData={() => {}}
                      setModalState={() => {}}
                      isEdit={false}
                      onSwap={() => {}}
                      onDelete={() => {}}
                      onCopy={() => {}}
                      onAdd={() => {}}
                    />
                  </div>
                )
              })}
          </div>
          {modulePermissions?.rating && (
            <div
              className={'border-t-2'}
              style={{
                marginTop: '100px',
                padding: '50px',
                borderTop: `1.5px solid ${theme.c5}`,
              }}
            >
              <ReviewsListing
                type={'venues'}
                id={venueId}
                title={venueDetail?.name}
                isRefreshed={venueDataLoading}
                onReviewSubmitted={venueDetailCall}
              />
            </div>
          )}
        </div>
      </VenueWrapper>
    </div>
  )
}

export default withTheme(VenueDetail)

import React from 'react'

const Volume2Right = ({ color = '#000', clickFunc = () => {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      onClick={clickFunc}
      viewBox="0 0 12.257 10.668"
    >
      <path
        d="M10.5 8.563h3.46m-1.73-1.73v3.46m-8.3-3.46h.52a2.493 2.493 0 0 0 .624-.04 1.153 1.153 0 0 0 .364-.171 2.493 2.493 0 0 0 .43-.454l1.506-1.816c.251-.3.377-.452.487-.468a.288.288 0 0 1 .255.092c.074.082.074.279.074.671v7.832c0 .392 0 .589-.074.671a.288.288 0 0 1-.255.092c-.11-.016-.235-.166-.487-.468L5.86 10.957a2.493 2.493 0 0 0-.43-.454 1.153 1.153 0 0 0-.364-.171 2.492 2.492 0 0 0-.624-.04h-.52a1.433 1.433 0 0 1-.608-.063.577.577 0 0 1-.252-.252A1.433 1.433 0 0 1 3 9.371V7.756a1.433 1.433 0 0 1 .063-.608.577.577 0 0 1 .252-.248 1.433 1.433 0 0 1 .608-.067z"
        transform="translate(-2.35 -3.229)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
    </svg>
  )
}

export default Volume2Right

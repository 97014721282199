import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { withTheme } from 'theming'
import EmailIcon from '../../../assets/email-icon.svg'
import PhoneIcon from '../../../assets/phone-icon.svg'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'

const ShadowBGFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onSwap,
}) => {
  library.add(fab)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <footer
      className={`relative z-20 w-full ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c6 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div
        className="w-full 2xl:px-20 xl:px-14 md:px-10 px-[16px] sm:h-[10vh] h-[60px] relative"
        style={{ backgroundColor: theme.c6 }}
      >
        <div
          className="w-full h-20 absolute left-0 top-full"
          style={{
            backgroundImage: 'linear-gradient(to bottom, #171717, rgba(0, 0, 0, 0))',
            zIndex: '100',
          }}
        ></div>
        <div className="w-32 h-full text-center flex flex-col justify-center items-start">
          <CustomImage
            imageUrl={getTemplateImage(data?.image_s3key)}
            imgClass="z-0 xl:w-[88px] xl:h-[50.44px] md:w-[85px] md:h-[48.72px] w-[43px] h-[29px] sm:ml-[2px] ml-[4px]"
            loaderClass="z-0 w-full h-2/3"
            loaderStyle={{
              background: '#5A5A5A50',
            }}
            imgAlt={'class img"'}
          />
        </div>
      </div>
      <div className="relative overflow-hidden w-full flex justify-between xl:flex-row-reverse flex-col-reverse sm:pt-10">
        <CustomImage
          imageUrl={getTemplateImage(data?.bg_image_s3key)}
          imgClass="z-0 md:w-full w-[270%] md:max-w-full max-w-[270%] md:h-full sm:h-auto md:object-cover absolute top-0"
          loaderClass="z-0 md:w-full w-[270%] md:h-full md:max-w-full max-w-[270%] sm:h-auto md:object-cover absolute top-0"
          loaderStyle={{
            background: '#5A5A5A50',
          }}
          imgAlt={'class img"'}
          imgStyle={{
            zIndex: '-20',
            top: '0%',
          }}
        />
        {/* sitemap code */}
        <div className="xl:w-3/5 w-full sm:mt-10 mt-[8px] xl:pb-20 pb-10">
          <div className="2xl:px-20 xl:px-14 md:px-10 px-[16px] xl:pb-10 xl:pt-10 md:flex md:flex-row flex-col md:items-baseline items-center xl:justify-start sm:justify-between xl:border-l-2 border-l-0 border-r-0 border-t-0 border-b-0 border-white border-solid">
            {!isEmpty(data.site_map) &&
              data?.site_map?.map(
                (siteMapItem, idx) =>
                  (siteMapItem?.title.trim().length > 0 ||
                    siteMapItem?.sub_heading_data.some((item) => item.text.trim().length > 0)) && (
                    <div
                      className="md:block flex flex-col items-center md:w-1/4 w-[auto]"
                      key={idx}
                    >
                      {siteMapItem?.title.length > 0 && (
                        <h3
                          className="font-semibold sm:mb-2.5 mb-[8px] xl:text-sm md:text-xs text-[10px] sm:mt-0 mt-[12px]"
                          style={{ color: theme.c2 }}
                        >
                          {siteMapItem.title}
                        </h3>
                      )}
                      {!isEmpty(siteMapItem?.sub_heading_data) &&
                        siteMapItem?.sub_heading_data.map((siteSubItem, idx) => (
                          <span
                            href="#"
                            className=" font-light block sm:mb-2.5 mb-[8px]  xl:text-sm md:text-xs text-[10px]"
                            style={{ textDecoration: 'none', color: theme.c7 }}
                            key={idx}
                            onClick={() => handleRedirect(siteSubItem.link)}
                          >
                            {siteSubItem.text}
                          </span>
                        ))}
                    </div>
                  ),
              )}
          </div>
        </div>
        <div
          className="2xl:w-auto xl:w-2/5 w-full xl:block flex flex-col items-center md:p-0 p-[16px] 2xl:ml-20 xl:ml-14 md:ml-10 ml-[0px]"
          style={{ color: theme.c7 }}
        >
          {/* email form with title*/}
          {data?.email_form?.enabled && (
            <>
              <p
                className="md:mt-8 mt-[16px] xl:text-xl sm:text-base text-[10px] xl:text-left md:text-center text-left w-full"
                style={{ color: theme.c7 }}
              >
                {data.email_form?.title}
              </p>
              <div
                style={{ borderColor: theme.c2 }}
                className="md:mt-8 mt-0 sm:border border-0 rounded-lg xl:w-full sm:w-[490px] w-full xl:h-[56px] sm:h-[53px] h-[100px] flex sm:flex-row flex-col"
              >
                <input
                  placeholder={data.email_form?.placeholder_text}
                  type="text"
                  className="pl-[8px] bg-transparent  2xl:text-xl xl:text-base text-xs 2xl:w-[380px] xl:w-[57%] sm:w-[70%] w-full h-full  placeholder:italic placeholder:text-gray-300 rounded-[inherit] border sm:border-none"
                  disabled={isEdit}
                  style={{
                    borderColor: theme.c2,
                    color: theme.c7,
                  }}
                />
                <button
                  className="sm:w-auto xl:px-4 px-[1px] w-full h-full font-semibold rounded-[inherit] sm:mt-0 mt-[12px] 2xl:text-xl xl:text-base text-xs"
                  style={{
                    backgroundColor: theme.c2,
                    color: theme.c5,
                    // border: `1px solid ${theme.c2}`,
                  }}
                  onClick={() => handleRedirect(data?.email_form?.link)}
                  disabled={isEdit}
                >
                  {data.email_form?.button_text}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="pl-1 w-6 h-6 inline"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>{' '}
                </button>
              </div>
            </>
          )}
          {/* social media handles */}
          <ul
            className="sm:mt-8 mt-[16px] flex justify-start items-center xl:text-2xl md:text-xl text-lg h-9"
            style={{ listStyle: 'none', padding: 0 }}
          >
            {data?.social_handles.map((socialHandleItem, idx) => (
              <li key={idx} className="mr-[8px] last:mr-0">
                <a
                  href={isEdit ? '#' : `${socialHandleItem.url}`}
                  target={isEdit ? '' : '_blank'}
                  rel="noopener noreferrer"
                  className="i0jNr selectable-text copyable-text"
                  style={{ textDecoration: 'none', color: theme.c7 }}
                >
                  <FontAwesomeIcon
                    icon={['fab', `${socialHandleItem.name.toLowerCase()}`]}
                    size="1x"
                    className="mx-1"
                  />
                </a>
              </li>
            ))}
          </ul>
          {/* contact mail */}
          <p className="sm:mt-[8px] mt-[2px] flex items-center gap-4 2xl:text-xl xl:text-lg sm:text-sm text-xs">
            <a
              href={isEdit ? '#' : `mailto:${data.email}`}
              title={`mailto:${data.email}`}
              rel="noopener noreferrer"
              className="i0jNr selectable-text copyable-text"
              style={{ textDecoration: 'none', color: theme.c7 }}
            >
              {data?.email.length > 0 && (
                <>
                  <img src={EmailIcon} alt="email-icon" className="inline" />
                  <span style={{ marginLeft: '15px' }}>{data.email}</span>
                </>
              )}
            </a>
          </p>
          {/* contact phone / mobile */}
          <p className="sm:mt-[8px] mt-[2px] flex items-center gap-4 2xl:text-xl xl:text-lg sm:text-sm text-xs">
            <a
              href={isEdit ? '#' : `tel:${data.phone}`}
              style={{ textDecoration: 'none', color: theme.c7 }}
            >
              {data?.phone.length > 0 && (
                <>
                  <img src={PhoneIcon} alt="phone-icon" className="inline" />
                  <span style={{ marginLeft: '15px' }}>{data.phone}</span>
                </>
              )}
            </a>
          </p>
          <p className="absolute text-right xl:mt-4 sm:mt-14 mt-6 sm:mb-[16px] mb-[8px] md:text-base sm:text-sm text-[10px] sm:right-[7%] right-[50%] sm:bottom-0 bottom-[2%] transform sm:transform-none translate-x-1/2">
            {data?.copyright_text}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default withTheme(ShadowBGFooter)

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { withTheme } from 'theming'
import EmailIcon from '../../../assets/email-icon.svg'
import PhoneIcon from '../../../assets/phone-icon.svg'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import { getExternalLink } from '../templateFunctions'

const ConventionalFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onSwap,
}) => {
  library.add(fab)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <footer
      className={`relative z-20 w-full ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c5 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div
        className="w-full 2xl:px-20 xl:px-14 md:px-10 px-[1rem] sm:h-[15vh] h-[107px] pt-[15px] flex justify-center md:justify-start items-center"
        style={{ backgroundColor: theme.c6 }}
      >
        <div className="w-32 sm:h-[90%] h-[95%] text-center flex flex-col justify-center items-center md:m-0 m-[auto] ">
          <img
            src={getTemplateImage(data?.image_s3key)}
            alt="company-logo"
            className="xl:w-[88px] xl:h-[50.44px] md:w-[85px] md:h-[48.72px] w-[82px] h-[47px] sm:ml-[2px] ml-[4px] cursor-pointer"
            onClick={() => customNavigate('DIRECT', '/home', isEdit)}
          />
          <p className="text-[10px] font-light md:mt-4" style={{ color: theme.c7 }}>
            #DISCIPLINE #DEDICATION
          </p>
        </div>
      </div>
      <div
        className="w-full min-h-[37vh] flex justify-between xl:flex-row flex-col"
        style={{ backgroundColor: theme.c5 }}
      >
        {/* sitemap code */}
        <div className="xl:w-3/5 w-full 2xl:px-20 xl:px-14 md:px-10 px-[1rem] sm:mt-8 mt-[8px] md:flex md:flex-row flex-col md:items-stretch items-center xl:justify-start sm:justify-between ">
          {!isEmpty(data.site_map) &&
            data?.site_map?.map(
              (siteMapItem, idx) =>
                (siteMapItem?.title.trim().length > 0 ||
                  siteMapItem?.sub_heading_data.some((item) => item.text.trim().length > 0)) && (
                  <div className="md:block flex flex-col items-center  md:w-1/4 w-full" key={idx}>
                    {siteMapItem?.title.length > 0 && (
                      <h3
                        className="font-medium sm:mb-4 mb-[8px] 2xl:text-xl xl:text-lg md:text-base sm:text-sm text-[10px] sm:mt-0 mt-[12px]"
                        style={{ color: theme.c2 }}
                      >
                        {siteMapItem.title}
                      </h3>
                    )}
                    {!isEmpty(siteMapItem?.sub_heading_data) &&
                      siteMapItem?.sub_heading_data.map((siteSubItem, idx) => (
                        <span
                          href="#"
                          className="block sm:mb-4 mb-[8px] 2xl:text-lg xl:text-base md:text-sm text-[10px] font-light"
                          style={{ textDecoration: 'none', color: theme.c7 }}
                          key={idx}
                          onClick={() => handleRedirect(siteSubItem.link)}
                        >
                          {siteSubItem.text}
                        </span>
                      ))}
                  </div>
                ),
            )}
        </div>
        <div
          className="2xl:w-auto xl:w-2/5 w-full xl:block flex flex-col items-center md:p-0 p-[16px] 2xl:mr-20 xl:mr-14 md:mr-10 mr-[0px]"
          style={{ color: theme.c7 }}
        >
          {data?.email_form?.enabled && (
            <>
              {/* email form with title*/}
              <p
                className="md:mt-8 mt-[16px] xl:text-xl sm:text-base text-[10px]"
                style={{ color: theme.c7 }}
              >
                {data.email_form?.title}
              </p>
              <div className="md:mt-8 sm:border border-0 rounded-lg xl:w-full sm:w-[490px] w-full xl:h-[56px] sm:h-[53px] h-[100px] flex sm:flex-row flex-col">
                <input
                  placeholder={data.email_form?.placeholder_text}
                  type="text"
                  className="pl-[8px] bg-transparent 2xl:text-xl xl:text-base text-xs 2xl:w-[380px] xl:w-[57%] sm:w-[70%] w-full h-full  placeholder:italic rounded-[inherit] border sm:border-none focus:outline-none"
                  disabled={isEdit}
                  style={{
                    border: `1px solid ${theme.c2}`,
                    color: theme.c7,
                  }}
                />
                <button
                  className="flex items-center justify-center xl:w-[43%] sm:w-auto xl:px-4 px-[1px] w-full h-full font-semibold rounded-[inherit] border sm:border-none sm:mt-0 mt-[12px] xl:text-lg md:text-base text-xs"
                  style={{
                    backgroundColor: theme.c2,
                    color: theme.c5,
                    border: `1px solid ${theme.c2}`,
                    // width: '150px',
                  }}
                  onClick={() => handleRedirect(data?.email_form?.link)}
                  disabled={isEdit}
                >
                  {data.email_form?.button_text}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="pl-1 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>{' '}
                </button>
              </div>
            </>
          )}
          {/* social media handles */}
          <ul
            className="sm:mt-8 mt-[16px] flex justify-start items-center xl:text-2xl md:text-xl text-lg h-9"
            style={{ listStyle: 'none', padding: 0 }}
          >
            {data?.social_handles.map((socialHandleItem, idx) => (
              <li key={idx} className="mr-[8px] last:mr-0">
                <a
                  href={isEdit ? '#' : getExternalLink(`${socialHandleItem.url}`)}
                  target={isEdit ? '' : '_blank'}
                  rel="noopener noreferrer"
                  className="i0jNr selectable-text copyable-text"
                  style={{ textDecoration: 'none', color: theme.c2 }}
                >
                  <FontAwesomeIcon
                    icon={['fab', `${socialHandleItem.name.toLowerCase()}`]}
                    size="1x"
                    className="mx-1"
                  />
                </a>
              </li>
            ))}
          </ul>
          {/* contact mail */}
          <p className="mt-[0.5rem] md:mt-4 flex items-center gap-4 2xl:text-xl xl:text-lg sm:text-sm text-xs">
            <a
              href={isEdit ? '#' : `mailto:${data.email}`}
              title={`mailto:${data.email}`}
              rel="noopener noreferrer"
              className="flex"
              style={{ textDecoration: 'none', color: theme.c7 }}
            >
              {data?.email.length > 0 && (
                <>
                  <img src={EmailIcon} alt="email-icon" />
                  <span style={{ marginLeft: '15px' }}>{data.email}</span>
                </>
              )}
            </a>
          </p>
          {/* contact phone / mobile */}
          <p className="mt-[0.5rem] md:mt-4 flex items-center gap-4 2xl:text-xl xl:text-lg sm:text-sm text-xs">
            <a
              href={isEdit ? '#' : `tel:${data.phone}`}
              className="flex"
              style={{ textDecoration: 'none', color: theme.c7 }}
            >
              {data?.phone.length > 0 && (
                <>
                  <img src={PhoneIcon} alt="phone-icon" />
                  <span style={{ marginLeft: '15px' }}>{data.phone}</span>
                </>
              )}
            </a>
          </p>
          <p className="font-thin text-right xl:mt-4 sm:mt-14 mt-6 sm:mb-[16px] mb-[8px] md:text-base sm:text-sm text-[10px]">
            {data?.copyright_text}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default withTheme(ConventionalFooter)

import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../context/app'
import { SubscriptionContext } from '../../../context/subscription'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import useAxios from 'axios-hooks'
import { CONTENT_FILTERING, CONTENT_WATCH_URL, DETAIL_CONTENT } from '../../../utils/apiUrls'
import PlaylistPlayer from '../../../container/player/playlistPlayer'
import LockIcon from '../../../assets/lock-icon.svg'

let currentIndexGlobal = 0
const TemplatePlaylistPlayer = ({ data, controlBar }) => {
  const playlistId = data.id
  const { isLogin, setLoader, setOpenLoginModal, setModalType } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const [detailObj, setDetailObj] = useState({})
  const [playListContents, setPlayListContents] = useState([])
  const [currentPlayingContent, setCurrentPlayingContent] = useState({})
  const [videoSource, setVideoSource] = useState(null)
  const [isPlaylistSubscribed, setIsPlaylistSubscribed] = useState(true)
  const [processingLoader, setProcessingLoader] = useState(false)

  const [{ data: contentWatchUrl }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: playlistDetail, loading: playlistLoading }, playlistDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${playlistId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: playlistcontentLoading }, playlistcontentCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    playlistDetailCall()
    setProcessingLoader(true)
  }, [playlistId])

  useEffect(() => {
    setLoader(playlistLoading || playlistcontentLoading || processingLoader)
  }, [playlistLoading, playlistcontentLoading, processingLoader])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setVideoSource(contentWatchUrl?.data?.signed_url)
    }
  }, [contentWatchUrl])

  useEffect(async () => {
    if (playlistDetail && playlistDetail.success) {
      const contentObj = playlistDetail.data
      setDetailObj(contentObj)
      let playloadObj =
        contentObj?.playlist_data?.playlist_type === 'MANUAL'
          ? {
              filters: {
                content_ids: contentObj?.playlist_data?.content_data,
                video_types: ['SPV', 'MPVC'],
              },
            }
          : {
              filters: {
                system_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'system_defined',
                ),
                custom_defined: contentObj?.playlist_data?.auto_payload.filter(
                  (info) => info.type === 'custom_defined',
                ),
                video_types: ['SPV', 'MPVC'],
              },
              order_by: 'modified_date',
              reverse: false,
            }

      let playlistRes = await playlistcontentCall({
        data: {
          ...playloadObj,
          page: 1,
          page_size: 0,
        },
      })
      let playlistcontent = await playlistcontentCall({
        data: {
          ...playloadObj,
          page: 1,
          page_size: playlistRes.data.total,
        },
      })

      if (playlistcontent.data && playlistcontent.data?.success) {
        let sortedData =
          contentObj?.playlist_data?.playlist_type === 'MANUAL'
            ? playlistcontent.data?.data.sort((a, b) => {
                let sortingArr = contentObj?.playlist_data?.content_data
                return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
              })
            : playlistcontent.data?.data

        setPlayListContents(sortedData)
        if (!isEmpty(sortedData)) {
          setCurrentPlayingContent(sortedData[0])
        }
      }
      setProcessingLoader(false)
    }
    if (playlistDetail && !playlistDetail.success) {
      setProcessingLoader(false)
    }
  }, [playlistDetail])

  useEffect(async () => {
    if (!isEmpty(currentPlayingContent)) {
      if (await checkRestriction()) {
        getContentWatchUrl({ url: `${CONTENT_WATCH_URL}${currentPlayingContent.content_id}` })
      }
    }
  }, [currentPlayingContent, isLogin, activeSubscription])

  const checkPlanExist = (contentActiveSubscription, userSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, userSubscription, isEqual))
  }

  const checkRestriction = async (openModal = false) => {
    if (!detailObj.is_restricted) {
      if (detailObj.is_login_required && !isLogin) {
        setIsPlaylistSubscribed(false)
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        return false
      }
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsPlaylistSubscribed(false)
        return false
      } else {
        let contentSub = [
          ...detailObj.active_subscription.map((item) => item.id),
          ...detailObj.active_ppv_subscription.map((item) => item.id),
        ]

        const planExist = checkPlanExist(contentSub, activeSubscription)

        if (!planExist) {
          setIsPlaylistSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(contentSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const handleChangeVideo = (index) => {
    currentIndexGlobal = index
    setCurrentPlayingContent(playListContents[index])
  }
  const handlePlayingSequence = () => {
    let currentVideoIndex = currentIndexGlobal
    if (currentVideoIndex < 0) return
    handleChangeVideo(currentVideoIndex < playListContents.length - 1 ? currentVideoIndex + 1 : 0)
  }

  return (
    <div className="w-full h-full">
      {videoSource && (
        <PlaylistPlayer
          contentInfo={currentPlayingContent}
          contentWatchUrl={videoSource}
          resumeTime={'0'}
          handlePlayingSequence={handlePlayingSequence}
          disableHeader={true}
          controlBar={controlBar}
        />
      )}
      {!videoSource && !isPlaylistSubscribed && (
        <div className="border w-full h-full bg-black  flex justify-center items-center">
          <div className=" w-[70%] md:w-fit">
            <div className="flex px-5 md:px-0">
              <img
                src={LockIcon}
                style={{
                  width: '18.5px',
                  height: '24.6px',
                }}
                alt="LockIcon"
              />
              <p
                style={{ color: '#cecece' }}
                className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
              >
                This Playlist is not included in your current Membership Plan.
              </p>
            </div>
            <button
              style={{ background: '#09e9e9', color: '#000' }}
              className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
              onClick={() => checkRestriction(true)}
            >
              Upgrade your Membership Plan to Watch Now
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TemplatePlaylistPlayer

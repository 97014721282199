import React from 'react'
import Resolution1 from '../../../assets/customPlayer/resolution/resolution1'
import Resolution2 from '../../../assets/customPlayer/resolution/resolution2'
import Resolution3 from '../../../assets/customPlayer/resolution/resolution3'
import Resolution4 from '../../../assets/customPlayer/resolution/resolution4'
import Resolution5 from '../../../assets/customPlayer/resolution/resolution5'
import Resolution6 from '../../../assets/customPlayer/resolution/resolution6'
import Resolution7 from '../../../assets/customPlayer/resolution/resolution7'

const ResolutionButton = ({ color = '#fff', iconType }) => {
  let icons = {
    RESOLUTION1: (
      <>
        <Resolution1 color={color} />
      </>
    ),
    RESOLUTION2: (
      <>
        <Resolution2 color={color} />
      </>
    ),
    RESOLUTION3: (
      <>
        <Resolution3 color={color} />
      </>
    ),
    RESOLUTION4: (
      <>
        <Resolution4 color={color} />
      </>
    ),
    RESOLUTION5: (
      <>
        <Resolution5 color={color} />
      </>
    ),
    RESOLUTION6: (
      <>
        <Resolution6 color={color} />
      </>
    ),
    RESOLUTION7: (
      <>
        <Resolution7 color={color} />
      </>
    ),
  }

  return (
    <div style={{ maxWidth: '15px' }} className="flex justify-center items-center w-auto h-full">
      {icons[iconType]}
    </div>
  )
}

export default ResolutionButton

export default {
  name: 'PANTONE_RED',
  c1: '#D1E8E2',
  c2: '#FC3E32',
  c3: '#116466',
  c4: '#373737',
  c5: '#28302C',
  c6: '#222926',
  c7: '#FFFFFF',
  c8: '#de1003',
  c9: '#114f66',
  c10: '#cfcfcf',
  c11: '#093233',
}

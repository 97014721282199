import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import './css/output.css'
import './utils/i18n'
import './css/custom-tailwind-output.css'
import './css/custom-vjs-styles.css'
import App from './appContainer/app'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'

const Previous1 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height={12} viewBox="0 0 14.25 12">
      <path
        data-name="play-next-svgrepo-com (1)"
        d="M15.847 4.085a.75.75 0 0 0-.776.051l-7.5 5.25a.75.75 0 0 0 0 1.228l7.5 5.25a.75.75 0 0 0 1.18-.614V4.75a.75.75 0 0 0-.4-.665zM5.75 5.35A1.35 1.35 0 0 0 4.4 4H3.35A1.35 1.35 0 0 0 2 5.35v9.3A1.35 1.35 0 0 0 3.35 16H4.4a1.35 1.35 0 0 0 1.35-1.35v-9.3z"
        transform="translate(-2 -4)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Previous1

import React from 'react'

const Setting7 = ({ color = '#323232' }) => {
  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-auto"
      viewBox="0 0 20 20"
    >
      <g data-name="invisible box">
        <path data-name="Rectangle 1542" style={{ fill: 'none' }} d="M0 0h20v20H0z" />
      </g>
      <g data-name="icons Q2">
        <path
          data-name="Path 1046"
          d="M19.777 5.67h-8.39A2.588 2.588 0 0 0 9.009 4a2.5 2.5 0 0 0-2.338 1.67H4.918A.877.877 0 0 0 4 6.5a.877.877 0 0 0 .918.835h1.753a2.5 2.5 0 0 0 2.337 1.67 2.588 2.588 0 0 0 2.379-1.67h8.39A.877.877 0 0 0 20.7 6.5a.877.877 0 0 0-.918-.835z"
          transform="translate(-2.33 -2.33)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1047"
          d="M19.894 19.67h-1.711A2.588 2.588 0 0 0 15.8 18a2.5 2.5 0 0 0-2.337 1.67H5.035a.835.835 0 1 0 0 1.67h8.431a2.5 2.5 0 0 0 2.337 1.67 2.588 2.588 0 0 0 2.379-1.67h1.711a.835.835 0 0 0 0-1.67z"
          transform="translate(-2.447 -10.487)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1048"
          d="M19.894 33.67h-7.137a2.588 2.588 0 0 0-2.38-1.67 2.5 2.5 0 0 0-2.337 1.67H5.035a.835.835 0 1 0 0 1.67H8.04a2.5 2.5 0 0 0 2.337 1.67 2.588 2.588 0 0 0 2.379-1.67h7.137a.835.835 0 0 0 0-1.67z"
          transform="translate(-2.447 -18.643)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Setting7

import React, { useContext, useEffect, useState } from 'react'
import Swimlane from '../../components/swimlane/swimlane'
import { isEmpty } from '../../utils/helper'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import { TEMPLATE_URL, ARTIST_LISTING, VENUE_LISTING } from '../../utils/apiUrls'
import NewCarousel from '../../components/carousel/newCarousel'
import FullScreenLoader from '../../components/fullscreenLoader'

const Home = () => {
  const [bannerData, setBannerData] = useState([])
  const [swimlaneData, setSwimlaneData] = useState([])
  const [artistData, setArtistData] = useState([])
  const [venueData, setVenueData] = useState([])
  const { setLoader, accountObj, isCustomTemplate } = useContext(AppContext)
  const language = localStorage.getItem('language')
  const defaultLanguage = localStorage.getItem('default_language')

  const [{ data: templateConfig, loading: templateConfigLoading }, templateConfigCall] = useAxios(
    {
      url: TEMPLATE_URL,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: artistList, loading: artistListLoading }, artistListCall] = useAxios(
    {
      url: ARTIST_LISTING,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: venueList, loading: venueListLoading }, venueListCall] = useAxios(
    {
      url: VENUE_LISTING,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (language && defaultLanguage && !isEmpty(accountObj) && !isCustomTemplate) {
      templateConfigCall()
      artistListCall({
        params: {
          page_size: 10,
          page: 1,
        },
      })
      venueListCall({
        params: {
          page_size: 10,
          page: 1,
        },
      })
    }
  }, [language, defaultLanguage, accountObj])

  useEffect(() => {
    setLoader(templateConfigLoading || artistListLoading || venueListLoading)
  }, [templateConfigLoading, artistListLoading, venueListLoading])

  useEffect(() => {
    if (templateConfig && templateConfig.success) {
      let page = templateConfig.data
      setBannerData(page.banner)
      setSwimlaneData(page.swimlane_list)
    }
  }, [templateConfig])

  useEffect(() => {
    if (venueList && venueList.success) {
      setVenueData(venueList.data)
    }
  }, [venueList])

  useEffect(() => {
    if (artistList && artistList.success) {
      setArtistData(artistList.data)
    }
  }, [artistList])

  return (
    <div className="min-h-screen">
      {isEmpty(swimlaneData) && isEmpty(swimlaneData) && <FullScreenLoader loading={true} />}
      {!isEmpty(bannerData) && <NewCarousel data={bannerData} />}
      {!isEmpty(swimlaneData) &&
        swimlaneData.map((swimlaneObj, index) => (
          <>
            {!isEmpty(swimlaneObj.data) && (
              <div
                className={index === 0 ? 'mt-8 md:mt-8 xl:mt-12' : ' lg:mt-20 xl:mt-16'}
                key={index}
              >
                <Swimlane
                  type={'content'}
                  filters={swimlaneObj.meta_data}
                  title={swimlaneObj.name}
                  data={swimlaneObj.data}
                  seeMoreVisible={swimlaneObj.total > swimlaneObj.data.length}
                />
              </div>
            )}
          </>
        ))}
      {!isEmpty(artistData) && (
        <div className={'mt-2 lg:mt-20 xl:mt-16'}>
          <Swimlane title={'Artist'} type={'artists'} data={artistData} seeMoreVisible={true} />
        </div>
      )}
      {!isEmpty(venueData) && (
        <div className={'mt-2 lg:mt-20 xl:mt-16'}>
          <Swimlane title={'Venue'} type={'venues'} data={venueData} seeMoreVisible={true} />
        </div>
      )}
    </div>
  )
}

export default Home

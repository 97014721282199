import React from 'react'

const Playback4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 15.517 15.517"
    >
      <g data-name="playback-speed-svgrepo-com (1)">
        <path
          data-name="Path 1128"
          d="M9.445 1.443a7.758 7.758 0 1 1 0 15.131.541.541 0 1 1 .24-1.056 6.676 6.676 0 1 0 0-13.019.541.541 0 1 1-.24-1.056z"
          transform="translate(-3.413 -1.25)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1129"
          d="M6.052 3.034a.541.541 0 0 1-.17.746 6.714 6.714 0 0 0-2.1 2.1.541.541 0 0 1-.916-.577 7.8 7.8 0 0 1 2.44-2.438.541.541 0 0 1 .746.169z"
          transform="translate(-1.676 -1.676)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1130"
          d="M2.5 9.685a.541.541 0 1 0-1.056-.24 7.822 7.822 0 0 0 0 3.453.541.541 0 1 0 1.056-.24 6.74 6.74 0 0 1 0-2.973z"
          transform="translate(-1.25 -3.413)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1131"
          d="M3.034 16.654a.541.541 0 0 1 .746.17 6.713 6.713 0 0 0 2.1 2.1.541.541 0 0 1-.577.916 7.8 7.8 0 0 1-2.438-2.44.541.541 0 0 1 .169-.746z"
          transform="translate(-1.676 -5.514)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1132"
          d="M13.629 10.123a.9.9 0 0 1 0 1.529l-3.407 2.011A.824.824 0 0 1 9 12.9V8.876a.824.824 0 0 1 1.222-.764z"
          transform="translate(-3.407 -3.129)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Playback4

import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { Link, useSearchParams } from 'react-router-dom'
import logo from '../../assets/basic-placehoder.jpg'
import useAxios from 'axios-hooks'
import { STATIC_PAGE } from '../../utils/apiUrls'
import { getImage, isEmpty } from '../../utils/helper'
import FBLogo from '../../assets/social/facebook.png'
import InstaLogo from '../../assets/social/instagram.png'
import TGLogo from '../../assets/social/telegram.png'
import YTLogo from '../../assets/social/youtube.png'
import TwitterLogo from '../../assets/social/twitter.png'
import BitchuteLogo from '../../assets/social/bitchute-logo.png'
import RumbleLogo from '../../assets/social/rumble-logo.png'
import BoxcastLogo from '../../assets/social/boxcast-icon.png'
import TikTokLogo from '../../assets/social/tiktok.png'
import moment from 'moment'
import { getExternalLink } from '../template/templateFunctions'

const Footer = () => {
  const [socialMediaLinks, setSocialMediaLinks] = useState({})
  const { staticPages, setStaticPages, staticPageRoutes, accountObj } = useContext(AppContext)

  const [searchParams] = useSearchParams()

  const mobileApp = searchParams.get('mobileApp') === 'true'

  const [{ data: staticRes }] = useAxios({
    url: `${STATIC_PAGE}`,
    method: 'get',
  })

  const socialIcons = {
    facebook: FBLogo,
    twitter: TwitterLogo,
    instagram: InstaLogo,
    telegram: TGLogo,
    youtube: YTLogo,
    bitchute: BitchuteLogo,
    tiktok: TikTokLogo,
    rumble: RumbleLogo,
    boxcast: BoxcastLogo,
  }

  useEffect(() => {
    if (staticRes && staticRes.success) {
      const { data } = staticRes
      setStaticPages(
        data.filter((obj) => obj.visible_on_website === true && obj.type !== 'SOCIAL_LINKS'),
      )
      const socialObj = data.find((obj) => obj.type === 'SOCIAL_LINKS')

      if (socialObj && socialObj?.visible_on_website) {
        let tempSocial = {}
        for (const key in socialObj.content) {
          const element = socialObj.content[key]
          if (element) {
            tempSocial[key] = element
          }
        }
        setSocialMediaLinks(tempSocial)
      }
    }
  }, [staticRes])
  return (
    <>
      {!mobileApp && (
        <footer className={`footer-1 mt-6 md:mt-1 xl:mt-28  pb-4 `}>
          <div className="md:px-24 mt-4 md:mt-12 lg:mt-20 xl:mt-1 py-4 mx-auto">
            <div className="lg:flex lg:items-center ">
              <div className="hidden xl:flex md:px-4">
                <Link to="/">
                  <div>
                    <img
                      src={
                        accountObj?.cf_domain_name
                          ? getImage(
                              accountObj?.cf_domain_name,
                              `public/static/logo.png?v=${moment(new Date()).unix()}`,
                            )
                          : logo
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = logo
                      }}
                      alt="logo"
                      className="h-6"
                    />
                  </div>
                </Link>
              </div>
              <div className="flex text-white justify-evenly">
                {!isEmpty(staticPages) &&
                  staticPages.map((page, index) => (
                    <Link
                      to={`/${staticPageRoutes[page.type]}`}
                      className={'text-center'}
                      key={index}
                    >
                      <p className="lg:px-6 inline-block cursor-pointer text-xs md:text-lg">
                        {page.title}
                      </p>
                    </Link>
                  ))}
              </div>
              {!isEmpty(socialMediaLinks) && (
                <div className="flex-1  text-right ">
                  {Object.keys(socialMediaLinks).map((key, index) => (
                    <div
                      className="hidden md:w-6 w-5 mx-2 mt-1 lg:inline-block align-middle"
                      key={index}
                    >
                      <a
                        href={getExternalLink(`${socialMediaLinks[key]}`)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={socialIcons[key]} alt={`${key}`} className="w-full" />
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!isEmpty(socialMediaLinks) && (
              <div className="lg:hidden flex justify-center mt-2 md:mt-4">
                {Object.keys(socialMediaLinks).map((key, index) => (
                  <div className="px-1 w-8 flex items-center" key={index}>
                    <a href={`${socialMediaLinks[key]}`} target="_blank" rel="noreferrer">
                      <img src={socialIcons[key]} alt={`${key}`} />
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer

import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import { isEmpty } from 'lodash'

const DefinityCards = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData

  const { customNavigate } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{
        background: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
      className={` w-full relative  ${isEdit && hoverState && 'component-hover-class'}`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="w-full relative 2xl:py-10 md:py-8 py-[0.5rem]">
        <h2
          style={{
            color: getStyleValue('heading', 'color', theme.c3),
            fontFamily: getStyleValue('heading', 'font', ''),
            textAlign: getStyleValue('heading', 'alignment', ''),
          }}
          className={`sm:text-3xl text-xl ${getStyleValue(
            'heading',
            'font_format',
            'font-bold',
          )} 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[0.5rem]`}
        >
          {data?.heading}
        </h2>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} wrapperClassName="w-full md:mt-4">
          {data?.slide_data &&
            data?.slide_data.map((info, index) => (
              <Card
                key={index}
                itemId={`index${index}`}
                index={index}
                info={info}
                handleRedirect={handleRedirect}
                theme={theme}
                getStyleValue={getStyleValue}
              />
            ))}
        </ScrollMenu>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
  const { FitnessPrev } = useTemplateHook()
  return (
    <button
      className={` absolute left-0 top-1/2 z-10  border-0 bg-transparent px-0 hidden lg:block`}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <img
        src={FitnessPrev}
        alt="prev"
        style={{
          width: '106px',
          height: '54px',
        }}
      />
    </button>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  const { FitnessNext } = useTemplateHook()
  return (
    <button
      className={` absolute right-0 top-1/2 z-10 border-0 bg-transparent px-0 hidden lg:block`}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <img
        src={FitnessNext}
        alt="next"
        style={{
          width: '106px',
          height: '54px',
        }}
      />
    </button>
  )
}

function Card({ index, info, handleRedirect, theme, getStyleValue }) {
  const { getTemplateImage } = useTemplateHook()
  return (
    <div
      key={index}
      className={`cursor-pointer ${
        !index && 'ml-2 2xl:ml-32 xl:ml-24 md:ml-16 sm:ml-10'
      }  border  2xl:h-[515px] sm:h-[412px] h-[185px] 2xl:w-[375px] sm:w-[300px]  w-[135px]  sm:mr-12 mr-5 rounded-lg overflow-hidden relative`}
      onClick={() => handleRedirect(info?.link)}
      tabIndex={0}
    >
      <CustomImage
        imageUrl={getTemplateImage(info?.image_s3key)}
        imgClass="z-0 w-full h-auto"
        loaderClass="z-0 w-full h-full"
        loaderStyle={{
          background: '#5A5A5A50',
        }}
        imgAlt={'class img"'}
      />
      <div
        style={{
          background: getStyleValue('card_style', 'background_color', theme.c2),
          color: getStyleValue('card_style', 'color', theme.c6),
          fontFamily: getStyleValue('card_style', 'font', ''),
          textAlign: getStyleValue('card_style', 'alignment', 'center'),
        }}
        className={`z-10 absolute w-full sm:h-[18%] h-[24%] md:text-lg 2xl:text-xl text-[10px] ${getStyleValue(
          'card_style',
          'font_format',
          'font-semibold',
        )}   bottom-0 flex items-center justify-center  2xl:px-4`}
      >
        {info.heading}
      </div>
    </div>
  )
}

export default withTheme(DefinityCards)

import styled from '@emotion/styled'

const ArtistWrapper = styled('div')`
  .artist-image::after {
    display: block;
    position: relative;
    background-image: linear-gradient(
      to bottom,
      transparent 10%,
      ${(props) => props.theme.c1} 101%
    );
    margin-top: -45rem;
    height: 45rem;
    /* border: 1px solid red; */
    /* width: 100%; */
    content: '';
  }

  .description-color {
    color: ${(props) => props.theme.c4};
  }
  .description-see-more-color {
    color: ${(props) => props.theme.c3};
  }
`

export default ArtistWrapper

export default {
  name: 'NEON_DARK',
  c1: '#12232E',
  c2: '#FFFF00',
  c3: '#00FFFF',
  c4: '#FFFFFF',
  c5: '#218BCB',
  c6: '#086CA8',
  c7: '#FFFFFF',
  c8: '#80ff00',
  c9: '#55e3d2',
  c10: '#1e3443',
  c11: '#008080',
}

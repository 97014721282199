import React, { createContext, useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { AppContext } from './app'
import { TEMPLATE_V2_URL } from '../utils/apiUrls'
import defaultTheme from '../components/themes/defaultTheme'
import magentaHighlight from '../components/themes/magentaHighlight'
import neonDark from '../components/themes/neonDark'
import pantoneRed from '../components/themes/pantoneRed'
import darkInfraRed from '../components/themes/darkInfraRed'
import greenEnergy from '../components/themes/greenEnergy'
import { isEmpty } from 'lodash'
import { TEMPLATE_TYPE } from '../utils/constants'

const themes = [defaultTheme, magentaHighlight, neonDark, pantoneRed, darkInfraRed, greenEnergy]

const initialState = {
  templateData: {},
  componentData: [],
  themeIndex: 0,
}

export const TemplateContext = createContext(initialState)

const TemplateProvider = (props) => {
  const [templateData, setTemplateData] = useState({})
  const [componentData, setComponentData] = useState([])
  const [loginTemplateData, setLoginTemplateData] = useState({})
  const [signUpTemplateData, setSignUpTemplateData] = useState({})
  const [contentDetailTemplateData, setContentDetailData] = useState({})
  const [playerTemplateData, setPlayerDetailData] = useState({})
  const [themeIndex, setThemeIndex] = useState(0)
  const [templateLoader, setTemplateLoader] = useState(false)
  const [contentTemplateLoaded, setContentTemplateLoaded] = useState(false)
  const [loginTemplateLoaded, setLoginTemplateLoaded] = useState(false)
  const [signupTemplateLoaded, setSignupTemplateLoaded] = useState(false)
  const [playerTemplateLoaded, setPlayerTemplateLoaded] = useState(false)
  const { setLoader, isCustomTemplate } = useContext(AppContext)

  const [{ data: templateList, loading: templateListLoading }, templateListCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: loginTemplateList }, loginTemplateCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )
  const [{ data: signupTemplateList }, signupTemplateCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )
  const [{ data: contentTemplateList }, contentTemplateCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )
  const [{ data: playerTemplateList }, playerTemplateCall] = useAxios(
    {
      url: TEMPLATE_V2_URL,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(async () => {
    if (isCustomTemplate) {
      await templateListCall({
        params: {
          template_type: TEMPLATE_TYPE.HOME,
        },
      })
      await loginTemplateCall({
        params: {
          template_type: TEMPLATE_TYPE.LOGIN,
        },
      })
      await signupTemplateCall({
        params: {
          template_type: TEMPLATE_TYPE.SIGNUP,
        },
      })
      await contentTemplateCall({
        params: {
          template_type: TEMPLATE_TYPE.CONTENT_DETAIL,
        },
      })
      await playerTemplateCall({
        params: {
          template_type: TEMPLATE_TYPE.PLAYER,
        },
      })
    }
  }, [isCustomTemplate])

  useEffect(() => {
    setLoader(templateListLoading)
    setTemplateLoader(templateListLoading)
  }, [templateListLoading])

  useEffect(() => {
    if (isCustomTemplate && templateList && templateList.success) {
      let tempData = templateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]
        if (particularTemplateData.template_type === TEMPLATE_TYPE.HOME) {
          setTemplateData(particularTemplateData)
          setComponentData(particularTemplateData?.components)
          if (!isEmpty(particularTemplateData)) {
            let tempThemeIndex = themes.findIndex(
              (info) => info.name === particularTemplateData?.selected_theme?.name,
            )
            if (tempThemeIndex > -1) {
              setThemeIndex(tempThemeIndex)
            }
          }
          return
        }
      }
    }
  }, [templateList])

  useEffect(() => {
    if (isCustomTemplate && loginTemplateList && loginTemplateList.success) {
      let tempData = loginTemplateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]

        if (particularTemplateData.template_type === TEMPLATE_TYPE.LOGIN) {
          setLoginTemplateLoaded(true)
          setLoginTemplateData(particularTemplateData.template_data)
          return
        }
      } else {
        setLoginTemplateLoaded(true)
      }
    }
  }, [loginTemplateList])

  useEffect(() => {
    if (isCustomTemplate && signupTemplateList && signupTemplateList.success) {
      let tempData = signupTemplateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]

        if (particularTemplateData.template_type === TEMPLATE_TYPE.SIGNUP) {
          setSignupTemplateLoaded(true)
          setSignUpTemplateData(particularTemplateData.template_data)
        }
      } else {
        setSignupTemplateLoaded(true)
      }
    }
  }, [signupTemplateList])

  useEffect(() => {
    if (isCustomTemplate && contentTemplateList && contentTemplateList.success) {
      let tempData = contentTemplateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]

        if (particularTemplateData.template_type === TEMPLATE_TYPE.CONTENT_DETAIL) {
          setContentTemplateLoaded(true)
          setContentDetailData(particularTemplateData.template_data)
        }
      } else {
        setContentTemplateLoaded(true)
      }
    }
  }, [contentTemplateList])

  useEffect(() => {
    if (isCustomTemplate && playerTemplateList && playerTemplateList.success) {
      let tempData = playerTemplateList?.data.filter((info) => info?.is_published)

      if (!isEmpty(tempData)) {
        let particularTemplateData = tempData[0]

        if (particularTemplateData.template_type === TEMPLATE_TYPE.PLAYER) {
          setPlayerTemplateLoaded(true)
          setPlayerDetailData(particularTemplateData.template_data)
        }
      } else {
        setPlayerTemplateLoaded(true)
      }
    }
  }, [playerTemplateList])

  return (
    <div style={{ backgroundColor: themes[themeIndex].c1 }}>
      <TemplateContext.Provider
        value={{
          templateData,
          componentData,
          themeIndex,
          templateLoader,
          loginTemplateData,
          signUpTemplateData,
          contentDetailTemplateData,
          playerTemplateData,
          contentTemplateLoaded,
          loginTemplateLoaded,
          signupTemplateLoaded,
          playerTemplateLoaded,
        }}
      >
        {props.children}
      </TemplateContext.Provider>
    </div>
  )
}

export default TemplateProvider

import React, { useState } from 'react'
import ChevronRight from '../../assets/imgComponents/chevronRight'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '../../utils/helper'

const DiscountModal = ({ onCancel, discountData, getEstimation, currency }) => {
  const [discountCode, setDiscountCode] = useState('')
  const { t } = useTranslation()
  const handleApplyCoupon = () => {
    if (discountCode.trim().length === 0) return
    getEstimation(discountCode.trim(), '')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleApplyCoupon()
    }
  }
  return (
    <div
      style={{ backgroundColor: `#00000070` }}
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative flex justify-center">
        <div
          style={{
            fontFamily: 'Arial',
            border: `1px solid #707070`,
            backgroundColor: `#000000`,
          }}
          className={`shadow-lg relative flex flex-col h-[549px] w-[94vw] sm:w-[422px] px-2 py-2 sm:px-[26px] sm:py-[30px] rounded-[10px]`}
        >
          <div className="">
            <div className="flex items-center text-white">
              <div onClick={onCancel} className="cursor-pointer">
                <ChevronRight imgClass="rotate-180" />
              </div>
              <p className="mb-0 text-sm font-bold ml-6">{t('deals_available_for_you')}</p>
            </div>
            <div
              className="w-full relative mt-5 rounded overflow-hidden"
              style={{ border: '1px solid #707070' }}
            >
              <input
                style={{ background: 'transparent' }}
                placeholder="Type your Coupon Code here"
                type="text"
                className="w-full text-[10px] p-2.5 text-white z-0"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <span
                style={{ color: '#606060', background: '#000' }}
                className="text-xs absolute h-full right-0 p-2 z-10 cursor-pointer uppercase"
                onClick={handleApplyCoupon}
              >
                {t('apply')}
              </span>
            </div>
          </div>
          <div style={{ height: '399px' }} className="scrollbar-class mt-3 w-full">
            {!isEmpty(discountData) ? (
              discountData.map((info, index) => {
                return (
                  <div
                    className={`w-full rounded-[10px] overflow-hidden flex justify-between ${
                      index && 'mt-5'
                    } h-[97px]`}
                    key={index}
                  >
                    <div
                      style={{
                        width: '8.1%',
                        background: '#e34600',
                        writingMode: 'vertical-lr',
                      }}
                      className="h-full rotate-180 flex items-center justify-center text-sm font-semibold text-white"
                    >
                      {info.value_type === 'percentage'
                        ? `${info.value} % OFF`
                        : `Flat ${info.value} Off.`}
                    </div>
                    <div style={{ width: '90.1%', background: '#e9e9e9' }} className="h-full p-2.5">
                      <div className="flex justify-between items-center">
                        <p className="cursor-pointer text-sm font-bold">{info.name}</p>
                        <p
                          className="cursor-pointer text-xs font-bold uppercase"
                          style={{ color: '#e34600' }}
                          onClick={() => getEstimation('', info._id)}
                        >
                          {t('apply')}
                        </p>
                      </div>
                      <p
                        style={{ color: '#0450ff' }}
                        className="mb-0 line-clamp-1 text-[10px] mt-1"
                      >
                        {` Save Maximum ${info.max_value} ${currency} !`}
                      </p>
                      <p
                        style={{ color: '#727272' }}
                        className="mb-0 line-clamp-2 text-[10px] mt-2"
                      >
                        {info.description}
                      </p>
                    </div>
                  </div>
                )
              })
            ) : (
              <p className="flex justify-center mt-4 text-white">{t('no_discounts_available')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscountModal

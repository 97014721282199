import React from 'react'

const Pip2 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 17.882 17.882">
      <path data-name="Path 1168" d="M0 0h17.882v17.882H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 1169"
        d="M16.156 3a.745.745 0 0 1 .745.745v5.216h-1.49V4.49H3.49v10.431h4.47v1.49H2.745A.745.745 0 0 1 2 15.666V3.745A.745.745 0 0 1 2.745 3zm0 7.451a.745.745 0 0 1 .745.745v4.47a.745.745 0 0 1-.745.745H10.2a.745.745 0 0 1-.745-.745V11.2a.745.745 0 0 1 .745-.745zm-.745 1.49h-4.47v2.98h4.47zm-9.9-6.487L7.183 7.13l1.523-1.522v4.1h-4.1L6.13 8.183 4.454 6.507l1.053-1.053z"
        transform="translate(-.51 -.765)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Pip2

import React from 'react'

const Next2 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
      <path
        data-name="Rectangle 1691"
        transform="translate(7.65 .65)"
        style={{
          strokeWidth: '1.1px',
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        d="M0 0h3v10H0z"
      />
      <path
        data-name="Path 1228"
        d="M9 9v9.535l6.13-4.767z"
        style={{
          strokeWidth: '1.3px',
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        transform="translate(-8.35 -8.35)"
      />
    </svg>
  )
}

export default Next2

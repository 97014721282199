import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import { isEmpty } from 'lodash'

const NarrowAdmonition = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      className={`relative ${isEdit && hoverState && 'component-hover-class'} cursor-pointer`}
      style={{ background: theme.c1 }}
      onClick={() => handleRedirect(data?.link)}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="md:py-8 py-6">
        <div
          className={`  w-full flex ${config[data?.position]?.topDiv} items-center relative `}
          style={{
            aspectRatio: '1903/200',
          }}
          onClick={() => handleRedirect(data?.link)}
        >
          <CustomImage
            imageUrl={getTemplateImage(data?.image_s3key)}
            imgClass="absolute w-full h-full object-contain"
            loaderClass="w-full h-full absolute "
            loaderStyle={{
              background: '#5A5A5A50',
            }}
            imgAlt={'category'}
          />
          {/* <div className={`flex  items-center h-full w-full md:mx-0 mx-6 relative`}> */}
          <div
            style={{ zIndex: 1 }}
            className={`${
              data.cta_button.enabled ? config[data.position]?.midDiv : ''
            } flex flex-col justify-between items-center h-full w-auto lg:px-3 px-[8px] xl:py-2 lg:font-bol`}
          >
            <span
              style={{
                color: getStyleValue('heading2', 'color', theme.c7),
                fontFamily: getStyleValue('heading2', 'font', ''),
              }}
              className={`2xl:text-3xl xl:text-2xl lg:text-xl sm:text-xs text-[8px] 
            ${getStyleValue('heading2', 'font_format', 'font-bold')}
            `}
            >
              {data?.heading_2}
            </span>
            <span
              style={{
                color: getStyleValue('heading1', 'color', theme.c7),
                fontFamily: getStyleValue('heading1', 'font', ''),
              }}
              className={`2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-[13px] text-[10px]
            ${getStyleValue('heading1', 'font_format', 'italic')}
            `}
            >
              {data?.heading_1}
            </span>
            <span
              style={{
                color: getStyleValue('paragraph', 'color', theme.c7),
                fontFamily: getStyleValue('paragraph', 'font', ''),
              }}
              className={`2xl:text-xl  xl:text-lg lg:text-base md:text-sm sm:text-[10px] text-[9px]   ${getStyleValue(
                'paragraph',
                'font_format',
                'font-bold',
              )}`}
            >
              {data?.description}
            </span>
          </div>
          {data?.cta_button?.enabled && (
            <div className={`z-10 ${config[data.position]?.btn}`}>
              <button
                style={{
                  background: getStyleValue('button', 'button_background', theme.c7),
                  color: getStyleValue('button', 'color', theme.c6),
                  fontFamily: getStyleValue('button', 'font', ''),
                }}
                className="md:mx-10 border-0 md:py-1 xl:py-2 py-[4px] md:px-2 xl:px-4 px-[8px] xl:text-xl md:text-base text-[9px] rounded-lg font-semibold shadow-xl sm:ml-0 -ml-3"
              >
                {data.cta_button?.text}
              </button>
            </div>
          )}
        </div>
        {/* </div> */}
        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </div>
    </div>
  )
}

export default withTheme(NarrowAdmonition)

import React from 'react'

const Pip6 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 17.49 13.111">
      <g data-name="Group 1187">
        <path
          data-name="Path 1173"
          d="M46.775 71.887a.726.726 0 1 1 0 1.452h-.731a.726.726 0 1 1 0-1.452z"
          transform="translate(-37.664 -60.227)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1174"
          d="M17.339 49.992a1.725 1.725 0 0 1 1.71 1.71v3.141a1.72 1.72 0 0 1-1.71 1.7H14.2a1.72 1.72 0 0 1-1.71-1.7V51.7a1.725 1.725 0 0 1 1.71-1.71zm0 1.457H14.2a.239.239 0 0 0-.253.253v3.141a.24.24 0 0 0 .253.253h3.141a.239.239 0 0 0 .253-.253V51.7a.24.24 0 0 0-.255-.251z"
          transform="translate(-1.559 -43.436)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1175"
          d="M61.606 71.887a.726.726 0 1 1 0 1.452h-.726a.726.726 0 1 1 0-1.452z"
          transform="translate(-55.958 -60.227)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1176"
          d="M81.989 52.094a.727.727 0 0 1 .726.726v.731a.729.729 0 0 1-1.457 0v-.731a.728.728 0 0 1 .731-.726z"
          transform="translate(-81.257 -45.048)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1177"
          d="M81.989 38.551a.729.729 0 0 1 .726.727v.731a.729.729 0 0 1-1.457 0v-.731a.729.729 0 0 1 .731-.727z"
          transform="translate(-81.257 -34.663)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1178"
          d="M61.606 21.875a.729.729 0 1 1 0 1.457h-.727a.729.729 0 1 1 0-1.457z"
          transform="translate(-55.958 -21.874)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1179"
          d="M46.775 21.875a.729.729 0 0 1 0 1.457h-.731a.728.728 0 0 1-.515-1.242.722.722 0 0 1 .515-.215z"
          transform="translate(-37.664 -21.874)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1180"
          d="M31.918 21.875a.729.729 0 1 1 0 1.457h-.726a.729.729 0 1 1 0-1.457z"
          transform="translate(-19.349 -21.874)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1181"
          d="M75.74 65.633a.729.729 0 0 1 .726.727v.522a.2.2 0 0 0 .209.209h.525a.726.726 0 1 1 0 1.452h-.522a1.677 1.677 0 0 1-1.667-1.662v-.522a.722.722 0 0 1 .215-.515.731.731 0 0 1 .516-.212z"
          transform="translate(-75.008 -55.431)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1182"
          d="M77.192 21.872a.729.729 0 0 1 0 1.457h-.522a.2.2 0 0 0-.209.2v.522a.732.732 0 0 1-.212.516.729.729 0 0 1-1.249-.512v-.522a1.677 1.677 0 0 1 1.667-1.662z"
          transform="translate(-75.003 -21.871)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1183"
          d="M13.736 21.875a1.677 1.677 0 0 1 1.664 1.662v.522a.729.729 0 1 1-1.457 0v-.522a.2.2 0 0 0-.209-.2h-.522a.729.729 0 0 1 0-1.457z"
          transform="translate(2.086 -21.874)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1184"
          d="M13.218 38.551a.729.729 0 0 1 .731.727v.731a.729.729 0 0 1-1.457 0v-.731a.73.73 0 0 1 .726-.727z"
          transform="translate(3.54 -34.662)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Pip6

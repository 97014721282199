import React from 'react'

const Subtitle4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 21.986 21.986"
    >
      <g data-name="Outlined-/-AV-/-subtitles">
        <g data-name="Group 1184">
          <path d="M0 0h21.986v21.986H0z" style={{ fillRule: 'evenodd', fill: 'none' }} />
          <path
            data-name="🔹-Icon-Color"
            d="M18.49 4H3.832A1.838 1.838 0 0 0 2 5.832v10.993a1.838 1.838 0 0 0 1.832 1.832H18.49a1.838 1.838 0 0 0 1.832-1.832V5.832A1.838 1.838 0 0 0 18.49 4zm0 12.825H3.832V5.832H18.49zM5.664 9.5H7.5v1.832H5.664zm0 3.664h7.329v1.832H5.664zm9.161 0h1.832v1.832h-1.832zM9.329 9.5h7.329v1.832H9.329z"
            transform="translate(-.168 -.336)"
            style={{ fill: color, fillRule: 'evenodd' }}
          />
        </g>
      </g>
    </svg>
  )
}

export default Subtitle4

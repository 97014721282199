import React, { useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import './newSwimlane.css'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import NewContentCardHover from '../../contentCard/newContentCardHover'
import PortraitOwlCard from '../../contentCard/portraitOwlCard'

const PortraitOwlCarousel = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  //   const { data, title, filters, seeMoreVisible, type, theme } = props
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { isBrowser, LG_SIZE, SM_SIZE, CONTENT_FILTER_XAVIER } = useTemplateHook()
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [contentData, setContentData] = useState([])
  const [contentMapData, setContentMapData] = useState([])

  const [{ data: contentList }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(7)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(5)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data.content_data) && data?.content_data[0]?.content_id) {
      contentListCall({
        data: {
          filters: { content_ids: data.content_data.map((card) => card.content_id) },
          page: 1,
          order_by: '',
          reverse: '',
          page_size: 10,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      // for geo fencing
      let contentIDsMap = [...contentList?.data]?.map((info) => info?.content_id)
      let allContentArr = [...data?.content_data]?.filter((info) =>
        contentIDsMap.includes(info?.content_id),
      )
      setContentMapData([...allContentArr])
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleHover = (index) => {
    if (isEdit) return
    // console.log('contentCardRef.current', contentCardRef.current.classList)
    setHoverCardIndex(index)
  }

  const handleUnHover = () => {
    if (isEdit) return
    setHoverCardIndex('')
  }

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-[16px] lg:py-8`}
      style={{
        backgroundColor: getStyleValue('component_settings', 'component_background', theme.c1),
        zIndex: hoverState ? 2 : 1,
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}

      <CarouselProvider
        naturalSlideWidth={isBrowser ? 228 : 140}
        naturalSlideHeight={isBrowser ? 342 : 210}
        visibleSlides={visibleSlides}
        step={7}
        totalSlides={contentMapData.length}
      >
        <div
          className="flex items-baseline"
          style={{
            padding: '0 5.52%',
          }}
        >
          <p
            style={{
              color: getStyleValue('component_settings', 'component_heading_text', theme.c3),
              fontFamily: getStyleValue('component_settings', 'font', ''),
              textAlign: getStyleValue('component_settings', 'alignment', 'left'),
            }}
            className={`sm:text-[0.8rem] md:text-[1.5rem] font-bold w-full ${getStyleValue(
              'component_settings',
              'font_format',
              'font-bold',
            )}`}
          >
            {data?.name}
          </p>
        </div>
        <div
          className={`swimlane-container-dock portrait-owl-slider-class h-[230px] md:h-[325px] 2xl:h-[380px] z-10`}
          style={{
            backgroundColor: getStyleValue('component_settings', 'component_background', theme.c1),
          }}
        >
          <Slider
            style={{
              minHeight: hoverCardIndex !== '' && isBrowser ? `23rem` : 'unset',
              maxHeight: '380px',
            }}
            className="pt-2 md:pt-4 lg:pt-8"
          >
            {!isEmpty(contentData) &&
              !isEmpty(contentMapData) &&
              contentMapData.map((item, index) => {
                return (
                  <Slide key={index} index={index} className="">
                    <div
                      style={{
                        display: hoverCardIndex === index && isBrowser ? 'block' : 'none',
                      }}
                      className="relative"
                      onMouseLeave={handleUnHover}
                    >
                      <NewContentCardHover
                        data={
                          contentData.filter((info) => info.content_id === item?.content_id).length
                            ? contentData.filter((info) => info.content_id === item?.content_id)[0]
                            : item
                        }
                        type={'content'}
                        position={index + 1}
                        total={contentMapData?.length}
                        visibleSlides={visibleSlides}
                        isVisible={hoverCardIndex === index && isBrowser}
                        top={'-8px'}
                      />
                    </div>
                    <div
                      className="relative"
                      style={{ opacity: hoverCardIndex === index && isBrowser ? 0 : 1 }}
                      onMouseOver={() => handleHover(index)}
                    >
                      <PortraitOwlCard
                        thumbnail={item?.card_thumbnail}
                        data={
                          contentData.filter((info) => info.content_id === item?.content_id).length
                            ? contentData.filter((info) => info.content_id === item?.content_id)[0]
                            : item
                        }
                        headingColor={getStyleValue('card_settings', 'card_title_heading', '#fff')}
                        subHeadingColor={getStyleValue(
                          'card_settings',
                          'card_title_subheading',
                          '#afafaf',
                        )}
                        gradientColor={getStyleValue(
                          'card_settings',
                          'card_text_background',
                          '#000',
                        )}
                        isEdit={isEdit}
                      />
                    </div>
                  </Slide>
                )
              })}
          </Slider>
          {isBrowser && (
            <>
              {contentMapData?.length > visibleSlides ? (
                <ButtonBack className="">
                  <div className="portrait-owl-prev-arrow  flex items-center justify-center">
                    <IoIosArrowBack className="" />
                  </div>
                </ButtonBack>
              ) : null}
              {contentMapData?.length > visibleSlides ? (
                <ButtonNext className="">
                  <div className="portrait-owl-next-arrow flex items-center justify-center">
                    <IoIosArrowForward className="" />
                  </div>
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(PortraitOwlCarousel)

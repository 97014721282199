import React, { useContext, useEffect, useState } from 'react'
import VenueBanner from '../../assets/venueBanner.png'
import { VENUE_LISTING_DESCRIPTION, VENUE_LISTING_TITLE } from '../../utils/constants'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { VENUE_LISTING } from '../../utils/apiUrls'
import VenueCard from '../../components/venueCard'
import { shuffle } from 'lodash'
import { withTheme } from 'theming'
const VenueListing = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)

  const [dataList, setDataList] = useState([])

  const [{ data: venueList, loading: venueListLoading }] = useAxios({
    url: VENUE_LISTING,
    method: 'get',
    params: {
      page_size: 1000,
      page: 1,
    },
  })

  // const hitAPI = (page) => {
  //   apiHit = true
  //   venueListCall({
  //     params: {
  //       page_size: PAGE_SIZE,
  //       page: page,
  //     },
  //   })
  // }

  useEffect(() => {
    // hitAPI(1)
    // currentPage = 1
    setDataList([])
    // totalRecords = 0
    // window.addEventListener('scroll', infiniteScroll)
    // return () => {
    //   window.removeEventListener('scroll', infiniteScroll)
    // }
  }, [])

  useEffect(() => {
    if (venueList && venueList.success) {
      setDataList(shuffle(venueList.data))
      // totalRecords = venueList.total
      // apiHit = false
    }
  }, [venueList])

  useEffect(() => {
    setLoader(venueListLoading)
  }, [venueListLoading])

  // const infiniteScroll = () => {
  //   // End of the document reached?
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >
  //     0.8 * document.documentElement.offsetHeight
  //   ) {
  //     if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
  //       apiHit = true
  //       currentPage += 1
  //       hitAPI(currentPage)
  //     }
  //   }
  // }

  return (
    <div>
      <div className="w-full relative">
        <img src={VenueBanner} className="w-full" alt="Venue Banner" />
        <div
          style={{ color: isCustomTemplate ? theme.c7 : '#fff' }}
          className={` absolute top-2 left-3 md:top-10 md:left-10 xl:top-12 xl:left-36`}
        >
          <p className="text-xs md:text-xl xl:text-4xl font-bold">{VENUE_LISTING_TITLE}</p>
          <p className="text-xs md:text-base xl:text-base">{VENUE_LISTING_DESCRIPTION}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-10 py-5 xl:px-20 xl:py-6">
        {dataList && dataList.map((item, index) => <VenueCard item={item} key={index} />)}
      </div>
    </div>
  )
}

export default withTheme(VenueListing)

import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import AddSection from '../addSection'
import { withTheme } from 'theming'

const DefaultBanner = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { customNavigate, getTemplateImage, BannerPlaceholder, isBrowser } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleClick = (index) => {
    let linkObj = data?.slide_data[index]?.link
    customNavigate('INDIRECT', linkObj, isEdit)
  }
  return (
    <div
      style={{ backgroundColor: theme.c1 }}
      className={`${isEdit && hoverState && 'component-hover-class'} relative`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
        />
      )}
      <Carousel
        showThumbs={false}
        showStatus={false}
        centerMode={isBrowser}
        centerSlidePercentage={70}
        showArrows={isBrowser}
        infiniteLoop={true}
        autoPlay={data?.auto_slide}
        useKeyboardArrows={true}
        // selectedItem={parseInt(data.length / 2) ? data.length / 2 < data.length : 1}
        emulateTouch={true}
        onClickItem={handleClick}
      >
        {data?.slide_data.map((slide, index) => (
          <div
            key={index}
            className={'items-center bg-black h-full justify-center flex'}
            style={{ borderRadius: '0.62rem', marginRight: '2rem', marginLeft: '2rem' }}
          >
            <CustomImage
              imageUrl={getTemplateImage(slide?.image_s3_key)}
              errorPlaceholder={BannerPlaceholder}
              imgClass="w-full"
              imgStyle={{ borderRadius: '0.62rem' }}
              loaderClass="h-[7.5rem] md:h-44 xl:h-[18.7rem] 2xl:h-[26rem]"
              imgAlt={index}
            />
          </div>
        ))}
      </Carousel>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(DefaultBanner)

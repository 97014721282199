import React from 'react'

const PlayPause2 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 19.64 19.64"
        >
          <g data-name="pause-circle-svgrepo-com (3)">
            <path
              data-name="Path 1082"
              d="M20.64 11.82A8.82 8.82 0 1 1 11.82 3a8.82 8.82 0 0 1 8.82 8.82z"
              style={{ fill: 'none', stroke: primaryColor, strokeWidth: '2px' }}
              transform="translate(-2 -2)"
            />
            <path
              data-name="Path 1083"
              d="M14 9v6"
              transform="translate(-2.22 -2.18)"
              style={{
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                fill: 'none',
                stroke: secondaryColor,
                strokeWidth: '2px',
              }}
            />
            <path
              data-name="Path 1084"
              d="M10 9v6"
              transform="translate(-2.14 -2.18)"
              style={{
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                fill: 'none',
                stroke: secondaryColor,
                strokeWidth: '2px',
              }}
            />
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 19.64 19.64"
        >
          <g data-name="play-circle-svgrepo-com (3)">
            <path
              data-name="Path 1094"
              d="M20.64 11.82A8.82 8.82 0 1 1 11.82 3a8.82 8.82 0 0 1 8.82 8.82z"
              style={{ fill: 'none', stroke: primaryColor, strokeWidth: '2px' }}
              transform="translate(-2 -2)"
            />
            <path
              data-name="Path 1095"
              d="m10.9 8.8-.242-.138a.774.774 0 0 0-1.158.671v5.333a.774.774 0 0 0 1.158.672l.242-.138 4.2-2.4a.921.921 0 0 0 0-1.6z"
              transform="translate(-2.196 -2.18)"
              style={{
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                fill: 'none',
                stroke: secondaryColor,
                strokeWidth: '2px',
              }}
            />
          </g>
        </svg>
      )}
    </div>
  )
}

export default PlayPause2

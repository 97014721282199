import React, { useEffect, useRef, useState } from 'react'
import { withTheme } from 'theming'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleAdNCarousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import { isEmpty } from 'lodash'

const AdNCarousel = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)

  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')

  const [setting, setSetting] = useState({
    infinite: true,
    autoplay: data?.carousel_section?.auto_slide,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    pauseOnHover: true,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],

    appendDots: (dots) => {
      return (
        <div
          style={{
            backgroundColor: 'transparent',
            borderRadius: '0px',
            padding: '0px',
            bottom: '-50px',
            // left: '98%',
            position: 'absolute',
            // outline: '2px solid hotpink',
            // transform: 'translate(-50%,50%) rotate(90deg)',
            zIndex: 10,
          }}
        >
          <ul style={{ marginTop: '0px', paddingInlineStart: '0' }}>
            {dots.map((info, idx) => (
              <li key={idx} className={`${info.props.className}`}>
                <div
                  style={{
                    backgroundColor: `${
                      info.props.className === 'slick-active' ? theme.c3 : '#d9d9d950'
                    }`,
                  }}
                  className="ft-slick__dots--custom"
                  onClick={info.props.children.props.onClick}
                >
                  <div className="" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )
    },
  })

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  useEffect(() => {
    setSetting({
      infinite: true,
      autoplay: data?.carousel_section?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      pauseOnHover: true,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
          },
        },
      ],

      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: 'transparent',
              borderRadius: '0px',
              padding: '0px',
              bottom: '-50px',
              // left: '98%',
              position: 'absolute',
              // outline: '2px solid hotpink',
              // transform: 'translate(-50%,50%) rotate(90deg)',
              zIndex: 10,
            }}
          >
            <ul style={{ marginTop: '0px', paddingInlineStart: '0' }}>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active' ? theme.c3 : '#d9d9d950'
                      }`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.carousel_section.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      <div
        className={`w-full 2xl:py-14 xl:py-10 py-8 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[10px] flex ${
          data?.is_right_aligned === true
            ? 'xl:flex-row flex-col'
            : 'xl:flex-row-reverse flex-col-reverse'
        } justify-between`}
        style={{ backgroundColor: 'transparent' }}
      >
        <div
          className={`${
            data?.is_right_aligned === true
              ? 'none xl:pb-0 pb-20'
              : 'xl:translate-x-[3vw] xl:pt-0 pt-20'
          } `}
        >
          <h1
            style={{
              color: getStyleValue('carousel_title', 'color', theme.c2),
              fontFamily: getStyleValue('carousel_title', 'font', ''),
              textAlign: getStyleValue('carousel_title', 'alignment', ''),
            }}
            className={`${getStyleValue(
              'carousel_title',
              'font_format',
              'font-bold',
            )} xl:text-3xl md:text-xl text-xs  sm:mb-[2vw] mb-6`}
          >
            {data?.carousel_section.heading}
          </h1>
          <div className="flex justify-between">
            <SliderWrapper className="xl:w-[54vw] w-full">
              <Slider ref={sliderRef} {...setting}>
                {data?.carousel_section.slide_data.map((slide, idx) => (
                  <div key={idx} className="sm:mr-[5vw]">
                    <div
                      className="2xl:w-[16vw] 2xl:h-[18.77vw] xl:w-[14vw] xl:h-[18.75vw] lg:w-[305px] lg:h-[288px] md:w-[20vw] md:h-[250px] sm:w-[139px] sm:h-[220px]  w-[139px] h-[175px] flex flex-col justify-start items-start static-img-scale"
                      onClick={() => handleRedirect(slide?.link)}
                    >
                      <div className="w-full mb-1 rounded-lg border cursor-pointer overflow-hidden">
                        <CustomImage
                          imageUrl={getTemplateImage(slide?.image_s3key)}
                          imgClass="z-0 w-full"
                          loaderClass="z-0 w-full h-2/3"
                          loaderStyle={{
                            background: '#5A5A5A50',
                          }}
                          imgAlt={'class img"'}
                        />
                      </div>
                      <h4
                        style={{
                          color: getStyleValue('carousel_heading', 'color', theme.c3),
                          fontFamily: getStyleValue('carousel_heading', 'font', ''),
                          textAlign: getStyleValue('carousel_heading', 'alignment', ''),
                        }}
                        className={`
                        ${getStyleValue('carousel_heading', 'font_format', 'font-semibold')}
                        w-full 2xl:text-lg xl:leading-5 xl:text-base md:text-sm text-[10px] 2xl:h-14 xl:h-auto flex-shrink-0`}
                      >
                        {slide?.heading}
                      </h4>
                      <p
                        style={{
                          color: getStyleValue('carousel_paragraph', 'color', theme.c4),
                          fontFamily: getStyleValue('carousel_paragraph', 'font', ''),
                          textAlign: getStyleValue('carousel_paragraph', 'alignment', ''),
                        }}
                        className={` ${getStyleValue(
                          'carousel_paragraph',
                          'font_format',
                          '',
                        )} 2xl:text-sm sm:text-xs text-[10px] font-light mt-auto sm:min-h-[85px] trending-news-line-clamp`}
                      >
                        {slide?.description}
                        {/* <span className="italic font-normal">Read more</span> */}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </SliderWrapper>
          </div>
        </div>
        <div className="">
          <h1
            style={{
              color: getStyleValue('ad_section_title', 'color', theme.c2),
              fontFamily: getStyleValue('ad_section_title', 'font', ''),
              textAlign: getStyleValue('ad_section_title', 'alignment', ''),
            }}
            className={`${getStyleValue(
              'ad_section_title',
              'font_format',
              '',
            )} xl:text-3xl md:text-xl text-xs font-bold sm:mb-[2vw] mb-6`}
          >
            {data?.ad_section?.heading}
          </h1>
          <div
            className={`2xl:w-[33vw] 2xl:h-[18.85vw] cursor-pointer xl:w-[29vw] xl:h-[15.8vw] md:w-[657px] md:h-[375px] sm:w-[80vw] sm:h-[45vw] w-[94vw] h-[53.7vw] relative p-[20px] flex flex-col ${
              config[data.ad_section.cta_button.position]
            }`}
            onClick={() => handleRedirect(data?.ad_section.link)}
          >
            <CustomImage
              imageUrl={getTemplateImage(data?.ad_section?.image_s3key)}
              imgClass="z-0 w-full h-auto"
              loaderClass="z-0 w-full h-5/6"
              loaderStyle={{
                background: '#5A5A5A50',
              }}
              imgStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
              }}
              imgAlt={'class img"'}
            />
            {data?.ad_section?.cta_button?.enabled && (
              <button
                style={{
                  color: getStyleValue('ad_section_button', 'color', theme.c7),
                  backgroundColor: getStyleValue(
                    'ad_section_button',
                    'button_background',
                    theme.c3,
                  ),
                }}
                className="relative sm:text-base text-xs font-semibold sm:py-2 py-1 sm:px-6 px-3 rounded-lg border-0"
              >
                {data.ad_section?.cta_button?.text}
              </button>
            )}
          </div>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(AdNCarousel)

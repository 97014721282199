import React, { useContext } from 'react'
import ContentDetail from './contentDetail'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import NewContentDetail from './newContentDetail'

const ContentDetailTemplate = () => {
  const { contentDetailTemplateData, contentTemplateLoaded } = useContext(TemplateContext)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      newContentDetail: <NewContentDetail {...pageProps} />,
    }

    return componentObj[key]
  }
  return (
    <div className="w-full h-full">
      {contentTemplateLoaded ? (
        !isEmpty(contentDetailTemplateData) ? (
          getPage(contentDetailTemplateData.template_key, contentDetailTemplateData.data)
        ) : (
          <ContentDetail />
        )
      ) : null}
    </div>
  )
}

export default ContentDetailTemplate

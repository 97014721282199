import React from 'react'

const Backward1 = ({ color = '#323232' }) => {
  return (
    <svg
      data-name="Group 1094"
      xmlns="http://www.w3.org/2000/svg"
      width="16.397"
      height="9.781"
      viewBox="0 0 16.397 9.781"
    >
      <path
        data-name="play-svgrepo-com (2)"
        d="M13.1 3.627 5.236 8.054a.464.464 0 0 0 0 .808l7.864 4.427a.464.464 0 0 0 .691-.4V4.032a.464.464 0 0 0-.691-.4z"
        transform="translate(2.601 -3.568)"
        style={{ fill: color }}
      />
      <path
        data-name="play-svgrepo-com (2)"
        d="M13.1 3.627 5.236 8.054a.464.464 0 0 0 0 .808l7.864 4.427a.464.464 0 0 0 .691-.4V4.032a.464.464 0 0 0-.691-.4z"
        transform="translate(-5 -3.568)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Backward1

import React from 'react'
import { withTheme } from 'theming'

const ProgressBar = ({
  progressWidth,
  bgColor,
  progressColor = 'red',
  height = '4px',
  barClass = '',
}) => {
  return (
    <div className={`w-full relative ${barClass}`} style={{ height: height, background: bgColor }}>
      <div
        className=" absolute h-full left-0 top-0"
        style={{
          width: `${progressWidth}`,
          background: progressColor,
          zIndex: 20,
        }}
      />
    </div>
  )
}

export default withTheme(ProgressBar)

import React from 'react'

const ListenIcon = ({ color = '#000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
      <g data-name="music-note-svgrepo-com (3)">
        <path
          data-name="Path 908"
          d="M17.586 3.443c-1.068-1.844-5.923-2.236-6.468-2.286l-.617-.039v11.338a3.286 3.286 0 0 0-.453-.307 4.655 4.655 0 0 0-3.649-.231c-2.3.743-3.783 2.867-3.3 4.733a2.978 2.978 0 0 0 1.484 1.857A4.18 4.18 0 0 0 6.6 19a5.331 5.331 0 0 0 1.665-.268 4.534 4.534 0 0 0 3.386-4.146V4.745c.559.067 1.324.184 2.135.358a6.242 6.242 0 0 1 2.269.816c.559.369.212 1.062.184 1.118a6.594 6.594 0 0 1-.928 1.118.559.559 0 0 0 .743.833c.118-.131 3.108-2.841 1.532-5.545z"
          style={{ fill: color }}
        />
        <path data-name="Rectangle 1178" style={{ fill: 'transparent' }} d="M0 0h20v20H0z" />
      </g>
    </svg>
  )
}

export default ListenIcon

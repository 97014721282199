export default {
  name: 'DEFAULT_THEME',
  c1: '#1b242f',
  c2: '#8BC0FF',
  c3: '#E2E2E2',
  c4: '#FFFFFF',
  c5: '#2680EB',
  c6: '#101720',
  c7: '#FFFFFF',
  c8: '#FFFFFF',
  c9: '#FFFFFF',
  c10: '#101720',
  c11: '#FFFFFF',
}

import React, { useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import EditMenu from '../editMenu'
import AddSection from '../addSection'
import ReactHtmlParser from 'react-html-parser'

const ArticleText = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')

  const handleHoverItems = (name) => {
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`${
        isEdit && hoverState && 'component-hover-class'
      } w-full relative py-[1rem] xl:py-6`}
      style={{}}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="w-full flex justify-center">
        <div
          style={{ maxWidth: '1056px', lineHeight: '1.875rem' }}
          className={`custom-page px-[5%] xl:px-0`}
        >
          <div style={{ color: getStyleValue('text_properties', 'color', theme.c4) }} className="">
            {!isEmpty(data) && ReactHtmlParser(data?.content)}
          </div>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(ArticleText)

import { useContext, useState } from 'react'
import useTemplateHook from '../template/templateHook'
import { AppContext } from '../../context/app'
import { getAdUrl } from '../../utils/helper'
import { useInterval } from '../../hooks/useInterval'
import { isEmpty } from 'lodash'

let SKIP_SECONDS = 0

const useAdvertisementHook = () => {
  const { accountObj } = useContext(AppContext)
  const [skipSeconds, setSkipSeconds] = useState(0)
  const [adDuration, setAdDuration] = useState(0)
  const [currentPlayingAd, setCurrentPlayingAd] = useState({})
  const { customNavigate } = useTemplateHook()

  const playPreRoll = (adInfo, playerRef, setShowSettingMenu) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode()
      // player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        commonAdPlayChanges(adInfo, player, setShowSettingMenu)

        // player.play()
        // player.muted(false)

        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        // player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const playMidRoll = (adInfo, playerRef, setShowSettingMenu) => {
    if (!playerRef.current) return
    let midRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let halfLength = Math.floor(player.duration() / 2)
      if (!midRollPlayed && currentTime >= halfLength) {
        player.ads.startLinearAdMode()
        // player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          midRollPlayed = true
          commonAdPlayChanges(adInfo, player, setShowSettingMenu)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          // player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }

  const playPostRoll = (adInfo, playerRef, setShowSettingMenu) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpostroll', function () {
      player.ads.startLinearAdMode()
      // player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        commonAdPlayChanges(adInfo, player, setShowSettingMenu)
        // player.muted(false)
        // player.play()
        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        // player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const playCustomRoll = (adInfo, playerRef, setShowSettingMenu) => {
    if (!playerRef.current) return
    let customRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let playTime = Math.floor((player.duration() * parseInt(adInfo.ad_location_percentage)) / 100)

      if (!customRollPlayed && currentTime >= playTime) {
        player.ads.startLinearAdMode()
        // player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          customRollPlayed = true

          commonAdPlayChanges(adInfo, player, setShowSettingMenu)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          // player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }

  const handleAdDuration = (seconds) => {
    if (!seconds) return
    setAdDuration(seconds)
  }

  useInterval(
    async () => {
      setAdDuration((prev) => prev - 1)
    },
    adDuration > 0 ? 1000 : null,
  )

  const handleSkip = (playerRef) => {
    if (!playerRef.current) return
    const player = playerRef.current

    player.ads.endLinearAdMode()
    // player.controls(true)
    setCurrentPlayingAd({})
  }

  let positionConfig = {
    TOP_LEFT: 'justify-start items-start ',
    TOP_CENTER: 'justify-center items-start ',
    TOP_RIGHT: 'justify-end items-start ',
    CENTER_LEFT: 'justify-start items-center ',
    CENTER: 'justify-center items-center ',
    CENTER_RIGHT: 'justify-end items-center ',
    BOTTOM_LEFT: { left: '60px' },
    BOTTOM_CENTER: { left: '47%' },
    BOTTOM_RIGHT: {},
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, false)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!currentPlayingAd?.button?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = currentPlayingAd?.button?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const commonAdPlayChanges = (adInfo, player, setShowSettingMenu) => {
    setCurrentPlayingAd(adInfo)
    player.trigger('ads-ad-started')
    handleAdDuration(adInfo?.ad_duration)
    setShowSettingMenu(false)
  }

  return {
    playPreRoll,
    playMidRoll,
    playPostRoll,
    playCustomRoll,
    currentPlayingAd,
    setCurrentPlayingAd,
    getStyleValue,
    handleRedirect,
    positionConfig,
    handleSkip,
    adDuration,
    skipSeconds,
  }
}

export default useAdvertisementHook

import React from 'react'

const Language3 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.816 14.364"
    >
      <g data-name="1.361879401634528e+22">
        <path
          data-name="Path 1162"
          d="M14.9 4.4h-4.2V1.911A1.913 1.913 0 0 0 8.79 0H1.911A1.913 1.913 0 0 0 0 1.911V6.5a1.913 1.913 0 0 0 1.911 1.911v.8a.764.764 0 0 0 1.306.538.34.34 0 0 0 .038-.044l.951-1.293h1.909v2.484A1.913 1.913 0 0 0 8.026 12.8h4.237l1.337 1.34a.764.764 0 0 0 1.3-.54v-.8a1.913 1.913 0 0 0 1.911-1.911V6.306A1.913 1.913 0 0 0 14.9 4.4zM4.013 7.643a.382.382 0 0 0-.306.156L2.675 9.2V8.026a.382.382 0 0 0-.382-.382h-.382A1.147 1.147 0 0 1 .764 6.5V1.911A1.147 1.147 0 0 1 1.911.764H8.79a1.147 1.147 0 0 1 1.147 1.147V4.4H8.026a1.894 1.894 0 0 0-1.128.376L5.7 2.134a.382.382 0 0 0-.7 0l-1.911 4.2a.382.382 0 0 0 .191.506.376.376 0 0 0 .157.035.382.382 0 0 0 .348-.224l.593-1.3h1.943l.027.059a1.888 1.888 0 0 0-.234.9v1.338zm1.961-3.057H4.728l.623-1.37zm10.078 6.306a1.147 1.147 0 0 1-1.152 1.147h-.382a.382.382 0 0 0-.382.382V13.6l-1.45-1.45a.382.382 0 0 0-.27-.112h-4.4a1.147 1.147 0 0 1-1.147-1.147V6.306a1.147 1.147 0 0 1 1.147-1.147H14.9a1.147 1.147 0 0 1 1.147 1.147z"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1163"
          d="M13.372 7.262h-1.525v-.764a.382.382 0 0 0-.764 0v.764H9.554a.382.382 0 0 0 0 .764h2.836a2.66 2.66 0 0 1-.927 1.662 2.666 2.666 0 0 1-.7-.9.382.382 0 1 0-.691.327 3.387 3.387 0 0 0 .717.983 2.724 2.724 0 0 1-1.074.218.371.371 0 0 0-.369.382.394.394 0 0 0 .4.382 3.414 3.414 0 0 0 1.726-.465 3.488 3.488 0 0 0 1.739.47.371.371 0 0 0 .369-.382.394.394 0 0 0-.4-.382 2.656 2.656 0 0 1-1.04-.216 3.428 3.428 0 0 0 1.017-2.077h.215a.382.382 0 0 0 0-.764z"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Language3

import React from 'react'

const PlayPause3 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 17.64 17.64"
        >
          <path
            data-name="pause-circle-svgrepo-com (2)"
            d="M9.82 1a8.82 8.82 0 1 0 8.82 8.82A8.82 8.82 0 0 0 9.82 1zm0 16.036a7.216 7.216 0 1 1 7.216-7.216 7.216 7.216 0 0 1-7.216 7.216zM7.415 6.613h1.6v6.415h-1.6zm3.207 0h1.6v6.415h-1.6z"
            transform="translate(-1 -1)"
            style={{ fill: primaryColor }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 17.64 17.64"
        >
          <path
            data-name="play-circle-svgrepo-com (2)"
            d="M9.82 1a8.82 8.82 0 1 0 8.82 8.82A8.82 8.82 0 0 0 9.82 1zm0 16.036a7.216 7.216 0 1 1 7.216-7.216 7.216 7.216 0 0 1-7.216 7.216zM8.216 6.613l4.811 3.207-4.811 3.207z"
            transform="translate(-1 -1)"
            style={{ fill: primaryColor }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause3

import React, { useContext, useEffect, useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import { useTranslation } from 'react-i18next'

const RatingReviewWidget = (props) => {
  const { t } = useTranslation()
  const { value, ratingCount, theme } = props
  const { isCustomTemplate } = useContext(AppContext)
  const [reRender, setRerender] = useState(false)
  useEffect(() => {
    if (value) {
      setRerender(true)
      setRerender(false)
    }
  }, [value])
  return (
    <>
      {ratingCount > 0 && (
        <div className={'flex flex-row'} style={{ alignItems: 'center' }}>
          {!reRender && (
            <ReactStars
              count={5}
              size={24}
              activeColor={isCustomTemplate ? theme.c2 : 'rgba(255,217,30,0.94)'}
              value={value}
              edit={false}
              isHalf={true}
            />
          )}
          <p
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className={'text-md md:text-md align-bottom ml-2'}
          >
            {ratingCount.toLocaleString()} {ratingCount === 1 ? t('rating') : t('ratings')}
          </p>
        </div>
      )}
    </>
  )
}

export default withTheme(RatingReviewWidget)

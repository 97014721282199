import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import BasicContentPlayer from '../templatePlayers/basicContentPlayer'
import TemplatePlaylistPlayer from '../templatePlayers/templatePlaylistPlayer'
import TemplateIPTVPlayer from '../templatePlayers/templateIPTVPlayer'
import TemplateTrailerPlayer from '../templatePlayers/templateTrailerPlayer'

const PassimPlayer = ({
  data,
  poster,
  controlBar = true,
  isBanner = false,
  iconPositionClass = '',
  onClickRedirect = () => {},
}) => {
  const [playerType, setPlayerType] = useState('')
  const [quality, setQuality] = useState('480')

  useEffect(() => {
    if (!isEmpty(data) && data?.type) {
      getPlayer()
    }
  }, [data])

  const getPlayer = () => {
    if (data?.type === 'CONTENT') {
      if (data?.data?.content_type === 'PLAYLIST') {
        setPlayerType('playlist')
        return
      }
      setPlayerType('basic')
    }
    if (data?.type === 'IPTV') {
      setPlayerType('iptv')
    }
    if (data?.type === 'TRAILER') {
      let qualityArr = Object.keys(data.data?.progress)
        .filter((ele) => data.data?.progress[ele] === 100)
        .sort((a, b) => Number(b) - Number(a))
      setQuality(qualityArr[0])
      setPlayerType('trailer')
    }
  }

  return (
    <div className="w-full h-full">
      {playerType === 'basic' && <BasicContentPlayer data={data?.data} controlBar={controlBar} />}
      {playerType === 'playlist' && (
        <TemplatePlaylistPlayer data={data?.data} controlBar={controlBar} />
      )}
      {playerType === 'iptv' && <TemplateIPTVPlayer data={data?.data} controlBar={controlBar} />}
      {playerType === 'trailer' && (
        <TemplateTrailerPlayer
          data={data?.data}
          poster={poster}
          quality={quality}
          controlBar={controlBar}
          isBanner={isBanner}
          iconPositionClass={iconPositionClass}
          onClickRedirect={onClickRedirect}
        />
      )}
    </div>
  )
}

export default PassimPlayer

import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CgMenuGridR, CgProfile } from 'react-icons/cg'
import { AppContext } from '../../context/app'
import { getImage, isEmpty } from '../../utils/helper'
import logo from '../../assets/basic-placehoder.jpg'
import languageLogo from '../../assets/translate.png'
import { SubscriptionContext } from '../../context/subscription'
import useAxios from 'axios-hooks'
import { GET_CATEGORY } from '../../utils/apiUrls'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CustomImage from '../customImage/customImage'
import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import { encodeURI } from 'js-base64'

const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const {
    setOpenLoginModal,
    isLogin,
    userObj,
    logoutCall,
    setModalType,
    accountObj,
    setLanguageModal,
  } = useContext(AppContext)
  const [profileToggle, setProfileToggle] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openMobileSearch, setOpenMobileSearch] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isTabVisible, setIsTabVisible] = useState(false)
  const { setOpenSubscriptionModal, activeSubscription, setSubscriptions } =
    useContext(SubscriptionContext)
  const [category, setCategory] = useState([])
  const [categoryDropdown, setCategoryDropdown] = useState(false)

  const [{ data: categoryRes }] = useAxios({
    url: GET_CATEGORY,
    method: 'get',
  })

  useEffect(() => {
    if (categoryRes && categoryRes.success) {
      setCategory(
        categoryRes.data
          .filter((item) => item.type === 'category' && item.status)
          .map((item) => ({
            ...item,
            urlArg: encodeURI(item.name),
            filters: { category: [item._id] },
          })),
      )
    }
  }, [categoryRes])

  const handleSearchButton = () => {
    if (searchText) {
      navigate(`search?search_text=${searchText}`)
      setOpenMobileSearch(false)
      setSearchText('')
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchText) {
      navigate(`search?search_text=${searchText}`)
      setSearchText('')
    }
  }

  useEffect(() => {
    let tabUrls = ['/home', '/']
    category.forEach((item) => {
      tabUrls.push(`/category/${item._id}`)
    })
    setIsTabVisible(tabUrls.includes(location.pathname))
  }, [location.pathname, category])

  const handleCatClick = (cat) => {
    setCategoryDropdown(false)
    const encoded = btoa(JSON.stringify(createSystemDefinedFilter('category', [cat._id])))
    navigate(`/category/${cat._id}?category=${encodeURI(cat.name)}&filters=${encoded}`)
  }

  const handleSubscription = () => {
    setSubscriptions([])
    setOpenSubscriptionModal(true)
  }

  return (
    <div
      className={`md:py-2   ${
        isTabVisible ? ' h-20 md:h-32 lg:h-28 xl:h-20' : 'h-12 md:h-16 xl:h-20'
      } `}
    >
      <nav
        style={{ fontFamily: 'Arial', backgroundColor: 'rgb(27, 36, 47,0.97)' }}
        className="text-md fixed top-0 z-50 h-auto w-full"
      >
        <div className="mx-auto px-2 py-3 md:py-3">
          <div className="flex justify-between ">
            <div className="flex space-x-10 ">
              <div className="self-center ml-2 md:ml-6 ">
                <Link to="/home">
                  <CustomImage
                    imageUrl={getImage(
                      accountObj?.cf_domain_name,
                      `public/static/logo.png?v=${moment(new Date()).unix()}`,
                    )}
                    imgClass="h-6 md:h-10"
                    imgStyle={{ backgroundColor: 'transparent' }}
                    // loaderClass=""
                    loaderStyle={{ background: '#5A5A5A50', hieght: '56px', width: '100px' }}
                    imgAlt={'Logo'}
                    errorPlaceholder={logo}
                  />
                </Link>
              </div>
              {/* <!-- Primary Navbar items --> */}
              <div className="hidden xl:flex items-center">
                {category &&
                  category.slice(0, 3).map((cat, index) => (
                    <div onClick={() => handleCatClick(cat)} key={index}>
                      <p
                        className={`px-2 font-semibold cursor-pointer ${
                          `/category/${cat._id}` === location.pathname
                            ? 'header-gradient'
                            : 'text-white'
                        }`}
                      >
                        {cat.name}
                      </p>
                    </div>
                  ))}
                {/*<div onClick={() => navigate('/artists')}>*/}
                {/*  <p className="px-2 text-white font-semibold cursor-pointer">Artists</p>*/}
                {/*</div>*/}
                {/*<div onClick={() => navigate('/venues')}>*/}
                {/*  <p className="px-2 text-white font-semibold cursor-pointer">Venues</p>*/}
                {/*</div>*/}
              </div>
              {category.length > 3 && (
                <div
                  className=" relative hidden xl:flex self-center"
                  style={{ marginLeft: '10px' }}
                >
                  <CgMenuGridR
                    style={{ color: '#e3e2e2', opacity: '0.85' }}
                    className="w-8 h-8"
                    onClick={() => setCategoryDropdown((categoryDropdown) => !categoryDropdown)}
                  />

                  {categoryDropdown && (
                    <ul
                      className={'absolute z-20 w-max px-2 py-3 shadow border mt-5 top-1'}
                      aria-labelledby="dropdown"
                      style={{
                        backgroundColor: 'rgb(27, 36, 47,0.90)',
                        borderColor: 'rgb(227, 226, 226,0.50)',
                        width: 'max-content',
                        top: '36%',
                        left: '50%',
                        // opacity: "0.85",
                      }}
                    >
                      {category &&
                        category.slice(3).map((cat, index) => (
                          <li onClick={() => handleCatClick(cat)} key={index}>
                            <p
                              className={`text-lg block px-4 py-2 cursor-pointer user-dropdown-menu ${
                                `/category/${cat._id}` === location.pathname
                                  ? 'header-gradient'
                                  : 'text-white'
                              }`}
                            >
                              {cat.name}
                            </p>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            {/* <!-- Secondary Navbar items --> */}
            <div className="hidden xl:flex items-center space-x-10 mr-2">
              <div className="relative mr-2 text-gray-600 lg:block border-b-2 hidden">
                <input
                  className="h-5 pl-2 pr-8 text-md bg-transparent border-none italic focus:outline-none text-white"
                  type="text"
                  name="search"
                  placeholder={t('search')}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => handleSearch(e)}
                />
                <button className="absolute right-0 top-0 mr-2" onClick={handleSearchButton}>
                  <svg
                    className="text-white h-4 w-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 56.966 56.966"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
              {!isLogin ? (
                <div className="flex justify-center">
                  <button
                    className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white px-4 rounded-full  my-1 focus:outline-none"
                    onClick={() => {
                      setModalType('register')
                      setOpenLoginModal(true)
                    }}
                  >
                    {t('register')}
                  </button>
                  <p
                    className="p-2 lg:px-4 md:mx-2 text-white cursor-pointer font-bold text-md"
                    onClick={() => {
                      setModalType('login')
                      setOpenLoginModal(true)
                    }}
                  >
                    {t('sign_in')}
                  </p>
                </div>
              ) : (
                <div className="flex justify-center">
                  <button
                    className={
                      isEmpty(activeSubscription) && isLogin
                        ? 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white px-4 rounded-full my-1 focus:outline-none'
                        : 'hidden'
                    }
                    onClick={handleSubscription}
                  >
                    {t('subscribe')}
                  </button>
                  <div
                    className="md:pl-10 ml-1 flex justify-center items-center "
                    onClick={() => setProfileToggle(!profileToggle)}
                    onBlur={(e) => {
                      const currentTarget = e.currentTarget
                      // Give browser time to focus the next element
                      requestAnimationFrame(() => {
                        // Check if the new focused element is a child of the original container
                        if (!currentTarget.contains(document.activeElement)) {
                          setProfileToggle(false)
                        }
                      })
                    }}
                    tabIndex={3}
                  >
                    <div className="flex justify-center items-center">
                      <div className="relative">
                        <div className="flex justify-center items-center space-x-3 cursor-pointer">
                          {/* <div className="w-12 h-12"> */}
                          <CgProfile
                            style={{ color: '#e3e2e2', opacity: '0.85' }}
                            className="w-8 h-8"
                          />
                          {/* </div> */}
                          <div className=" font-semibold dark:text-white text-md">
                            <div
                              style={{ color: '#e3e2e2', opacity: '0.85' }}
                              className="flex cursor-pointer  text-white"
                            >
                              <span>{userObj?.name ? userObj?.name.split(' ')[0] : ''}</span>
                              <svg
                                className={
                                  profileToggle
                                    ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                    : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                                }
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {profileToggle ? (
                          <div
                            style={{
                              backgroundColor: 'rgb(27, 36, 47,0.90)',
                              borderColor: 'rgb(227, 226, 226,0.50)',
                              // opacity: "0.85",
                            }}
                            className="absolute right-0 z-20 w-48 px-2 py-3 shadow border mt-5"
                          >
                            <ul className="py-1" aria-labelledby="dropdown">
                              <li>
                                <Link to="/profile">
                                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                    {t('my_profile')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/watchlist">
                                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                    {t('my_watchlist')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/favourites">
                                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                    {t('my_favorites')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/subscriptions">
                                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                    {t('my_subscriptions')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/transactions">
                                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                    {t('my_transactions')}
                                  </p>
                                </Link>
                              </li>
                              <li onClick={handleSubscription}>
                                <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                  {t('change_plan')}
                                </p>
                              </li>
                              {/*<li>*/}
                              {/*  <Link to="/faq">*/}
                              {/*    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">*/}
                              {/*      Help*/}
                              {/*    </p>*/}
                              {/*  </Link>*/}
                              {/*</li>*/}
                              <li onClick={logoutCall}>
                                <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                                  {t('sign_out')}
                                </p>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {accountObj?.allowed_languages && accountObj?.allowed_languages.length > 1 && (
                <div className="flex justify-center">
                  <img
                    src={languageLogo}
                    alt="Logo"
                    className="h-6 cursor-pointer"
                    onClick={() => setLanguageModal(true)}
                  />
                </div>
              )}
            </div>
            {/* <!-- Mobile menu button --> */}
            <div className="xl:hidden flex items-center">
              <button
                className={
                  isEmpty(activeSubscription) && isLogin
                    ? 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white px-4 text-sm rounded-full mr-5 focus:outline-none font-semibold'
                    : 'hidden'
                }
                onClick={handleSubscription}
              >
                {t('subscribe')}
              </button>
              <button
                className="outline-none mr-2"
                onClick={() => {
                  setOpenMobileSearch((openMobileSearch) => !openMobileSearch)
                  setOpenMobileMenu(false)
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              <button
                className="outline-none mobile-menu-button"
                onClick={() => {
                  setOpenMobileMenu((openMobileMenu) => !openMobileMenu)
                  setOpenMobileSearch(false)
                }}
              >
                {!openMobileMenu ? (
                  <svg
                    className=" w-6 h-6 text-gray-500"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
              {accountObj?.allowed_languages && accountObj?.allowed_languages.length > 1 && (
                <div className="flex mx-2 justify-center">
                  <img
                    src={languageLogo}
                    alt="Logo"
                    className="h-6 cursor-pointer"
                    onClick={() => setLanguageModal(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {openMobileSearch && (
          <div className="pt-2 relative mx-auto text-gray-600 flex ml-5 mb-3">
            <input
              className="border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none w-full"
              type="search"
              name="search"
              placeholder={t('search')}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="h-10 text-sm text-white rounded-md bg-red-500 hover:bg-red-600 px-5 mx-3"
              onClick={handleSearchButton}
            >
              {t('search')}
            </button>
          </div>
        )}

        {/* <!-- mobile menu --> */}
        <div
          className={openMobileMenu ? 'mobile-menu' : 'hidden mobile-menu bg-slate-50'}
          style={{ borderBottom: 'white' }}
        >
          <ul
            className="pl-4 pr-4 divide-y divide-stale-50"
            onClick={() => setOpenMobileMenu(false)}
          >
            {!isLogin ? (
              <>
                <li>
                  <p
                    onClick={() => {
                      setModalType('login')
                      setOpenLoginModal(true)
                    }}
                    className="block text-sm px-2 py-4 text-white font-semibold"
                  >
                    {t('sign_in')}
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => {
                      setModalType('register')
                      setOpenLoginModal(true)
                    }}
                    className="block text-sm px-2 py-4 text-white font-semibold"
                  >
                    {t('register')}
                  </p>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/profile">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_profile')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/watchlist">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_watchlist')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/favourites">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_favorites')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/subscriptions">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_subscriptions')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/transactions">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_transactions')}
                    </p>
                  </Link>
                </li>
                <li onClick={handleSubscription}>
                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                    {t('change_plan')}
                  </p>
                </li>
                {/*<li>*/}
                {/*  <Link to="/faq">*/}
                {/*    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">*/}
                {/*      Help*/}
                {/*    </p>*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li onClick={logoutCall}>
                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                    {t('sign_out')}
                  </p>
                </li>
              </>
            )}
          </ul>
        </div>
        {isTabVisible && !openMobileMenu && !openMobileSearch && (
          <div className="xl:hidden flex space-x-10 mb-1 px-4 overflow-x-auto pb-2 overflow-scroll no-scrollbar">
            {category &&
              category.map((cat, index) => (
                <div onClick={() => handleCatClick(cat)} key={index} className={'flex'}>
                  <p
                    className={`px-2 text-xs md:text-lg font-semibold  ${
                      location.pathname === `/category/${cat._id}`
                        ? 'header-gradient'
                        : 'text-white'
                    }`}
                    style={{ width: 'max-content' }}
                  >
                    {cat.name}
                  </p>
                </div>
              ))}
          </div>
        )}
      </nav>
    </div>
  )
}

export default Header

import React, { useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import AddSection from '../addSection'
import NewContentCard from '../../contentCard/newContentCard'
import NewContentCardHover from '../../contentCard/newContentCardHover'
import LoaderTile from '../../contentCard/loaderTile'

let totalRecords = 0
let currentPage = 1
let apiHit = false
const PAGE_SIZE = 15

const ContentGrid = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { t } = useTranslation()

  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { CONTENT_FILTER_XAVIER, isBrowser } = useTemplateHook()
  const [contentData, setContentData] = useState([])
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList, loading: contentListLoading }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
      totalRecords = contentList.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    if (!isEmpty(data.content_data) || !isEmpty(data.auto_payload)) {
      hitAPI(1)
    } else {
      setContentData([])
    }
  }, [data])

  const hitAPI = (page) => {
    if (isEmpty(data)) return
    let filterPayload =
      data.type === 'STATIC'
        ? { content_ids: data.content_data }
        : {
            system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
            video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
          }

    let filters = {
      filters: filterPayload,
      page: page,
      order_by:
        data.type === 'STATIC'
          ? ''
          : data?.order_by === 'title'
          ? `title.${language}`
          : data?.order_by,
      reverse: data.type === 'STATIC' ? '' : data?.reverse,
      page_size: PAGE_SIZE,
    }

    apiHit = true
    contentListCall({
      data: filters,
    })
  }

  const nextClick = () => {
    if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
      apiHit = true
      currentPage += 1

      hitAPI(currentPage)
    }
  }

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleHover = (index) => {
    if (isEdit) return
    setHoverCardIndex(index)
  }

  const handleUnHover = () => {
    if (isEdit) return
    setHoverCardIndex('')
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-6 lg:py-10`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
        zIndex: hoverState ? 2 : 1,
      }}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}

      <div className="w-full">
        <div
          style={{
            background: getStyleValue('component_background', 'primary_color', theme.c1),
            color: getStyleValue('heading', 'color', '#00ffff'),
            textAlign: getStyleValue('heading', 'alignment', 'left'),
          }}
          className={`w-full px-10 md:px-16 lg:px-20 flex justify-between items-center pb-[20px] xl:relative `}
        >
          <p
            className={` xl:text-3xl md:text-xl w-full ${getStyleValue(
              'heading',
              'font_format',
              'font-bold',
            )} mb-0`}
            style={{ fontFamily: getStyleValue('heading', 'font', '') }}
          >
            {data?.name || ''}
          </p>
        </div>

        <div className="">
          <div className="px-10 md:px-16 lg:px-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[20px]">
            {!isEmpty(contentData) &&
              contentData.map((content, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: hoverCardIndex === index && isBrowser ? 'block' : 'none',
                    }}
                    className="relative"
                    onMouseLeave={handleUnHover}
                  >
                    <NewContentCardHover
                      data={content}
                      type={'content'}
                      position={index + 1}
                      total={data.length + 10}
                      isVisible={hoverCardIndex === index && isBrowser}
                    />
                  </div>
                  <div
                    className="relative"
                    style={{
                      display: hoverCardIndex === index && isBrowser ? 'none' : 'block',
                    }}
                    onMouseOver={() => handleHover(index)}
                  >
                    {contentListLoading ? (
                      <LoaderTile height={'auto'} />
                    ) : (
                      <NewContentCard
                        textStyle={{
                          color: getStyleValue('card_style', 'color', theme.c4),
                          fontFamily: getStyleValue('card_style', 'font', ''),
                        }}
                        fontFormat={getStyleValue('card_style', 'font_format', 'font-bold')}
                        data={content}
                        type={'content'}
                        isEdit={isEdit}
                      />
                    )}
                  </div>
                </div>
              ))}
            {isEmpty(contentData) && contentListLoading
              ? [1, 2, 3, 4, 5].map((idx) => <LoaderTile height={'auto'} key={idx} />)
              : null}
          </div>
          {isEmpty(contentData) && !contentListLoading && (
            <div className="flex justify-center align-middle max-h-full">
              <p style={{ color: theme.c4 }} className="text-xl md:text-3xl">
                {isEdit ? 'no content' : t('no_content')}
              </p>
            </div>
          )}

          {!isEmpty(contentData) && (isEdit || totalRecords > currentPage * PAGE_SIZE) && (
            <div className={'flex justify-center mt-5'}>
              <button
                className="py-1 px-2 md:px-4 md:py-2 md:text-sm text-xs border-none"
                style={{
                  borderRadius: '6px',
                  textTransform: 'uppercase',
                  backgroundImage: `linear-gradient(to ${getStyleValue(
                    'button',
                    'gradient',
                    'left',
                  )}, ${getStyleValue('button', 'primary_color', '#1976d2')}, ${getStyleValue(
                    'button',
                    'secondary_color',
                    '#1976d2',
                  )})`,
                  color: getStyleValue('button', 'text_color', 'white'),
                }}
                onClick={isEdit ? null : () => nextClick()}
              >
                {isEdit ? 'View More' : t('view_more')}
              </button>
            </div>
          )}
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(ContentGrid)

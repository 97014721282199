export default {
  name: 'MAGENTA_HIGHLIGHT',
  c1: '#FFFFFF',
  c2: '#f1b814',
  c3: '#bd1e51',
  c4: '#4A4949',
  c5: '#490B3D',
  c6: '#5A0E4B',
  c7: '#FFFFFF',
  c8: '#cc007a',
  c9: '#9c1882',
  c10: '#e1e1e1',
  c11: '#54011C',
}

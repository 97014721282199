import React from 'react'
import Setting1 from '../../../assets/customPlayer/settingIcon/setting1'
import Setting2 from '../../../assets/customPlayer/settingIcon/setting2'
import Setting3 from '../../../assets/customPlayer/settingIcon/setting3'
import Setting4 from '../../../assets/customPlayer/settingIcon/setting4'
import Setting5 from '../../../assets/customPlayer/settingIcon/setting5'
import Setting6 from '../../../assets/customPlayer/settingIcon/setting6'
import Setting7 from '../../../assets/customPlayer/settingIcon/setting7'
import Setting8 from '../../../assets/customPlayer/settingIcon/setting8'

const SettingButton = (props) => {
  let { showSettingMenu, setShowSettingMenu, data } = props

  let icons = {
    SETTING1: (
      <>
        <Setting1 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING2: (
      <>
        <Setting2 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING3: (
      <>
        <Setting3 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING4: (
      <>
        <Setting4 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING5: (
      <>
        <Setting5 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING6: (
      <>
        <Setting6 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING7: (
      <>
        <Setting7 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
    SETTING8: (
      <>
        <Setting8 color={showSettingMenu ? data.active_color : data.icon_color} />
      </>
    ),
  }

  return (
    <div className="h-full" style={{ padding: '2px 0' }}>
      {data.visible ? (
        <div
          className="flex justify-center items-center w-auto h-full  cursor-pointer"
          onClick={() => setShowSettingMenu(!showSettingMenu)}
        >
          {icons[data.icon_type]}
        </div>
      ) : null}
    </div>
  )
}

export default SettingButton

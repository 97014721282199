import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyle'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import './flyerIntro.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const FlyerIntro = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({
    arrows: false,
    infinite: true,
    autoplay: data?.auto_slide,
    autoplaySpeed: 3000,
    centerMode: true,
    className: 'center',
    centerPadding: '500px',
    slidesToShow: 1,
    slidesToScroll: 1,
    // speed: 500,
    pauseOnHover: true,
    adaptiveHeight: true,
    dots: true,
    appendDots: (dots) => {
      return (
        <div
          style={{
            backgroundColor: theme.c1,
          }}
        >
          <ul style={{ marginTop: '5px' }}>
            {dots.map((info, idx) => (
              <li key={idx} className={`${info.props.className}`}>
                <div
                  style={{
                    backgroundColor: `${info.props.className === 'slick-active' ? theme.c3 : ''}`,
                  }}
                  className="ft-slick__dots--custom"
                  onClick={info.props.children.props.onClick}
                >
                  <div className="" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )
    },
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '400px',
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '150px',
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '30px',
        },
      },
    ],
  })

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  useEffect(() => {
    setSetting({
      arrows: false,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      centerMode: true,
      className: 'center',
      centerPadding: '500px',
      slidesToShow: 1,
      slidesToScroll: 1,
      // speed: 500,
      pauseOnHover: true,
      adaptiveHeight: true,
      dots: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: theme.c1,
            }}
          >
            <ul style={{ marginTop: '5px' }}>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${info.props.className === 'slick-active' ? theme.c3 : ''}`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      responsive: [
        {
          breakpoint: 1536,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '400px',
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '150px',
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '30px',
          },
        },
      ],
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <div
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c1, color: theme.c3 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="2xl:py-14 xl:py-12 py-8">
        <h2 className="sm:text-3xl text-xl font-bold 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[0.5rem] sm:mb-5 mb-[8px] ">
          {data?.title}
        </h2>
        <div
          className="w-full sm:py-3 py-[0px] xl:h-[27.5vw] sm:h-[32.5vw] h-[240px] trainers-section"
          style={{ background: `linear-gradient(to bottom, ${theme.c3}, ${theme.c11})` }}
        >
          <SliderWrapper>
            <Slider ref={sliderRef} {...setting}>
              {data?.slide_data.map((item, idx) => (
                // <div key={idx}>
                <div
                  key={idx}
                  className="sm:h-[23.1vw] h-[182px] bg-white rounded-xl overflow-hidden z-10 shadow-lg inner-div flex-shrink-0 cursor-pointer"
                  style={{ width: '100%', color: theme.c5 }}
                  onClick={() => handleRedirect(item?.link)}
                >
                  <CustomImage
                    imageUrl={getTemplateImage(item?.image_s3key)}
                    imgClass="w-[64%] h-full "
                    imgStyle={{ clipPath: 'polygon(0 0, 100% 0%, 70% 100%, 0% 100%)' }}
                    loaderClass="w-[64%] h-full"
                    loaderStyle={{ background: '#5A5A5A50' }}
                    imgAlt={'trainer'}
                  />

                  <div
                    style={{ color: theme.c6, width: '36%' }}
                    className="2xl:p-5 2xl:pt-5 xl:p-2 lg:p-1 p-[12px] pt-[12px]"
                  >
                    <h2 className="2xl:text-[28px] xl:text-2xl md:text-base sm:text-sm sm:mb-4 text-[10px] font-bold mb-[8px]">
                      {item?.heading}
                    </h2>
                    <p className="xl:text-base md:text-sm sm:text-xs text-[10px] sm:leading-5 leading-1  fitness-trainer-line-clamp ">
                      {item?.description}
                    </p>
                  </div>
                </div>
                // </div>
              ))}
            </Slider>
          </SliderWrapper>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(FlyerIntro)

import React, { useContext } from 'react'
import { getImage } from '../../utils/helper'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'
import CustomImage from '../../components/customImage/customImage'
import { withTheme } from 'theming'
import FreeIcon from '../../assets/free-channel-icon.png'
import PaidIcon from '../../assets/paid-crown-icon.svg'

const ChannelCard = ({ item, theme }) => {
  const { accountObj } = useContext(AppContext)
  const navigate = useNavigate()

  const handleDetail = (item) => {
    navigate(`/channel/${item._id}`)
  }

  return (
    <div className="">
      <div
        style={{ aspectRatio: '440/162', background: '#fff' }}
        className={`  w-full cursor-pointer flex rounded-lg overflow-hidden`}
        onClick={() => handleDetail(item)}
      >
        <div style={{ width: '35%' }} className="relative flex items-center justify-center">
          <CustomImage
            imageUrl={getImage(accountObj?.cf_domain_name, item?.logo?.website?.s3_key)}
            imgClass=""
            imgStyle={{ width: '65%' }}
            loaderClass={'h-32'}
            imgAlt={item.name}
          />
          {item.is_restricted ? (
            <img
              src={PaidIcon}
              style={{ width: '25px', height: '25px', margin: '5%' }}
              className="absolute bottom-0 left-0"
              alt="PaidIcon"
            />
          ) : (
            <img
              src={FreeIcon}
              style={{ width: '25px', height: '25px', margin: '5%' }}
              className="absolute bottom-0 left-0"
              alt="freeIcon"
            />
          )}
        </div>
        <div style={{ width: '65%' }} className="h-full">
          <CustomImage
            imageUrl={getImage(accountObj?.cf_domain_name, item?.thumbnail?.website?.s3_key)}
            imgClass="w-full h-full"
            loaderClass={'h-32'}
            imgAlt={item.name}
          />
        </div>
      </div>
      <p className="w-full text-white mt-3 text-sm lg:text-xl font-semibold  line-clamp-1">
        {item.name}
      </p>
    </div>
  )
}

export default withTheme(ChannelCard)

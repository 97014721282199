import React, { useState } from 'react'

const NewShareIcon = ({ color = '#fff', hoverColor = '#bbfa33' }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <g data-name="Layer 2">
        <g data-name="invisible box">
          <path data-name="Rectangle 1148" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
        </g>
        <g data-name="Q3 icons">
          <path
            data-name="Path 889"
            d="m13.446 9.213 3.842 3.881-3.842 3.842v-2.783l-1.646.078a23.1 23.1 0 0 0-6.311.941 10.234 10.234 0 0 1 6.546-2.94l1.411-.118v-2.9M12.309 6a.47.47 0 0 0-.431.51v4.155c-5.958.588-9.8 4.665-9.878 8.781 0 .235.078.392.235.392s.274-.118.431-.431c.941-2.117 3.058-3.332 9.212-3.606v3.8a.47.47 0 0 0 .431.51.353.353 0 0 0 .314-.157l6.468-6.468a.588.588 0 0 0 0-.784l-6.468-6.546A.353.353 0 0 0 12.309 6z"
            transform="translate(-1.639 -3.714)"
            style={{ fill: isHovered ? hoverColor : color }}
          />
        </g>
      </g>
    </svg>
  )
}

export default NewShareIcon

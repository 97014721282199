import React from 'react'

const Resolution4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.528 14.995"
    >
      <path
        data-name="Path 1121"
        d="M7 21h7.664"
        transform="translate(-2.568 -6.605)"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: color,
          strokeWidth: '1.2px',
        }}
      />
      <path
        data-name="Path 1122"
        d="M13.5 7v3.066m0 1.533v-1.533m0 0 1.05-1.2M16.182 7 14.55 8.865m0 0 1.632 2.735"
        transform="translate(-4.087 -3.334)"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: color,
          strokeWidth: '1.2px',
        }}
      />
      <path
        data-name="Path 1123"
        d="m8.8 7-2.3 3.449h2.682V11.6"
        transform="translate(-2.451 -3.334)"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: color,
          strokeWidth: '1.2px',
        }}
      />
      <path
        data-name="Path 1124"
        d="M2 13.27V3.46A.46.46 0 0 1 2.46 3h14.408a.46.46 0 0 1 .46.46v9.81a.46.46 0 0 1-.46.46H2.46a.46.46 0 0 1-.46-.46z"
        style={{ fill: 'none', stroke: color, strokeWidth: '1.2px' }}
        transform="translate(-1.4 -2.4)"
      />
    </svg>
  )
}

export default Resolution4

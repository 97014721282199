import React from 'react'
import VideoPlaceholder from '../../assets/video-content.png'
import CustomImage from '../customImage/customImage'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'
import AudioIcon from '../../assets/audio-content-badge.svg'

const ContentCard = (props) => {
  const { data, background, fontFormat, textStyle, type, isEdit = false, theme } = props
  // const navigate = useNavigate()
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const handleClick = () => {
    if (isEdit) return
    if (data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST') {
      customNavigate('DIRECT', `/playlist/${data.content_id}`)
      return
    }
    if (data.video_type === 'MPVP' || data.audio_type === 'MPAP') {
      customNavigate('DIRECT', `/parent-content/${data.content_id}`)
    } else {
      customNavigate('DIRECT', `/content/${data.content_id}`)
    }
  }

  return (
    <div
      className="relative rounded-lg overflow-hidden shadow-xl transform transition duration-500 ease z-0"
      onClick={handleClick}
      style={{ background: background ? background : theme.c1 }}
    >
      <div className="bg-black flex justify-center relative">
        {data.asset_type === 'AUDIO' && (
          <img
            style={{ width: '24px', height: '24px' }}
            className="absolute right-0 m-2"
            src={AudioIcon}
            alt="AudioIcon"
          />
        )}
        <CustomImage
          imageUrl={getTemplateImage(data?.thumbnail)}
          imgClass="w-full h-full object-center"
          loaderClass={'h-20 md:h-32'}
          imgAlt={data.title}
          errorPlaceholder={VideoPlaceholder}
        />
      </div>
      <div
        style={textStyle ? { ...textStyle } : { color: theme.c4 }}
        // style={{ color: '#fff' }}
        className="px-[1rem] py-[0.5rem] shadow-2xl"
      >
        <div className="mb-[0.5rem] flex justify-between items-center ">
          <p
            className={`text-xs md:text-base ${
              fontFormat ? fontFormat : 'font-bold'
            } line-clamp-1 `}
          >
            {type === 'content' ? data.title : data.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ContentCard)

import { isEmpty } from 'lodash'
import React from 'react'
import { withTheme } from 'theming'
import { createCustomDefinedFilter } from '../../utils/filterPayloadCreator'
import { encodeURI } from 'js-base64'
import { useNavigate } from 'react-router'

const CustomMetadata = ({
  customMetadata = {},
  theme,
  valueColor = '',
  valueFontFamily = '',
  valueFontFormat = 'font-semibold',
  parentDivClass = 'flex justify-between mt-2',
  nameClass = 'text-sm lg:w-[29%]',
  valueClass = 'lg:w-[70%] flex flex-col  xl:flex-row',
  nameColor = '',
}) => {
  const navigate = useNavigate()

  const handleCustomMetadata = (id, value, name, type) => {
    if (type === 'link') {
      const url = value.link.includes('http') ? value.link : `http://${value.link}`
      window.open(url, '_blank')
      return
    }
    value = value.trim()
    const encoded = encodeURI(JSON.stringify(createCustomDefinedFilter(id, name, value)))
    navigate(`/content/filter/${encodeURI(value)}-${encodeURI(name)}?filters=${encoded}`)
  }

  return (
    <>
      {!isEmpty(customMetadata) &&
        customMetadata.map((item, index) => {
          return !isEmpty(item.value) ? (
            <div className={`${parentDivClass}`} key={index}>
              <p style={{ color: nameColor ? nameColor : '' }} className={`${nameClass}`}>
                {item.name}
              </p>
              <div
                style={{
                  color: valueColor ? valueColor : theme.c3,
                  fontFamily: valueFontFamily,
                }}
                className={`${valueFontFormat}   ${valueClass}`}
              >
                <p className=" text-right lg:text-left">
                  {item.type === 'checkboxes' ? (
                    item.value.map((obj, index) => (
                      <span
                        key={index}
                        onClick={() =>
                          handleCustomMetadata(item.id, obj.name, item.name, item.type)
                        }
                        className={'cursor-pointer  hover:underline'}
                      >
                        {index !== 0 && <span className={''}>{' , '}</span>}
                        {obj.name}
                      </span>
                    ))
                  ) : item.type === 'link' ? (
                    <span
                      key={index}
                      onClick={() =>
                        handleCustomMetadata(item.id, item.value, item.name, item.type)
                      }
                      className={'cursor-pointer  hover:underline'}
                    >
                      {item.value.placeholder}
                    </span>
                  ) : (
                    <span
                      key={index}
                      onClick={() =>
                        handleCustomMetadata(item.id, item.value, item.name, item.type)
                      }
                      className={'cursor-pointer  hover:underline'}
                    >
                      {item.value}
                    </span>
                  )}
                </p>
              </div>
            </div>
          ) : null
        })}
    </>
  )
}

export default withTheme(CustomMetadata)

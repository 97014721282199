import React, { useEffect, useState } from 'react'
// import { Link, useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import PhoneIconComponent from '../imgComponents/phoneIcon'
import EmailIconComponent from '../imgComponents/emailIcon'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'

const UImediaFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  theme,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const {
    customNavigate,
    getTemplateImage,
    FBLogo,
    InstaLogo,
    TGLogo,
    YTLogo,
    TwitterLogo,
    BitchuteLogo,
    RumbleLogo,
    BoxcastLogo,
    TikTokLogo,
    BasicPlaceholder,
    getExternalLink,
  } = useTemplateHook()

  //   const [searchParams] = useSearchParams()
  const location = useLocation()
  const [isWordpressPages, setIsWordpressPages] = useState(false)

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  useEffect(() => {
    if (location) {
      if (['/be-a-guest', '/be-a-host', '/be-the-change'].includes(location.pathname)) {
        setIsWordpressPages(true)
      } else {
        setIsWordpressPages(false)
      }
    }
  }, [location])

  //   const mobileApp = searchParams.get('mobileApp') === 'true'

  const socialIcons = {
    facebook: FBLogo,
    twitter: TwitterLogo,
    instagram: InstaLogo,
    telegram: TGLogo,
    youtube: YTLogo,
    bitchute: BitchuteLogo,
    tiktok: TikTokLogo,
    rumble: RumbleLogo,
    boxcast: BoxcastLogo,
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <>
      {/* {!mobileApp && ( */}
      <footer
        className={`footer-1  pb-4 ${isEdit && hoverState && 'component-hover-class'}`}
        style={{
          backgroundColor: getStyleValue('component_background', 'primary_color', '#31383c'),
        }}
        onMouseOver={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {!isWordpressPages && (
          <div
            style={{
              backgroundColor: getStyleValue('margin_background', 'color', theme.c1),
            }}
            className="h-8 md:h-12 lg:h-20 xl:h-16 2xl:h-32"
          />
        )}
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onDelete={() => onDelete(id)}
            onEdit={() => handleModal()}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <div
          className="lg:pl-[2.7%] lg:pr-[8.1%] py-7  mx-auto  relative mb-8  "
          style={{
            borderBottom: `2px solid ${getStyleValue(
              'component_background',
              'secondary_color',
              '#5fc1f2',
            )}`,
          }}
        >
          <p
            className={`${getStyleValue(
              'copyright',
              'font_format',
              '',
            )} absolute sm:-bottom-[45px] -bottom-8 left-0 text-center text-white w-full sm:text-sm text-xs lg:text-base 2xl:text-lg`}
            style={{
              fontFamily: getStyleValue('copyright', 'font', 'sans-serif'),
              textAlign: getStyleValue('copyright', 'alignment'),
              color: getStyleValue('copyright', 'color', 'white'),
            }}
          >
            © {data?.copyright_text}
          </p>
          <div className="flex lg:flex-row flex-col items-center lg:items-start justify-between">
            <div className="flex justify-end lg:pb-0 pb-9">
              <div>
                <img
                  src={getTemplateImage(data?.image_s3key)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = BasicPlaceholder
                  }}
                  alt="logo"
                  className="2xl:w-[140px] 2xl:h-[61px] xl:w-28 xl:h-12 lg:w-20 lg:h-9 w-[78px] h-[34px] cursor-pointer"
                  onClick={() => customNavigate('DIRECT', '/home', isEdit)}
                />
              </div>
            </div>
            <div
              className=" lg:grid gap-x-16 lg:pb-0 pb-6"
              style={{
                width: '55%',
                gridAutoFlow: 'column',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(4, max-content)',
                // gridColumnGap: '50px',
                gridRowGap: '0px',
                color: getStyleValue('sitemap', 'color', '#FFFFFF'),
                fontFamily: getStyleValue('sitemap', 'font', 'sans-serif'),
              }}
            >
              {data?.column_one_menus.map((page, index) => (
                <div
                  style={{
                    textAlign: getStyleValue('sitemap', 'alignment', ''),
                  }}
                  className={'lg:text-left text-center'}
                  key={index}
                >
                  <p
                    className={`lg:px-6 cursor-pointer sm:text-sm text-xs lg:text-base 2xl:text-lg h-full sm:mb-0 mb-3 lg:w-fit ${getStyleValue(
                      'sitemap',
                      'font_format',
                      '',
                    )}`}
                    style={{ fontFamily: 'sans-serif' }}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
              {data?.column_two_menus.map((page, index) => (
                <div
                  style={{
                    textAlign: getStyleValue('sitemap', 'alignment', ''),
                  }}
                  className={'lg:text-left text-center'}
                  key={index}
                >
                  <p
                    className={`lg:px-6 cursor-pointer sm:text-sm text-xs lg:text-base 2xl:text-lg h-full sm:mb-0 mb-3 lg:w-fit ${getStyleValue(
                      'sitemap',
                      'font_format',
                      '',
                    )}`}
                    // style={{ fontFamily: 'sans-serif' }}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
            <div className={`text-white lg:text-left ${!isEdit && 'text-center'}`}>
              {data?.button?.enabled && (
                <button
                  className=" lg:px-4 px-4 xl:py-2 lg:py-1 py-2 mb-8 sm:text-sm text-xs lg:text-base 2xl:text-lg border-0"
                  style={{
                    outline: 'none',
                    backgroundImage: getStyleValue(
                      'footer_button',
                      'button_background',
                      'linear-gradient(454deg, rgb(4, 96, 133) -17%, rgb(95, 193, 242) 53%, rgb(4, 96, 133) 108%)',
                    ),
                    background: getStyleValue(
                      'footer_button',
                      'button_background',
                      'linear-gradient(454deg, rgb(4, 96, 133) -17%, rgb(95, 193, 242) 53%, rgb(4, 96, 133) 108%)',
                    ),
                    borderRadius: '6px',
                    fontWeight: '700',
                    fontFamily: getStyleValue('footer_button', 'font', 'sans-serif'),
                    color: getStyleValue('footer_button', 'color', '#1b242f'),
                  }}
                  onClick={() => handleRedirect(data.button?.link)}
                >
                  {data.button?.button_text}
                </button>
              )}

              <p
                style={{
                  fontFamily: getStyleValue('header', 'font', 'sans-serif'),
                  textAlign: getStyleValue('header', 'alignment'),
                  color: getStyleValue('header', 'color', 'white'),
                }}
                className={`${getStyleValue(
                  'header',
                  'font_format',
                  '',
                )} mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg `}
              >
                {data?.header}
              </p>
              {data?.email && (
                <p className="flex mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg justify-center lg:justify-start">
                  <a
                    href={`mailto:${data?.email}`}
                    title={`mailto:${data?.email}`}
                    rel="noopener noreferrer"
                    className="flex text-decoration-none"
                    style={{ color: getStyleValue('email', 'color', 'white') }}
                  >
                    <div className="mr-2">
                      <EmailIconComponent color={getStyleValue('email', 'color', 'white')} />
                    </div>
                    <span
                      style={{
                        fontFamily: getStyleValue('email', 'font', 'sans-serif'),
                        textAlign: getStyleValue('email', 'alignment'),
                      }}
                      className={`${getStyleValue('email', 'font_format', '')} mx-1`}
                    >
                      {data?.email}
                    </span>
                  </a>
                </p>
              )}

              {data?.phone && (
                <p className="flex mb-2 sm:text-sm text-xs lg:text-base 2xl:text-lg justify-center lg:justify-start">
                  <a
                    href={`tel:${data?.phone}`}
                    title={`tel:${data?.phone}`}
                    rel="noopener noreferrer"
                    className="flex text-decoration-none"
                    style={{ color: getStyleValue('phone', 'color', 'white') }}
                  >
                    <div className="mr-2">
                      <PhoneIconComponent color={getStyleValue('phone', 'color', 'white')} />
                    </div>
                    <span
                      style={{
                        fontFamily: getStyleValue('phone', 'font', 'sans-serif'),
                        textAlign: getStyleValue('phone', 'alignment'),
                      }}
                      className={`${getStyleValue('phone', 'font_format', '')} mx-1`}
                    >
                      {data?.phone}
                    </span>
                  </a>
                </p>
              )}

              <div className="flex lg:justify-start justify-center items-center">
                {data?.social_handles.map((item, index) => (
                  <div className=" md:w-6 w-4 ml-6 first:ml-0 mt-1 inline-block" key={index}>
                    <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                      <img className="w-full" src={socialIcons[item.name]} alt={`${item.name}`} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* )} */}
    </>
  )
}

export default withTheme(UImediaFooter)

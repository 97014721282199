export const createSystemDefinedFilter = (key, ids) => ({
  filters: {
    system_defined: [
      {
        key: key,
        name: key,
        type: 'system_defined',
        filter_type: 'AND',
        operator: 'eq',
        values: ids,
      },
    ],
    custom_defined: [],
  },
})

export const createCustomDefinedFilter = (id, name, value) => ({
  filters: {
    custom_defined: [
      {
        key: id,
        name: name,
        type: 'custom_defined',
        filter_type: 'AND',
        operator: 'eq',
        values: [value],
      },
    ],
    system_defined: [],
  },
})

import React, { useContext, useRef, useState } from 'react'
import { withTheme } from 'theming'
import { AppContext } from '../../../context/app'

const Accordion = ({ title, content, theme }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('0px')
  const [rotate, setRotate] = useState(
    'transform duration-700 ease inline-block md:w-10 md:h-10 w-4 h-4 -rotate-90',
  )

  const contentSpace = useRef(null)

  function toggleAccordion() {
    setActive(!active)
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`)
    setRotate(
      active
        ? ' transform duration-700 ease inline-block md:w-10 md:h-10 w-4 h-4 -rotate-90'
        : 'transform duration-700 ease inline-block md:w-10 md:h-10 w-4 h-4 rotate-0',
    )
  }

  return (
    <div
      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
      className="flex flex-col text-sm md:text-lg"
    >
      <div className="flex cursor-pointer ">
        <button
          className="md:py-4 py-3 box-border appearance-none focus:outline-none flex items-center justify-start"
          onClick={toggleAccordion}
        >
          <svg
            className={rotate}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <p
          className="text-footnote light px-2 py-2 md:py-8 md:px-8 md:font-bold"
          onClick={toggleAccordion}
        >
          {title}
        </p>
      </div>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto overflow-y-hidden transition-max-height duration-700 ease-in-out"
      >
        <div className="pb-4 px-4 md:px-16 ml-2">{content}</div>
      </div>
    </div>
  )
}

export default withTheme(Accordion)

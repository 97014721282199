import React, { useEffect, useRef, useState } from 'react'
import CustomImage from '../../components/customImage/customImage'
import useTemplateHook from '../../components/template/templateHook'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import useAxios from 'axios-hooks'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import SliderWrapper from './_slickSliderStyle'
import PassimPlayer from '../../components/template/rosemary/passimPlayer'

const EclipseSignIn = (props) => {
  let { isEdit = false, theme, data, setModalState } = props

  console.log('props', data)
  const sliderRef = useRef(null)
  const { t } = useTranslation()

  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const [setting, setSetting] = useState({})

  const [errorData, setErrorData] = useState({})

  const [hoverState, setHoverState] = useState('')
  // const {} = useContext(AppContext)
  const {
    customNavigate,
    getTemplateImage,
    setLoader,
    setOpenForgotPasswordModal,
    setUser,
    LOGIN_API,
    EditIcon,
  } = useTemplateHook()

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}

    if (loginEmail.trim().length === 0) {
      isFormValid = false
      temp.loginEmail = `${t('email_empty')}!`
    }

    if (loginPassword.trim().length === 0) {
      isFormValid = false
      temp.loginPassword = `${t('password_empty')}!`
    }
    setErrorData({ ...temp })
    return isFormValid
  }

  const handleLogin = async () => {
    if (isEdit) return

    if (validateInfo('login')) {
      loginCall({
        data: {
          email_id: loginEmail,
          password: loginPassword,
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data)
      customNavigate('DIRECT', '/')
    }
  }, [loginRes])

  useEffect(() => {
    setLoader(loginResLoading)
  }, [loginResLoading])

  useEffect(() => {
    setSetting({
      dots: true,
      autoplay: data?.carousel_data?.auto_slide,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: false,
      adaptiveHeight: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              background: 'transparent',
              borderRadius: '0px',
              padding: '0px',
              zIndex: 0,
            }}
          >
            <ul style={{ marginTop: '5px' }} className="mb-0">
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active' ? '#757575' : '#'
                      }`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="" />
        </div>
      ),
    })
    const sliderElement = sliderRef.current
    if (sliderElement) {
      if (data?.carousel_data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleModal = (data) => {
    setModalState({ ...data })
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <div
          className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-4 right-4 z-10 flex justify-center items-center cursor-pointer"
          onClick={() => handleModal({ modalKey: 'eclipseSignInModal', data: data })}
        >
          <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
        </div>
      )}
      <div
        className={`relative w-full h-full flex ${
          data?.aligned === 'LEFT' ? 'flex-row' : 'flex-row-reverse'
        } justify-center xl:justify-between xl:pl-[6%] xl:pr-[6%] 2xl:pl-[12.8125%] 2xl:pr-[12.083%] xl:pt-[30px] `}
        style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      >
        <div className="w-full xl:w-1/2 flex justify-center xl:justify-start">
          <div className="w-[80%] md:w-[400px] xl:w-[500px] flex flex-col pb-10 xl:pb-0 items-center">
            <div
              className={` w-full flex justify-center ${
                isEdit && hoverState === 'IMAGE' && 'component-hover-class'
              } xl:px-[50px] relative`}
              onMouseOver={() => handleHoverItems('IMAGE')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'IMAGE' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'logoModal',
                      data: data,
                      modalSpecificData: {
                        imgWidth: 255,
                        imgHeight: 101,
                        divWidth: '17.67rem',
                        divHeight: '7rem',
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <CustomImage
                imageUrl={getTemplateImage(data?.logo)}
                imgClass="w-[146px] lg:w-[255px]"
                loaderClass=""
                imgAlt={'image_s3key'}
              />
            </div>
            <div
              className={`w-full ${
                isEdit && hoverState === 'LOGIN_TEXT' && 'component-hover-class'
              }  mt-8 xl:px-[50px] relative`}
              onMouseOver={() => handleHoverItems('LOGIN_TEXT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'LOGIN_TEXT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'loginTextModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['login_heading', 'login_description'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <p
                style={{
                  color: getStyleValue('login_heading', 'color', theme.c5),
                  fontFamily: getStyleValue('login_heading', 'font', ''),
                  textAlign: getStyleValue('login_heading', 'alignment', ''),
                }}
                className={` mb-0 text-xl lg:text-[25px] font-bold ${getStyleValue(
                  'login_heading',
                  'font_format',
                  '',
                )}`}
              >
                {data?.login_text}
              </p>
              <p
                style={{
                  color: getStyleValue('login_description', 'color', theme.c3),
                  fontFamily: getStyleValue('login_description', 'font', ''),
                  textAlign: getStyleValue('login_description', 'alignment', ''),
                }}
                className={` mb-0 mt-3 text-xs ${getStyleValue(
                  'login_description',
                  'font_format',
                  '',
                )}`}
              >
                {data?.login_description}
              </p>
            </div>

            <div
              className={`w-full ${
                isEdit && hoverState === 'INPUT' && 'component-hover-class'
              }  mt-10 xl:px-[50px] relative`}
              onMouseOver={() => handleHoverItems('INPUT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'INPUT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'inputModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: [
                          'email_password_text',
                          'email_password_input',
                          'remember_text',
                        ],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0  text-sm lg:text-base`}
              >
                {data?.email_text}
              </p>
              <input
                type="email"
                name="loginEmail"
                value={loginEmail}
                onChange={(e) => handleChange(e, setLoginEmail)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
              />
              {errorData?.loginEmail && (
                <div className="error-message">
                  <p>{errorData.loginEmail}</p>
                </div>
              )}
              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-4 text-sm lg:text-base`}
              >
                {data?.password_text}
              </p>
              <input
                type="password"
                name="loginPassword"
                value={loginPassword}
                onChange={(e) => handleChange(e, setLoginPassword)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none"
              />
              {errorData?.loginPassword && (
                <div className="error-message">
                  <p>{errorData.loginPassword}</p>
                </div>
              )}

              <div className="mt-4 flex justify-between">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <input
                      id="remember"
                      type="checkbox"
                      defaultChecked={true}
                      className="h-4 w-4"
                    />
                  </div>
                  <div className=" ml-2">
                    <label
                      htmlFor="remember"
                      style={{
                        color: getStyleValue('remember_text', 'color', theme.c4),
                        fontFamily: getStyleValue('remember_text', 'font', ''),
                        textAlign: getStyleValue('remember_text', 'alignment', ''),
                      }}
                      className={` text-sm lg:text-base ${getStyleValue(
                        'remember_text',
                        'font_format',
                        '',
                      )}`}
                    >
                      {data?.remember_text}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`mt-10 w-full text-center ${
                isEdit && hoverState === 'BUTTON' && 'component-hover-class'
              } xl:px-[50px] relative`}
              onMouseOver={() => handleHoverItems('BUTTON')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'BUTTON' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'buttonModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['login_button', 'account_text', 'forgot_text'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <button
                style={{
                  backgroundImage: `linear-gradient(to ${getStyleValue(
                    'login_button',
                    'gradient',
                    'left',
                  )}, ${getStyleValue('login_button', 'primary_color', theme.c5)}, ${getStyleValue(
                    'login_button',
                    'secondary_color',
                    theme.c5,
                  )})`,
                  color: getStyleValue('login_button', 'color', theme.c7),
                }}
                className="w-full  py-2 rounded xl:text-lg font-medium focus:outline-none"
                onClick={handleLogin}
              >
                {data?.login_button_text}
              </button>
              <p
                className={`mb-0 mt-8 text-sm lg:text-base ${getStyleValue(
                  'account_text',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('account_text', 'color', theme.c3),
                  fontFamily: getStyleValue('account_text', 'font', ''),
                  textAlign: getStyleValue('account_text', 'alignment', ''),
                }}
              >
                {data?.account_text}{' '}
                <span
                  className="font-semibold cursor-pointer"
                  style={{ color: getStyleValue('account_text', 'register_text_color', theme.c5) }}
                  onClick={() => {
                    customNavigate('DIRECT', '/signup')
                  }}
                >
                  {data?.register_text}
                </span>
              </p>
              <p
                className={`mb-0 mt-6 italic cursor-pointer text-sm lg:text-base ${getStyleValue(
                  'forgot_text',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('forgot_text', 'color', theme.c5),
                  fontFamily: getStyleValue('forgot_text', 'font', ''),
                  textAlign: getStyleValue('forgot_text', 'alignment', ''),
                }}
                onClick={() => {
                  setOpenForgotPasswordModal(true)
                }}
              >
                {data?.forgot_text}
              </p>
            </div>
          </div>
        </div>
        <div className=" w-1/2  hidden xl:block ">
          <div className=" hidden xl:block xl:h-[676px] w-[604px]">
            {!isEmpty(setting) && (
              <SliderWrapper className="">
                <Slider ref={sliderRef} {...setting}>
                  {data?.carousel_data?.slide_data.map((slide, idx) => (
                    <div key={idx} className={`xl:h-[636px]`} style={{ width: '100%' }}>
                      <div
                        className={`rounded-[20px] overflow-hidden w-full h-full `}
                        onClick={() => handleRedirect(slide?.link)}
                      >
                        {isEdit || !slide?.player?.type ? (
                          <CustomImage
                            imageUrl={getTemplateImage(slide?.image_s3_key)}
                            imgClass="w-full h-full"
                            loaderClass="w-full h-full"
                            loaderStyle={{
                              background: '#5A5A5A50',
                            }}
                            imgAlt={'category'}
                            onClickRedirect={() => handleRedirect(slide?.link)}
                          />
                        ) : (
                          <div className="w-full h-full">
                            <PassimPlayer
                              data={slide?.player}
                              poster={getTemplateImage(slide?.image_s3key)}
                              controlBar={false}
                              isBanner={true}
                              onClickRedirect={() => handleRedirect(slide.link)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </SliderWrapper>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(EclipseSignIn)

import React from 'react'

const Resolution7 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.079 16.079"
    >
      <path
        d="M3.193 17.079a.731.731 0 0 1-.731-.731V9.04a.731.731 0 0 1 1.462 0v7.309a.731.731 0 0 1-.731.73zm5.116-5.116v4.385a.731.731 0 1 0 1.462 0v-4.385a.731.731 0 0 0-1.462 0zM9.04 1a.731.731 0 0 0-.731.731v6.578h-.731a.731.731 0 0 0 0 1.462H10.5a.731.731 0 1 0 0-1.462h-.73V1.731A.731.731 0 0 0 9.04 1zm6.578 15.348v-9.5a.731.731 0 1 0-1.462 0v9.5a.731.731 0 1 0 1.462 0zM1 6.116a.731.731 0 0 0 .731.731h2.923a.731.731 0 0 0 0-1.462h-.731V1.731a.731.731 0 1 0-1.462 0v3.654h-.73A.731.731 0 0 0 1 6.116zm15.348-2.923h-.731V1.731a.731.731 0 1 0-1.462 0v1.462h-.731a.731.731 0 0 0 0 1.462h2.923a.731.731 0 1 0 0-1.462z"
        transform="translate(-1 -1)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Resolution7

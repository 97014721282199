import React, { useState } from 'react'
import logo from '../../../assets/basic-placehoder.jpg'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'

const DefaultFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onSwap,
}) => {
  const { data, id } = componentData
  const {
    customNavigate,
    getTemplateImage,
    FBDefaultLogo,
    InstaDefaultLogo,
    TGDefaultLogo,
    YTDefaultLogo,
    TwitterDefaultLogo,
    BitchuteDefaultLogo,
    RumbleDefaultLogo,
    BoxcastDefaultLogo,
    TikTokDefaultLogo,
    getExternalLink,
  } = useTemplateHook()

  const socialIcons = {
    facebook: FBDefaultLogo,
    twitter: TwitterDefaultLogo,
    instagram: InstaDefaultLogo,
    telegram: TGDefaultLogo,
    youtube: YTDefaultLogo,
    bitchute: BitchuteDefaultLogo,
    tiktok: TikTokDefaultLogo,
    rumble: RumbleDefaultLogo,
    boxcast: BoxcastDefaultLogo,
  }

  const [hoverState, setHoverState] = useState('')

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }
  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }
  return (
    <>
      <footer
        className={`footer-1 mt-6 md:mt-1 xl:mt-4 xl:mb-4 relative ${
          isEdit && hoverState && 'component-hover-class'
        }`}
        style={{ background: theme?.c1 }}
        onMouseOver={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onEdit={handleModal}
            onDelete={() => onDelete(id)}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <div className="md:px-12 mt-[16px] md:mt-12 lg:mt-20 xl:mt-1 py-[16px] mx-auto">
          <div className="lg:flex lg:items-center ">
            <div className="hidden xl:flex md:px-4">
              <div onClick={() => customNavigate('DIRECT', '/home', isEdit)}>
                <CustomImage
                  imageUrl={getTemplateImage(data?.image_s3key)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = logo
                  }}
                  loaderStyle={{ background: '#5A5A5A50', hieght: '56px', width: '100px' }}
                  imgAlt={'Logo'}
                  errorPlaceholder={logo}
                  imgClass="h-6 md:h-16 cursor-pointer"
                />
              </div>
            </div>
            <div style={{ color: theme?.c4 }} className="flex justify-evenly">
              {!isEmpty(data.site_map) &&
                data.site_map.map((page, index) => (
                  <div key={index}>
                    {page?.text.length > 0 && (
                      <p
                        onClick={() => handleRedirect(page?.link)}
                        className={'text-center'}
                        key={index}
                        style={{ marginBlockEnd: 0 }}
                      >
                        <p
                          className="lg:px-6 inline-block cursor-pointer text-xs 2xl:text-lg md:text-base"
                          style={{ marginBlockEnd: 0 }}
                        >
                          {page.text}
                        </p>
                      </p>
                    )}
                  </div>
                ))}
            </div>
            {!isEmpty(data.social_handles) && (
              <div className="flex-1  text-right ">
                {data?.social_handles.map((socialHandleItem, index) => (
                  <div
                    className="hidden md:w-6 w-5 mx-[16px] mt-1 lg:inline-block align-middle"
                    key={index}
                  >
                    <a
                      href={getExternalLink(socialHandleItem.url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={socialIcons[socialHandleItem.name]}
                        alt={`${socialHandleItem.name}`}
                        className="w-full"
                      />
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!isEmpty(data.social_handles) && (
            <div className="lg:hidden flex justify-center mt-[8px] md:mt-4">
              {data?.social_handles.map((socialHandleItem, index) => (
                <div className="px-[4px] w-8 flex items-center" key={index}>
                  <a href={getExternalLink(socialHandleItem.url)} target="_blank" rel="noreferrer">
                    <img
                      src={socialIcons[socialHandleItem.name]}
                      alt={`${socialHandleItem.name}`}
                      className="w-full"
                    />
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </footer>
    </>
  )
}

export default withTheme(DefaultFooter)

import React from 'react'

const PlayPause7 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 12.081 16.913"
        >
          <path
            d="M473.624 584.1a.6.6 0 0 1-.6.6h-1.208a.6.6 0 0 1-.6-.6v-13.288a.6.6 0 0 1 .6-.6h1.208a.6.6 0 0 1 .6.6V584.1zm0-15.1h-2.416a1.209 1.209 0 0 0-1.208 1.208v14.5a1.209 1.209 0 0 0 1.208 1.208h2.416a1.208 1.208 0 0 0 1.208-1.208v-14.5a1.208 1.208 0 0 0-1.208-1.208zm7.248 15.1a.6.6 0 0 1-.6.6h-1.208a.6.6 0 0 1-.6-.6v-13.288a.6.6 0 0 1 .6-.6h1.208a.6.6 0 0 1 .6.6V584.1zm0-15.1h-2.416a1.208 1.208 0 0 0-1.208 1.208v14.5a1.208 1.208 0 0 0 1.208 1.208h2.416a1.209 1.209 0 0 0 1.208-1.208v-14.5a1.209 1.209 0 0 0-1.207-1.208z"
            transform="translate(-470 -569)"
            style={{ fill: primaryColor, fillRule: 'evenodd' }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 13.288 16.913"
        >
          <path
            d="M418.2 584.242v-13.6l10.777 6.8-10.777 6.8zm11.606-7.675-11.358-7.4c-.674-.367-1.446-.2-1.446.989v14.573c0 1.184.828 1.394 1.446.988l11.358-7.4a1.032 1.032 0 0 0 0-1.749z"
            transform="translate(-417 -568.992)"
            style={{ fill: primaryColor, fillRule: 'evenodd' }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause7

import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { GET_MY_PURCHASED_PLANS, GET_RENEWAL_SUBSCRIPTION } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { getReleaseDate, raiseToast } from '../../utils/helper'
import { isEmpty } from 'lodash'
import CancelSubscriptionModal from './cancelSubscriptionModal'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import CustomImage from '../../components/customImage/customImage'
import HeroImg from '../../assets/pricing-page-hero.png'
import moment from 'moment'
import { useNavigate } from 'react-router'

const MyPlans = ({ theme }) => {
  const { setLoader } = useContext(AppContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [cancelShow, setCancelShow] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [ppv, setPpv] = useState([])
  const [mobileSubscriptionView, setMobileSubscriptionView] = useState(true)
  const [renewalSubscriptions, setRenewalSubscriptions] = useState([])

  const [{ data: subscriptionData, loading: subscriptionDataLoading }, subscriptionDataCall] =
    useAxios(
      {
        url: GET_RENEWAL_SUBSCRIPTION,
        method: 'get',
      },
      { manual: true },
    )

  const [{ data: subscriptionDelete, loading: subscriptionDeleteLoading }, subscriptionDeleteCall] =
    useAxios(
      {
        url: GET_RENEWAL_SUBSCRIPTION,
        method: 'delete',
      },
      { manual: true },
    )

  useEffect(() => {
    subscriptionDataCall()
  }, [])

  const [{ data: myPlansData, loading: myPlansDataLoading }] = useAxios({
    url: GET_MY_PURCHASED_PLANS,
    method: 'get',
  })

  useEffect(() => {
    if (subscriptionDelete && subscriptionDelete.success) {
      raiseToast('success', t('subscription_cancel_success_toast'))
      subscriptionDataCall()
    }
  }, [subscriptionDelete])

  useEffect(() => {
    setLoader(subscriptionDataLoading || subscriptionDeleteLoading || myPlansDataLoading)
  }, [subscriptionDataLoading, subscriptionDeleteLoading, myPlansDataLoading])

  useEffect(() => {
    if (subscriptionData && subscriptionData.success) {
      setRenewalSubscriptions(subscriptionData.data)
    }
  }, [subscriptionData])

  useEffect(() => {
    if (myPlansData && myPlansData.success) {
      setPpv(
        myPlansData.data
          .filter((info) => info.subscription_type === 'ppv')
          .sort((a, b) => (b.end_date > a.end_date ? 1 : -1)),
      )
    }
  }, [myPlansData])

  const handleCancelSubscription = () => {
    setCancelShow(false)
    subscriptionDeleteCall({
      params: {
        subs_renewal_id: selectedTransaction.id,
      },
    })
  }

  console.log('ppv', ppv)

  return (
    <div className="relative">
      <div className=" w-full flex justify-center ">
        <div className="mt-6 xl:mt-10 flex flex-col items-center w-full z-10">
          <p className="mb-0 text-white xl:text-[28px] font-bold">{t('my_plans_caps')}</p>
          <p style={{ color: '#b5b5b5' }} className="mb-0 text-xs lg:text-sm italic text-center">
            {t('all_active_inactive_membership')}
          </p>
          <div
            className="mt-4 w-[94%] lg:w-[1000px] rounded-lg xl:rounded-xl  overflow-hidden"
            style={{
              backgroundImage: 'linear-gradient(to right, #000 8%, #00224a 94%)',
            }}
          >
            <div className="block lg:hidden">
              <div className="w-full flex">
                <div
                  style={{
                    height: '36px',
                    background: mobileSubscriptionView ? '#bbfa33' : '#617d26',
                  }}
                  className="w-1/2 flex justify-center items-center font-bold"
                  onClick={() => setMobileSubscriptionView(true)}
                >
                  {t('subscription_plans')}
                </div>
                <div
                  style={{
                    height: '36px',
                    background: !mobileSubscriptionView ? '#bbfa33' : '#617d26',
                  }}
                  className="w-1/2 flex justify-center items-center font-bold"
                  onClick={() => setMobileSubscriptionView(false)}
                >
                  {t('PPV_plans')}
                </div>
              </div>
              <div className="h-[400px] lg:h-[523px] pl-4 pr-4 lg:pl-[33px] lg:pr-[24px] text-lg scrollbar-class">
                {mobileSubscriptionView ? (
                  !isEmpty(renewalSubscriptions) ? (
                    renewalSubscriptions.map((info, index) => {
                      let today = moment(new Date(), 'DD.MM.YYYY')
                      let startDate = moment(new Date(info.last_renewal_date), 'DD.MM.YYYY')
                      let endDate = moment(new Date(info.next_renewal_date), 'DD.MM.YYYY')

                      let endDays = endDate.diff(today, 'days')

                      let lifetimeValidity = endDate.diff(startDate, 'days') > 999
                      return (
                        <div
                          style={{ color: '#b5b5b5' }}
                          className="w-full lg:flex items-center mt-5 text-xs border-b lg:border-b-0 pb-2 lg:pb-0"
                          key={index}
                        >
                          <div className="text-white flex justify-between items-center w-full lg:w-[15%]">
                            <div className="flex items-center lg:w-full text-base">
                              <p className="clamp-text-15-char">{info.subscription_name}</p>
                              {endDays > 0 && (
                                <span style={{ color: '#b5b5b5' }} className="text-xs ml-1">
                                  ({t('ends_in')} {endDays} {t('days')})
                                </span>
                              )}
                            </div>
                            <div
                              className={`text-xs ${
                                info.renewal_status === 'ongoing'
                                  ? 'text-green-500'
                                  : 'text-red-600'
                              }`}
                            >
                              {' '}
                              {info.renewal_status === 'ongoing' ? t(`active`) : t(`cancelled`)}
                            </div>
                          </div>
                          <div className="mt-2 lg:mt-0 lg:text-center w-full lg:w-[15%] flex justify-between">
                            <div className="flex items-center">
                              <div
                                style={{ color: '#b5b5b5', width: '50px' }}
                                className="block lg:hidden "
                              >
                                {t('validity')} :
                              </div>
                              <div className="ml-1">
                                {' '}
                                {lifetimeValidity
                                  ? t('lifetime')
                                  : `${getReleaseDate(info.last_renewal_date)} - ${getReleaseDate(
                                      info.next_renewal_date,
                                    )}`}
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 lg:mt-0 lg:text-center w-full lg:w-[15%] flex justify-between">
                            <div className="flex items-center ">
                              <div
                                style={{ color: '#b5b5b5', width: '50px' }}
                                className="block lg:hidden "
                              >
                                {t('date')} :
                              </div>
                              <div className="ml-1 ">{getReleaseDate(info.created_date)}</div>
                            </div>
                            {info.renewal_status === 'ongoing' && (
                              <div
                                className="text-red-600 "
                                onClick={() => {
                                  setSelectedTransaction(info)
                                  setCancelShow(true)
                                }}
                              >
                                {t('cancel')}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="font-bold w-full xl:text-center text-xs mt-10 text-white">
                      {t('no_subscription_found')}
                    </div>
                  )
                ) : !isEmpty(ppv) ? (
                  ppv.map((info, index) => {
                    let today = moment(new Date(), 'DD.MM.YYYY')
                    let startDate = moment(new Date(info.start_date), 'DD.MM.YYYY')
                    let endDate = moment(new Date(info.end_date), 'DD.MM.YYYY')
                    let endDays = endDate.diff(today, 'days')
                    let lifetimeValidity = endDate.diff(startDate, 'days') > 999
                    return (
                      <div
                        style={{ color: '#b5b5b5' }}
                        className="w-full lg:flex items-center mt-5 text-xs border-b lg:border-b-0 pb-2 lg:pb-0"
                        key={index}
                      >
                        <div className="text-white flex justify-between items-center w-full lg:w-[15%]">
                          <div className="flex items-center lg:w-full text-base">
                            <p className="clamp-text-15-char">{info.subscription_name}</p>
                            {info.status && (
                              <span style={{ color: '#b5b5b5' }} className="text-xs ml-1">
                                ({t('ends_in')} {endDays} {t('days')})
                              </span>
                            )}
                          </div>
                          <div
                            className={`text-xs ${info.status ? 'text-green-500' : 'text-red-600'}`}
                          >
                            {info.status ? t(`active`) : t(`expired`)}
                          </div>
                        </div>
                        <div className="mt-2 lg:mt-0 lg:text-center w-full lg:w-[15%] flex justify-between">
                          <div className="flex items-center">
                            <div
                              style={{ color: '#b5b5b5', width: '50px' }}
                              className="block lg:hidden "
                            >
                              {t('validity')} :
                            </div>
                            <div className="ml-1">
                              {' '}
                              {lifetimeValidity
                                ? `${t('lifetime')}`
                                : `${getReleaseDate(info.start_date)} - ${getReleaseDate(
                                    info.end_date,
                                  )}`}
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 lg:mt-0 lg:text-center w-full lg:w-[15%] flex justify-between">
                          <div className="flex items-center">
                            <div
                              style={{ color: '#b5b5b5', width: '50px' }}
                              className="block lg:hidden "
                            >
                              {t('date')} :
                            </div>
                            <div className="ml-1 ">{getReleaseDate(info.created_date)}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="font-bold w-full xl:text-center text-xs mt-10 text-white">
                    {t('no_ppv_found')}
                  </div>
                )}
              </div>
            </div>

            <div className="hidden lg:block">
              <div
                style={{ height: '36px', background: '#bbfa33' }}
                className="w-full flex justify-center items-center font-bold"
              >
                {t('subscription_plans')}
              </div>

              <div className="w-full">
                <div
                  style={{
                    width: 'max(100%,1000px)',
                  }}
                  className="h-[275px]"
                >
                  <div
                    style={{ height: '21px', background: '#617d26' }}
                    className="w-full flex items-center text-white text-xs pl-4 pr-4 lg:pl-[50px] lg:pr-[39px]"
                  >
                    <div style={{ width: '45%' }}> {t('plan')} </div>
                    <div style={{ width: '27%' }}> {t('validity')} </div>
                    <div style={{ width: '16%' }}> {t('status')} </div>
                    <div style={{ width: '9%' }}></div>
                  </div>
                  <div className="h-[253px] pl-4 pr-4  lg:pl-[50px] lg:pr-[39px] scrollbar-class">
                    {!isEmpty(renewalSubscriptions) ? (
                      renewalSubscriptions.map((info, index) => {
                        let today = moment(new Date(), 'DD.MM.YYYY')
                        let startDate = moment(new Date(info.last_renewal_date), 'DD.MM.YYYY')
                        let endDate = moment(new Date(info.next_renewal_date), 'DD.MM.YYYY')

                        let endDays = endDate.diff(today, 'days')

                        let lifetimeValidity = endDate.diff(startDate, 'days') > 999

                        return (
                          <div className="w-full flex items-center mt-5 text-white" key={index}>
                            <div style={{ width: '45%' }} className="line-clamp-1">
                              {info.subscription_name}
                              {info.renewal_status === 'ongoing' && (
                                <span style={{ color: '#b5b5b5' }} className="text-xs ml-1">
                                  ({t('ends_in')} {endDays} {t('days')})
                                </span>
                              )}
                            </div>
                            <div style={{ width: '27%' }} className="">
                              <span style={{ color: '#b5b5b5' }} className="text-xs">
                                {lifetimeValidity
                                  ? `${t('lifetime')}`
                                  : `${getReleaseDate(info.last_renewal_date)} - ${getReleaseDate(
                                      info.next_renewal_date,
                                    )}`}
                              </span>
                            </div>
                            <div
                              style={{ width: '16%' }}
                              className={`text-xs ${
                                info.renewal_status === 'ongoing'
                                  ? 'text-green-500'
                                  : 'text-red-600'
                              }`}
                            >
                              {info.renewal_status === 'ongoing' ? t(`active`) : t(`cancelled`)}

                              {info.renewal_status !== 'ongoing' && (
                                <span className="ml-1" style={{ color: '#b5b5b5' }}>
                                  {getReleaseDate(info?.cancellation_date)}
                                </span>
                              )}
                            </div>
                            <div style={{ width: '9%' }} className={`text-xs`}>
                              {info.renewal_status === 'ongoing' && (
                                <div
                                  className="hover:text-red-600 hover:underline cursor-pointer"
                                  onClick={() => {
                                    setSelectedTransaction(info)
                                    setCancelShow(true)
                                  }}
                                >
                                  {t('cancel')}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <>
                        <div className="font-bold w-full text-center text-xs xl:text-base mt-10 text-white">
                          {t('no_subscription_found')}
                        </div>
                        <div className="flex justify-center w-full mt-10">
                          <div
                            style={{
                              background: '#bbfa33',
                              color: '#0d0d0d',
                              height: '36px',
                              padding: '0px 15px',
                            }}
                            className="cursor-pointer text-xs xl:text-base font-semibold rounded flex items-center justify-center"
                            onClick={() => navigate('/pricing')}
                          >
                            {t('buy_membership_plan')}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ height: '36px', background: '#bbfa33' }}
                className="w-full flex justify-center items-center font-bold"
              >
                {t('pay_per_view_rental_plans')}
              </div>

              <div className="w-full">
                <div
                  style={{
                    width: 'max(100%,1000px)',
                  }}
                  className="h-[275px]"
                >
                  <div
                    style={{ height: '21px', background: '#617d26' }}
                    className="w-full flex items-center text-white text-xs pl-4 pr-4 lg:pl-[50px] lg:pr-[39px]"
                  >
                    <div style={{ width: '45%' }}> {t('plan')} </div>
                    <div style={{ width: '27%' }}> {t('validity')} </div>
                    <div style={{ width: '16%' }}> {t('status')} </div>
                    <div style={{ width: '9%' }}></div>
                  </div>
                  <div className="h-[253px] pl-4 pr-4  lg:pl-[50px] lg:pr-[39px] scrollbar-class">
                    {!isEmpty(ppv) ? (
                      ppv.map((info, index) => {
                        let today = moment(new Date(), 'DD.MM.YYYY')
                        let startDate = moment(new Date(info.start_date), 'DD.MM.YYYY')
                        let endDate = moment(new Date(info.end_date), 'DD.MM.YYYY')
                        let endDays = endDate.diff(today, 'days')
                        let lifetimeValidity = endDate.diff(startDate, 'days') > 999
                        return (
                          <div className="w-full flex items-center mt-5 text-white" key={index}>
                            <div style={{ width: '45%' }} className="line-clamp-1">
                              {info.subscription_name}
                              {info.status && (
                                <span style={{ color: '#b5b5b5' }} className="text-xs ml-1">
                                  ({t('ends_in')} {endDays} {t('days')})
                                </span>
                              )}
                            </div>
                            <div style={{ width: '27%' }} className="">
                              <span style={{ color: '#b5b5b5' }} className="text-xs">
                                {lifetimeValidity
                                  ? `${t('lifetime')}`
                                  : `${getReleaseDate(info.start_date)} - ${getReleaseDate(
                                      info.end_date,
                                    )}`}
                              </span>
                            </div>
                            <div
                              style={{ width: '16%' }}
                              className={`text-xs ${
                                info.status ? 'text-green-500' : 'text-red-600'
                              }`}
                            >
                              {info.status ? t(`active`) : t('expired')}
                            </div>
                            <div style={{ width: '9%' }} className={`text-xs`}></div>
                          </div>
                        )
                      })
                    ) : (
                      <>
                        <div className="font-bold w-full text-center text-xs xl:text-base mt-10 text-white">
                          {t('no_ppv_found')}
                        </div>
                        {!isEmpty(renewalSubscriptions) ? (
                          <div className="flex justify-center w-full mt-10">
                            <div
                              style={{
                                background: '#bbfa33',
                                color: '#0d0d0d',
                                height: '36px',
                                padding: '0px 15px',
                              }}
                              className="cursor-pointer text-xs xl:text-base font-semibold rounded flex items-center justify-center"
                              onClick={() => navigate('/pricing')}
                            >
                              {t('buy_membership_plan')}
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="hidden xl:block absolute top-0 w-full"
        style={{
          aspectRatio: '1920/322',
        }}
      >
        <div className="relative w-full">
          <CustomImage imageUrl={HeroImg} imgClass="w-full h-full" loaderClass={'h-64'} />
          <div
            style={{
              height: '191px',
              backgroundImage: `linear-gradient(to bottom, rgba(27, 36, 47, 0), rgba(27, 36, 47, 0.91) 70%, ${theme.c1})`,
            }}
            className="w-full absolute bottom-0"
          />
        </div>
      </div>

      {cancelShow && (
        <CancelSubscriptionModal
          onCancel={() => {
            setSelectedTransaction({})
            setCancelShow(false)
          }}
          cancelCallback={handleCancelSubscription}
        />
      )}
    </div>
  )
}

export default withTheme(MyPlans)

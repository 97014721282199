import React, { useContext } from 'react'
import { withTheme } from 'theming'
// import { AppContext } from '../../context/app'
import { useTranslation } from 'react-i18next'
import { getReleaseDate } from '../../utils/helper'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'
import { AppContext } from '../../context/app'
import CustomMetadata from '../../components/customMetadata/customMetadata'

const PlayerPageAbout = ({ theme, detailObj, contentId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isCustomTemplate } = useContext(AppContext)

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleSubCategory = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  return (
    <div className={`z-10 w-full `} style={{ color: '#e3e2e2', marginTop: '2vw' }}>
      {!isEmpty(detailObj) && (
        <>
          <p
            style={{ color: isCustomTemplate ? theme.c4 : '#cecece' }}
            className="text-xl font-bold"
          >
            {detailObj?.title}
          </p>
          <p
            style={{ color: isCustomTemplate ? theme.c4 : '#cecece', marginTop: '0.5vw' }}
            className="text-lg "
          >
            {detailObj?.description}
          </p>

          <div className={' text-lg'} style={{ marginTop: '2vw' }}>
            <div className={'flex'}>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
              >
                {t('category')}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9', flexWrap: 'wrap' }}
                className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex'}
              >
                {[...detailObj?.category].map((item, index) => (
                  <div key={index} className="flex">
                    {index !== 0 && <span className={' mr-1'}>{','}</span>}
                    <p
                      onClick={() => handleCategoryClick(item.id, item.name)}
                      className={'cursor-pointer hover:underline'}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {detailObj?.release_time && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('release_date')}
                </p>
                <p
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                  className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {getReleaseDate(detailObj?.release_time)}
                </p>
              </div>
            )}

            {Object.keys(detailObj.artists).map((artist, index) => (
              <div className={'flex mt-2'} key={index}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {artist}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9', flexWrap: 'wrap' }}
                  className={'  text-sm md:text-lg   w-8/12 lg:w-10/12 flex flex-col  xl:flex-row'}
                >
                  {detailObj.artists[artist].map((item, index) => (
                    <div key={index} className="flex">
                      {index !== 0 && <span className={'hidden xl:block mr-1'}>{' , '}</span>}
                      <p
                        onClick={() => navigate(`/artist/detail/${item.id}`)}
                        className={'cursor-pointer hover:underline'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {!isEmpty(detailObj.venues) && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'  text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('venue')}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9', flexWrap: 'wrap' }}
                  className={'  text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {detailObj.venues.map((item, index) => (
                    <div key={index} className="flex">
                      {index !== 0 && <span className={'hidden xl:block mr-1'}>{' , '}</span>}
                      <p
                        onClick={() => navigate(`/venue/detail/${item.id}`)}
                        className={'cursor-pointer hover:underline'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!isEmpty(detailObj.sub_category) && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'  text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('genre')}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9', flexWrap: 'wrap' }}
                  className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {detailObj.sub_category.map((item, index) => (
                    <div key={index} className="flex">
                      {index !== 0 && <span className={'hidden xl:block mr-1'}>{' , '}</span>}
                      <p
                        onClick={() => handleSubCategory(item.id, item.name)}
                        className={'cursor-pointer hover:underline'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {detailObj.custom_metadata_new ? (
              <CustomMetadata
                customMetadata={detailObj.custom_metadata_new}
                nameClass={'text-sm md:text-lg w-4/12 lg:w-2/12'}
                valueClass={'text-sm md:text-lg w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                valueFontFormat={''}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}

export default withTheme(PlayerPageAbout)

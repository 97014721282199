import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'

const LanguageModal = ({ theme }) => {
  const { t, i18n } = useTranslation()
  const { accountObj, setLanguageModal, isCustomTemplate } = useContext(AppContext)
  let selectedLanguage = localStorage.getItem('language')

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
    window.location.reload()
  }

  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
      >
        <div className={`relative w-11/12  xl:w-4/12 md:w-2/3 my-2 mx-auto`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#151c26',
              fontFamily: 'Arial',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
              border: '',
            }}
            className="shadow-lg relative flex flex-col md:w-full p-5"
          >
            <div className=" py-2 object-right-top">
              <button
                className="p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={() => setLanguageModal(false)}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="  h-6 w-6 text-xl xl:text-2xl block outline-none focus:outline-none"
                >
                  ×
                </span>
              </button>
              <div className="flex flex-col justify-start">
                <h4
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className=" text-lg md:text-xl  font-semibold"
                >
                  {t('selectlanguage')}
                </h4>
              </div>
            </div>
            <div style={{ gridAutoRows: '1fr' }} className="grid md:grid-cols-3 gap-2 mt-2">
              {!isEmpty(accountObj?.allowed_languages) &&
                accountObj.allowed_languages.map((info, index) => {
                  return (
                    <div
                      className={`${
                        selectedLanguage && selectedLanguage === info.code
                          ? 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'
                          : 'bg-white'
                      } w-full cursor-pointer shadow rounded-lg flex justify-center items-center text-center p-4 relative z-30 my-4 border border-gray-400 hover:border-black`}
                      key={index}
                      onClick={() => {
                        changeLanguage(info.code)
                      }}
                    >
                      {info.displayName}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(LanguageModal)

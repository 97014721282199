import React, { useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import EditMenu from '../editMenu'
import DeleteModal from '../../DeleteModal/DeleteModal'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const FineTunedGallery = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData

  const { customNavigate, getTemplateImage, EditIcon } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedCardIndex, setSelectedCardIndex] = useState('')

  const deleteCard = (index) => {
    setSelectedCardIndex(index)
    setDeleteModal(true)
  }

  const handleModal = (cardInfo, type, cardIndex, isEdit) => {
    setModalState({
      keyID: id,
      modalData: cardInfo,
      type: type,
      cardIndex: cardIndex,
      isEdit,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const onDeleteCard = () => {
    let tempdata = data
    tempdata?.card_data.splice(selectedCardIndex, 1)
    modifyData(id, tempdata)
    setDeleteModal(false)
    setSelectedCardIndex('')
  }
  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="w-full relative py-10">
        <h2
          style={{ color: theme.c3 }}
          className={`w-fit sm:text-3xl text-xl font-bold 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[0.5rem] relative ${
            isEdit && hoverState === 'HEADING' && 'component-hover-class pr-10'
          }`}
          onMouseOver={() => handleHoverItems('HEADING')}
          onMouseLeave={() => handleHoverItems('TOP')}
        >
          {data?.title}
          {hoverState === 'HEADING' && (
            <div
              className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-1 -right-3 flex justify-center items-center cursor-pointer"
              onClick={() => handleModal({ title: data?.title }, 'HEADING', 0, true)}
            >
              <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
            </div>
          )}
        </h2>

        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} wrapperClassName="w-full md:mt-4">
          {data?.card_data.map((cardInfo, index) => (
            <Card
              key={index}
              itemId={`index${index}`}
              index={index}
              cardInfo={cardInfo}
              handleRedirect={handleRedirect}
              isEdit={isEdit}
              hoverState={hoverState}
              theme={theme}
              handleHoverItems={handleHoverItems}
              handleModal={handleModal}
              deleteCard={deleteCard}
              getTemplateImage={getTemplateImage}
            />
          ))}
        </ScrollMenu>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      {deleteModal && (
        <DeleteModal
          openModal={deleteModal}
          title={`Are you sure you want to delete this Card ?`}
          confirmButtonText={`Yes`}
          onCancel={() => {
            setSelectedCardIndex('')
            setDeleteModal(false)
          }}
          onConfirm={onDeleteCard}
        />
      )}
    </div>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
  const { FitnessPrev } = useTemplateHook()
  return (
    <button
      className={` absolute left-0 top-1/2 z-10  border-0 bg-transparent px-0 hidden lg:block`}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <img
        src={FitnessPrev}
        alt="prev"
        style={{
          width: '106px',
          height: '54px',
        }}
        // className="w-[54px] h-[54px] "
      />
    </button>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  const { FitnessNext } = useTemplateHook()
  return (
    <button
      className={` absolute right-0 top-1/2 z-10 border-0 bg-transparent px-0 hidden lg:block`}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <img
        src={FitnessNext}
        alt="next"
        style={{
          width: '106px',
          height: '54px',
        }}
      />
    </button>
  )
}

function Card({
  index,
  cardInfo,
  handleRedirect,
  isEdit,
  hoverState,
  theme,
  handleHoverItems,
  handleModal,
  deleteCard,
  getTemplateImage,
}) {
  const { PlusIcon, EditIcon, TrashIcon, VideoPlaceholder } = useTemplateHook()
  return (
    <div key={index} className={`flex cursor-pointer`}>
      {!cardInfo?.is_multiple ? (
        <div
          style={{ color: theme.c7 }}
          className={`${isEdit && hoverState === `CARD${index}` && 'component-hover-class'}  ${
            !index && 'ml-2 2xl:ml-32 xl:ml-24 md:ml-16 sm:ml-10'
          } xl:h-[596px] sm:h-[518px] h-[209px] xl:w-[804px] sm:w-[700px] w-[282px] rounded-lg shadow-md overflow-hidden flex-shrink-0 mx-6 relative`}
          onMouseOver={() => handleHoverItems(`CARD${index}`)}
          onMouseLeave={() => handleHoverItems('TOP')}
          onClick={() => handleRedirect(cardInfo?.link)}
        >
          {hoverState === `CARD${index}` && (
            <div style={{ zIndex: 25 }} className={`relative `}>
              <div className={`mt-2 absolute w-full top-3 flex justify-center items-center`}>
                <span className={`text-[#2172eb] text-sm`}>{'804px X 596px'}</span>
              </div>

              <div className={`absolute w-full top-3 right-4  flex justify-end items-center`}>
                <div
                  className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                  onClick={() => handleModal(cardInfo, 'CARD', index, true)}
                >
                  <img src={EditIcon} className="w-[17px] h-[17px]" alt="editicon" />
                </div>
                <div
                  className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                  onClick={() => deleteCard(index)}
                >
                  <img src={TrashIcon} className="w-[17px] h-[17px]" alt="trashicon" />
                </div>
              </div>
            </div>
          )}
          <CustomImage
            imageUrl={getTemplateImage(cardInfo.image_s3key)}
            imgClass="w-full h-full"
            loaderClass="w-full h-full"
            loaderStyle={{ background: '#5A5A5A50' }}
            imgAlt={'card img'}
            errorPlaceholder={VideoPlaceholder}
          />
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, ${theme.c3}, ${theme.c3} 10%, rgba(0, 0, 0, 0))`,
            }}
            className="sm:w-[31.5%] w-[104px] h-full px-[0.5rem] py-[0.5rem] md:px-5 md:py-7 absolute top-0 right-0"
          >
            <h2 className="sm:text-2xl text-xs md:font-semibold">{cardInfo?.heading}</h2>
            <section className="md:mt-6 sm:text-[14px] text-[10px] sm:font-normal font-light fitness-plan-line-clamp-lg">
              <p>{cardInfo?.description}</p>
            </section>
          </div>
        </div>
      ) : (
        <div
          style={{ color: theme.c7 }}
          className={`${isEdit && hoverState === `CARD${index}` && 'component-hover-class'} ${
            !index && 'ml-2 2xl:ml-32 xl:ml-24 md:ml-16 sm:ml-10'
          } 
        xl:h-[596px] sm:h-[518px] h-[209px] xl:w-[804px] sm:w-[700px] w-[282px] flex flex-col justify-between relative mx-6`}
          onMouseOver={() => handleHoverItems(`CARD${index}`)}
          onMouseLeave={() => handleHoverItems('TOP')}
        >
          {hoverState === `CARD${index}` && (
            <div style={{ zIndex: 25 }} className={`relative `}>
              <div className={`absolute w-full top-3 right-4  flex justify-end items-center`}>
                <div
                  className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                  onClick={() => handleModal(cardInfo, 'CARD', index, true)}
                >
                  <img src={EditIcon} className="w-[17px] h-[17px]" alt="editicon" />
                </div>
                <div
                  className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                  onClick={() => deleteCard(index)}
                >
                  <img src={TrashIcon} className="w-[17px] h-[17px]" alt="trashicon" />
                </div>
              </div>
            </div>
          )}
          {cardInfo?.card_a?.image_s3key && (
            <div
              className="absolute top-0 w-full"
              onClick={() => handleRedirect(cardInfo?.card_a?.link)}
            >
              <div className=" w-full xl:h-[278px] sm:h-[244px] h-[98px] overflow-hidden  rounded-lg shadow-md relative">
                {hoverState === `CARD${index}` && (
                  <div className={`mt-2 absolute w-full top-3 flex justify-center items-center`}>
                    <span className={`text-[#2172eb] text-sm`}>{'804px X 278px'}</span>
                  </div>
                )}
                <CustomImage
                  imageUrl={getTemplateImage(cardInfo?.card_a?.image_s3key)}
                  imgClass="w-full h-full"
                  loaderClass="w-full h-full"
                  loaderStyle={{ background: '#5A5A5A50' }}
                  imgAlt={'img a'}
                  errorPlaceholder={VideoPlaceholder}
                />
                <div
                  style={{
                    backgroundImage: `linear-gradient(to bottom, ${theme.c3}, ${theme.c3} 10%, rgba(0, 0, 0, 0))`,
                  }}
                  className="w-[42%] h-full px-[0.5rem] py-[0.5rem] md:pl-10 md:py-5 absolute top-0 right-0 "
                >
                  <h2 className="sm:text-2xl text-xs md:font-semibold">
                    {cardInfo?.card_a?.heading}
                  </h2>
                  <section className="md:mt-4 sm:text-[14px] text-[10px] sm:font-normal font-light fitness-plan-line-clamp-sm">
                    <p>{cardInfo?.card_a?.description}</p>
                  </section>
                </div>
              </div>
            </div>
          )}
          {cardInfo?.card_b?.image_s3key && (
            <div
              className="absolute bottom-0 w-full"
              onClick={() => handleRedirect(cardInfo?.card_b?.link)}
            >
              <div className=" w-full xl:h-[278px] sm:h-[244px] h-[98px] overflow-hidden rounded-lg shadow-md relative">
                {hoverState === `CARD${index}` && (
                  <div className={`mt-2 absolute w-full top-3 flex justify-center items-center`}>
                    <span className={`text-[#2172eb] text-sm`}>{'804px X 278px'}</span>
                  </div>
                )}
                <CustomImage
                  imageUrl={getTemplateImage(cardInfo?.card_b?.image_s3key)}
                  imgClass="w-full h-full"
                  loaderClass="w-full h-full"
                  loaderStyle={{ background: '#5A5A5A50' }}
                  imgAlt={'img b'}
                  errorPlaceholder={VideoPlaceholder}
                />
                <div
                  style={{
                    backgroundImage: `linear-gradient(to bottom, ${theme.c3}, ${theme.c3} 10%, rgba(0, 0, 0, 0))`,
                  }}
                  className="w-[42%] h-full px-[0.5rem] py-[0.5rem] md:pl-10 md:py-5 absolute top-0 right-0 "
                >
                  <h2 className="sm:text-2xl text-xs md:font-semibold">
                    {cardInfo?.card_b?.heading}
                  </h2>
                  <section className="md:mt-4 sm:text-[14px] text-[10px] sm:font-normal font-light fitness-plan-line-clamp-sm">
                    <p>{cardInfo?.card_b?.description}</p>
                  </section>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isEdit && (
        <div className="rosemary-toptrending-add-button d-flex justify-content-center align-items-center py-2 ">
          <div style={{ border: '1px solid #2172eb' }} className="relative h-full">
            <div
              onClick={() => handleModal({}, 'CARD', index, false)}
              className="absolute h-[32px] w-[32px] rounded-full bg-[#2172eb] top-[45%] -left-4 flex justify-center items-center"
            >
              <img src={PlusIcon} className="w-[17px] h-[17px]" alt="plus icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withTheme(FineTunedGallery)

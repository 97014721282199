import React, { useContext, useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import './globalStyles.css'
import './swimlane.css'
import { isEmpty } from '../../utils/helper'
import ContentCardHover from '../contentCard/contentCardHover'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ContentCard from '../contentCard/contentCard'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import { LG_SIZE, SM_SIZE } from '../../utils/constants'
import { encodeURI } from 'js-base64'

const Swimlane = (props) => {
  const { data, title, filters, seeMoreVisible, type, theme } = props
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const [visibleSlides, setVisibleSlides] = useState(2)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isCustomTemplate, isBrowser } = useContext(AppContext)

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(5)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(3)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleHover = (index) => {
    setHoverCardIndex(index)
  }

  const handleUnHover = () => {
    setHoverCardIndex('')
  }

  const handleSeeMore = () => {
    if (type === 'content') {
      const encoded = encodeURI(JSON.stringify(filters))
      navigate(`/content/filter/${encodeURI(title)}?filters=${encoded}`)
    } else {
      navigate(`/${type}`)
    }
  }

  return (
    <>
      <div className="flex items-baseline">
        <p
          style={{ color: isCustomTemplate ? theme.c3 : '#fff' }}
          className="sm:text-[0.8rem] md:text-[1.5rem] font-bold ml-6 md:ml-16 xl:ml-28"
        >
          {title}
        </p>
        {seeMoreVisible && (
          <p
            style={{ cursor: 'pointer' }}
            className={
              'text-blue-400 text-xs md:text-md  ml-2 cursor-pointer hover:opacity z-30 mt-3'
            }
            onClick={handleSeeMore}
          >
            {t('see_more')}
          </p>
        )}
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={data.length}
        visibleSlides={visibleSlides}
        step={5}
      >
        <div
          className={
            isEmpty(data)
              ? 'animate-pulse swimlane-container h-[8.5rem] md:h-60 lg:h-[14rem]'
              : hoverCardIndex !== ''
              ? 'swimlane-container-hover'
              : 'swimlane-container h-[8.5rem] md:h-60 lg:h-[14rem]'
          }
        >
          <Slider
            style={hoverCardIndex !== '' && isBrowser ? { minHeight: `24.3rem` } : {}}
            className=""
          >
            {data?.map((item, index) => {
              return (
                <Slide key={index} index={index} className="">
                  {hoverCardIndex === index && isBrowser ? (
                    <div className="pt-10" onMouseLeave={handleUnHover}>
                      <ContentCardHover data={item} type={type} />
                    </div>
                  ) : (
                    <div
                      className="pt-2 md:pt-4 lg:pt-8 xl:pt-10"
                      onMouseOver={() => handleHover(index)}
                    >
                      <ContentCard data={item} type={type} />
                    </div>
                  )}
                </Slide>
              )
            })}
          </Slider>
          {isBrowser && (
            <>
              {data.length > visibleSlides ? (
                <ButtonBack>
                  <IoIosArrowBack className="left-Swimlane-arrow " />
                </ButtonBack>
              ) : null}
              {data.length > visibleSlides ? (
                <ButtonNext>
                  <IoIosArrowForward className="right-Swimlane-arrow " />
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
    </>
  )
}

export default withTheme(Swimlane)

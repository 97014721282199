import React from 'react'

const Language2 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 18.694 9.347"
    >
      <path
        style={{ fill: color }}
        data-name="language-svgrepo-com (7)"
        d="M4.443 4.1c-.1-.353-.228-.97-.228-.97H4.2s-.126.616-.228.97l-.324 1.1h1.113zM17.993 0H9.815v9.347h8.179a.7.7 0 0 0 .7-.7V.7a.7.7 0 0 0-.701-.7zm-.7 3.505a.352.352 0 0 1-.351.351h-.333a5.234 5.234 0 0 1-1.247 2.042 9.055 9.055 0 0 0 .762.526.351.351 0 0 1 .12.473l-.231.406a.352.352 0 0 1-.488.126 10.161 10.161 0 0 1-1.034-.727 10.3 10.3 0 0 1-1.034.727.352.352 0 0 1-.488-.126l-.231-.406a.35.35 0 0 1 .123-.473 8.333 8.333 0 0 0 .762-.526 6.777 6.777 0 0 1-.613-.751.349.349 0 0 1 .108-.5l.19-.114.213-.126a.353.353 0 0 1 .467.1 5.569 5.569 0 0 0 .508.61 4.468 4.468 0 0 0 .876-1.262h-3.338a.352.352 0 0 1-.351-.351v-.467a.352.352 0 0 1 .351-.351H13.9V2.22a.352.352 0 0 1 .351-.351h.467a.352.352 0 0 1 .351.351v.467h1.869a.352.352 0 0 1 .351.351zM0 .7v7.945a.7.7 0 0 0 .7.7h8.18V0H.7a.7.7 0 0 0-.7.7zm1.72 6.312L3.4 2.074a.349.349 0 0 1 .333-.237h.949a.356.356 0 0 1 .333.237l1.68 4.939a.351.351 0 0 1-.333.464h-.669a.351.351 0 0 1-.336-.251l-.275-.932H3.324l-.266.929a.353.353 0 0 1-.336.254h-.669a.353.353 0 0 1-.333-.464z"
      />
    </svg>
  )
}

export default Language2

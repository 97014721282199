import React from 'react'

const ChevronRight = ({ color = '#b8b8b8', imgClass = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7}
      height={12}
      viewBox="0 0 7 12"
      className={imgClass}
    >
      <path
        d="M12.707 14.707a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L12 12.586l4.293-4.293a1 1 0 0 1 1.414 1.414z"
        transform="rotate(-90 5 13)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default ChevronRight

import React from 'react'

const Playback5 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 15.52 15.52"
    >
      <path
        data-name="Path 1139"
        d="M11.011 1.25c3.964 0 7.178 3.474 7.178 7.76s-3.214 7.76-7.178 7.76a6.683 6.683 0 0 1-1.6-.193.544.544 0 0 1-.377-.648.5.5 0 0 1 .6-.408 5.755 5.755 0 0 0 1.375.166 6.441 6.441 0 0 0 6.178-6.677 6.441 6.441 0 0 0-6.176-6.677 5.754 5.754 0 0 0-1.375.167.5.5 0 0 1-.6-.408.544.544 0 0 1 .377-.648 6.684 6.684 0 0 1 1.598-.194z"
        transform="translate(-2.669 -1.25)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1140"
        d="M3.016 16.648a.5.5 0 0 1 .691.157 6.211 6.211 0 0 0 1.945 1.945.5.5 0 0 1-.534.848A7.214 7.214 0 0 1 2.86 17.34a.5.5 0 0 1 .156-.692z"
        transform="translate(-1.759 -5.177)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1141"
        d="M2.405 9.636a.5.5 0 1 0-.977-.222 7.237 7.237 0 0 0 0 3.195.5.5 0 1 0 .977-.222 6.235 6.235 0 0 1 0-2.751z"
        transform="translate(-1.25 -3.251)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1142"
        d="M5.808 3.016a.5.5 0 0 1-.157.691 6.211 6.211 0 0 0-1.945 1.944.5.5 0 0 1-.848-.534 7.213 7.213 0 0 1 2.259-2.258.5.5 0 0 1 .691.157z"
        transform="translate(-1.759 -1.759)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1143"
        d="M8.25 8.558a1.263 1.263 0 0 1 1.886-1.138l3.152 1.86a1.335 1.335 0 0 1 0 2.277l-3.152 1.861a1.263 1.263 0 0 1-1.886-1.139zm1.141-.273a.312.312 0 0 0-.14.273v3.722a.312.312 0 0 0 .14.273.216.216 0 0 0 .236 0l3.152-1.861a.335.335 0 0 0 0-.552L9.627 8.282a.216.216 0 0 0-.236.003z"
        transform="translate(-2.949 -2.659)"
        style={{ fillRule: 'evenodd', fill: color }}
      />
    </svg>
  )
}

export default Playback5

import React from 'react'

const FullScreen1 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 13.85 13.85"
        >
          <path
            data-name="Path 1198"
            d="M33 6v3.16h3.16"
            transform="translate(-22.911 -5.399)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
          <path
            data-name="Path 1199"
            d="M9.16 6v3.16H6"
            transform="translate(-5.4 -5.4)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
          <path
            data-name="Path 1200"
            d="M9.16 36.163V33H6"
            transform="translate(-5.4 -22.913)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
          <path
            data-name="Path 1201"
            d="M33 36.16V33h3.16"
            transform="translate(-22.91 -22.913)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 13.845 13.845"
        >
          <g data-name="full-screen-svgrepo-com (4)">
            <path
              data-name="Path 1194"
              d="M33 6h3.161v3.161"
              transform="translate(-22.916 -5.4)"
              style={{
                fill: 'none',
                stroke: color,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '1.2px',
              }}
            />
            <path
              data-name="Path 1195"
              d="M36.161 33v3.161H33"
              transform="translate(-22.916 -22.916)"
              style={{
                fill: 'none',
                stroke: color,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '1.2px',
              }}
            />
            <path
              data-name="Path 1196"
              d="M9.161 36.161H6V33"
              transform="translate(-5.4 -22.916)"
              style={{
                fill: 'none',
                stroke: color,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '1.2px',
              }}
            />
            <path
              data-name="Path 1197"
              d="M6 9.161V6h3.161"
              style={{
                fill: 'none',
                stroke: color,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '1.2px',
              }}
              transform="translate(-5.4 -5.4)"
            />
          </g>
        </svg>
      )}
    </div>
  )
}

export default FullScreen1

import React from 'react'

const AddedWatchList = ({ color = '#fff', imgClass = '' }) => {
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="24.75"
      height={33}
      viewBox="0 0 24.75 33"
    >
      <g id="Group_781" data-name="Group 781" transform="translate(-620.25 -260)">
        <g
          id="files-svgrepo-com_1_"
          data-name="files-svgrepo-com (1)"
          transform="translate(617.25 260)"
        >
          <g id="Group_778" data-name="Group 778" transform="translate(3)">
            <g id="Group_777" data-name="Group 777">
              <path
                id="Path_882"
                data-name="Path 882"
                fill={color}
                d="M20.875,27.5H4.375A1.3,1.3,0,0,1,3,26.125V1.375A1.3,1.3,0,0,1,4.375,0h16.5A1.3,1.3,0,0,1,22.25,1.375v24.75A1.3,1.3,0,0,1,20.875,27.5ZM5.75,24.75H19.5v-22H5.75Z"
                transform="translate(-3)"
              />
            </g>
          </g>
          <g id="Group_780" data-name="Group 780" transform="translate(8.5 5.5)">
            <g id="Group_779" data-name="Group 779">
              <path
                id="Path_883"
                data-name="Path 883"
                fill={color}
                d="M24.875,31.5H8.375A1.3,1.3,0,0,1,7,30.125v-5.5A1.3,1.3,0,0,1,8.375,23.25,1.3,1.3,0,0,1,9.75,24.625V28.75H23.5v-22H19.375A1.3,1.3,0,0,1,18,5.375,1.3,1.3,0,0,1,19.375,4h5.5A1.3,1.3,0,0,1,26.25,5.375v24.75A1.3,1.3,0,0,1,24.875,31.5Z"
                transform="translate(-7 -4)"
              />
            </g>
          </g>
        </g>
        <path
          id="tick-sign-svgrepo-com"
          fill={color}
          d="M10.484,33.286,4.3,39.472l-2.405-2.4L0,38.959l4.3,4.3,8.079-8.077Z"
          transform="translate(623.6 235.745)"
        />
      </g>
    </svg>
  )
}

export default AddedWatchList

import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { AppContext } from './context/app'
import { captureGAEvent } from './utils/helper'
import { useLocation } from 'react-router-dom'

const RouteChangeTracker = ({ history }) => {
  const location = useLocation()
  const { accountObj } = useContext(AppContext)
  ReactGA.initialize('G-LZP7S2SLQJ')

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: `${accountObj?.name}`,
    })
    captureGAEvent('page_view', 'page_view', 'Page View', {
      url: location.pathname,
      account_name: accountObj?.name,
      user_id: accountObj?._id,
    })
  }, [location])

  return <></>
}

export default RouteChangeTracker

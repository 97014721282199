import React, { useContext, useEffect, useState } from 'react'
// import VideoJS from '../../components/player/videoJS'
import { getImage, raiseToast } from '../../utils/helper'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import useAxios from 'axios-hooks'
import { ANALYTICS_API } from '../../utils/apiUrls'
import { LG_SIZE, SM_SIZE } from '../../utils/constants'
import AdVideoJS from '../../components/player/adVideoJS'
import { ContentContext } from '../../context/content'

const PlaylistPlayer = (props) => {
  let PLAYER_STARTED = false
  const {
    contentInfo,
    contentWatchUrl,
    resumeTime,
    handlePlayingSequence,
    disableHeader = false,
    controlBar = true,
    adCampaignData,
  } = props
  const [error, setError] = useState(false)
  const playerRef = React.useRef(null)
  const { accountObj } = useContext(AppContext)
  const { updateContentResumeTimestamp } = useContext(ContentContext)
  const [source, setSource] = useState('WEB')
  const randomVal = Math.floor(1000 + Math.random() * 9000)
  const trackingId = `${contentInfo?.content_id + Date.now() + randomVal}`

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setSource('WEB')
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setSource('TABLET')
    } else {
      setSource('MOBILE')
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [{ error: analyticsError }, analyticsEventCall] = useAxios(
    {
      url: ANALYTICS_API,
      method: 'post',
    },
    { manual: true, autoCancel: false },
  )

  useEffect(() => {
    console.error(analyticsError)
  }, [analyticsError])

  useEffect(() => {
    if (error) {
      handleError()
    }
  }, [error])

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5],
    controls: controlBar,
    // muted: true,
    responsive: true,
    liveui: true,
    poster: getImage(accountObj?.cf_domain_name, contentInfo?.poster),
    // fluid: true,
    userActions: {
      hotkeys: true,
    },
    fill: true,
    // loop: loop,
    sources: [
      {
        src: contentWatchUrl,
        type: 'application/x-mpegURL',
      },
    ],
    plugins: {
      qualityLevels: {},
      hlsQualitySelectors: {},
      // ads: {},
    },
    html5: {
      hls: {
        overrideNative: true,
      },
    },
  }

  const handleError = () => {
    if (!playerRef.current) {
      return
    }
    // playerRef.current.suppressNotSupportedError(true);
  }

  useEffect(() => {
    if (playerRef.current) {
      playVideo(contentWatchUrl)
    }
  }, [contentWatchUrl])

  const playVideo = (url) => {
    let videoElm = playerRef.current

    if (!videoElm.paused) {
      videoElm.pause()
    }
    videoElm.src({ type: 'application/x-mpegURL', src: url })

    videoElm.load()
    videoElm.play()
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
    let timeInterval
    let previousTime = 0
    let currentTime = 0
    let position = 0
    // you can handle player events here

    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
    })

    player.on('loadedmetadata', async () => {
      if (resumeTime > 0) {
        player.currentTime(resumeTime)
      }

      await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_START',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: `${player.videoHeight()}`,
        },
      })

      let playerStartedRes = await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_PLAY',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: `${player.videoHeight()}`,
        },
      })

      playerStartedRes?.data?.success ? (PLAYER_STARTED = true) : (PLAYER_STARTED = false)

      timeInterval = setInterval(async () => {
        if (PLAYER_STARTED) {
          updateContentResumeTimestamp(contentInfo?.content_id, player.currentTime())
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PING',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: `${player.videoHeight()}`,
            },
          })
        }
      }, 30000)
    })

    player.on('play', async () => {
      if (PLAYER_STARTED && !player.seeking()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PLAY',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
    })

    player.on('pause', async () => {
      if (PLAYER_STARTED && !player.seeking() && player.currentTime() < player.duration()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
      // clearInterval(timeInterval)
      // console.log('player is paused', player.textTracks())
    })

    player.on('timeupdate', function () {
      previousTime = currentTime
      currentTime = Math.floor(player.currentTime())

      // save 'position' so long as time is moving forward with each update
      if (previousTime < currentTime) {
        position = previousTime
        previousTime = currentTime
      }
    })

    player.on('seeked', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${position}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })

        if (!player.paused()) {
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PLAY',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: `${player.videoHeight()}`,
            },
          })
        }
      }
    })

    player.on('dispose', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_CLOSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }

      clearInterval(timeInterval)
    })

    player.on('ended', async () => {
      if (PLAYER_STARTED) {
        handlePlayingSequence()
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_END',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
      clearInterval(timeInterval)
    })

    player.on('error', () => {
      // console.log(player.error());
      let error = player.error()
      raiseToast('error', error.message)
      setError(true)
    })
  }

  return (
    <div className="flex flex-col xl:flex-row w-full h-full">
      <div style={{ maxWidth: '100%' }} className={`player-container w-full h-full relative`}>
        {!disableHeader && (
          <div
            style={{
              zIndex: 40,
              backgroundColor: 'rgb(27, 36, 47,0.30)',
            }}
            className={` overlay grid grid-cols-12 items-center md:px-4 h-10 md:h-14 w-full`}
          >
            <div className="text-white col-span-2">
              {!isEmpty(contentInfo?.content_rating) ? (
                <div className="mx-2">
                  <div className="flex items-center justify-start">
                    {contentInfo?.content_rating?.icon?.s3_key && (
                      <div className="mr-1">
                        <img
                          src={getImage(
                            accountObj?.cf_domain_name,
                            contentInfo?.content_rating?.icon?.s3_key,
                          )}
                          className="w-4 h-4 md:w-7 md:h-7 mx-1"
                          alt="Rating"
                        />
                      </div>
                    )}
                    <p className="text-xs md:text-sm font-semibold line-clamp-1">
                      {contentInfo?.content_rating?.name}
                    </p>
                  </div>
                  <div className="line-clamp-2 text-xs md:text-[10px]">
                    {contentInfo?.content_rating?.description}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="text-white font-semibold text-sm md:text-lg col-span-8 text-center">
              {contentInfo?.title}
            </div>
          </div>
        )}

        <AdVideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          cover={false}
          adSetData={adCampaignData?.ad_sets ? adCampaignData?.ad_sets : []}
        />
      </div>
    </div>
  )
}

export default PlaylistPlayer

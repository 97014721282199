import React from 'react'

const PlayPause5 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 19.64 19.64"
        >
          <path
            data-name="Path 1085"
            d="M14 9v6"
            transform="translate(-2.22 -2.18)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: primaryColor,
              strokeWidth: '2px',
            }}
          />
          <path
            data-name="Path 1086"
            d="M10 9v6"
            transform="translate(-2.14 -2.18)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: primaryColor,
              strokeWidth: '2px',
            }}
          />
          <path
            data-name="Path 1087"
            d="M3 11.82C3 4.557 4.557 3 11.82 3s8.82 1.557 8.82 8.82-1.557 8.82-8.82 8.82S3 19.083 3 11.82z"
            style={{ fill: 'none', stroke: primaryColor, strokeWidth: '2px' }}
            transform="translate(-2 -2)"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 19.64 19.64"
        >
          <path
            data-name="Path 1092"
            d="M3 11.82C3 4.557 4.557 3 11.82 3s8.82 1.557 8.82 8.82-1.557 8.82-8.82 8.82S3 19.083 3 11.82z"
            style={{ fill: 'none', stroke: primaryColor, strokeWidth: '2px' }}
            transform="translate(-2 -2)"
          />
          <path
            data-name="Path 1093"
            d="m10.9 8.8-.242-.138a.774.774 0 0 0-1.158.671v5.333a.774.774 0 0 0 1.158.672l.242-.138 4.2-2.4a.921.921 0 0 0 0-1.6z"
            transform="translate(-2.196 -2.18)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: primaryColor,
              strokeWidth: '2px',
            }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause5

import React from 'react'

const Pip5 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 17.485 13.211">
      <path
        data-name="Path 1172"
        d="M20.931 29.211h-6.994a1.559 1.559 0 0 1-1.554-1.554v-4.274a1.559 1.559 0 0 1 1.554-1.554h6.994a1.559 1.559 0 0 1 1.554 1.554v4.274a1.559 1.559 0 0 1-1.554 1.554zm-6.994-5.828v4.274h6.994v-4.274zM10.246 26.3H6.554A1.559 1.559 0 0 1 5 24.743v-7.189A1.559 1.559 0 0 1 6.554 16h11.463a1.559 1.559 0 0 1 1.554 1.554v2.137a.777.777 0 0 1-1.554 0v-2.137H6.554v7.188h3.691a.777.777 0 0 1 0 1.554z"
        transform="translate(-5 -16)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Pip5

import React from 'react'

const Resolution3 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 14.795 14.795"
    >
      <path
        data-name="HD-[#942]"
        d="M326.219 3844.178v4.438a.74.74 0 0 0 1.479 0v-1.479h1.479v1.479a.74.74 0 0 0 1.479 0v-4.438a.74.74 0 0 0-1.479 0v1.479H327.7v-1.479a.74.74 0 0 0-1.479 0zm8.877 1.11a.368.368 0 0 0-.37-.37h-1.11v2.959h1.11a.373.373 0 0 0 .37-.37zm.292 4.069h-2.511a.741.741 0 0 1-.74-.74v-4.438a.741.741 0 0 1 .74-.74h2.511a1.187 1.187 0 0 1 1.187 1.184v3.551a1.187 1.187 0 0 1-1.187 1.184zM325.479 3839a1.481 1.481 0 0 0-1.479 1.479v.74a.74.74 0 0 0 1.479 0 .741.741 0 0 1 .74-.74h10.356a.741.741 0 0 1 .74.74.74.74 0 0 0 1.479 0v-.74a1.481 1.481 0 0 0-1.479-1.479zm11.836 12.576a.74.74 0 0 1 1.479 0v.74a1.481 1.481 0 0 1-1.479 1.479h-11.836a1.481 1.481 0 0 1-1.479-1.479v-.74a.74.74 0 0 1 1.479 0 .741.741 0 0 0 .74.74h10.356a.741.741 0 0 0 .74-.74z"
        transform="translate(-324 -3839)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Resolution3

import React, { createContext, useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { AppContext } from './app'
import { GET_ACTIVE_SUBSCRIPTIONS, GET_SUBSCRIPTIONS } from '../utils/apiUrls'

const initialState = {
  setOpenSubscriptionModal: () => {},
  openSubscriptionModal: false,
  availableSubscription: [],
  setRedirectionUrl: () => {},
}
//create context

export const SubscriptionContext = createContext(initialState)

const SubscriptionProvider = (props) => {
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
  const [availableSubscription, setAvailableSubscription] = useState([])
  const [redirectionUrl, setRedirectionUrl] = useState('/')
  const [activeSubscription, setActiveSubscription] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const { isLogin, setLoader } = useContext(AppContext)

  const getActivePlans = (planData) => {
    return planData.filter((item) => item.status).map((item) => item.subscription)
  }

  useEffect(() => {
    if (isLogin) {
      activeSubCall()
    } else {
      setActiveSubscription([])
    }
  }, [isLogin])

  const [{ data: subsData, loading: subsLoading }] = useAxios({
    url: GET_SUBSCRIPTIONS + '?is_active=true',
    method: 'get',
  })

  const [{ data: activeSub, loading: activeSubLoading }, activeSubCall] = useAxios(
    {
      url: GET_ACTIVE_SUBSCRIPTIONS,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (activeSub && activeSub.success) {
      setActiveSubscription(getActivePlans(activeSub.data))
    }
  }, [activeSub])

  useEffect(() => {
    if (subsData && subsData.success) {
      setAvailableSubscription(subsData.data.filter((item) => item.status))
    }
  }, [subsData])

  useEffect(() => {
    setLoader(subsLoading || activeSubLoading)
  }, [subsLoading, activeSubLoading])

  return (
    <SubscriptionContext.Provider
      value={{
        openSubscriptionModal,
        setOpenSubscriptionModal: setOpenSubscriptionModal,
        availableSubscription,
        setActiveSubscription,
        activeSubscription,
        setSubscriptions,
        subscriptions,
        setRedirectionUrl: setRedirectionUrl,
        redirectionUrl,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider

import React, { useState } from 'react'

const NewAddToWatchList = ({ color = '#fff', hoverColor = '#bbfa33' }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={14}
      viewBox="0 0 20 14"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <path
        data-name="Path 884"
        d="M2 5h12v2H2z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-2 -5)"
      />
      <path
        data-name="Path 885"
        d="M2 9h12v2H2z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-2 -5)"
      />
      <path
        data-name="Path 886"
        d="M10 13H2v2h8z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-2 -5)"
      />
      <path
        data-name="Path 887"
        d="M16 9h2v4h4v2h-4v4h-2v-4h-4v-2h4z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-2 -5)"
      />
    </svg>
  )
}

export default NewAddToWatchList

import React from 'react'

const Playback1 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 20.673 13.446"
    >
      <path
        data-name="Path 1011"
        d="M4 15.637a9.637 9.637 0 0 1 19.273 0"
        transform="translate(-3.3 -5.3)"
        style={{ fill: 'none', stroke: color, strokeLinejoin: 'round', strokeWidth: '1.4px' }}
      />
      <path
        data-name="Path 1012"
        d="M10 15.409a2.409 2.409 0 1 1 4.818 0"
        transform="translate(-2.072 -3.868)"
        style={{ fill: 'none', stroke: color, strokeLinejoin: 'round', strokeWidth: '1.4px' }}
      />
      <path
        data-name="Path 1013"
        d="M13 13.614 15.409 10"
        transform="translate(-1.459 -4.482)"
        style={{
          strokeLinecap: 'round',
          fill: 'none',
          stroke: color,
          strokeLinejoin: 'round',
          strokeWidth: '1.4px',
        }}
      />
      <path
        data-name="Path 1014"
        d="M23.273 14v1.2a1.2 1.2 0 0 1-1.2 1.2H5.2A1.2 1.2 0 0 1 4 15.2V14"
        transform="translate(-3.3 -3.663)"
        style={{
          strokeLinecap: 'round',
          fill: 'none',
          stroke: color,
          strokeLinejoin: 'round',
          strokeWidth: '1.4px',
        }}
      />
    </svg>
  )
}

export default Playback1

import React from 'react'

const Language7 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.785 12.757"
      style={{ fill: color }}
    >
      <path
        data-name="language-svgrepo-com (10)"
        d="M16.577 7.116a3.866 3.866 0 0 0-3.912-2.288c-.076 0-.148 0-.22.005l-.013-1.731 2.958-.509c.1-.016.112-.1.091-.194s-.14-.741-.158-.819c-.023-.11-.086-.107-.174-.091s-2.728.387-2.728.387L12.41.132c0-.1-.058-.133-.163-.131l-.86.013c-.088 0-.138.041-.136.124l.03 1.932s-2.569.442-2.639.456-.142.044-.126.123.159.918.175.984.06.108.158.09L11.3 3.3l.029 1.694a3.95 3.95 0 0 0-1.891 1.093A3.651 3.651 0 0 0 8.425 8.57a2.12 2.12 0 0 0 1.954 2.262c2.654.325 4.3-2.568 4.842-3.957.921 1.264.215 3.654-1.757 5.019a.125.125 0 0 0-.028.174l.519.634a.162.162 0 0 0 .215.019c2.106-1.452 3.072-3.789 2.408-5.609zm-6.2 2.675c-.811-.1-.792-.767-.792-1.219a2.664 2.664 0 0 1 1.767-2.48l.069 3.589a2.327 2.327 0 0 1-1.042.11zm2.037-.464.039-3.448c.07 0 .139-.008.211-.008a3.613 3.613 0 0 1 1.582.3c.328.182-.859 2.277-1.832 3.154zM4.9 2.892a.164.164 0 0 0-.16-.122H3.1a.163.163 0 0 0-.163.121l-2.93 9.266c-.014.043-.009.064.052.064h1.454c.063 0 .083-.019.1-.06l.846-2.785h2.93l.846 2.785c.013.041.033.06.095.06h1.455c.06 0 .065-.021.052-.064S5.249 3.982 4.9 2.892zm-2.2 5.3 1.222-4.404 1.214 4.406H2.707z"
      />
    </svg>
  )
}

export default Language7

import React from 'react'

const Subtitle3 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 15.948 15.948"
    >
      <path
        data-name="Path 1154"
        d="M7.127 16.647h4.394c3.662 0 5.127-1.465 5.127-5.127V7.127C16.647 3.465 15.183 2 11.521 2H7.127C3.465 2 2 3.465 2 7.127v4.394c0 3.662 1.465 5.126 5.127 5.126z"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
        transform="translate(-1.35 -1.35)"
      />
      <path
        data-name="Path 1155"
        d="M17 17.08h-1.35"
        transform="translate(-5.003 -5.386)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
      <path
        data-name="Path 1156"
        d="M11.238 17.08H6.5"
        transform="translate(-2.554 -5.386)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
      <path
        data-name="Path 1157"
        d="M16.02 13.32h-4.05"
        transform="translate(-4.018 -4.38)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
      <path
        data-name="Path 1158"
        d="M8.529 13.32H6.5"
        transform="translate(-2.554 -4.38)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
    </svg>
  )
}

export default Subtitle3

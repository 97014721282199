import React, { createRef, useState } from 'react'
import ReactAudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import CustomImage from '../../components/customImage/customImage'
import VideoPlaceholder from '../../assets/video-content.png'
import AudioGif from '../../assets/audio-wave.gif'
import PlayIcon from '../../assets/audio-teaser-play.svg'
import StopIcon from '../../assets/audio-teaser-stop.svg'
import { getImage } from '../../utils/helper'

const AudioTrailerPlayer = (props) => {
  const { url, detailObj, accountObj } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const playerRef = createRef(null)
  return (
    <>
      <CustomImage
        imageUrl={getImage(accountObj?.cf_domain_name, detailObj?.thumbnail)}
        loaderClass="content-loader"
        imgAlt="content"
        errorPlaceholder={VideoPlaceholder}
        imgClass="w-ful"
      />
      <div className="absolute z-10 right-0 top-0 mt-4 mr-4 lg:mr-6 lg:mt-8 cursor-pointer">
        {isPlaying ? (
          <img
            src={StopIcon}
            onClick={() => setIsPlaying(false)}
            alt="StopIcon"
            className=" w-6 h-6 lg:w-11 lg:h-11"
          />
        ) : (
          <img
            src={PlayIcon}
            onClick={() => setIsPlaying(true)}
            alt="PlayIcon"
            className=" w-6 h-6 lg:w-11 lg:h-11"
          />
        )}
      </div>
      {isPlaying && (
        <div
          style={{ background: '#00000090' }}
          className="px-2 lg:px-5 absolute top-[60%] lg:top-1/2 right-0 w-full lg:w-3/4 lg:mx-10 z-10 py-2 lg:h-[110px]  flex"
        >
          <img src={AudioGif} alt="AudioGif" style={{ width: '25%' }} className="" />
          <img src={AudioGif} alt="AudioGif" style={{ width: '25%' }} className="" />
          <img src={AudioGif} alt="AudioGif" style={{ width: '25%' }} className="" />
          <img src={AudioGif} alt="AudioGif" style={{ width: '25%' }} className="" />
        </div>
      )}
      {isPlaying && (
        <ReactAudioPlayer
          autoPlay={true}
          ref={playerRef}
          loop={true}
          muted={false}
          src={url}
          className="z-0 absolute opacity-0 top-0 right-0 right text-white "
          style={{ backgroundColor: '#00000090', color: '#fff', width: '400px' }}
        />
      )}
    </>
  )
}

export default AudioTrailerPlayer

import React from 'react'

const Backward6 = ({ color = '#323232' }) => {
  return (
    <svg
      data-name="Group 1161"
      xmlns="http://www.w3.org/2000/svg"
      width="20.479"
      height="22.423"
      viewBox="0 0 20.479 22.423"
    >
      <path
        d="M14.333 24.475a10.316 10.316 0 0 1-10.24-10.24A10.289 10.289 0 0 1 8.38 5.954a.827.827 0 0 1 1.245.181.9.9 0 0 1-.281 1.2 8.5 8.5 0 1 0 6.927-1.415v1.449c0 .713-.492.9-1.034.512l-3.213-2.249a.633.633 0 0 1 0-1.124l3.2-2.249c.552-.4 1.044-.211 1.044.512v1.416a10.236 10.236 0 0 1-1.937 20.289z"
        transform="translate(-4.094 -2.053)"
        style={{ fill: color }}
      />
      <path
        data-name="goforward-10-svgrepo-com"
        d="M17.335 19.219c1.737 0 2.867-1.643 2.867-4.154 0-2.532-1.13-4.2-2.867-4.2s-2.877 1.664-2.877 4.2c-.001 2.512 1.142 4.154 2.877 4.154zm-5.462-.126a.66.66 0 0 0 .68-.722v-6.58a.739.739 0 0 0-.753-.837 1.474 1.474 0 0 0-.858.345l-1.442.973a.694.694 0 0 0-.345.607.628.628 0 0 0 .607.638.756.756 0 0 0 .5-.188l.973-.712v5.755a.646.646 0 0 0 .638.722zm5.462-1.14c-.931 0-1.517-1.1-1.517-2.888 0-1.821.575-2.93 1.517-2.93s1.5 1.1 1.5 2.93c-.004 1.79-.58 2.888-1.5 2.888z"
        transform="translate(-4.438 -2.591)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Backward6

import React, { useContext, useEffect, useState } from 'react'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import WhiteCloseIcon from '../../assets/close-icon-white.svg'
import imageSpinner from '../../assets/image-spinner.gif'

const IframeModal = ({ theme }) => {
  const { popupModalLink, setPopupModalLink, popUpTitle, setPopUpTitle } = useContext(AppContext)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setLoader(true)
  }, [popupModalLink])

  return (
    <div className="flex overflow-x-hidden overflow-y-auto fixed lg:right-9 top-[8.5%] z-50 outline-none focus:outline-none ">
      {/* <div style={{}} className={`relative w-screen lg:w-[40vw] xl:w-[90rem]`}> */}
      <div style={{}} className={`relative w-screen lg:w-[70vw] xl:w-[80vw]`}>
        <div
          style={{
            backgroundColor: '#000',
            fontFamily: 'Arial',
            border: '',
            borderRadius: '10px',
          }}
          className="shadow-lg relative flex flex-col w-full "
        >
          <div className=" ">
            <div
              style={{ borderRadius: '10px' }}
              className="flex justify-center items-center w-full"
            >
              <p className="text-white w-full text-center 2xl:text-lg ml-6">{popUpTitle}</p>
              <div className=" z-20 right-0">
                <img
                  src={WhiteCloseIcon}
                  alt="Logo"
                  className="h-6 cursor-pointer float-right w-6 my-2 mx-2"
                  onClick={() => {
                    setPopupModalLink('')
                    setPopUpTitle('')
                  }}
                />
              </div>
            </div>
            <div className="flex justify-center items-center w-full relative">
              {loader && (
                <div className={`absolute flex justify-center w-full`}>
                  <img src={imageSpinner} alt="spinner" style={{ width: '2rem' }} />
                </div>
              )}
              <iframe
                id="myIframe"
                title="myIframe"
                src={popupModalLink}
                className="scrollbar-class w-full h-[30rem] lg:h-[50vw] xl:h-[45rem] 2xl:h-[46rem]"
                onLoad={() => {
                  setLoader(false)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(IframeModal)

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleCategorySlider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './categorySlider.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import PassimPlayer from '../rosemary/passimPlayer'

const CategorySlider = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  useEffect(() => {
    setSetting({
      arrows: false,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: false,
      dots: true,
      adaptiveHeight: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: theme.c1,
              borderRadius: '0px',
              padding: '0px',
              bottom: '-24px',
              height: '15px',
              // border: '1px solid red',
            }}
          >
            <ul>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${info.props.className === 'slick-active' ? theme.c3 : ''}`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="" />
        </div>
      ),
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full relative h-[80px] lg:h-auto xl:pt-10 ${
        isEdit && hoverState && 'component-hover-class'
      }`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}

      <div className="w-full flex justify-center">
        <div className="w-[95%] lg:w-[90%] h-full hero-section">
          <SliderWrapper>
            <Slider ref={sliderRef} {...setting}>
              {data?.slide_data.map((slide, idx) => (
                <div
                  style={{
                    width: '100%',
                    marginTop: '0px',
                    cursor: 'pointer',
                  }}
                  key={idx}
                  className="-z-10"
                >
                  <div
                    className={`-z-10 rounded-lg xl:rounded-xl relative w-full flex ${
                      config[slide.content.position]
                    } items-center overflow-hidden `}
                    style={{
                      aspectRatio: '1718/286',
                    }}
                  >
                    {isEdit || !slide?.player?.type ? (
                      <CustomImage
                        imageUrl={getTemplateImage(slide?.image_s3key)}
                        imgClass="absolute -z-10 w-full h-full "
                        loaderClass="w-full h-full absolute -z-10"
                        loaderStyle={{
                          background: '#5A5A5A50',
                        }}
                        imgAlt={'category'}
                        onClickRedirect={() => handleRedirect(slide.link)}
                      />
                    ) : (
                      <div className="absolute -z-10 w-full h-full">
                        <PassimPlayer
                          data={slide?.player}
                          poster={getTemplateImage(slide?.image_s3key)}
                          controlBar={false}
                          isBanner={true}
                          iconPositionClass={'decomposed-banner-player-icon'}
                          onClickRedirect={() => handleRedirect(slide.link)}
                        />
                      </div>
                    )}

                    <div className="flex flex-col justify-center items-start z-10 lg:mx-14 lg:my-8 lg:mr-40 pb-3 px-2">
                      <p
                        className={`2xl:text-[40px] xl:text-3xl md:text-xl sm:text-base text-sm font-bold tw-line-clamp-2 
                   ${getStyleValue('heading', 'font_format', 'font-bold')}
                   `}
                        style={{
                          color: getStyleValue('heading', 'color', theme.c7),
                          fontFamily: getStyleValue('heading', 'font', ''),
                          textAlign: getStyleValue('heading', 'alignment', 'left'),
                          maxWidth: '37ch',
                          lineHeight: '2.25ch',
                        }}
                        onClick={() => handleRedirect(slide.link)}
                      >
                        {slide?.content?.heading}
                      </p>
                      <h3
                        style={{
                          color: getStyleValue('sub_heading', 'color', theme.c7),
                          fontFamily: getStyleValue('sub_heading', 'font', ''),
                          textAlign: getStyleValue('sub_heading', 'alignment', 'left'),
                          maxWidth: '90ch',
                          // marginBottom: '1ch',
                        }}
                        className={` hidden md:block font-normal md:text-lg text-sm ${getStyleValue(
                          'sub_heading',
                          'font_format',
                          'italic',
                        )} lg:mt-4 pb-2`}
                        onClick={() => handleRedirect(slide.link)}
                      >
                        {slide?.content?.sub_heading}
                      </h3>
                      {slide?.cta_button?.enabled && (
                        <button
                          className="sm:px-[2.75ch] px-[11px] xl:py-[.6ch] lg:py-[.4ch] md:py-[.3ch] sm:py-[.3ch] py-[2px] font-semibold 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px] lg:mt-4 outline-none border-none"
                          style={{
                            color: getStyleValue('button', 'color', theme.c6),
                            fontFamily: getStyleValue('button', 'font', ''),
                            backgroundColor: getStyleValue('button', 'button_background', theme.c7),
                            borderRadius: '6px',
                          }}
                          onClick={() => handleRedirect(slide.link)}
                        >
                          {slide?.cta_button?.text}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </SliderWrapper>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(CategorySlider)

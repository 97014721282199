import React from 'react'

const Forward4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.622"
      height="13.041"
      viewBox="0 0 22.622 13.041"
    >
      <path
        d="M1.783 11.765a.835.835 0 0 1-1.648.262A10.488 10.488 0 0 1 0 10.375 10.393 10.393 0 0 1 8.729.133 10.5 10.5 0 0 1 10.382 0a10.382 10.382 0 0 1 10.372 9.918l.427-.448a.835.835 0 0 1 1.21 1.151l-1.876 1.964a.96.96 0 0 1-1.315.046l-1.832-2.005A.836.836 0 0 1 18.605 9.5l.477.522a8.706 8.706 0 0 0-8.7-8.349 8.876 8.876 0 0 0-1.392.106 8.718 8.718 0 0 0-7.316 8.593 8.862 8.862 0 0 0 .108 1.39zm9.078.473a2.7 2.7 0 0 1-.371-.966 7.72 7.72 0 0 1-.1-1.379 8.825 8.825 0 0 1 .1-1.467 2.691 2.691 0 0 1 .371-.966 1.533 1.533 0 0 1 .769-.611 3.6 3.6 0 0 1 1.27-.192 3.556 3.556 0 0 1 1.267.192 1.561 1.561 0 0 1 .77.611 2.586 2.586 0 0 1 .371.966 9.221 9.221 0 0 1 .1 1.472 7.981 7.981 0 0 1-.1 1.379 2.591 2.591 0 0 1-.371.961 1.56 1.56 0 0 1-.77.61 3.565 3.565 0 0 1-1.267.192 3.606 3.606 0 0 1-1.272-.192 1.532 1.532 0 0 1-.769-.61zm1.373-3.525v2.78h.394a2.414 2.414 0 0 0 .763-.091c.112-.061.169-.2.169-.418V8.2h-.441a2.315 2.315 0 0 0-.735.086c-.1.057-.15.2-.15.423zM9.345 6.657v6.334h-2.13v-4l-1.295.53-.563-1.557 2.2-1.307h1.788z"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Forward4

import React, { useState } from 'react'

const NewAddedToWatchList = ({ color = '#fff', hoverColor = '#bbfa33' }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.183"
      height="12.561"
      viewBox="0 0 18.183 12.561"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <path
        data-name="Path 909"
        d="M15 6H3v2h12z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-3 -6)"
      />
      <path
        data-name="Path 910"
        d="M15 10H3v2h12z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-3 -6)"
      />
      <path
        data-name="Path 911"
        d="M3 14h8v2H3z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-3 -6)"
      />
      <path
        data-name="Path 912"
        d="m11.991 15.025 1.409-1.414 2.121 2.121 4.243-4.243 1.419 1.411-5.657 5.657z"
        style={{ fill: isHovered ? hoverColor : color }}
        transform="translate(-3 -6)"
      />
    </svg>
  )
}

export default NewAddedToWatchList

import React, { useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/fantasy/index.css'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import MuteIcon from '../../assets/mute-icon.svg'
import SoundIcon from '../../assets/sound-icon.svg'
import FullscreenIcon from '../../assets/fullscreen-icon.svg'

const ChannelPlayerVideoJs = (props) => {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const { options, onReady, cover, watermarkLogo, muted } = props
  const [toggleMute, setToggleMute] = useState(muted)

  React.useEffect(() => {
    videojs.registerPlugin('qualityLevels', qualityLevels)
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
      }))
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const changePlayerOptions = (status) => {
    // you can update the player through the Video.js player instance
    if (!playerRef.current) {
      return
    }

    // [update player through instance's api]
    playerRef.current.muted(status)
    setToggleMute(status)
  }
  const fullScreenToggle = () => {
    if (!playerRef.current) {
      return
    }
    if (playerRef.current.isFullscreen()) {
      playerRef.current.exitFullscreen()
      return
    }
    playerRef.current.requestFullscreen()
  }

  let coverCss = cover ? { objectFit: 'cover' } : { objectFit: '' }
  return (
    <div data-vjs-player="" className="relative">
      <div
        style={{ zIndex: 40 }}
        className=" p-2 md:p-6 absolute flex items-center justify-end w-full"
      >
        {/* <div
          style={{ background: '#00000070' }}
          className={` px-2 py-1 md:px-4 md:py-1 rounded xl:rounded-lg text-xs lg:text-lg font-semibold  line-clamp-1 `}
        >
          {contentInfo?.title}
        </div> */}
        <img src={watermarkLogo} className={`   h-6 w-9 md:h-12 md:w-20 right-0`} alt="poster" />
      </div>

      <div
        className="flex justify-end absolute right-0 bottom-0 m-4 z-10 text-white"
        style={{ alignItems: 'center' }}
      >
        {toggleMute ? (
          <img
            src={MuteIcon}
            className={'w-8 h-8 cursor-pointer mx-2'}
            alt="MuteIcon"
            style={{ opacity: '75%' }}
            onClick={() => changePlayerOptions(false)}
          />
        ) : (
          <img
            src={SoundIcon}
            className={'w-8 h-8 cursor-pointer mx-2'}
            alt="SoundIcon"
            style={{ opacity: '75%' }}
            onClick={() => changePlayerOptions(true)}
          />
        )}
        <img
          src={FullscreenIcon}
          className={'w-8 h-8 cursor-pointer mx-2'}
          style={{ opacity: '75%' }}
          alt="FullscreenIcon"
          onClick={fullScreenToggle}
        />
      </div>

      <video
        ref={videoRef}
        style={coverCss}
        className="video-js vjs-fill vjs-big-play-centered object-cove vjs-theme-fantasy"
      />
    </div>
  )
}

export default ChannelPlayerVideoJs

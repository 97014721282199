import React, { useContext, useState } from 'react'
import VideoPlaceholder from '../../assets/video-content.png'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'
import './hoverCard.css'
import LoaderTile from './loaderTile'
import { AppContext } from '../../context/app'
import { isBrowser, isMobile } from 'react-device-detect'
import { convertDurationSmall } from '../../utils/helper'
import { isEmpty } from 'lodash'

const PortraitOwlCard = (props) => {
  const {
    data,
    background,
    isEdit = false,
    thumbnail,
    theme,
    gradientColor,
    subHeadingColor,
    headingColor,
  } = props
  // const navigate = useNavigate()
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [imgLoaded, setImgLoaded] = useState(false)
  const { accountObj } = useContext(AppContext)

  console.log('data', data)

  const handleClick = () => {
    if (isEdit) return
    if (data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST') {
      customNavigate('DIRECT', `/playlist/${data.content_id}`)
      return
    }
    if (data.video_type === 'MPVP' || data.audio_type === 'MPAP') {
      customNavigate('DIRECT', `/parent-content/${data.content_id}`)
    } else {
      if (
        (accountObj?.od_resource_allowances?.player_page && data.video_type === 'MPVC') ||
        data.video_type === 'MPAC'
      ) {
        customNavigate('DIRECT', `/series-watch/${data?.content_id}`)
        return
      }

      customNavigate('DIRECT', `/content/${data.content_id}`)
    }
  }

  return (
    <div
      className="relative transform transition duration-500 ease z-0  rounded-lg xl:rounded-xl overflow-hidden"
      onClick={handleClick}
      style={{
        background: background ? background : theme.c1,
        aspectRatio: '0.6666',
        width: isBrowser && !isMobile ? '96%' : '76%',
      }}
    >
      <div className="flex items-center justify-start relative h-full">
        {!imgLoaded && <LoaderTile aspectRatio={'0.6666'} width={'100%'} />}
        <img
          src={getTemplateImage(thumbnail)}
          className={` h-full`}
          alt={data?.title}
          style={{ display: !imgLoaded ? 'none' : 'block', width: '100%' }}
          onLoad={() => setImgLoaded(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = VideoPlaceholder
          }}
        />
        <div
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${gradientColor} 47%)`,
          }}
          className="w-full absolute bottom-0 z-10  h-[27%] flex justify-center items-end"
        >
          <div className="w-full mb-1 ">
            <p
              style={{ color: headingColor }}
              className="text-center font-bold text-xs md:text-base line-clamp-2 px-1 xl:px-4 mb-0"
            >
              {data.title || ''}
            </p>
            <div
              style={{ color: subHeadingColor }}
              className="flex justify-center items-center text-[10px] md:text-xs px-1 xl:px-4 mt-[3px]  xl:mt-[9px] w-full"
            >
              {data?.duration_in_s > 0 && (
                <p className="mb-0 line-clamp-1"> {convertDurationSmall(data?.duration_in_s)}</p>
              )}

              {!isEmpty(data?.category) && (
                <>
                  {data?.duration_in_s > 0 ? (
                    <div
                      style={{ background: subHeadingColor }}
                      className="rounded-full w-[3px] h-[3px] md:h-[5px] md:w-[5px] mx-1 xl:mx-2"
                    />
                  ) : null}

                  <p className="mb-0 line-clamp-1"> {data?.category[0]?.name}</p>
                </>
              )}

              {!isEmpty(data?.category[1]) && (
                <>
                  <div
                    style={{ background: subHeadingColor }}
                    className="rounded-full w-[3px] h-[3px] md:h-[5px] md:w-[5px] mx-1 xl:mx-2"
                  />
                  <p className="mb-0 line-clamp-1"> {data?.category[1]?.name}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(PortraitOwlCard)

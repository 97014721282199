import React from 'react'

const Resolution5 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 18.083 14.795"
    >
      <path
        d="M1 7.11V3.822A.822.822 0 0 1 1.822 3h4.932a.822.822 0 0 1 0 1.644h-4.11V7.11A.822.822 0 0 1 1 7.11zM18.261 3h-4.932a.822.822 0 1 0 0 1.644h4.11V7.11a.822.822 0 0 0 1.644 0V3.822A.822.822 0 0 0 18.261 3zM1.822 17.795h4.932a.822.822 0 1 0 0-1.644h-4.11v-2.466a.822.822 0 1 0-1.644 0v3.288a.822.822 0 0 0 .822.822zm16.439-4.932a.822.822 0 0 0-.822.822v2.466h-4.11a.822.822 0 1 0 0 1.644h4.932a.822.822 0 0 0 .822-.822v-3.288a.822.822 0 0 0-.822-.822zm-3.288 1.644H5.11a.822.822 0 0 1-.822-.822V7.11a.822.822 0 0 1 .822-.822h9.863a.822.822 0 0 1 .822.822v6.576a.822.822 0 0 1-.822.821zm-.822-6.576H5.932v4.932h8.219z"
        transform="translate(-1 -3)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Resolution5

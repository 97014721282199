import React from 'react'

const BriefcaseIcon = ({ color = '#ffee00' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.2" height={18} viewBox="0 0 19.2 18">
      <path
        d="M216.8 215.6a1.188 1.188 0 0 0-.166-.6h-2.068a1.188 1.188 0 0 0-.166.6 1.2 1.2 0 1 0 2.4 0zm1.2 0a2.4 2.4 0 0 1-4.8 0 2.272 2.272 0 0 1 .085-.6H206v6.6a2.4 2.4 0 0 0 2.4 2.4h14.4a2.4 2.4 0 0 0 2.4-2.4V215h-7.285a2.271 2.271 0 0 1 .085.6zm0-7.2h-4.8v-.6a.6.6 0 0 1 .6-.6h3.6a.6.6 0 0 1 .6.6v.6zm4.8 0h-3.6v-1.2a1.2 1.2 0 0 0-1.2-1.2h-4.8a1.2 1.2 0 0 0-1.2 1.2v1.2h-3.6a2.4 2.4 0 0 0-2.4 2.4v3h19.2v-3a2.4 2.4 0 0 0-2.4-2.4z"
        transform="translate(-206 -206)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default BriefcaseIcon

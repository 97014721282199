import React from 'react'

const Setting8 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 18.914 15.985">
      <path
        d="M17.209 12.558a2.262 2.262 0 0 0 2.121-1.492h3.248a.75.75 0 0 0 .723-.76.742.742 0 0 0-.723-.751h-3.239a2.251 2.251 0 0 0-4.252 0h-9.94a.749.749 0 0 0-.76.751.757.757 0 0 0 .76.76h9.94a2.262 2.262 0 0 0 2.122 1.492zm0-1.155a1.1 1.1 0 0 1 0-2.206 1.1 1.1 0 0 1 0 2.206zm-12.1 3.877a.75.75 0 0 0-.723.76.742.742 0 0 0 .723.751H8.46a2.251 2.251 0 0 0 4.252 0h9.828a.749.749 0 0 0 .76-.751.757.757 0 0 0-.76-.76h-9.828a2.26 2.26 0 0 0-4.252 0zm5.472 1.858a1.1 1.1 0 0 1 0-2.2 1.1 1.1 0 0 1 0 2.2zm6.627 6.89a2.257 2.257 0 0 0 2.121-1.5h3.248a.742.742 0 0 0 .723-.751.75.75 0 0 0-.723-.76H19.33a2.254 2.254 0 0 0-4.243 0h-9.94a.757.757 0 0 0-.76.76.749.749 0 0 0 .76.751h9.94a2.257 2.257 0 0 0 2.122 1.5zm0-1.155a1.094 1.094 0 0 1-1.1-1.1 1.1 1.1 0 1 1 1.1 1.1z"
        transform="translate(-4.387 -8.043)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Setting8

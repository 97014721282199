import React from 'react'

const AddWatchList = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      viewBox="0 0 34 34"
    >
      <g id="Group_774" data-name="Group 774" transform="translate(-616 -262)">
        <path
          id="circle-plus-svgrepo-com_1_"
          data-name="circle-plus-svgrepo-com (1)"
          fill={color}
          d="M19.125,19.125H25.5v-4.25H19.125V8.5h-4.25v6.375H8.5v4.25h6.375V25.5h4.25Z"
          transform="translate(616 262)"
        />
        <g id="Complete" transform="translate(615 261)">
          <g id="Circle" transform="translate(2 2)">
            <circle id="Circle-2" data-name="Circle" style={pathStyle} cx={16} cy={16} r={16} />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AddWatchList

export default {
  name: 'GREEN_ENERGY',
  c1: '#EBEED9',
  c2: '#B9C34E',
  c3: '#6491BB',
  c4: '#2D475B',
  c5: '#2D475B',
  c6: '#314d63',
  c7: '#FFFFFF',
  c8: '#8a9332',
  c9: '#1c2c39',
  c10: '#e3e9c0',
  c11: '#32495E',
}

import React, { useState } from 'react'
import { isEmpty } from '../../utils/helper'

const menuBtnStyles = {
  fontSize: 'max(1.2vw,16px)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  //   background: 'gray',
  paddingBottom: '10px',
  borderBottom: '3px solid',
}

const ChannelBottom = ({ options = [] }) => {
  const [menuState, setMenuState] = useState(0)

  const renderComponent = (num) => {
    return options[num]?.child
  }
  return (
    <div className="lg:w-[88.5%] w-full ">
      <div className=" h-[7vh] w-full" style={{ position: 'relative', zIndex: '10' }}>
        <div
          style={{
            borderBottom: '1px solid #707070',
            overflowX: 'auto',
          }}
          className="relative w-full lg:w-auto h-full no-scrollbar"
        >
          <div className="flex justify-start items-end h-full md:gap-x-[min(5vw,71px)] gap-x-[43px] md:w-full w-[560px] lg:px-0 px-4 ">
            {!isEmpty(options) &&
              options.map((option, idx) => (
                <button
                  key={idx}
                  style={{
                    ...menuBtnStyles,
                    color: menuState === idx ? '#09e9e9' : '#cecece',
                    borderBottomColor: menuState === idx ? '#09e9e9' : 'transparent',
                  }}
                  onClick={() => setMenuState(idx)}
                >
                  {option.title}
                </button>
              ))}
          </div>
        </div>
      </div>
      <div
        style={{ border: '2px solid red', borderColor: 'transparent' }}
        className="w-full lg:px-0 px-4 relative text-white scrollbar-class aspect-[370/404] lg:aspect-[1087/567]"
      >
        {renderComponent(menuState)}
      </div>
    </div>
  )
}

export default ChannelBottom

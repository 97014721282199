import React from 'react'
import Playback1 from '../../../assets/customPlayer/playback/playback1'
import Playback2 from '../../../assets/customPlayer/playback/playback2'
import Playback3 from '../../../assets/customPlayer/playback/playback3'
import Playback4 from '../../../assets/customPlayer/playback/playback4'
import Playback5 from '../../../assets/customPlayer/playback/playback5'
import Playback6 from '../../../assets/customPlayer/playback/playback6'
import Playback7 from '../../../assets/customPlayer/playback/playback7'

const PlaybackSpeedButton = ({ color = '#fff', iconType }) => {
  let icons = {
    PLAYBACK1: (
      <>
        <Playback1 color={color} />
      </>
    ),
    PLAYBACK2: (
      <>
        <Playback2 color={color} />
      </>
    ),
    PLAYBACK3: (
      <>
        <Playback3 color={color} />
      </>
    ),
    PLAYBACK4: (
      <>
        <Playback4 color={color} />
      </>
    ),
    PLAYBACK5: (
      <>
        <Playback5 color={color} />
      </>
    ),
    PLAYBACK6: (
      <>
        <Playback6 color={color} />
      </>
    ),
    PLAYBACK7: (
      <>
        <Playback7 color={color} />
      </>
    ),
  }

  return (
    <div style={{ maxWidth: '15px' }} className="flex justify-center items-center w-auto h-full">
      {icons[iconType]}
    </div>
  )
}

export default PlaybackSpeedButton

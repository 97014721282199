import React, { useContext, useEffect, useState } from 'react'
import ChannelBanner from '../../assets/channels-bg.png'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { IPTV_CHANNEL_FILTER } from '../../utils/apiUrls'
import { withTheme } from 'theming'
import ChannelCard from '../../components/channelCard'
import { shuffle } from 'lodash'
import { useTranslation } from 'react-i18next'

const ChannelListing = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [dataList, setDataList] = useState([])
  const { t } = useTranslation()

  const [{ data: channelData, loading: channelLoading }, channelCall] = useAxios(
    {
      url: IPTV_CHANNEL_FILTER,
      method: 'post',
    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    setDataList([])
    channelCall({
      data: {
        filters: {},
        order_by: 'name',
        reverse: true,
        page_size: '1000',
        page: '1',
      },
    })
  }, [])

  useEffect(() => {
    if (channelData && channelData.success) {
      setDataList(shuffle(channelData.data))
    }
  }, [channelData])

  useEffect(() => {
    setLoader(channelLoading)
  }, [channelLoading])

  return (
    <div>
      <div className="w-full relative ">
        <img src={ChannelBanner} className="w-full" alt="ChannelBanner" />
        <div
          style={{
            color: isCustomTemplate ? theme.c7 : '#fff',
            top: '38%',
          }}
          className=" absolute text-xs md:text-4xl font-bold left-3 lg:left-24"
        >
          {t('tv_channels_caps')}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-[10vw] gap-y-[3.16vw] px-4 py-5 md:px-16 md:py-10 lg:px-24 xl:py-[3.7vw]">
        {dataList && dataList.map((item, index) => <ChannelCard item={item} key={index} />)}
      </div>
    </div>
  )
}

export default withTheme(ChannelListing)

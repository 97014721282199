import React from 'react'

const Subtitle5 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 19.974 14.647"
    >
      <g data-name="09.SUBTITLE">
        <path
          data-name="Path 1159"
          d="M9.623 17.714c.573-.1 2.31.36 2.277-.666s-1.7-.573-2.277-.666c-2.663-.053-2.663-4.175 0-4.221h1.611a.666.666 0 1 0 0-1.332H9.623c-4.423.114-4.423 6.771 0 6.885z"
          transform="translate(-2.772 -6.948)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1160"
          d="M20.664 17.714c.573-.1 2.31.36 2.277-.666s-1.7-.573-2.277-.666c-2.663-.053-2.663-4.175 0-4.221h1.611a.666.666 0 0 0 0-1.332h-1.611c-4.427.114-4.42 6.771 0 6.885z"
          transform="translate(-6.463 -6.948)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1161"
          d="M20.308 5H1.666A.666.666 0 0 0 1 5.666v13.316a.666.666 0 0 0 .666.666h18.642a.666.666 0 0 0 .666-.666V5.666A.666.666 0 0 0 20.308 5zm-.666 13.316H2.332V6.332h17.31z"
          style={{ fill: color }}
          transform="translate(-1 -5)"
        />
      </g>
    </svg>
  )
}

export default Subtitle5

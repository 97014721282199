import React from 'react'

const SelectionArrow = ({ iconClass = '', color = '#ff1818' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`cursor-pointer w-full h-full ${iconClass}`}
      viewBox="0 0 7.183 13.196"
    >
      <path
        d="M9.711 18.293a1 1 0 0 0 1.414 0l4.887-4.893a2 2 0 0 0 0-2.828l-4.89-4.89A1 1 0 0 0 9.707 7.1l4.186 4.186a1 1 0 0 1 0 1.414l-4.182 4.179a1 1 0 0 0 0 1.414z"
        transform="translate(-9.414 -5.39)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default SelectionArrow

import React, { useContext, useState } from 'react'
import VideoJS from '../../player/videoJS'
import { AppContext } from '../../../context/app'
import { getTrailerUrl } from '../../../utils/helper'
import MuteIcon from '../../../assets/mute-icon.svg'
import SoundIcon from '../../../assets/sound-icon.svg'
import FullscreenIcon from '../../../assets/fullscreen-icon.svg'
import PlayIcon from '../../../assets/audio-teaser-play.svg'
import StopIcon from '../../../assets/audio-teaser-stop.svg'
import { isIOS, isMobile } from 'react-device-detect'

const TemplateTrailerPlayer = ({
  data,
  poster,
  quality,
  controlBar = true,
  isBanner = false,
  iconPositionClass = '',
  onClickRedirect = () => {},
}) => {
  const playerRef = React.useRef(null)
  const { accountObj } = useContext(AppContext)
  const [toggleMute, setToggleMute] = useState(true)
  const [togglePlay, setTogglePlay] = useState(false)
  const isIphone = isMobile && isIOS

  const videoJsOptions = {
    autoplay: false,
    muted: toggleMute,
    responsive: true,
    loadingSpinner: true,
    liveui: true,
    fill: true,
    loop: true,
    poster: poster,
    suppressNotSupportedError: true,
    sources: [
      {
        src: getTrailerUrl(accountObj?.cf_domain_name, data?.id, quality),
        type: 'application/x-mpegURL',
      },
    ],
    plugins: {
      qualityLevels: {},
      hlsQualitySelector: {},
    },
    html5: {
      hls: {
        overrideNative: true,
      },
    },
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      // console.log('player is waiting')
    })

    player.on('canplay', () => {
      // console.log('player is canplay')

      const startVideo = async () => {
        try {
          if (!playerRef.current) {
            return
          }
          if (!isIphone) {
            await playerRef.current.play()
          }

          // playerRef.current.setAttribute("autoplay", true);
          // setShowMute(true)
          // console.log('video started playing successfully')
        } catch (err) {
          // console.log(err, 'video play error')
          // do stuff in case your video is unavailable to play/autoplay
        }
      }

      setTimeout(startVideo, 2000)
    })

    player.on('dispose', () => {
      // console.log('player will dispose')
    })
  }

  const changePlayerOptions = (status) => {
    // you can update the player through the Video.js player instance
    if (!playerRef.current) {
      return
    }

    // [update player through instance's api]
    playerRef.current.muted(status)
    setToggleMute(status)
  }
  const fullScreenToggle = () => {
    if (!playerRef.current) {
      return
    }
    playerRef.current.requestFullscreen()
  }

  const playToggle = (value) => {
    if (!playerRef.current) {
      return
    }
    setTogglePlay(value)
    if (value) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }

  return (
    <div className="w-full h-full relative">
      {isBanner && (
        <div className={`flex absolute m-4 z-10 text-white ${iconPositionClass}`}>
          {isIphone ? (
            togglePlay ? (
              <img
                src={StopIcon}
                onClick={() => playToggle(false)}
                alt="StopIcon"
                className="w-9 h-9 xl:w-12 xl:h-12 cursor-pointer mx-2"
              />
            ) : (
              <img
                src={PlayIcon}
                onClick={() => playToggle(true)}
                alt="PlayIcon"
                className="w-9 h-9 xl:w-12 xl:h-12 cursor-pointer mx-2"
              />
            )
          ) : null}
          {toggleMute ? (
            <div
              className="w-9 h-9 xl:w-12 xl:h-12 cursor-pointer mx-2"
              onClick={() => changePlayerOptions(false)}
            >
              <img
                src={MuteIcon}
                className={'w-full h-full'}
                alt="MuteIcon"
                style={{ opacity: '75%' }}
              />
            </div>
          ) : (
            <div
              className="w-9 h-9 xl:w-12 xl:h-12 cursor-pointer mx-2"
              onClick={() => changePlayerOptions(true)}
            >
              <img
                src={SoundIcon}
                className={'w-full h-full'}
                alt="SoundIcon"
                style={{ opacity: '75%' }}
              />
            </div>
          )}
        </div>
      )}
      {controlBar && (
        <div
          className={`flex justify-end absolute right-0 bottom-0 m-4 z-10 text-white`}
          style={{ alignItems: 'center' }}
        >
          {isIphone ? (
            togglePlay ? (
              <img
                src={StopIcon}
                onClick={() => playToggle(false)}
                alt="StopIcon"
                className=" w-8 h-8 cursor-pointer mx-2"
              />
            ) : (
              <img
                src={PlayIcon}
                onClick={() => playToggle(true)}
                alt="PlayIcon"
                className=" w-8 h-8 cursor-pointer mx-2"
              />
            )
          ) : null}
          {toggleMute ? (
            <img
              src={MuteIcon}
              className={'w-8 h-8 cursor-pointer mx-2'}
              alt="MuteIcon"
              style={{ opacity: '75%' }}
              onClick={() => changePlayerOptions(false)}
            />
          ) : (
            <img
              src={SoundIcon}
              className={'w-8 h-8 cursor-pointer mx-2'}
              alt="SoundIcon"
              style={{ opacity: '75%' }}
              onClick={() => changePlayerOptions(true)}
            />
          )}
          <img
            src={FullscreenIcon}
            className={'w-8 h-8 cursor-pointer mx-2'}
            style={{ opacity: '75%' }}
            alt="FullscreenIcon"
            onClick={fullScreenToggle}
          />
        </div>
      )}

      <VideoJS
        options={videoJsOptions}
        onReady={handlePlayerReady}
        cover={true}
        onClickRedirect={onClickRedirect}
      />
    </div>
  )
}

export default TemplateTrailerPlayer

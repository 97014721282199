import React from 'react'

const PlayPause1 = ({ isPlaying = false, primaryColor = '#323232' }) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 17.64 17.64"
        >
          <path
            d="M12.914 21.734a8.885 8.885 0 0 0 8.82-8.82 8.9 8.9 0 0 0-8.829-8.82 8.82 8.82 0 0 0 .009 17.64zm-2.266-5.7c-.4 0-.571-.208-.571-.527v-5.18c0-.311.173-.527.571-.527h.787c.4 0 .571.216.571.527v5.18c0 .32-.173.527-.571.527zm3.736 0c-.389 0-.579-.208-.579-.527v-5.18a.507.507 0 0 1 .579-.527h.787a.5.5 0 0 1 .571.527v5.18c0 .32-.182.527-.571.527z"
            transform="translate(-4.094 -4.094)"
            style={{ fill: primaryColor }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 17.635 17.635"
        >
          <path
            d="M12.911 21.729a8.883 8.883 0 0 0 8.818-8.818A8.9 8.9 0 0 0 12.9 4.094a8.818 8.818 0 0 0 .009 17.635zm-1.582-5.481c-.4.242-.847.052-.847-.363V9.938c0-.4.475-.571.847-.354l4.858 2.879a.525.525 0 0 1 0 .916z"
            transform="translate(-4.094 -4.094)"
            style={{ fill: primaryColor }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause1

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleClassyBanner'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './classyBanners.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import PassimPlayer from '../rosemary/passimPlayer'

const ClassBannerPlayer = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const { customNavigate, getTemplateImage, UpArrow, DownArrow } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transaprent`,
          width: '5.56%',
          height: '2.78vw',
          position: 'absolute',
          top: '85%',
          right: '2.5%',
          content: `url(${DownArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '5.56%',
          height: '2.78vw',
          position: 'absolute',
          top: '15%',
          left: '92%',
          content: `url(${UpArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }

  useEffect(() => {
    setSetting({
      arrows: true,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: true,
      vertical: true,
      verticalSwiping: true,
      dots: true,
      adaptiveHeight: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: 'transparent',
              borderRadius: '0px',
              padding: '0px',
              bottom: '50%',
              left: '98%',
              position: 'absolute',
              // outline: '2px solid hotpink',
              transform: 'translate(-50%,50%) rotate(90deg)',
              zIndex: 10,
            }}
          >
            <ul style={{ marginTop: '5px' }}>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active' ? '#d9d9d9' : '#d9d9d950'
                      }`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full 2xl:h-[82vh] lg:h-[72vh] md:h-[62vh] sm:h-[42vh] h-[180px]  relative ${
        isEdit && hoverState && 'component-hover-class'
      }`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
          position={'top-20 right-4'}
          menuPosition={'top-24 right-4'}
        />
      )}
      <div className="overflow-hidden w-full h-full bg-cover bg-center hero-section">
        <SliderWrapper>
          <Slider ref={sliderRef} {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div
                style={{ width: '100%', height: '100%', marginTop: '0px', cursor: 'pointer' }}
                key={idx}
                className="-z-10"
              >
                <div
                  className={`-z-10 relative w-full 2xl:h-[82vh] lg:h-[72vh] md:h-[62vh] sm:h-[42vh] h-[180px] flex ${
                    config[slide.content.position]
                  }  `}
                  // 2xl:py-16 2xl:px-28 xl:py-12 xl:px-20 lg:py-10 lg:px-16 md:py-8 md:px-14 sm:p-6 p-[16px]
                >
                  {isEdit || !slide?.player?.type ? (
                    <CustomImage
                      imageUrl={getTemplateImage(slide?.image_s3key)}
                      imgClass="absolute -z-10 w-full h-full"
                      loaderClass="w-full h-full absolute -z-10"
                      loaderStyle={{
                        background: '#5A5A5A50',
                      }}
                      imgAlt={'category'}
                      onClickRedirect={() => handleRedirect(slide.link)}
                    />
                  ) : (
                    <div className="absolute -z-10 w-full h-full">
                      <PassimPlayer
                        data={slide?.player}
                        poster={getTemplateImage(slide?.image_s3key)}
                        controlBar={false}
                        isBanner={true}
                        iconPositionClass={'decomposed-banner-player-icon'}
                        onClickRedirect={() => handleRedirect(slide.link)}
                      />
                    </div>
                  )}

                  <div className="flex flex-col justify-center items-start z-10 lg:mx-14 lg:my-16 lg:mr-40 pb-3 px-2">
                    <p
                      className={`2xl:text-[40px] xl:text-3xl md:text-xl sm:text-base text-sm font-bold tw-line-clamp-2 
                      ${getStyleValue('heading', 'font_format', 'font-bold')}
                      `}
                      style={{
                        color: getStyleValue('heading', 'color', theme.c7),
                        fontFamily: getStyleValue('heading', 'font', ''),
                        textAlign: getStyleValue('heading', 'alignment', 'left'),
                        maxWidth: '37ch',
                        lineHeight: '2.25ch',
                      }}
                      onClick={() => handleRedirect(slide.link)}
                    >
                      {slide?.content?.heading}
                    </p>
                    <h3
                      style={{
                        color: getStyleValue('sub_heading', 'color', theme.c7),
                        fontFamily: getStyleValue('sub_heading', 'font', ''),
                        textAlign: getStyleValue('sub_heading', 'alignment', 'left'),
                        maxWidth: '90ch',
                        // marginBottom: '1ch',
                      }}
                      className={` hidden md:block font-normal md:text-lg text-sm ${getStyleValue(
                        'sub_heading',
                        'font_format',
                        'italic',
                      )} lg:mt-4 pb-2`}
                      onClick={() => handleRedirect(slide.link)}
                    >
                      {slide?.content?.sub_heading}
                    </h3>
                    {slide?.cta_button?.enabled && (
                      <button
                        className="sm:px-[2.75ch] px-[11px] xl:py-[.6ch] lg:py-[.4ch] md:py-[.3ch] sm:py-[.3ch] py-[2px] font-semibold 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px] lg:mt-4"
                        style={{
                          color: getStyleValue('button', 'color', theme.c6),
                          fontFamily: getStyleValue('button', 'font', ''),
                          backgroundColor: getStyleValue('button', 'button_background', theme.c7),
                          borderRadius: '6px',
                        }}
                        onClick={() => handleRedirect(slide.link)}
                      >
                        {slide?.cta_button?.text}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(ClassBannerPlayer)

import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import useTemplateHook from '../templateHook'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'

const IntentMarketingForm = ({ data, isEdit, onFormSubmit, theme }) => {
  const { getTemplateImage } = useTemplateHook()
  let { form_metadata, form_fields } = data
  let { data: formMetadata } = form_metadata
  const [fieldData, setFieldData] = useState([])
  const [errorData, setErrorData] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  let buttonPosition = {
    LEFT: 'justify-start',
    CENTER: 'justify-center',
    RIGHT: 'justify-end',
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      setFieldData(form_fields)
      setErrorData(Array(form_fields.length).fill(''))
      checkIfFormSubmitted()
    }
  }, [data])

  const validateInfo = () => {
    let isFormValid = true
    let tempError = errorData

    for (let i = 0; i < fieldData.length; i++) {
      const element = fieldData[i]
      if (element.is_mandatory && !element.value) {
        isFormValid = false
        tempError[i] = 'Field cannot be empty'
      } else if (
        element.type === 'EMAIL' &&
        (element.value.length < 5 || !element.value.includes('@') || !element.value.includes('.'))
      ) {
        isFormValid = false
        tempError[i] = 'Enter a valid E-mail'
      }
    }
    setErrorData([...tempError])
    return isFormValid
  }

  const handleInputDataChange = (index, event) => {
    const { value } = event.target
    let tempError = errorData
    let tempFieldData = fieldData

    tempFieldData[index].value = value
    tempError[index] = ''

    setFieldData([...tempFieldData])
    setErrorData([...tempError])
  }

  const handleCheckBoxDataChange = (index, event) => {
    const { value, checked } = event.target
    let tempError = errorData
    let tempFieldData = fieldData

    let valueArr = tempFieldData[index].value ? tempFieldData[index].value : []

    if (checked) {
      valueArr.push(value)
      tempFieldData[index].value = valueArr
    } else {
      tempFieldData[index].value = valueArr.filter((ele) => ele !== value)
    }

    tempError[index] = ''

    setFieldData([...tempFieldData])
    setErrorData([...tempError])
  }

  const handleSave = async () => {
    if (isEdit || !validateInfo()) return

    let isSuccess = await onFormSubmit(data, fieldData)

    setIsSubmitted(isSuccess)
  }

  const checkIfFormSubmitted = () => {
    let submittedFormsIDs = JSON.parse(localStorage.getItem('submittedFormIDs'))
    if (submittedFormsIDs) {
      setIsSubmitted(submittedFormsIDs.includes(data._id))
    }
  }

  const getFieldInput = (field, index) => {
    if (field.type === 'TEXT') {
      return (
        <input
          type="text"
          placeholder="Your text here"
          value={field.value}
          style={{
            border: `solid 0.5px ${getStyleValue('field_colors', 'input_text', '#ffffff')}90`,
            color: getStyleValue('field_colors', 'input_text', '#ffffff'),
            background: 'transparent',
          }}
          className="w-full rounded-sm text-sm px-1 py-1 focus:outline-none"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }

    if (field.type === 'TEXT_AREA') {
      return (
        <textarea
          placeholder="Your text here"
          rows="3"
          value={field.value}
          style={{
            border: `solid 0.5px ${getStyleValue('field_colors', 'input_text', '#ffffff')}90`,
            color: getStyleValue('field_colors', 'input_text', '#ffffff'),
            background: 'transparent',
          }}
          className="w-full rounded-sm text-sm px-1 py-1 focus:outline-none"
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }

    if (field.type === 'DROPDOWN') {
      return (
        <select
          value={field.value}
          style={{
            border: `solid 0.5px ${getStyleValue('field_colors', 'input_text', '#ffffff')}90`,
            color: getStyleValue('field_colors', 'input_text', '#ffffff'),
            background: 'transparent',
          }}
          className="w-full rounded-sm text-sm px-1 py-1 focus:outline-none"
          onChange={(event) => handleInputDataChange(index, event)}
        >
          <option value="" disabled>
            Select
          </option>
          {field.options.map((ele, idx) => (
            <option value={ele} key={idx} style={{ color: 'black' }}>
              {ele}
            </option>
          ))}
        </select>
      )
    }

    if (field.type === 'MULTI_CHOICE') {
      return (
        <div
          style={{ color: getStyleValue('field_colors', 'input_text', '#ffffff') }}
          className="w-full flex flex-col"
        >
          {[...field.options].map((ele, idx) => {
            return (
              <label className={`w-full ${idx && 'mt-[8px]'}`} key={idx}>
                <input
                  type="radio"
                  name={ele}
                  value={ele}
                  onClick={(event) => handleCheckBoxDataChange(index, event)}
                  className="mr-2"
                />
                {ele}
              </label>
            )
          })}
        </div>
      )
    }

    if (field.type === 'CHECKBOX') {
      return (
        <div
          style={{ color: getStyleValue('field_colors', 'input_text', '#ffffff') }}
          className="w-full flex flex-col"
        >
          {[...field.options].map((ele, idx) => {
            return (
              <label className={`w-full ${idx && 'mt-[8px]'}`} key={idx}>
                <input
                  type="checkbox"
                  name={ele}
                  value={ele}
                  onClick={(event) => handleCheckBoxDataChange(index, event)}
                  className="mr-2"
                />
                {ele}
              </label>
            )
          })}
        </div>
      )
    }

    if (field.type === 'DATE') {
      return (
        <input
          type="date"
          style={{
            border: `solid 0.5px ${getStyleValue('field_colors', 'input_text', '#ffffff')}90`,
            color: getStyleValue('field_colors', 'input_text', '#ffffff'),
            background: 'transparent',
            accentColor: getStyleValue('field_colors', 'input_text', '#ffffff'),
          }}
          className="w-3/4 md:w-1/2 xl:w-1/3 rounded-sm text-sm px-1 py-1 focus:outline-none"
          value={field.value}
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }

    if (field.type === 'TIME') {
      return (
        <input
          type="time"
          style={{
            border: `solid 0.5px ${getStyleValue('field_colors', 'input_text', '#ffffff')}90`,
            color: getStyleValue('field_colors', 'input_text', '#ffffff'),
            background: 'transparent',
            accentColor: getStyleValue('field_colors', 'input_text', '#ffffff'),
          }}
          className="w-3/4 md:w-1/2 xl:w-1/3 rounded-sm text-sm px-1 py-1 focus:outline-none"
          value={field.value}
          onChange={(event) => handleInputDataChange(index, event)}
        />
      )
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!formMetadata?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = formMetadata?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className="flex justify-center items-center py-8 xl:py-12 relative"
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
    >
      {isSubmitted ? (
        <div
          style={{ zIndex: 10, background: 'rgba(0, 0, 0, 0.75)' }}
          className="w-full h-full absolute flex justify-center items-center text-[22px] lg:text-3xl font-semibold text-white"
        >
          Thank you for submitting your response.
        </div>
      ) : null}

      <div className="flex flex-col xl:flex-row xl:justify-between w-[88%] md:w-[75%] xl:w-[1212px]">
        <div
          className="w-full xl:w-[602px] overflow-hidden xl:rounded-[10px]"
          style={{ aspectRatio: '602/632' }}
        >
          <CustomImage
            imageUrl={getTemplateImage(formMetadata?.image)}
            imgClass="w-full h-full "
            loaderClass={'w-full h-full'}
            imgAlt={`item.name`}
          />
        </div>

        <div
          className="w-full mt-8 xl:mt-0 xl:w-[485px] pl-[7px] xl:pl-0 flex flex-col justify-between"
          style={{}}
        >
          <div className="w-full">
            <p
              style={{
                color: getStyleValue('heading_subheading', 'heading', '#fff'),
              }}
              className="mb-0 text-[22px] lg:text-3xl font-semibold pr-[8px]"
            >
              {formMetadata.heading}
            </p>
            <p
              style={{
                color: getStyleValue('heading_subheading', 'sub_heading', '#cbcbcb'),
              }}
              className=" text-xs lg:text-sm mt-[8px] lg:mt-3 mb-[18px] xl:mb-[30px] pr-[8px]"
            >
              {formMetadata.sub_heading}
            </p>
            <div
              className="custom-scrollbar-vertical pr-[8px] max-h-[400px] 2xl:max-h-[460px]"
              style={{ overflowX: 'hidden', overflowY: 'auto' }}
            >
              {[...fieldData].map((fieldInfo, idx) => (
                <div
                  key={idx}
                  className={` relative`}
                  style={{
                    color: getStyleValue('field_colors', 'field_titles', '#d6d6d6'),
                  }}
                >
                  <p
                    style={{ marginBottom: '5px', marginTop: idx ? '23px' : '0' }}
                    className="text-white "
                  >
                    {fieldInfo.name}
                    {fieldInfo.is_mandatory && <span className="text-red-600 px-1">*</span>}
                  </p>
                  {getFieldInput(fieldInfo, idx)}
                  <p className="text-red-600 text-xs mb-0">{errorData[idx]}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`w-full pr-[8px] mt-10 xl:mt-0 flex items-center ${
              buttonPosition[formMetadata?.button?.aligned]
            } `}
          >
            <button
              className="text-xl px-[18px] border-0"
              style={{
                outline: 'none',
                backgroundImage: `linear-gradient(to ${getStyleValue(
                  'button',
                  'gradient',
                  'bottom',
                )}, ${getStyleValue('button', 'primary_color', '#ff6600')}, ${getStyleValue(
                  'button',
                  'secondary_color',
                  '#a84300',
                )})`,
                color: getStyleValue('button', 'text_color', 'white'),
                borderRadius: '6px',
                height: '38px',
              }}
              onClick={handleSave}
            >
              {formMetadata?.button?.name}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(IntentMarketingForm)

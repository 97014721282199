import React from 'react'

const Language4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.428"
      height="14.188"
      viewBox="0 0 14.428 14.188"
      className="cursor-pointer w-full h-full"
    >
      <g data-name="language-svgrepo-com (8)">
        <path
          data-name="Path 1166"
          d="M9.138 0H0v9.138h5.29v5.05h9.138V5.05h-5.29zM3.977 2.164 2.468 6.471h1.019l.245-.7H5.29v2.4H.962V.962h7.214V5.05h-2L5.165 2.164zm1.092 2.645h-1l.5-1.426zm8.4 1.2v7.214H6.252V6.012z"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1167"
          d="M9.06 10.354a4.1 4.1 0 0 0 .3.424 2.467 2.467 0 0 1-1.188.283v.962a3.362 3.362 0 0 0 1.924-.566 3.362 3.362 0 0 0 1.924.566v-.962a2.463 2.463 0 0 1-1.188-.283 4.106 4.106 0 0 0 .3-.424 5 5 0 0 0 .6-1.457h.521v-.962H10.57v-.721h-.962v.721H7.935v.962h.521a5 5 0 0 0 .604 1.457zm1.686-1.457a4 4 0 0 1-.424.947 3.132 3.132 0 0 1-.223.314 3.112 3.112 0 0 1-.208-.29 3.98 3.98 0 0 1-.439-.971z"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Language4

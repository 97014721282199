import React from 'react'

const LikeIcon = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="21.652"
      height="21.647"
      viewBox="0 0 21.652 21.647"
    >
      <g id="thumb-up-svgrepo-com" transform="translate(0.5 0.5)">
        <path
          id="Path_364"
          data-name="Path 364"
          style={pathStyle}
          d="M10.178,20.824h-4.2A1.475,1.475,0,0,1,4.5,19.349V11.975A1.475,1.475,0,0,1,5.975,10.5h4.2a.221.221,0,0,1,.221.221V20.6A.221.221,0,0,1,10.178,20.824Z"
          transform="translate(-4.5 -1.651)"
        />
        <path
          id="Path_365"
          data-name="Path 365"
          style={pathStyle}
          d="M8.5,14.824l2.794-5.588a1.473,1.473,0,0,0,.156-.66v-2.6A1.475,1.475,0,0,1,12.924,4.5h0a2.95,2.95,0,0,1,2.95,2.95v5.9"
          transform="translate(-2.601 -4.5)"
        />
        <path
          id="Path_366"
          data-name="Path 366"
          style={pathStyle}
          d="M12.924,10.5h8.845a1.475,1.475,0,0,1,1.416,1.887l-2.577,8.849A1.475,1.475,0,0,1,19.192,22.3H13.535a1.475,1.475,0,0,1-1.043-.432l-.611-.611a1.475,1.475,0,0,0-1.043-.432H8.5"
          transform="translate(-2.601 -1.651)"
        />
      </g>
    </svg>
  )
}

export default LikeIcon

import React from 'react'

const ApplyDiscountImage = ({ color = '#20a800', imgClass = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="14.966"
      viewBox="0 0 15 14.966"
      className={imgClass}
    >
      <path
        data-name="Path 903"
        d="m15.6 7.554-1.4-1.4V4.172a1.364 1.364 0 0 0-1.364-1.364h-1.978l-1.4-1.364a1.364 1.364 0 0 0-1.923 0l-1.4 1.37H4.157a1.364 1.364 0 0 0-1.364 1.365v1.977L1.43 7.554a1.327 1.327 0 0 0-.03 1.922l1.4 1.4v1.984a1.364 1.364 0 0 0 1.364 1.364h1.977l1.4 1.4a1.366 1.366 0 0 0 1.923 0l1.4-1.4h1.984a1.364 1.364 0 0 0 1.364-1.364v-1.986l1.4-1.4a1.364 1.364 0 0 0 0-1.923zm-2.761 2.761v2.536H10.3l-1.8 1.8-1.8-1.8H4.163v-2.536l-1.8-1.8 1.8-1.8V4.179H6.7l1.8-1.8 1.8 1.8h2.536v2.536l1.8 1.8z"
        style={{ fill: color }}
        transform="translate(-1 -1.048)"
      />
      <path
        data-name="Path 904"
        d="m12.254 7.739-4.09 4.772A.682.682 0 1 0 9.2 13.4l4.091-4.772a.682.682 0 1 0-1.036-.886z"
        transform="translate(-3.228 -3.101)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 905"
        d="M10.29 9.514a1.023 1.023 0 1 0-1.261-.682 1.023 1.023 0 0 0 1.261.682z"
        transform="translate(-3.541 -3.104)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 906"
        d="M12.755 13.532a1.023 1.023 0 1 0 1.261.682 1.023 1.023 0 0 0-1.261-.682z"
        transform="translate(-4.506 -5.008)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default ApplyDiscountImage

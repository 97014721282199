import { getImage } from '../../../utils/helper'

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export async function displayRazorpay(
  transaction_id,
  transactionDetails,
  navigate,
  accountObj,
  subscriptionName,
) {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?')
    return
  }

  let { amount, currency, id: orderId } = transactionDetails

  const options = {
    amount: amount.toString(),
    currency: currency,
    name: accountObj?.name || '',
    description: subscriptionName,
    image: getImage(accountObj.cf_domain_name, 'public/static/logo.png'),
    order_id: orderId,
    handler: async function (response) {
      const data = {
        transaction_id: transaction_id,
        details: {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        },
      }

      const encoded = btoa(JSON.stringify(data))
      navigate(`/payment-status/${encoded}`)
    },
    notes: {
      address: accountObj?.name,
    },
    theme: {
      color: '#1b242f',
    },
  }

  const paymentObject = new window.Razorpay(options)
  paymentObject.open()
}

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleDecomposedBanners'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './decomposedBanners.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'

const DecomposedBanners = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage, UpArrow, DownArrow } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transaprent`,
          width: '3.56%',
          height: '2.2vw',
          position: 'absolute',
          top: '70%',
          right: '87%',
          content: `url(${DownArrow})`,
          transform: 'rotate(-90deg)',
          // outline: '2px solid hotpink',
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '3.56%',
          height: '2.2vw',
          position: 'absolute',
          top: '70%',
          left: '5%',
          content: `url(${UpArrow})`,
          zIndex: '10',
          transform: 'rotate(-90deg)',
          // outline: '2px solid hotpink',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }

  useEffect(() => {
    setSetting({
      arrows: true,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: true,
      dots: false,
      adaptiveHeight: true,
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full 2xl:h-screen xl:h-[85vh] lg:h-[75vh] md:h-[60vh] sm:h-[45vh] h-[30vh] relative ${
        isEdit && hoverState && 'component-hover-class'
      } `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
          position={'top-20 right-4'}
          menuPosition={'top-24 right-4'}
        />
      )}
      <div
        className="absolute w-full h-full bg-cover bg-center hero-section z-0"
        style={{
          overflow: 'visible',
        }}
      >
        <SliderWrapper theme={theme} isEdit={isEdit} hoverState={hoverState}>
          <Slider ref={sliderRef} {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div
                style={{ width: '100%', height: '100%', marginTop: '0px' }}
                key={idx}
                onClick={() => handleRedirect(slide.link)}
              >
                <div
                  className={`relative w-full 2xl:h-screen xl:h-[85vh] lg:h-[75vh] md:h-[60vh] sm:h-[45vh] h-[30vh] flex flex-col ${
                    config[slide?.content?.position]
                  } 2xl:py-16 2xl:px-28 xl:py-12 xl:px-20 lg:py-10 lg:px-16 md:py-8 md:px-14 sm:p-6 p-[16px] img-gradient z-0`}
                >
                  <CustomImage
                    imageUrl={getTemplateImage(slide?.image_s3key)}
                    imgClass={`z-0 w-full ${
                      isEdit ? 'h-[99%]' : 'h-full'
                    } h-full object-cover absolute `}
                    loaderClass="z-0 w-full h-full object-cover absolute"
                    loaderStyle={{
                      background: '#5A5A5A50',
                    }}
                    imgAlt={'class img"'}
                  />
                  <div className="hidden md:block w-[720px] drop-shadow">
                    <h1 style={{ color: theme.c7 }} className="text-2xl font-semibold">
                      {slide.content.heading}
                    </h1>
                    <p
                      style={{ color: theme.c7 }}
                      className="mt-[4px] text-base leading-5 font-normal mb-0"
                    >
                      {slide.content.sub_heading}
                    </p>
                    {slide.cta_button.enabled && (
                      <button
                        className="mt-6 font-semibold py-[8px] px-9 border-none rounded-lg tracking-normal"
                        style={{
                          color: theme.c7,
                          // border: `1px solid currentColor`,
                          backgroundColor: theme.c3,
                        }}
                      >
                        {slide.cta_button.text}
                      </button>
                    )}
                  </div>
                  <span
                    style={{
                      background: `transaprent`,
                      width: '3.56%',
                      height: '2.2vw',
                      position: 'absolute',
                      top: '70.5%',
                      left: '1%',
                      // outline: '2px solid hotpink',
                      color: 'white',
                    }}
                    className="lg:block hidden"
                  >
                    {' '}
                    {idx + 1} / {data?.slide_data.length}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(DecomposedBanners)

import React from 'react'

const Subtitle6 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 20.125 14.647"
    >
      <path
        data-name="subtitles-svgrepo-com (2)"
        d="M19.5 5.265H3.126A1.879 1.879 0 0 0 1.25 7.141v10.895a1.879 1.879 0 0 0 1.876 1.876H19.5a1.879 1.879 0 0 0 1.876-1.876V7.141A1.879 1.879 0 0 0 19.5 5.265zm.853 12.77a.854.854 0 0 1-.853.853H3.126a.854.854 0 0 1-.853-.853V7.14a.854.854 0 0 1 .853-.853H19.5a.854.854 0 0 1 .853.853zM7.38 9.672h.038a2.728 2.728 0 0 1 1.542.475l-.01-.006a2.8 2.8 0 0 1 1.032 1.226l.007.018a.41.41 0 0 0 .376.248.4.4 0 0 0 .169-.037l.271-.121a.411.411 0 0 0 .243-.374.4.4 0 0 0-.036-.168 3.978 3.978 0 0 0-1.429-1.7l-.014-.009a3.844 3.844 0 0 0-2.151-.652h-.1a3.838 3.838 0 0 0-2.76 1.166 3.887 3.887 0 0 0-1.16 2.776v.15a3.9 3.9 0 0 0 1.16 2.78 3.843 3.843 0 0 0 2.761 1.165h.1a3.859 3.859 0 0 0 2.165-.66l-.014.009A3.971 3.971 0 0 0 11 14.266l.01-.025a.409.409 0 0 0-.206-.538l-.271-.12a.409.409 0 0 0-.166-.034.416.416 0 0 0-.152.029.414.414 0 0 0-.226.216 2.8 2.8 0 0 1-1.027 1.238l-.01.006a2.716 2.716 0 0 1-1.534.469h-.093a2.729 2.729 0 0 1-1.978-.845 2.793 2.793 0 0 1-.843-2v-.146a2.792 2.792 0 0 1 .842-2 2.733 2.733 0 0 1 1.979-.845h.057zm8.187 0h.033a2.734 2.734 0 0 1 1.543.474l-.01-.006a2.8 2.8 0 0 1 1.031 1.227l.007.018a.408.408 0 0 0 .224.218.4.4 0 0 0 .149.028.414.414 0 0 0 .169-.036l.27-.121a.411.411 0 0 0 .243-.374.4.4 0 0 0-.036-.168 3.97 3.97 0 0 0-1.429-1.7l-.014-.009a3.98 3.98 0 0 0-6.174 3.322V12.661a3.9 3.9 0 0 0 1.159 2.78 4.009 4.009 0 0 0 5.031.5l-.015.01a3.959 3.959 0 0 0 1.433-1.688l.01-.025a.4.4 0 0 0 .035-.165.411.411 0 0 0-.24-.374l-.27-.12a.4.4 0 0 0-.166-.035.407.407 0 0 0-.153.03.415.415 0 0 0-.225.216 2.791 2.791 0 0 1-1.027 1.237l-.01.006a2.869 2.869 0 0 1-3.607-.375 2.792 2.792 0 0 1-.842-2v-.148a2.79 2.79 0 0 1 .841-2 2.731 2.731 0 0 1 1.983-.84h.058z"
        transform="translate(-1.25 -5.265)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Subtitle6

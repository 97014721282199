import React from 'react'
import Language1 from '../../../assets/customPlayer/audioLanguage/language1'
import Language2 from '../../../assets/customPlayer/audioLanguage/language2'
import Language3 from '../../../assets/customPlayer/audioLanguage/language3'
import Language4 from '../../../assets/customPlayer/audioLanguage/language4'
import Language5 from '../../../assets/customPlayer/audioLanguage/language5'
import Language6 from '../../../assets/customPlayer/audioLanguage/language6'
import Language7 from '../../../assets/customPlayer/audioLanguage/language7'

const LanguageButton = ({ color = '#fff', iconType }) => {
  let icons = {
    LANGUAGE1: (
      <>
        <Language1 color={color} />
      </>
    ),
    LANGUAGE2: (
      <>
        <Language2 color={color} />
      </>
    ),
    LANGUAGE3: (
      <>
        <Language3 color={color} />
      </>
    ),
    LANGUAGE4: (
      <>
        <Language4 color={color} />
      </>
    ),
    LANGUAGE5: (
      <>
        <Language5 color={color} />
      </>
    ),
    LANGUAGE6: (
      <>
        <Language6 color={color} />
      </>
    ),
    LANGUAGE7: (
      <>
        <Language7 color={color} />
      </>
    ),
  }

  return (
    <div style={{ maxWidth: '15px' }} className="flex justify-center items-center w-auto h-full">
      {icons[iconType]}
    </div>
  )
}

export default LanguageButton

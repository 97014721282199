import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AddSection from '../addSection'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import CustomImage from '../../customImage/customImage'
import { isEmpty } from 'lodash'
import SearchIcon from '../imgComponents/searchIcon'
import { useTranslation } from 'react-i18next'

const MiamiFoxHeader = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onSwap,
  onDelete,
  theme,
  onAdd,
}) => {
  const { t } = useTranslation()
  const { data, id } = componentData
  const location = useLocation()
  const {
    customNavigate,
    isLogin,
    userObj,
    logoutCall,
    handleSubscription,
    getTemplateImage,
    SearchIconBlue,
    HamburgerIcon,
    UIMediaStarLogo,
    BasicPlaceholder,
    setLanguageModal,
    isMultilingual,
  } = useTemplateHook()
  const [profileToggle, setProfileToggle] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [hoverState, setHoverState] = useState('')

  const [drops, setDrops] = useState(data.site_map.map((dat) => ({ opened: false })))
  const handleDropDown = (selectedDropDownIndex) => {
    let tempDrops = drops
    if (tempDrops[selectedDropDownIndex].opened) tempDrops[selectedDropDownIndex].opened = false
    else tempDrops[selectedDropDownIndex].opened = true
    setDrops([...tempDrops])
  }
  const handleBlurDropDown = (blurDropDownIndex) => {
    let tempDrops = drops
    tempDrops[blurDropDownIndex].opened = false
    setDrops([...tempDrops])
  }

  useEffect(() => {
    setDrops(data.site_map.map((dat) => ({ opened: false })))
  }, [data])

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }
  const handleSearchButton = () => {
    if (searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setSearchText('')
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setSearchText('')
    }
  }

  const [mobileMenu, setMobileMenu] = useState(false)
  const [searchBarHover, setSearchBarHover] = useState(false)

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey) => {
    if (!data?.style_config) {
      return ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? tempArr[0].value : ''
  }

  return (
    <div
      className={`${
        !isEdit &&
        `py-7  lg:py-9 xl:py-[40.5px] 2xl:py-[47px] ${
          ['/home', '/'].includes(location.pathname) ? 'h-20' : 'h-12'
        } xl:h-20 `
      }`}
      //   className={` z-50 ${isEdit && hoverState && 'component-hover-class'}`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      <nav
        style={{
          fontFamily: 'Arial',
          backgroundColor: getStyleValue('component_background', 'primary_color')
            ? getStyleValue('component_background', 'primary_color')
            : '#111111',
        }}
        className={`text-md ${isEdit ? 'relative' : 'fixed'} top-0 z-50 h-auto w-full ${
          isEdit && hoverState && 'component-hover-class'
        }`}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onEdit={handleModal}
            onDelete={() => onDelete(id)}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <div className="mx-auto px-2 py-3 md:py-3 relative" style={{ fontFamily: 'Arial' }}>
          <div className="flex 2xl:space-x-10 xl:space-x-6 space-x-0 justify-between items-center">
            <div
              className="self-center ml-2 md:ml-6 2xl:mr-4 cursor-pointer"
              onClick={() => {
                setMobileMenu(false)
                setProfileToggle(false)
                customNavigate('DIRECT', '/home', isEdit)
              }}
            >
              <CustomImage
                imageUrl={getTemplateImage(data?.logo_s3key)}
                imgClass="2xl:w-[161px] 2xl:h-[70px] xl:w-[130px] xl:h-[57px] lg:w-[107px] lg:h-[46px] w-[50px] h-[22px]"
                imgStyle={{ backgroundColor: 'transparent' }}
                loaderClass="2xl:w-[161px] 2xl:h-[70px] xl:w-[130px] xl:h-[57px] lg:w-[107px] lg:h-[46px] w-[50px] h-[22px]"
                loaderStyle={{ background: '#5A5A5A50' }}
                imgAlt={'Logo'}
                errorPlaceholder={BasicPlaceholder}
              />
            </div>
            <div
              className="xl:hidden group flex-shrink-0 group xl:h-11 lg:h-10 h-8 xl:w-6 md:w-[300px] sm:w-[200px] w-[160px] flex text-white"
              style={{
                borderBottom: `1px solid ${
                  getStyleValue('title_primary', 'color')
                    ? getStyleValue('title_primary', 'color')
                    : 'red'
                }`,

                transition: '500ms',
                overflow: 'hidden',
                marginLeft: 'auto',
                marginRight: '10px',
                fontFamily: 'Bahnschrift',
                width: '30%',
              }}
            >
              <input
                type="text"
                className="flex-shrink-0 bg-transparent outline-none xl:px-4 sm:px-2 px-1 xl:py-2.5 lg:py-1.5 py-[0] sm:w-[90%] w-[80%] lg:text-base text-sm placeholder:italic"
                placeholder={t('search_here')}
                style={{ outline: 'none', border: 'none', color: 'white' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => handleSearch(e)}
              />
              <img
                src={SearchIconBlue}
                alt="SearchIconBlue"
                className=" xl:w-9 lg:w-6 w-5 m-auto flex-shrink-0"
                style={{ transition: '500ms' }}
                onClick={handleSearchButton}
              />
            </div>
            {isMultilingual ? (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.491"
                  height="29.982"
                  viewBox="0 0 30.491 29.982"
                  style={{ fill: '#fff' }}
                  className="cursor-pointer w-6 h-6 mx-2 xl:hidden"
                  onClick={() => setLanguageModal(true)}
                >
                  <path
                    data-name="Path 865"
                    d="M35.311 24H16v19.311h11.18v10.671h19.311v-19.31h-11.18zM24.4 28.574l-3.19 9.1h2.154l.519-1.48h3.297v5.082h-9.147V26.033h15.245v8.639h-4.235l-2.136-6.1zm2.307 5.59H24.6l1.056-3.013zM44.458 36.7v15.25H29.213V36.7z"
                    transform="translate(-16 -24)"
                  />
                  <path
                    data-name="Path 866"
                    d="M282.378 270.637a8.7 8.7 0 0 0 .641.9 5.213 5.213 0 0 1-2.51.6v2.033a7.106 7.106 0 0 0 4.065-1.2 7.106 7.106 0 0 0 4.065 1.2v-2.033a5.2 5.2 0 0 1-2.51-.6 8.676 8.676 0 0 0 .641-.9 10.558 10.558 0 0 0 1.276-3.08h1.1v-2.033h-3.556V264h-2.033v1.525H280v2.033h1.1a10.558 10.558 0 0 0 1.278 3.079zm3.563-3.08a8.45 8.45 0 0 1-.9 2 6.635 6.635 0 0 1-.472.664 6.568 6.568 0 0 1-.44-.613 8.409 8.409 0 0 1-.928-2.053z"
                    transform="translate(-263.23 -248.755)"
                  />
                </svg>
              </div>
            ) : null}

            {!isLogin ? null : (
              <div
                className="xl:hidden flex justify-center"
                style={{
                  margin: '0 10px',
                }}
              >
                <div
                  className="md:pl-10 ml-1 flex justify-center items-center "
                  onClick={() => {
                    setProfileToggle(!profileToggle)
                    setMobileMenu(false)
                  }}
                  onBlur={() => {
                    setProfileToggle(!profileToggle)
                    setMobileMenu(false)
                  }}
                >
                  <div className="flex justify-center items-center">
                    <div className="">
                      <div className="flex justify-center items-center space-x-3 cursor-pointer">
                        <img src={UIMediaStarLogo} style={{}} className="w-8 h-8 " alt={'avatar'} />
                        <div className=" font-semibold dark:text-white text-md">
                          <div
                            style={{
                              color: getStyleValue('title_primary', 'color')
                                ? getStyleValue('title_primary', 'color')
                                : 'white',
                              opacity: '0.85',
                            }}
                            className="flex cursor-pointer"
                          >
                            <span>{userObj?.name ? userObj?.name.split(' ')[0] : ''}</span>
                            <svg
                              className={
                                profileToggle
                                  ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                  : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                              }
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {profileToggle ? (
                        <div
                          style={{
                            backgroundColor: getStyleValue('component_background', 'primary_color')
                              ? getStyleValue('component_background', 'primary_color')
                              : '#111',
                            width: '100vw',
                            fontFamily: getStyleValue('title_primary', 'font')
                              ? getStyleValue('title_primary', 'font')
                              : 'Bahnschrift',
                            // opacity: "0.85",
                          }}
                          className="absolute right-0 z-30 w-48 shadow mt-2"
                        >
                          <ul className="text-center" aria-labelledby="dropdown">
                            <li>
                              <Link to="/profile">
                                <p
                                  style={{
                                    color: getStyleValue('title_primary', 'color')
                                      ? getStyleValue('title_primary', 'color')
                                      : 'white',
                                  }}
                                  className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                                >
                                  {t('my_profile')}
                                </p>
                              </Link>
                            </li>
                            <li>
                              <Link to="/watchlist">
                                <p
                                  style={{
                                    color: getStyleValue('title_primary', 'color')
                                      ? getStyleValue('title_primary', 'color')
                                      : 'white',
                                  }}
                                  className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                                >
                                  {t('my_watchlist')}
                                </p>
                              </Link>
                            </li>
                            <li>
                              <Link to="/favourites">
                                <p
                                  style={{
                                    color: getStyleValue('title_primary', 'color')
                                      ? getStyleValue('title_primary', 'color')
                                      : 'white',
                                  }}
                                  className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                                >
                                  {t('my_favorites')}
                                </p>
                              </Link>
                            </li>
                            <li>
                              <Link to="/subscriptions">
                                <p
                                  style={{
                                    color: getStyleValue('title_primary', 'color')
                                      ? getStyleValue('title_primary', 'color')
                                      : 'white',
                                  }}
                                  className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                                >
                                  {t('my_subscriptions')}
                                </p>
                              </Link>
                            </li>
                            <li>
                              <Link to="/transactions">
                                <p
                                  style={{
                                    color: getStyleValue('title_primary', 'color')
                                      ? getStyleValue('title_primary', 'color')
                                      : 'white',
                                  }}
                                  className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                                >
                                  {t('my_transactions')}
                                </p>
                              </Link>
                            </li>
                            <li onClick={handleSubscription}>
                              <p
                                style={{
                                  color: getStyleValue('title_primary', 'color')
                                    ? getStyleValue('title_primary', 'color')
                                    : 'white',
                                }}
                                className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                              >
                                {t('buy_membership')}
                              </p>
                            </li>
                            <li onClick={logoutCall}>
                              <p
                                style={{
                                  color: getStyleValue('title_primary', 'color')
                                    ? getStyleValue('title_primary', 'color')
                                    : 'white',
                                }}
                                className="text-lg   hover:text-white hover:bg-[#212b37] block px-4 py-2 cursor-pointer user-dropdown-menu"
                              >
                                {t('sign_out')}
                              </p>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="xl:hidden"
              onClick={() => {
                setMobileMenu(!mobileMenu)
                setProfileToggle(false)
              }}
            >
              <img src={HamburgerIcon} alt="HamburgerIcon" />
            </div>
            <div
              className={`${
                mobileMenu ? 'flex' : 'xl:flex hidden'
              } xl:flex-row flex-col xl:items-strech justify-between xl:static absolute top-full left-0 xl:w-[89%] w-full xl:bg-transparent
             
              `}
              style={{
                marginRight: '2rem',
                fontFamily: getStyleValue('title_primary', 'font')
                  ? getStyleValue('title_primary', 'font')
                  : 'Bahnschrift',
                // outline: '2px solid lime',
                alignItems: 'center',
                backgroundColor: getStyleValue('component_background', 'primary_color')
                  ? getStyleValue('component_background', 'primary_color')
                  : '#111',
              }}
            >
              {/* nav links */}
              <div
                className="flex xl:flex-row flex-col xl:items-strech items-strech xl:gap-x-0 gap-x-2"
                style={{
                  // outline: '2px solid lime',
                  width: '100%',
                }}
              >
                {data?.site_map.map((siteMapItem, idx) => (
                  <div
                    key={idx}
                    className="group xl:relative static h-full"
                    // style={{ outline: '2px solid red' }}
                    onClick={
                      siteMapItem?.link?.type.length > 0
                        ? () => handleRedirect(siteMapItem?.link)
                        : () => handleDropDown(idx)
                    }
                    onBlur={(e) => {
                      const currentTarget = e.currentTarget
                      // Give browser time to focus the next element
                      requestAnimationFrame(() => {
                        // Check if the new focused element is a child of the original container
                        if (!currentTarget.contains(document.activeElement)) {
                          // setShowsDropdown(false)
                          handleBlurDropDown(idx)
                        }
                      })
                    }}
                    tabIndex={100}
                  >
                    <button
                      className={`2xl:text-[0.94vw] xl:text-[.9vw] lg:text-[1.1vw] text-sm cursor-pointer font-bold h-12 xl:w-max w-full flex xl:justify-start justify-center items-center border-white ${
                        !getStyleValue('component_background', 'primary_color') &&
                        'text-white bg-transparent'
                      }`}
                      style={{
                        border: 'none',
                        outline: 'none',
                        background: getStyleValue('component_background', 'primary_color')
                          ? getStyleValue('component_background', 'primary_color')
                          : 'transparent',
                        color: getStyleValue('title_primary', 'color')
                          ? getStyleValue('title_primary', 'color')
                          : 'white',
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        padding: '0 20px',
                      }}
                    >
                      <span
                        className={`${drops[idx]?.opened ? 'underline' : ''} ${
                          getStyleValue('title_primary', 'font_format')
                            ? getStyleValue('title_primary', 'font_format')
                            : ''
                        }`}
                        style={{
                          textDecorationColor: 'currentcolor',
                          textUnderlineOffset: '5px',
                          fontFamily: getStyleValue('title_primary', 'font')
                            ? getStyleValue('title_primary', 'font')
                            : 'Bahnschrift',
                          textAlign: getStyleValue('title_primary', 'alignment')
                            ? getStyleValue('title_primary', 'alignment')
                            : '',
                        }}
                      >
                        {siteMapItem?.title}
                      </span>
                      {siteMapItem?.sub_heading_data.some((item) => item.text.length > 0) && (
                        <span
                          className={`ml-1.5 mt-[2px] ${
                            drops[idx]?.opened ? 'rotate-180' : ''
                          } transition`}
                        >
                          &#9662;
                        </span>
                      )}
                    </button>
                    <ul
                      className={`${drops[idx]?.opened ? 'block' : 'hidden'} 
                    xl:absolute static top-[100%] xl:w-auto w-full lg:-left-12 -left-6 xl:left-0`}
                      style={{
                        paddingInlineStart: '0',
                        marginBottom: '0',
                        color: '#fff',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {siteMapItem?.sub_heading_data.map((item, idx) => {
                        return (
                          item?.text.length > 0 && (
                            <li
                              className={`cursor-pointer font-bold w-full h-max flex xl:justify-start justify-center 2xl:text-[0.94vw] xl:text-[.9vw] lg:text-[1.1vw] text-sm items-center py-2.5 xl:pl-6 pl-0 xl:pr-6 pr-0  hover:text-white ${
                                getStyleValue('title_secondary', 'font_format')
                                  ? getStyleValue('title_secondary', 'font_format')
                                  : ''
                              }`}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                color: getStyleValue('title_secondary', 'color')
                                  ? getStyleValue('title_secondary', 'color')
                                  : 'white',
                                backgroundColor: getStyleValue(
                                  'component_background',
                                  'primary_color',
                                )
                                  ? getStyleValue('component_background', 'primary_color')
                                  : '#111',
                                fontFamily: getStyleValue('title_secondary', 'font')
                                  ? getStyleValue('title_secondary', 'font')
                                  : 'Bahnschrift',
                                textAlign: getStyleValue('title_secondary', 'alignment')
                                  ? getStyleValue('title_secondary', 'alignment')
                                  : '',
                              }}
                              key={idx}
                              onClick={() => {
                                // handleCategoryClick(item._id, item.name)
                                handleRedirect(item?.link)
                                setMobileMenu(false)
                              }}
                            >
                              {item.text}
                            </li>
                          )
                        )
                      })}
                    </ul>
                  </div>
                ))}
                {data?.menu_btn?.enabled && (
                  <div className="group xl:relative static h-full self-center">
                    <button
                      className="2xl:text-xl xl:text-lg lg:text-base text-sm cursor-pointer font-bold xl:h-11 h-8 lg:my-0 my-1 lg:w-max w-full flex xl:justify-start justify-center items-center lg:border-0 lg:ml-2 ml-0"
                      style={{
                        outline: 'none',
                        color: getStyleValue('menu_btn', 'color')
                          ? getStyleValue('menu_btn', 'color')
                          : '#fff',
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        padding: '0 10px',
                        backgroundColor: getStyleValue('menu_btn', 'button_background')
                          ? getStyleValue('menu_btn', 'button_background')
                          : 'red',
                        borderRadius: '4px',
                        fontFamily: getStyleValue('title_primary', 'font')
                          ? getStyleValue('title_primary', 'font')
                          : 'Bahnschrift',
                      }}
                      onClick={() => {
                        setMobileMenu(false)
                        handleRedirect(data?.menu_btn?.link)
                      }}
                    >
                      {data?.menu_btn?.text}
                    </button>
                  </div>
                )}
              </div>
              {/* sign in / register */}
              <div
                className="self-center flex xl:flex-row flex-col justify-end items-center xl:flex-shrink-0 xl:py-0 py-1.5 2xl:gap-x-12 xl:gap-x-9 xl:pb-0 pb-[16px]"
                style={{
                  width: 'auto',
                  position: 'relative',
                  // outline: '2px solid lime',
                }}
              >
                <div
                  className="group flex-shrink-0 group xl:h-11 lg:h-10 h-8 lg:w-9 hover:w-[300px]  transition-[500ms] xl:flex hidden hover:text-white"
                  style={{
                    position: 'absolute',
                    right: '100%',
                    background: getStyleValue('component_background', 'primary_color')
                      ? getStyleValue('component_background', 'primary_color')
                      : '',
                    borderBottom: `1px solid ${
                      searchBarHover === true
                        ? getStyleValue('add_btn1', 'color')
                          ? getStyleValue('add_btn1', 'color')
                          : 'red'
                        : getStyleValue('component_background', 'primary_color')
                        ? getStyleValue('component_background', 'primary_color')
                        : 'currentColor'
                    }`,
                    transition: '500ms',
                    // overflow: 'hidden',
                    marginRight: '10px',
                  }}
                  onMouseEnter={() => setSearchBarHover(true)}
                  onMouseLeave={() => setSearchBarHover(false)}
                >
                  <input
                    type="text"
                    className="group-hover:block hidden bg-transparent flex-shrink-0 outline-none xl:px-4 px-2 xl:py-2.5 lg:py-1.5 py-[0] w-[88%] lg:text-base text-sm placeholder:italic"
                    placeholder={t('search_here')}
                    style={{
                      outline: 'none',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'Arial',
                      fontWeight: 'normal',
                    }}
                    disabled={isEdit}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={(e) => handleSearch(e)}
                  />
                  <div
                    className=" 2xl:w-9 xl:w-7 lg:w-6 w-5 m-auto flex-shrink-0"
                    style={{
                      transition: '500ms',
                      transform: searchBarHover === true ? 'translateX(-20%)' : '',
                    }}
                    onClick={handleSearchButton}
                  >
                    <SearchIcon
                      color={
                        getStyleValue('add_btn1', 'color')
                          ? getStyleValue('add_btn1', 'color')
                          : searchBarHover
                          ? 'red'
                          : '#fff'
                      }
                    />
                  </div>
                  {/* <img
                    src={searchBarHover === true ? SearchIconBlue : SearchIconWhite}
                    alt="SearchIconBlue"

                  /> */}
                </div>
                {isMultilingual ? (
                  <div className="mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.491"
                      height="29.982"
                      viewBox="0 0 30.491 29.982"
                      style={{
                        fill: getStyleValue('add_btn1', 'color')
                          ? getStyleValue('add_btn1', 'color')
                          : '#fff',
                      }}
                      className="cursor-pointer w-6 h-6 hidden xl:block"
                      onClick={() => setLanguageModal(true)}
                    >
                      <path
                        data-name="Path 865"
                        d="M35.311 24H16v19.311h11.18v10.671h19.311v-19.31h-11.18zM24.4 28.574l-3.19 9.1h2.154l.519-1.48h3.297v5.082h-9.147V26.033h15.245v8.639h-4.235l-2.136-6.1zm2.307 5.59H24.6l1.056-3.013zM44.458 36.7v15.25H29.213V36.7z"
                        transform="translate(-16 -24)"
                      />
                      <path
                        data-name="Path 866"
                        d="M282.378 270.637a8.7 8.7 0 0 0 .641.9 5.213 5.213 0 0 1-2.51.6v2.033a7.106 7.106 0 0 0 4.065-1.2 7.106 7.106 0 0 0 4.065 1.2v-2.033a5.2 5.2 0 0 1-2.51-.6 8.676 8.676 0 0 0 .641-.9 10.558 10.558 0 0 0 1.276-3.08h1.1v-2.033h-3.556V264h-2.033v1.525H280v2.033h1.1a10.558 10.558 0 0 0 1.278 3.079zm3.563-3.08a8.45 8.45 0 0 1-.9 2 6.635 6.635 0 0 1-.472.664 6.568 6.568 0 0 1-.44-.613 8.409 8.409 0 0 1-.928-2.053z"
                        transform="translate(-263.23 -248.755)"
                      />
                    </svg>
                  </div>
                ) : null}

                {!isLogin ? (
                  <>
                    {data?.add_btn1?.enabled && (
                      <a href="#" className="lg:w-[unset] w-full">
                        <button
                          className="2xl:text-xl lg:text-base text-sm lg:w-[unset] w-full  2xl:py-2.5 xl:py-2 lg:py-1 py-2 font-bold bg-transparent"
                          style={{
                            outline: 'none',
                            border: 'none',
                            color: getStyleValue('add_btn1', 'color')
                              ? getStyleValue('add_btn1', 'color')
                              : 'white',
                          }}
                          onClick={() => {
                            setMobileMenu(false)
                            handleRedirect(data?.add_btn1?.link)
                          }}
                        >
                          {data?.add_btn1?.text}
                        </button>
                      </a>
                    )}
                    {data?.add_btn2?.enabled && (
                      <a href="#" className="lg:w-[unset] w-full text-center">
                        <button
                          className="2xl:text-xl lg:text-base text-sm lg:w-[unset] 2xl:px-5 xl:px-3 px-3 self-center 2xl:py-1.5 py-1 font-bold border-0"
                          style={{
                            outline: 'none',
                            borderRadius: '6px',
                            color: getStyleValue('add_btn2', 'color')
                              ? getStyleValue('add_btn2', 'color')
                              : 'white',
                            backgroundColor: getStyleValue('add_btn2', 'button_background')
                              ? getStyleValue('add_btn2', 'button_background')
                              : 'red',
                          }}
                          onClick={() => {
                            handleRedirect(data?.add_btn2?.link)
                            setMobileMenu(false)
                          }}
                        >
                          {data?.add_btn2?.text}
                        </button>
                      </a>
                    )}
                  </>
                ) : (
                  <div className="xl:flex hidden justify-center">
                    <div
                      className="md:pl-10 ml-1 flex justify-center items-center "
                      onClick={() => {
                        setProfileToggle(!profileToggle)
                        setMobileMenu(false)
                      }}
                      onBlur={(e) => {
                        const currentTarget = e.currentTarget
                        // Give browser time to focus the next element
                        requestAnimationFrame(() => {
                          // Check if the new focused element is a child of the original container
                          if (!currentTarget.contains(document.activeElement)) {
                            setProfileToggle(false)
                            setMobileMenu(false)
                          }
                        })
                      }}
                      tabIndex={3}
                    >
                      <div className="flex justify-center items-center">
                        <div className="relative">
                          <div className="flex justify-center items-center space-x-3 cursor-pointer">
                            {/* <div className="w-12 h-12"> */}
                            <img
                              src={UIMediaStarLogo}
                              alt="UIMediaStarLogo"
                              style={{}}
                              className="w-8 h-8 xl:w-10 xl:h-10"
                            />
                            {/* </div> */}
                            <div className=" font-semibold text-md">
                              <div
                                style={{
                                  color: getStyleValue('title_primary', 'color')
                                    ? getStyleValue('title_primary', 'color')
                                    : 'white',
                                  opacity: '0.85',
                                }}
                                className="flex cursor-pointer"
                              >
                                <span className="text-base lg:text-lg 2xl:text-xl">
                                  {userObj?.name ? userObj?.name.split(' ')[0] : ''}
                                </span>
                                <svg
                                  className={
                                    profileToggle
                                      ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                      : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                                  }
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          {profileToggle ? (
                            <div
                              style={{
                                backgroundColor: getStyleValue(
                                  'component_background',
                                  'primary_color',
                                )
                                  ? getStyleValue('component_background', 'primary_color')
                                  : '#111',
                                fontFamily: getStyleValue('title_primary', 'font')
                                  ? getStyleValue('title_primary', 'font')
                                  : 'Bahnschrift',
                              }}
                              className="absolute right-0 z-20 w-48 px-2 py-3 shadow mt-5"
                            >
                              <ul className="py-1" aria-labelledby="dropdown">
                                <li>
                                  <Link to="/profile">
                                    <p
                                      style={{
                                        color: getStyleValue('title_primary', 'color')
                                          ? getStyleValue('title_primary', 'color')
                                          : 'white',
                                      }}
                                      className="text-lg hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    >
                                      {t('my_profile')}
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/watchlist">
                                    <p
                                      style={{
                                        color: getStyleValue('title_primary', 'color')
                                          ? getStyleValue('title_primary', 'color')
                                          : 'white',
                                      }}
                                      className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    >
                                      {t('my_watchlist')}
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/favourites">
                                    <p
                                      style={{
                                        color: getStyleValue('title_primary', 'color')
                                          ? getStyleValue('title_primary', 'color')
                                          : 'white',
                                      }}
                                      className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    >
                                      {t('my_favorites')}
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/subscriptions">
                                    <p
                                      style={{
                                        color: getStyleValue('title_primary', 'color')
                                          ? getStyleValue('title_primary', 'color')
                                          : 'white',
                                      }}
                                      className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    >
                                      {t('my_subscriptions')}
                                    </p>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/transactions">
                                    <p
                                      style={{
                                        color: getStyleValue('title_primary', 'color')
                                          ? getStyleValue('title_primary', 'color')
                                          : 'white',
                                      }}
                                      className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    >
                                      {t('my_transactions')}
                                    </p>
                                  </Link>
                                </li>
                                <li onClick={handleSubscription}>
                                  <p
                                    style={{
                                      color: getStyleValue('title_primary', 'color')
                                        ? getStyleValue('title_primary', 'color')
                                        : 'white',
                                    }}
                                    className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                  >
                                    {t('buy_membership')}
                                  </p>
                                </li>
                                <li onClick={logoutCall}>
                                  <p
                                    style={{
                                      color: getStyleValue('title_primary', 'color')
                                        ? getStyleValue('title_primary', 'color')
                                        : 'white',
                                    }}
                                    className="text-lg   hover:text-white block px-4 py-2 cursor-pointer user-dropdown-menu"
                                  >
                                    {t('sign_out')}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </nav>
    </div>
  )
}

export default MiamiFoxHeader

import React from 'react'

const Language6 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 13.043 13.729"
    >
      <path
        data-name="language-svgrepo-com (9)"
        d="M12.356 6.865h-1.373a.687.687 0 0 0 0 1.373v1.373h-.813a2.035 2.035 0 0 0 .126-.686 2.059 2.059 0 0 0-3.842-1.03.686.686 0 1 0 1.189.686.686.686 0 1 1 .594 1.03.687.687 0 1 0 0 1.373.686.686 0 1 1-.594 1.03.686.686 0 1 0-1.189.686 2.059 2.059 0 0 0 3.843-1.029 2.035 2.035 0 0 0-.126-.686h.813v2.059a.687.687 0 0 0 1.373 0V8.238a.687.687 0 0 0 0-1.373zm-8.212-.52a.686.686 0 0 0 1.331-.333l-1.21-4.826a1.565 1.565 0 0 0-3.037 0L.02 6.012a.686.686 0 0 0 1.331.333l.385-1.54h2.019zM2.08 3.432l.478-1.913a.2.2 0 0 1 .375 0l.478 1.913zm5.471 0h.686a.687.687 0 0 1 .686.686v.686a.687.687 0 1 0 1.373 0v-.685A2.062 2.062 0 0 0 8.237 2.06h-.686a.687.687 0 1 0 0 1.373zM4.805 9.611h-.687a.687.687 0 0 1-.686-.686v-.686a.687.687 0 1 0-1.373 0v.686a2.062 2.062 0 0 0 2.059 2.059h.686a.687.687 0 1 0 0-1.373z"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Language6

import React from 'react'

const Resolution1 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 21.411 15.348"
    >
      <path
        d="M6.042 12.074h4.042M6.042 9.042v6.063m4.042-6.063v6.063m3.537-5.558V14.6a.505.505 0 0 0 .505.505h1.011a3.032 3.032 0 1 0 0-6.063h-1.01a.505.505 0 0 0-.506.505zm-8.388 9.6h13.744a5.021 5.021 0 0 0 2.13-.22 2.021 2.021 0 0 0 .883-.883 5.021 5.021 0 0 0 .22-2.13v-7.68a5.021 5.021 0 0 0-.22-2.13 2.021 2.021 0 0 0-.883-.883A5.021 5.021 0 0 0 18.977 5H5.234a5.021 5.021 0 0 0-2.13.22 2.021 2.021 0 0 0-.884.88A5.021 5.021 0 0 0 2 8.234v7.68a5.021 5.021 0 0 0 .22 2.13 2.021 2.021 0 0 0 .883.883 5.021 5.021 0 0 0 2.131.221z"
        transform="translate(-1.4 -4.4)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.2px',
        }}
      />
    </svg>
  )
}

export default Resolution1

import React from 'react'

const DislikeIcon = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="21.652"
      height="21.647"
      viewBox="0 0 21.652 21.647"
    >
      <g id="thumb-down-svgrepo-com" transform="translate(0.508 0.5)">
        <path
          id="Path_361"
          data-name="Path 361"
          style={pathStyle}
          d="M15.721,6.5h4.2A1.475,1.475,0,0,1,21.4,7.975v7.374a1.475,1.475,0,0,1-1.475,1.475h-4.2A.221.221,0,0,1,15.5,16.6V6.721A.221.221,0,0,1,15.721,6.5Z"
          transform="translate(-0.755 -5.025)"
        />
        <path
          id="Path_362"
          data-name="Path 362"
          style={pathStyle}
          d="M17.874,12.5,15.08,18.088a1.473,1.473,0,0,0-.156.66v2.6a1.475,1.475,0,0,1-1.475,1.475h0a2.95,2.95,0,0,1-2.95-2.95v-5.9"
          transform="translate(-3.129 -2.176)"
        />
        <path
          id="Path_363"
          data-name="Path 363"
          style={pathStyle}
          d="M15.822,17.3H6.978a1.475,1.475,0,0,1-1.416-1.887L8.139,6.562A1.475,1.475,0,0,1,9.555,5.5h5.657a1.475,1.475,0,0,1,1.043.432l.611.611a1.475,1.475,0,0,0,1.043.432h2.339"
          transform="translate(-5.502 -5.5)"
        />
      </g>
    </svg>
  )
}

export default DislikeIcon

import React from 'react'

const AccountDisable = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center h-[60vh]">
      <p className="md:text-2xl xl:text-3xl text-[#e3e2e2] font-bold mt-4">
        {'Account disabled please contact at admin@cadjin.com'}
      </p>
    </div>
  )
}

export default AccountDisable

import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { CUSTOM_FORM } from '../../utils/apiUrls'
import CustomFormComponent from './customForm'
import { raiseToast } from '../../utils/helper'
import { getCurrentFormPath } from './utils'
import { ContentContext } from '../../context/content'

const PLAYER_ENTITY = { CONTENT_DETAIL_PLAYER: 'CONTENT_DETAIL_PLAYER', PLAYER: 'PLAYER' }

const CustomForm = () => {
  const [currentForm, setCurrentForm] = useState({})
  const { isLogin, setLoader, setOpenLoginModal, modulePermissions, setComponentCustomForms } =
    useContext(AppContext)
  const { isVideoPlaying, setIsVideoPlaying } = useContext(ContentContext)

  const location = useLocation()

  const [{ data: formData }, getCustomForm] = useAxios(
    {
      url: CUSTOM_FORM,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: formSubmitData, loading: formSubmitLoading }, formSubmitCall] = useAxios(
    {
      url: CUSTOM_FORM,
      method: 'post',
    },
    { manual: true },
  )

  const getCurrentActiveForm = () => {
    let submittedFormsIDs = JSON.parse(localStorage.getItem('submittedFormIDs'))
    let tempData = formData?.data?.filter((info) => info.form_metadata.form_type === 'floating')
    let tempComponentData = formData?.data?.filter(
      (info) => info.form_metadata.form_type === 'component',
    )
    setComponentCustomForms([...tempComponentData])
    let formArr = tempData
    if (!isLogin && submittedFormsIDs) {
      formArr = tempData.filter((ele) => !submittedFormsIDs.includes(`${ele._id}`))
    }
    setCurrentForm(getCurrentFormPath(formArr, location.pathname))
  }

  useEffect(() => {
    if (modulePermissions?.custom_forms) {
      getCustomForm()
    }
  }, [modulePermissions])

  useEffect(() => {
    if (formData && formData.success && !isEmpty(formData.data)) {
      getCurrentActiveForm()
    }
  }, [formData])

  useEffect(() => {
    if (formData) {
      setIsVideoPlaying(false)
      getCurrentActiveForm()
    }
  }, [location.pathname])

  const onFormSubmit = async (data) => {
    if (currentForm.is_login_required && !isLogin) {
      setOpenLoginModal(true)
    }
    formSubmitCall({
      data: {
        form_id: currentForm?._id,
        form_data: {
          fields: data,
        },
      },
    })
  }

  useEffect(() => {
    if (formSubmitData && formSubmitData.success) {
      if (!isLogin) {
        let submittedFormArr = localStorage.getItem('submittedFormIDs')
          ? JSON.parse(localStorage.getItem('submittedFormIDs'))
          : []

        submittedFormArr.push(`${formSubmitData.data.custom_form}`)
        localStorage.setItem('submittedFormIDs', JSON.stringify(submittedFormArr))
      }
      raiseToast('success', 'Your response has been submitted!')
      getCustomForm()
      setCurrentForm({})
    }
  }, [formSubmitData])

  useEffect(() => {
    setLoader(formSubmitLoading)
  }, [formSubmitLoading])

  const checkApplicableToVideoPlayer = () => {
    if (isVideoPlaying) {
      return (
        currentForm.form_metadata.entities.type === PLAYER_ENTITY.CONTENT_DETAIL_PLAYER ||
        currentForm.form_metadata.entities.type === PLAYER_ENTITY.PLAYER
      )
    } else {
      return currentForm.form_metadata.entities.type !== PLAYER_ENTITY.PLAYER
    }
  }

  return (
    <>
      {!isEmpty(currentForm) && checkApplicableToVideoPlayer() && (
        <CustomFormComponent onFormSubmit={onFormSubmit} currentForm={currentForm} />
      )}
    </>
  )
}

export default CustomForm

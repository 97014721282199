import React, { useContext } from 'react'
import { getImage, isEmpty } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'
import PlaylistDecorater from '../../assets/playlist-decorator-related.svg'
import PlaylistIcon from '../../assets/playlist-icon-related.svg'
import { useNavigate } from 'react-router'
import useAxios from 'axios-hooks'
import { RELATED_CONTENT } from '../../utils/apiUrls'
import InComponentLoader from '../../components/InComponentLoader'

const PlaylistRelated = (props) => {
  const { theme, playlistId } = props
  const { t } = useTranslation()
  const { accountObj, isCustomTemplate } = useContext(AppContext)
  const navigate = useNavigate()

  const handleRedirect = (content_id) => {
    navigate(`/playlist/${content_id}`)
  }

  const [{ data: relatedPlayList, loading: relatedPlayListLoading }] = useAxios({
    url: `${RELATED_CONTENT}${playlistId}`,
    method: 'post',
    data: {
      filters: {
        video_types: ['PLAYLIST'],
      },
    },
  })

  return (
    <div>
      <InComponentLoader loading={relatedPlayListLoading} />
      <div
        className="mt-10 flex flex-wrap md:gap-x-[3%] sm:gap-x-[5%] sm:px-0 px-3"
        style={{ rowGap: '3vw' }}
      >
        {!isEmpty(relatedPlayList?.data) ? (
          relatedPlayList?.data.map((playlistItem, index) => (
            <div
              className="playlist rounded-lg md:w-[17.25%] sm:w-[47%] w-full sm:block flex cursor-pointer"
              style={{
                aspectRatio: window.matchMedia('(max-width: 640px)').matches ? 'unset' : '302/231',
              }}
              key={playlistItem.content_id}
              onClick={() => handleRedirect(playlistItem.content_id)}
            >
              <div className="sm:w-full relative w-2/5">
                <img
                  src={getImage(accountObj?.cf_domain_name, playlistItem?.thumbnail)}
                  className="w-full object-cover rounded-lg"
                  alt=""
                  style={{ aspectRatio: '302/169' }}
                />
                <img
                  src={PlaylistDecorater}
                  className="w-full object-cover rounded-lg absolute z-10"
                  alt=""
                  style={{ aspectRatio: '302/169', right: '0', bottom: '0' }}
                />
                <p
                  style={{
                    marginBlock: '0',
                    right: '3%',
                    bottom: '3%',
                    gap: '0 2ch',
                    fontSize: 'max(.55vw,12px)',
                  }}
                  className="absolute z-10 flex justify-center items-center"
                >
                  <span className="font-bold text-black">{t('playlist')}</span>
                  <img src={PlaylistIcon} alt="" />
                </p>
              </div>
              <div className="text-white sm:w-auto w-3/5 sm:pl-0 pl-2">
                <h1 className="font-bold lg:text-[1.1vw] text-base">{playlistItem.title}</h1>
              </div>
            </div>
          ))
        ) : (
          <p
            className={'text-md md:text-md font-bold mt-5 '}
            style={{ textAlign: 'center', color: isCustomTemplate ? theme.c4 : '#fff' }}
          >
            {t('no_related_playlist_available')}
          </p>
        )}
      </div>
    </div>
  )
}

export default withTheme(PlaylistRelated)

import React from 'react'
import Pip1 from '../../../assets/customPlayer/pipIcons/pip1'
import Pip2 from '../../../assets/customPlayer/pipIcons/pip2'
import Pip3 from '../../../assets/customPlayer/pipIcons/pip3'
import Pip4 from '../../../assets/customPlayer/pipIcons/pip4'
import Pip5 from '../../../assets/customPlayer/pipIcons/pip5'
import Pip6 from '../../../assets/customPlayer/pipIcons/pip6'

const PipButton = (props) => {
  let { isPIPMode, togglePIPMode, data } = props

  let icons = {
    PIP1: (
      <>
        <Pip1 color={data.icon_color} />
      </>
    ),
    PIP2: (
      <>
        <Pip2 color={data.icon_color} />
      </>
    ),
    PIP3: (
      <>
        <Pip3 color={data.icon_color} />
      </>
    ),
    PIP4: (
      <div style={{ height: '13.2px' }}>
        <Pip4 color={data.icon_color} />
      </div>
    ),
    PIP5: (
      <div style={{ height: '13.2px' }}>
        <Pip5 color={data.icon_color} />
      </div>
    ),
    PIP6: (
      <div style={{ height: '13.2px' }}>
        <Pip6 color={data.icon_color} />
      </div>
    ),
  }

  return (
    <div className="h-full">
      {data.visible ? (
        <div
          className="flex justify-center items-center w-auto h-full  cursor-pointer"
          onClick={() => togglePIPMode(!isPIPMode)}
        >
          {icons[data.icon_type]}
        </div>
      ) : null}
    </div>
  )
}

export default PipButton

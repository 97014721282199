import React, { useState } from 'react'
import { useParams } from 'react-router'
import './contentDetail.css'
import { withTheme } from 'theming'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import RelatedVideos from '../contentDetail/relatedVideos'
import Episodes from './episodes'

const menuBtnStyles = {
  fontSize: 'max(1.2vw,16px)',
  textTransform: 'uppercase',
  //   background: 'gray',
  paddingBottom: '10px',
  borderBottom: '3px solid',
}

const MultipartDetailBottom = ({
  theme,
  detailObj,
  childContentList = [],
  removeMargin = false,
  data = {},
}) => {
  const contentId = useParams().contentId
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState('episodes')

  const tabs = [
    {
      title: `${t('episodes')} (${childContentList.length})`,
      id: 'episodes',
    },
    {
      title: t('related_contents'),
      id: 'related_contents',
    },
  ]

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div className={`${!removeMargin && 'xl:mt-[-10vh] lg:mt-[-8vh]'} `}>
      <div
        style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
        className=" 2xl:px-24 xl:px-14 md:px-10 px-4 xl:h-[10vh] lg:h-[8vh] h-[7vh] w-full"
      >
        <div
          style={{
            borderBottom: '1px solid #707070',
            overflowX: 'auto',
          }}
          className="relative h-full no-scrollbar"
        >
          <div className="flex justify-start items-end h-full md:gap-x-[min(5vw,71px)] gap-x-[43px] md:w-full w-[560px] overflow-x-hidden">
            {tabs.map((option) => (
              <button
                key={option.id}
                style={{
                  ...menuBtnStyles,
                  color:
                    selectedTab === option.id
                      ? getStyleValue('related_content_active_tab', 'color', '#09e9e9')
                      : getStyleValue('related_content_inactive_tab', 'color', '#cecece'),
                  borderBottomColor:
                    selectedTab === option.id
                      ? getStyleValue('related_content_active_tab', 'color', '#09e9e9')
                      : 'transparent',
                  fontFamily:
                    selectedTab === option.id
                      ? getStyleValue('related_content_active_tab', 'font', '')
                      : getStyleValue('related_content_inactive_tab', 'font', ''),
                }}
                onClick={() => setSelectedTab(option.id)}
                className={`${
                  selectedTab === option.id
                    ? getStyleValue('related_content_active_tab', 'font_format', 'font-bold')
                    : getStyleValue('related_content_inactive_tab', 'font_format', 'font-bold')
                }`}
              >
                {option.title}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: '48vh',
          border: '2px solid red',
          borderColor: 'transparent',
          background: getStyleValue('component_background', 'primary_color', theme.c1),
        }}
        className="w-full relative text-white 2xl:px-24 xl:px-14 md:px-10 px-4 xl:pb-6"
      >
        {selectedTab === 'related_contents' && (
          <RelatedVideos
            contentId={contentId}
            theme={theme}
            viewMoreButtonStyle={{
              color: getStyleValue('play_button', 'button_text', '#fff'),
              backgroundImage: `linear-gradient(to left , ${getStyleValue(
                'play_button',
                'background_color1',
                '#1976d2',
              )}, ${getStyleValue('play_button', 'background_color2', '#1976d2')})`,
            }}
            isMultiPart={true}
          />
        )}

        {selectedTab === 'episodes' && (
          <Episodes
            childContentList={childContentList}
            getStyleValue={getStyleValue}
            contentId={contentId}
          />
        )}
      </div>
    </div>
  )
}

export default withTheme(MultipartDetailBottom)

import React from 'react'

const Setting2 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 19.52 20.029">
      <path
        data-name="setting-svgrepo-com (2)"
        d="M87.142 64a.715.715 0 0 1 .681.5l.787 2.445a7.78 7.78 0 0 1 .949.548l2.512-.541a.715.715 0 0 1 .77.343l1.983 3.432a.715.715 0 0 1-.09.838l-1.724 1.9a7.984 7.984 0 0 1 0 1.1l1.724 1.906a.715.715 0 0 1 .09.838l-1.984 3.43a.715.715 0 0 1-.77.342l-2.512-.541a7.951 7.951 0 0 1-.947.548l-.788 2.445a.715.715 0 0 1-.681.5h-3.966a.715.715 0 0 1-.681-.5l-.785-2.444a7.857 7.857 0 0 1-.951-.551l-2.511.542a.715.715 0 0 1-.77-.343l-1.983-3.432a.715.715 0 0 1 .09-.838l1.724-1.906a7.983 7.983 0 0 1 0-1.093l-1.724-1.906a.715.715 0 0 1-.09-.838l1.983-3.434a.715.715 0 0 1 .77-.342l2.511.542a7.945 7.945 0 0 1 .951-.551l.79-2.439a.715.715 0 0 1 .678-.5h3.962zm-.524 1.431H83.7l-.813 2.528-.548.268a6.573 6.573 0 0 0-.778.449l-.506.343-2.6-.561L77 70.987l1.781 1.971-.043.607a6.553 6.553 0 0 0 0 .9l.043.607-1.784 1.971 1.461 2.529 2.6-.559.506.342a6.576 6.576 0 0 0 .778.449l.548.268.81 2.528h2.921l.815-2.529.547-.266a6.444 6.444 0 0 0 .777-.449l.505-.342 2.6.559 1.459-2.529-1.783-1.974.043-.607a6.555 6.555 0 0 0 0-.9l-.043-.607 1.784-1.97-1.461-2.529-2.6.558-.505-.34a6.429 6.429 0 0 0-.777-.449l-.547-.266-.817-2.529zm-1.459 4.292a4.292 4.292 0 1 1-4.292 4.292 4.292 4.292 0 0 1 4.292-4.292zm0 1.431a2.861 2.861 0 1 0 2.861 2.861 2.861 2.861 0 0 0-2.861-2.862z"
        transform="translate(-75.399 -64)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Setting2

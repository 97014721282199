import React, { useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import '../default/globalStyles.css'
import '../default/swimlane.css'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import useAxios from 'axios-hooks'
import { encodeURI } from 'js-base64'
import NewContentCard from '../../contentCard/newContentCard'
import LoaderTile from '../../contentCard/loaderTile'
import NewContentCardHover from '../../contentCard/newContentCardHover'
import { useTranslation } from 'react-i18next'

const DecomposedSwimlane = ({ componentData, theme, isEdit }) => {
  const { t } = useTranslation()
  const { data } = componentData
  const { customNavigate, isBrowser, LG_SIZE, SM_SIZE, CONTENT_FILTER_XAVIER } = useTemplateHook()
  const [hoverCardIndex, setHoverCardIndex] = useState('')
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [contentData, setContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList, loading: contentListLoading }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(5)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(3)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data?.content_data) || !isEmpty(data?.auto_payload)) {
      let filterPayload =
        data.type === 'STATIC'
          ? { content_ids: data.content_data }
          : {
              system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
              custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
              video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
            }

      contentListCall({
        data: {
          filters: filterPayload,
          page: 1,
          order_by:
            data.type === 'STATIC'
              ? ''
              : data?.order_by === 'title'
              ? `title.${language}`
              : data?.order_by,
          reverse: data.type === 'STATIC' ? '' : data?.reverse,
          page_size: 15,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleHover = (index) => {
    if (isEdit) return
    setHoverCardIndex(index)
  }

  const handleUnHover = () => {
    if (isEdit) return
    setHoverCardIndex('')
  }

  const handleSeeMore = () => {
    let payloadObj = {
      filters: {},
      order_by:
        data.type === 'STATIC'
          ? ''
          : data?.order_by === 'title'
          ? `title.${language}`
          : data?.order_by,
      reverse: data.type === 'STATIC' ? '' : data?.reverse,
    }
    payloadObj.filters =
      data.type === 'STATIC'
        ? {
            content_ids: data.content_data,
          }
        : {
            system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
          }
    const encoded = encodeURI(JSON.stringify(payloadObj))
    customNavigate('DIRECT', `/content/filter/${encodeURI(data.name)}?filters=${encoded}`, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }
  return (
    <div
      className={` relative py-[16px] lg:py-0 lg:pb-8 2xl:pb-2`}
      style={{ backgroundColor: 'transparent' }}
    >
      <CarouselProvider
        naturalSlideWidth={isBrowser ? 100 : 300}
        naturalSlideHeight={isBrowser ? 100 : 195}
        totalSlides={contentData.length}
        visibleSlides={visibleSlides}
        step={5}
      >
        <div
          className="flex items-baseline"
          style={{
            padding: '0 5.52%',
          }}
        >
          <p
            style={{
              color: getStyleValue('heading', 'color', theme.c3),
              fontFamily: getStyleValue('heading', 'font', ''),
              textAlign: getStyleValue('heading', 'alignment', 'left'),
            }}
            className={`sm:text-[0.8rem] md:text-[1.5rem] font-bold w-full ${getStyleValue(
              'heading',
              'font_format',
              'font-bold',
            )}`}
          >
            {data?.name}
            {contentData.length > 14 && (
              <span
                style={{ cursor: 'pointer' }}
                className={
                  'text-blue-400 text-xs md:text-md  ml-2 cursor-pointer hover:opacity z-30 mt-3'
                }
                onClick={handleSeeMore}
              >
                {t('see_more')}
              </span>
            )}
          </p>
        </div>
        <div
          className={`swimlane-container-dock swimlane-slider-class h-[8.5rem] md:h-[12rem] lg:h-[14rem] 2xl:h-[16rem]`}
        >
          <Slider
            style={{
              minHeight: hoverCardIndex !== '' && isBrowser ? `23rem` : 'unset',
              maxHeight: '320px',
            }}
            className="pt-2 md:pt-4 lg:pt-8"
          >
            {contentData.map((item, index) => {
              return (
                <Slide key={index} index={index} className="">
                  <div
                    style={{ display: hoverCardIndex === index && isBrowser ? 'block' : 'none' }}
                    className="relative"
                    onMouseLeave={handleUnHover}
                  >
                    <NewContentCardHover
                      data={item}
                      type={'content'}
                      position={index + 1}
                      total={contentData?.length}
                      isVisible={hoverCardIndex === index && isBrowser}
                    />
                  </div>
                  {contentListLoading ? (
                    <LoaderTile height={'auto'} />
                  ) : (
                    <div
                      className="relative"
                      style={{ opacity: hoverCardIndex === index && isBrowser ? 0 : 1 }}
                      onMouseOver={() => handleHover(index)}
                    >
                      <NewContentCard
                        textStyle={{
                          color: getStyleValue('card_style', 'color', theme.c4),
                          fontFamily: getStyleValue('card_style', 'font', ''),
                        }}
                        fontFormat={getStyleValue('card_style', 'font_format', 'font-bold')}
                        data={item}
                        type={'content'}
                        isEdit={isEdit}
                      />
                    </div>
                  )}
                </Slide>
              )
            })}
          </Slider>
          {isBrowser && (
            <>
              {contentData.length > visibleSlides ? (
                <ButtonBack className="">
                  <div className="prev-Swimlane-arrow flex items-center justify-center">
                    <IoIosArrowBack className="  " />
                  </div>
                </ButtonBack>
              ) : null}
              {contentData.length > visibleSlides ? (
                <ButtonNext className="">
                  <div
                    style={{ background: '#000 !important' }}
                    className="next-Swimlane-arrow flex items-center justify-center"
                  >
                    <IoIosArrowForward className="" />
                  </div>
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
    </div>
  )
}

export default withTheme(DecomposedSwimlane)

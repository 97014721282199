import React from 'react'
import Subtitle1 from '../../../assets/customPlayer/subtitle/subtitle1'
import Subtitle2 from '../../../assets/customPlayer/subtitle/subtitle2'
import Subtitle3 from '../../../assets/customPlayer/subtitle/subtitle3'
import Subtitle4 from '../../../assets/customPlayer/subtitle/subtitle4'
import Subtitle5 from '../../../assets/customPlayer/subtitle/subtitle5'
import Subtitle6 from '../../../assets/customPlayer/subtitle/subtitle6'

const SubtitleButton = ({ color = '#fff', iconType }) => {
  let icons = {
    SUBTITLE1: (
      <>
        <Subtitle1 color={color} />
      </>
    ),
    SUBTITLE2: (
      <>
        <Subtitle2 color={color} />
      </>
    ),
    SUBTITLE3: (
      <>
        <Subtitle3 color={color} />
      </>
    ),
    SUBTITLE4: (
      <>
        <Subtitle4 color={color} />
      </>
    ),
    SUBTITLE5: (
      <>
        <Subtitle5 color={color} />
      </>
    ),
    SUBTITLE6: (
      <>
        <Subtitle6 color={color} />
      </>
    ),
  }

  return (
    <div style={{ maxWidth: '15px' }} className="flex justify-center items-center w-auto h-full">
      {icons[iconType]}
    </div>
  )
}

export default SubtitleButton

import React, { useEffect, useRef, useState } from 'react'
import { uniq, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import SelectionArrow from '../../../assets/customPlayer/selectionArrow'
import ResolutionButton from './resolutionButton'
import PlaybackSpeedButton from './playbackSpeedButton'
import SubtitleButton from './subtitleButton'
import LanguageButton from './languageButton'

const CustomSettingMenu = ({ playerRef, setShowSettingMenu, data }) => {
  const { t } = useTranslation()
  const settingRef = useRef(null)
  const [selectedSetting, setSelectedSetting] = useState('')
  const [selectedSpeed, setSelectedSpeed] = useState(1)
  const [qualityLevelsMaster, setQualityLevelsMaster] = useState([])
  const [currentPlayingQuality, setCurrentPlayingQuality] = useState(0)
  const [textTrackMaster, setTextTrackMaster] = useState([])
  const [currentTextTrack, setCurrentTextTrack] = useState('')
  const [audioTrackMaster, setAudioTrackMaster] = useState([])
  const [currentAudioTrack, setCurrentAudioTrack] = useState('')

  useEffect(() => {
    settingRef.current.focus()
  }, [])

  useEffect(() => {
    if (playerRef.current) {
      let player = playerRef.current
      let qualityLevels = player.qualityLevels()
      let textTracks = player.textTracks()
      let audioTracks = player.audioTracks()
      //   [...player.qualityLevels().levels_].map((info) => info.height),

      setQualityLevelsMaster(
        uniq([...qualityLevels.levels_].map((info) => info.height).sort((a, b) => b - a)),
      )
      setSelectedSpeed(player.playbackRate())
      setCurrentPlayingQuality(qualityLevels[qualityLevels.selectedIndex]?.height)

      for (let i = 0; i < textTracks.length; i++) {
        let track = textTracks[i]

        if (track.kind === 'subtitles' && track.mode === 'showing') {
          setCurrentTextTrack(track.language)
        }
      }

      setTextTrackMaster(
        [...textTracks.tracks_].filter((info) => info.kind === 'subtitles').map((ele) => ele),
      )

      for (let i = 0; i < audioTracks.length; i++) {
        let track = audioTracks[i]

        if (track.enabled) {
          setCurrentAudioTrack(track.language)
        }
      }

      setAudioTrackMaster([...audioTracks.tracks_].map((ele) => ele))
    }
  }, [playerRef])

  const changePlaybackRate = (rate) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    player.playbackRate(rate)
    setSelectedSpeed(rate)
  }

  const changeVideoQuality = (quality) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let qualityLevels = player.qualityLevels()

    if (qualityLevels) {
      let chosenQualityIndex = [...qualityLevels.levels_]
        .map((info) => info.height)
        .findIndex((q) => q === quality)

      for (let i = 0; i < qualityLevels.length; i++) {
        let qualityLevel = qualityLevels[i]
        qualityLevel.enabled = i === chosenQualityIndex ? true : false
      }
      qualityLevels.selectedIndex_ = chosenQualityIndex
      qualityLevels.trigger({ type: 'change', selectedIndex: chosenQualityIndex })
      setCurrentPlayingQuality(quality)
      console.log('chosenQualityIndex', quality, chosenQualityIndex)
    }
  }

  const changeTextTrack = (language) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let textTracks = player.textTracks()
    for (let i = 0; i < textTracks.length; i++) {
      let track = textTracks[i]

      track.mode = 'disabled'
      if (track.kind === 'subtitles' && track.language === language) {
        track.mode = 'showing'
      }
    }
    setCurrentTextTrack(language)
  }

  const changeAudioTrack = (language) => {
    setShowSettingMenu(true)
    let player = playerRef.current
    if (!player) {
      return
    }
    let audioTracks = player.audioTracks()
    for (let i = 0; i < audioTracks.length; i++) {
      let track = audioTracks[i]

      track.enabled = false
      if (track.language === language) {
        track.enabled = true
      }
    }
    setCurrentAudioTrack(language)
  }

  const handleChangeSetting = (type) => {
    setShowSettingMenu(true)
    setSelectedSetting(type)
  }

  return (
    <div
      style={{
        background: `${data.bg_color}70`,
        width: '160px',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 30,
        height: isEmpty(selectedSetting) ? '155px' : '171px',
        fontFamily: data.font_style,
      }}
      className="absolute rounded-lg left-0 lg:left-auto bottom-[45px] lg:bottom-[50px] h-[140px] lg:h-[160px] right-0 flex py-1.5"
      onBlur={() => setShowSettingMenu(false)}
      tabIndex={-1}
      ref={settingRef}
    >
      <div style={{ padding: '6px 0px' }} className="w-full h-full text-xs">
        {isEmpty(selectedSetting) && (
          <>
            {' '}
            {data.resolution_icon.visible ? (
              <div
                className={`flex justify-between items-center cursor-pointer mb-2 setting-main-menu w-full`}
                style={{
                  color:
                    selectedSetting === 'Resolution' ? data.selected_font_color : data.font_color,
                  height: '24px',
                  padding: '5px 13px',
                }}
                onClick={() => handleChangeSetting('Resolution')}
              >
                <div className="flex items-center h-full">
                  <ResolutionButton
                    color={data.icon_color}
                    iconType={data.resolution_icon.icon_type}
                  />

                  <p
                    style={{ marginLeft: '14px' }}
                    className={` ${selectedSetting === 'Resolution' && ' font-semibold'}`}
                  >
                    {t('resolution')}
                  </p>
                </div>
                <div style={{ height: '13.2px' }} className="">
                  {selectedSetting === 'Resolution' ? (
                    <SelectionArrow color={data.selected_font_color} />
                  ) : null}
                </div>
              </div>
            ) : null}
            {data.playback_icon.visible ? (
              <div
                className={`flex justify-between items-center cursor-pointer mb-2 setting-main-menu w-full`}
                style={{
                  color: selectedSetting === 'Speed' ? data.selected_font_color : data.font_color,
                  height: '24px',
                  padding: '5px 13px',
                }}
                onClick={() => handleChangeSetting('Speed')}
              >
                <div className="flex items-center h-full">
                  <PlaybackSpeedButton
                    color={data.icon_color}
                    iconType={data.playback_icon.icon_type}
                  />

                  <p
                    style={{ marginLeft: '14px' }}
                    className={`${selectedSetting === 'Speed' && ' font-semibold'}`}
                  >
                    {t('playback_speed')}
                  </p>
                </div>
                <div style={{ height: '13.2px' }} className="">
                  {selectedSetting === 'Speed' ? (
                    <SelectionArrow color={data.selected_font_color} />
                  ) : null}
                </div>
              </div>
            ) : null}
            {data.subtitle_icon.visible ? (
              <div
                className={`flex justify-between items-center cursor-pointer mb-2 setting-main-menu w-full`}
                style={{
                  color:
                    selectedSetting === 'Subtitle' ? data.selected_font_color : data.font_color,
                  height: '24px',
                  padding: '5px 13px',
                }}
                onClick={() => handleChangeSetting('Subtitle')}
              >
                <div className="flex items-center h-full">
                  <SubtitleButton color={data.icon_color} iconType={data.subtitle_icon.icon_type} />

                  <p
                    style={{ marginLeft: '14px' }}
                    className={`${selectedSetting === 'Subtitle' && ' font-semibold'}`}
                  >
                    {t('subtitle')}
                  </p>
                </div>
                <div style={{ height: '13.2px' }} className="">
                  {selectedSetting === 'Subtitle' ? (
                    <SelectionArrow color={data.selected_font_color} />
                  ) : null}
                </div>
              </div>
            ) : null}
            {data.language_icon.visible ? (
              <div
                className={`flex justify-between items-center cursor-pointer mb-2 setting-main-menu w-full`}
                style={{
                  color:
                    selectedSetting === 'Language' ? data.selected_font_color : data.font_color,
                  height: '24px',
                  padding: '5px 13px',
                }}
                onClick={() => handleChangeSetting('Language')}
              >
                <div className="flex items-center h-full">
                  <LanguageButton color={data.icon_color} iconType={data.language_icon.icon_type} />

                  <p
                    style={{ marginLeft: '14px' }}
                    className={`${selectedSetting === 'Language' && ' font-semibold'}`}
                  >
                    {t('language')}
                  </p>
                </div>
                <div style={{ height: '13.2px' }} className="">
                  {selectedSetting === 'Language' ? (
                    <SelectionArrow color={data.selected_font_color} />
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        )}

        {selectedSetting.length ? (
          <div className="w-full h-full">
            <div className="flex items-center " style={{ paddingLeft: '11px' }}>
              <div
                style={{ height: '13.2px', width: '7.2px', marginBottom: '5px' }}
                onClick={() => setSelectedSetting('')}
              >
                <SelectionArrow color={data.selected_font_color} iconClass={'rotate-180'} />
              </div>
              <p
                className="pl-1"
                style={{
                  borderBottom: '0.5px solid #fff',
                  color: data.font_color,
                  width: '124px',
                  paddingLeft: '5px',
                  paddingBottom: '5px',
                  marginLeft: '14px',
                }}
              >
                {selectedSetting === 'Resolution' && t('resolution')}
                {selectedSetting === 'Speed' && t('playback_speed')}
                {selectedSetting === 'Subtitle' && t('subtitle')}
                {selectedSetting === 'Language' && t('language')}
              </p>
            </div>

            {selectedSetting === 'Resolution' && (
              <div
                style={{
                  width: '124px',
                  paddingLeft: '5px',
                  marginLeft: '32px',
                  height: '90%',
                }}
                className="mt-1 scrollbar-class"
              >
                {!isEmpty(qualityLevelsMaster) ? (
                  qualityLevelsMaster.map((quality, idx) => (
                    <p
                      style={{
                        color:
                          currentPlayingQuality === quality
                            ? data.selected_font_color
                            : data.font_color,
                      }}
                      className={`cursor-pointer mb-2.5 `}
                      onClick={() => changeVideoQuality(quality)}
                      key={idx}
                    >
                      {`${quality}`}p {(quality === 1080 || quality === 720) && `HD`}
                    </p>
                  ))
                ) : (
                  <p style={{ color: data.font_color }} className="">
                    {t('no_resolution')}
                  </p>
                )}
              </div>
            )}

            {selectedSetting === 'Speed' && (
              <div
                style={{
                  width: '124px',
                  paddingLeft: '5px',
                  marginLeft: '32px',
                  height: '90%',
                }}
                className="mt-1 scrollbar-class"
              >
                {[0.5, 0.75, 1, 1.25, 1.5].map((speed, idx) => (
                  <p
                    style={{
                      color: selectedSpeed === speed ? data.selected_font_color : data.font_color,
                    }}
                    className={`cursor-pointer mb-2.5 `}
                    onClick={() => changePlaybackRate(speed)}
                    key={idx}
                  >
                    {`${speed}`}x {speed === 1 && `(Normal)`}
                  </p>
                ))}
              </div>
            )}

            {selectedSetting === 'Subtitle' && (
              <div
                style={{
                  width: '124px',
                  paddingLeft: '5px',
                  marginLeft: '32px',
                  height: '90%',
                }}
                className="mt-1 scrollbar-class"
              >
                {!isEmpty(textTrackMaster) ? (
                  <>
                    {textTrackMaster.map((info, index) => (
                      <p
                        style={{
                          color:
                            currentTextTrack === info.language
                              ? data.selected_font_color
                              : data.font_color,
                        }}
                        className={`cursor-pointer mb-2.5 `}
                        onClick={() => changeTextTrack(info.language)}
                        key={index}
                      >
                        {info.label}
                      </p>
                    ))}
                  </>
                ) : (
                  <p style={{ color: data.font_color }} className="">
                    {t('no_subtitle')}{' '}
                  </p>
                )}
              </div>
            )}

            {selectedSetting === 'Language' && (
              <div
                style={{
                  width: '124px',
                  paddingLeft: '5px',
                  marginLeft: '32px',
                  height: '90%',
                }}
                className="mt-1 scrollbar-class"
              >
                {!isEmpty(audioTrackMaster) ? (
                  <>
                    {audioTrackMaster.map((info, index) => (
                      <p
                        style={{
                          color:
                            currentAudioTrack === info.language
                              ? data.selected_font_color
                              : data.font_color,
                        }}
                        className={`cursor-pointer mb-2.5 `}
                        onClick={() => changeAudioTrack(info.language)}
                        key={index}
                      >
                        {info.label}
                      </p>
                    ))}
                  </>
                ) : (
                  <p style={{ color: data.font_color }} className="">
                    {t('no_language')}
                  </p>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CustomSettingMenu

import React, { useContext } from 'react'
import { SubscriptionContext } from '../../context/subscription'
import { withTheme } from 'theming'
import Subscription from './subscription'
import BackIconImg from '../../assets/imgComponents/backIconImg'
import { useTranslation } from 'react-i18next'

const SubscriptionModal = ({ theme }) => {
  const { setOpenSubscriptionModal } = useContext(SubscriptionContext)
  const { t } = useTranslation()
  return (
    <div
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className=" w-full flex justify-center ">
        <div className="mt-6 xl:mt-[115px] flex flex-col items-center w-full z-10">
          <div className="flex items-center ">
            <BackIconImg
              onClick={() => {
                setOpenSubscriptionModal(false)
              }}
              imgClass="cursor-pointer"
            />
            <p className="mb-0 text-white xl:text-[28px] font-bold ml-3 lg:ml-8">
              {t('choose_membership_plans_caps')}
            </p>
          </div>
          <p style={{ color: '#b5b5b5' }} className="mb-0 text-xs lg:text-sm italic mt-2 lg:mt-0">
            {t('choose_plan_that_works_friends')}
          </p>
          <div className="mt-4 w-[94%] lg:w-[905px]">
            <Subscription />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(SubscriptionModal)

import React, { useContext, useEffect } from 'react'
import CustomImage from '../../customImage/customImage'
import { getImage } from '../../../utils/helper'
import { AppContext } from '../../../context/app'

let showWatermark = false

const PlayerWatermark = ({ playerData, isPlaying }) => {
  let { accountObj } = useContext(AppContext)
  let data = playerData?.screen?.data.watermark

  const positionClass = {
    TOP_CENTER: 'top-0 watermark-center',
    CENTER_LEFT: 'watermark-top-center left-0',
    CENTER: 'watermark-top-center watermark-center',
    CENTER_RIGHT: 'watermark-top-center right-0',
    BOTTOM_LEFT: 'bottom-0 left-0',
    BOTTOM_CENTER: 'bottom-0 watermark-center',
    BOTTOM_RIGHT: 'bottom-0 right-0',
  }

  useEffect(() => {
    if (isPlaying) {
      showWatermark = true
    }
  }, [isPlaying])

  return (
    <div style={{ display: showWatermark && data.visible ? 'block' : 'none', opacity: 0.6 }}>
      <div
        className={`absolute m-2 xl:m-4 ${positionClass[data.position]} `}
        style={{ height: '60px', aspectRatio: '1.777' }}
      >
        {data.image_s3key ? (
          <CustomImage
            imageUrl={getImage(accountObj?.cf_domain_name, data.image_s3key)}
            imgClass="w-full h-full "
            loaderClass={'h-full'}
            imgAlt={'thumbnail'}
          />
        ) : null}
      </div>
    </div>
  )
}

export default PlayerWatermark

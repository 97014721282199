import React, { useContext, useEffect, useState } from 'react'
import { BsEye } from 'react-icons/bs'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
import { getImage, viewsFormatter } from '../../utils/helper'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { ARTIST_LISTING, CONTENT_FILTERING, GET_CATEGORY, VENUE_LISTING } from '../../utils/apiUrls'
import { useParams } from 'react-router'
import './artist.css'
import { groupBy, isEmpty } from 'lodash'
import Dropdown from '../../components/filterDropdown/filterDropdown'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import ArtistPlaceholder from '../../assets/artist-placeholder.jpg'
import ShowMoreText from 'react-show-more-text'
import RatingReviewWidget from '../../components/reviewRating/ratingReviewWidget'
import ReviewsListing from '../../components/reviewRating'
import { withTheme } from 'theming'
import ArtistWrapper from './_artistDetailStyle'
import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'

const ArtistDetail = ({ theme }) => {
  const artistId = useParams().artistId
  const { setLoader, accountObj, modulePermissions, isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  const [artistDetail, setArtistDetail] = useState({})
  const [artistContents, setArtistContent] = useState({})
  const [genreData, setGenreData] = useState([])
  const [contentPayload, setContentPayload] = useState({
    ...createSystemDefinedFilter('artists', [artistId]),
    order_by: 'created_date',
    reverse: true,
    page_size: '100',
    page: '1',
  })

  const handleRedirect = (link) => {
    if (link) {
      let finalURL = link.includes('http') ? link : `http://${link}`

      window.open(finalURL)
    }
  }

  const [{ data: artistData, loading: artistDataLoading }, artistDetailCall] = useAxios({
    url: `${ARTIST_LISTING}/detail/${artistId}`,
    method: 'get',
  })

  const [{ data: venueList, loading: venueListLoading }] = useAxios({
    url: VENUE_LISTING,
    method: 'get',
  })

  const [{ data: categoryRes, loading: categoryLoading }] = useAxios({
    url: GET_CATEGORY,
    method: 'get',
  })

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(artistDataLoading || contentListLoading || venueListLoading || categoryLoading)
  }, [artistDataLoading, contentListLoading, venueListLoading, categoryLoading])

  useEffect(() => {
    contentFilterCall({
      data: contentPayload,
    })
  }, [contentPayload])

  useEffect(() => {
    if (artistData && artistData.success) {
      setArtistDetail(artistData.data)
    }
  }, [artistData])

  useEffect(() => {
    if (categoryRes && categoryRes.success) {
      setGenreData(categoryRes.data.filter((obj) => obj.type === 'sub_category'))
    }
  }, [categoryRes])

  useEffect(() => {
    if (contentList && contentList.success) {
      setArtistContent(groupBy(contentList.data, (content) => content.category[0].name))
    }
  }, [contentList])

  const handleFilterChange = (filterPayload) => {
    setContentPayload({ ...contentPayload, filters: filterPayload })
  }
  return (
    <div>
      <ArtistWrapper theme={theme}>
        <div className="hidden artist-image xl:contents">
          <div className="artist-image-div flex flex-col justify-center">
            <CustomImage
              imageUrl={getImage(accountObj?.cf_domain_name, artistDetail.thumbnail)}
              imgClass="object-cover"
              imgAlt={'Artist Thumbnail'}
            />
          </div>
        </div>
        <div className="xl:hidden flex flex-col justify-center">
          <CustomImage
            imageUrl={getImage(accountObj?.cf_domain_name, artistDetail.thumbnail)}
            imgClass="object-fill h-50 w-screen"
            imgAlt={'Artist Thumbnail'}
          />
        </div>

        <div
          className="z-10 px-4 lg:grid xl:grid-cols-8 xl:gap-20 xl:absolute xl:px-32 xl:top-72 w-full"
          style={{ color: '#e3e2e2', alignItems: 'center' }}
        >
          <div
            style={{
              borderColor: isCustomTemplate ? theme.c4 : '#fff',
              aspectRatio: '87/119',
            }}
            className="hidden xl:flex shadow my-1 col-span-2 border-2 justify-center"
          >
            <CustomImage
              imageUrl={getImage(accountObj?.cf_domain_name, artistDetail.poster)}
              imgClass="w-full h-full object-cover"
              imgAlt={artistDetail.name}
              errorPlaceholder={ArtistPlaceholder}
            />
          </div>

          <div className="col-span-6">
            <div className="flex items-center mt-5 xl:mt-48">
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="text-2xl font-semibold"
              >
                {artistDetail.name}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="flex items-center mx-10"
              >
                {artistDetail.views_count !== 0 && <BsEye />}
                {artistDetail.views_count !== 0 && (
                  <p className=" text-xs md:text-sm px-1">
                    {viewsFormatter(artistDetail.views_count)} {t('views')}
                  </p>
                )}
              </div>
            </div>
            {modulePermissions?.rating && (
              <RatingReviewWidget
                value={artistDetail.average_rating}
                ratingCount={artistDetail.review_count}
              />
            )}
            <ShowMoreText
              lines={4}
              more="Show more"
              less="Show less"
              className={`mt-5 w-full  text-lg mr-5 mb-3 description-color`}
              anchorClass={`text-sm description-see-more-color`}
              expanded={false}
              truncatedEndingComponent={'... '}
            >
              {artistDetail.bio}
            </ShowMoreText>
            {/*<p className={'text-sm md:text-lg line-clamp-3 my-3'}>{`${artistDetail.bio}`}</p>*/}
            <div className="grid xl:grid-cols-12">
              <div className="xl:col-span-11">
                {artistDetail.custom_metadata &&
                  artistDetail.custom_metadata.map((item, index) => (
                    <div className={'flex'} key={index}>
                      <p
                        style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                        className={' w-4/12 md:w-2/12'}
                      >{`${item.name} :`}</p>
                      <p
                        style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                        className={' w-8/12 md:w-10/12'}
                      >
                        {item.value}
                      </p>
                    </div>
                  ))}
              </div>
              <div
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className="flex mt-5 lg:mt-10 justify-center grid  grid-cols-10 gap-1 xl:grid-cols-3 xl:gap-6 text-xs xl:text-sm items-end"
              >
                {artistDetail.social_profiles && artistDetail.social_profiles.facebook && (
                  <FaFacebookF
                    className="cursor-pointer mx-2 md:mx-1 w-4 h-4 md:w-6 md:h-6"
                    onClick={() => handleRedirect(artistDetail.social_profiles.facebook)}
                  />
                )}
                {artistDetail.social_profiles && artistDetail.social_profiles.twitter && (
                  <FaTwitter
                    className="cursor-pointer mx-2 md:mx-1 w-4 h-4 md:w-6 md:h-6"
                    onClick={() => handleRedirect(artistDetail.social_profiles.twitter)}
                  />
                )}
                {artistDetail.social_profiles && artistDetail.social_profiles.instagram && (
                  <FaInstagram
                    className="cursor-pointer mx-2 md:mx-1 w-4 h-4 md:w-6 md:h-6"
                    onClick={() => handleRedirect(artistDetail.social_profiles.instagram)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-2">
          <div className="w-full px-2 xl:px-20">
            <div
              style={{
                color: isCustomTemplate ? theme.c4 : '#fff',
                borderColor: isCustomTemplate ? theme.c4 : '',
              }}
              className="w-full py-2 flex justify-end  border-b-2 z-50"
            >
              <div className="flex justify-between xl:mr-5">
                {!isEmpty(venueList?.data) && (
                  <Dropdown
                    title={t('venue')}
                    options={venueList?.data}
                    filterKey={'venue'}
                    filterFunction={handleFilterChange}
                    payload={contentPayload.filters}
                  />
                )}
                {!isEmpty(genreData) && (
                  <Dropdown
                    title={t('genre')}
                    options={genreData}
                    filterKey={'sub_category'}
                    filterFunction={handleFilterChange}
                    payload={contentPayload.filters}
                  />
                )}
              </div>
              {!isEmpty(genreData) || !isEmpty(venueList?.data) ? (
                <button
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="px-5 rounded-lg md:px-2"
                  onClick={() =>
                    handleFilterChange({
                      ...createSystemDefinedFilter('artists', [artistId]),
                      order_by: 'created_date',
                      reverse: true,
                      page_size: '100',
                      page: '1',
                    })
                  }
                >
                  {t('clear')}
                </button>
              ) : null}
            </div>
          </div>
          <div className="mt-10">
            {!isEmpty(artistContents) &&
              Object.keys(artistContents).map((key, index) => {
                let data = {
                  id: '0330d0e-7523-14e5-784-da18542fd05',
                  component_key: 'newDefaultSwimlane',
                  category: 'card_carousel',
                  config: {},
                  data: {
                    name: key,
                    type: 'STATIC',
                    content_data: artistContents[key].map((info) => info.content_id),
                    auto_payload: [],
                    order_by: '',
                    reverse: '',
                    style_config: {},
                  },
                }
                return (
                  <div className={`md:mt-2 lg:mt-10 2xl:mt-16`} key={index}>
                    <NewDefaultSwimlane
                      componentData={data}
                      modifyData={() => {}}
                      setModalState={() => {}}
                      isEdit={false}
                      onSwap={() => {}}
                      onDelete={() => {}}
                      onCopy={() => {}}
                      onAdd={() => {}}
                    />
                  </div>
                )
              })}
          </div>
          {modulePermissions?.rating && (
            <div
              className={'border-t-2'}
              style={{
                marginTop: '100px',
                padding: '50px',
                borderTop: `1.5px solid ${theme.c5}`,
              }}
            >
              <ReviewsListing
                type={'artists'}
                id={artistId}
                title={artistDetail?.name}
                isRefreshed={artistDataLoading}
                onReviewSubmitted={artistDetailCall}
              />
            </div>
          )}
        </div>
      </ArtistWrapper>
    </div>
  )
}

export default withTheme(ArtistDetail)

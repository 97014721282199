import React, { useState } from 'react'

const RestartIcon = ({ color = '#fff', hoverColor = '#bbfa33' }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.004"
      height={15}
      viewBox="0 0 14.004 15"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <g data-name="Group 968">
        <path
          d="M10.759 6.121 1.5.2C.673-.322 0 .208 0 1.382v11.383c0 1.174.673 1.7 1.5 1.18l9.259-5.915a1.048 1.048 0 0 0 0-1.909z"
          transform="rotate(180 7.002 7.074)"
          style={{ fill: isHovered ? hoverColor : color }}
          className="icon-hover"
        />
        <g
          data-name="Rectangle 1149"
          className="icon-hover"
          style={{ stroke: isHovered ? hoverColor : color, fill: isHovered ? hoverColor : color }}
        >
          <path style={{ stroke: 'none' }} d="M0 0h2v15H0z" />
          <path style={{ fill: 'none' }} d="M.5.5h1v14h-1z" />
        </g>
      </g>
    </svg>
  )
}

export default RestartIcon

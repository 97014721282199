import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { FORGOT_PASSWORD } from '../../utils/apiUrls'
import { raiseToast } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'

const ForgotPassword = ({ theme }) => {
  const { setLoader, setOpenForgotPasswordModal, isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  const [email, setEmail] = useState('')

  const [{ data: forgotPasswordRes, loading: forgotPasswordLoading }, forgotPasswordCall] =
    useAxios(
      {
        url: FORGOT_PASSWORD,
        method: 'post',
      },
      { manual: true },
    )

  useEffect(() => {
    setLoader(forgotPasswordLoading)
  }, [forgotPasswordLoading])

  const handleSubmit = async () => {
    if (!isEmpty(email)) {
      forgotPasswordCall({
        data: {
          email_id: email,
        },
      })
    } else {
      raiseToast('error', t('please_provide_email_id'))
    }
  }

  useEffect(() => {
    if (forgotPasswordRes) {
      if (forgotPasswordRes.success) {
        raiseToast('success', t('email_password_reset_check_your_mailbox'))
        setOpenForgotPasswordModal(false)
      } else {
        raiseToast('error', forgotPasswordRes.message)
      }
    }
  }, [forgotPasswordRes])

  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none `}
      >
        <div className={`relative my-6 w-10/12 lg:w-8/12  xl:w-5/12`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#151c26',
              fontFamily: 'Arial',
              opacity: '0.96',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.90)',
              border: '',
            }}
            className="shadow-lg relative flex flex-col p-1 md:p-8"
          >
            <div className=" py-2 object-right-top">
              <button
                className="p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={() => setOpenForgotPasswordModal(false)}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className={` h-6 w-6 text-xl xl:text-2xl block outline-none focus:outline-none`}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="flex justify-center">
              <h3
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={` text-2xl font-semibold`}
              >
                {t('forgot_password')}
              </h3>
            </div>
            {/*body*/}
            <div className="relative md:mt-4 py-6 px-6 md:px-10 flex-auto">
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className="mt-6 border  lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                placeholder={'Please provide email'}
              />
              <div className="mt-6 flex justify-center">
                <button
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                    color: isCustomTemplate ? theme.c7 : '#fff',
                  }}
                  className="mt-10 px-4 rounded focus:outline-none"
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(ForgotPassword)

import React, { useContext } from 'react'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'
import { isEmpty, sortBy } from 'lodash'
import CustomImage from '../../components/customImage/customImage'
import { getImage } from '../../utils/helper'
import VideoPlaceholder from '../../assets/video-content.png'
import NewAddedToFavorite from '../../assets/imgComponents/newAddedToFavorite'
import NewAddedToWatchList from '../../assets/imgComponents/newAddedToWatchlist'
import NewAddToWatchList from '../../assets/imgComponents/newAddToWatchlist'
import NewAddToFavorite from '../../assets/imgComponents/newAddToFavorite'
import { ContentContext } from '../../context/content'
import PlayIcon from '../../assets/light-play-icon.svg'
import AudioIcon from '../../assets/audio-thumbnail-icon.svg'
import ProgressBar from '../../components/progressBar/progressBar'

const Episodes = ({ childContentList, getStyleValue }) => {
  const navigate = useNavigate()
  const { isLogin, accountObj, isBrowser } = useContext(AppContext)
  const {
    isInFavoriteList,
    isInWatchList,
    addRemoveFavouriteList,
    addRemoveWatchList,
    getResumeTimestamp,
  } = useContext(ContentContext)

  const handleNavigate = (contentId) => {
    if (accountObj?.od_resource_allowances?.player_page) {
      navigate(`/series-watch/${contentId}`)
      return
    }
    navigate(`/content/${contentId}`)
  }

  return (
    <div className="mt-4 w-full">
      {!isEmpty(childContentList) &&
        sortBy([...childContentList], (o) => o.serial_no).map((info, index) => (
          <div className="w-full flex justify-center items-center" key={index}>
            <div
              style={{ backgroundColor: '#000000' }}
              className="w-full flex rounded-xl relative mt-2.5 py-2.5 pl-3"
            >
              <div
                className={`flex justify-center items-center relative`}
                onClick={() => handleNavigate(info.content_id)}
              >
                <div
                  style={{ backgroundImage: 'linear-gradient(138deg, #303030 14%, #676767 85%)' }}
                  className="w-5 h-5 xl:h-10 xl:w-10 rounded-lg xl:text-xl  justify-center items-center text-white font-bold hidden xl:flex "
                >
                  {index + 1}
                </div>
                <div className="relative flex justify-center items-center xl:ml-3">
                  <CustomImage
                    imageUrl={getImage(accountObj?.cf_domain_name, info.thumbnail)}
                    loaderClass="w-full h-full object-fill p-2 rounded-xl cursor-pointer w-[99px] md:w-[150px] xl:h-[118px] xl:w-[210px]"
                    imgClass=" rounded xl:rounded-lg cursor-pointer  w-[99px] md:w-[150px] xl:h-[118px] xl:w-[210px] "
                    imgAlt="movie"
                    imgStyle={{ aspectRatio: '1.77' }}
                    loaderStyle={{ aspectRatio: '1.77' }}
                    errorPlaceholder={VideoPlaceholder}
                  />

                  {getResumeTimestamp(info?.content_id) ? (
                    <div className=" w-full px-2.5 absolute bottom-0 mb-1">
                      <ProgressBar
                        bgColor={'#bbbbbb'}
                        barClass={''}
                        height={isBrowser ? '4px' : '2px'}
                        progressWidth={`${Number(
                          (getResumeTimestamp(info?.content_id) / info?.duration_in_s) * 100,
                        )}%`}
                        progressColor={getStyleValue('play_button', 'background_color1', '#bbfa33')}
                      />
                    </div>
                  ) : null}

                  {info?.asset_type === 'VIDEO' ? (
                    <img
                      style={{ aspectRatio: '1' }}
                      className="absolute z-10 w-5 lg:w-12 "
                      src={PlayIcon}
                      alt="PlayIcon"
                    />
                  ) : (
                    <img
                      style={{ aspectRatio: '1' }}
                      className="absolute z-10 w-5 lg:w-12"
                      src={AudioIcon}
                      alt="AudioIcon"
                    />
                  )}
                </div>
                <div
                  style={{
                    backgroundImage: 'linear-gradient(138deg, #303030 14%, #676767 85%)',
                    left: '4px',
                  }}
                  className="absolute z-10 w-5 h-5 top-1  rounded justify-center items-center text-white font-bold flex xl:hidden "
                >
                  {index + 1}
                </div>
              </div>
              <div className="ml-2 xl:ml-4 w-[65%] md:w-[82%] xl:w-[82%]">
                <div className="flex justify-between ">
                  <div className="flex items-center my-1">
                    <p
                      style={{ color: '#fff' }}
                      className="text-xs xl:text-xl font-semibold cursor-pointer line-clamp-1"
                      onClick={() => handleNavigate(info.content_id)}
                    >{`${info.title}`}</p>

                    <p style={{ color: '#bbbbbb' }} className="ml-4 italic text-xs hidden lg:block">
                      {info?.duration}
                    </p>
                  </div>
                  {isLogin && (
                    <div
                      style={{
                        color: '#fff',
                        backgroundImage: 'linear-gradient(138deg, #303030 14%, #676767 85%)',
                      }}
                      className="flex items-center justify-center md:mx-2 xl:mx-4 px-1 xl:px-4 rounded  xl:rounded-lg"
                    >
                      <div className="flex items-center justify-between ">
                        <div
                          className=" cursor-pointer relative"
                          onClick={() => addRemoveWatchList(info?.content_id)}
                        >
                          {isInWatchList(info?.content_id) ? (
                            <NewAddedToWatchList
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          ) : (
                            <NewAddToWatchList
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          )}
                        </div>
                        <div
                          className="cursor-pointer ml-2 xl:ml-4 relative"
                          onClick={() => addRemoveFavouriteList(info?.content_id)}
                        >
                          {isInFavoriteList(info?.content_id) ? (
                            <NewAddedToFavorite
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          ) : (
                            <NewAddToFavorite
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  style={{ color: '#bbbbbb', width: '90%' }}
                  className="text-xs lg:text-base mt-2 xl:mt-4"
                >
                  <p className={`${isBrowser ? 'line-clamp-2' : 'line-clamp-1'}`}>
                    {`${info.description}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Episodes

import React from 'react'

const ScreenForwardIcon = ({ primaryColor = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 44.756 49.002">
      <path
        d="M26.471 51.055a22.544 22.544 0 0 0 22.378-22.378 22.485 22.485 0 0 0-9.368-18.1 1.806 1.806 0 0 0-2.72.395 1.958 1.958 0 0 0 .614 2.633 18.578 18.578 0 1 1-15.138-3.093v3.159c0 1.558 1.075 1.974 2.26 1.119l7.02-4.914a1.382 1.382 0 0 0 0-2.457l-7-4.914c-1.207-.878-2.282-.461-2.282 1.119v3.092A22.542 22.542 0 0 0 4.094 28.677a22.529 22.529 0 0 0 22.377 22.378zm5.309-13.141c3.642 0 6.011-3.444 6.011-8.71 0-5.309-2.369-8.8-6.011-8.8s-6.033 3.488-6.033 8.8c.001 5.265 2.392 8.709 6.034 8.709zm-11.451-.264a1.384 1.384 0 0 0 1.426-1.514v-13.8a1.55 1.55 0 0 0-1.58-1.755 3.09 3.09 0 0 0-1.8.724l-3.028 2.04a1.455 1.455 0 0 0-.724 1.272 1.317 1.317 0 0 0 1.277 1.34 1.586 1.586 0 0 0 1.053-.395l2.04-1.492v12.066a1.354 1.354 0 0 0 1.336 1.514zm11.452-2.391c-1.953 0-3.181-2.3-3.181-6.055 0-3.817 1.207-6.143 3.181-6.143 1.953 0 3.137 2.3 3.137 6.143 0 3.751-1.207 6.055-3.137 6.055z"
        transform="translate(-4.094 -2.053)"
        style={{ fill: primaryColor, opacity: '.6' }}
      />
    </svg>
  )
}

export default ScreenForwardIcon

import React from 'react'

const AddedFavorite = ({ color = '#fff', imgClass = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.838"
      height="30.086"
      viewBox="0 0 31.838 30.086"
      className={imgClass}
    >
      <path
        id="love-svgrepo-com_1_"
        data-name="love-svgrepo-com (1)"
        fill={color}
        d="M34.5,16.913c-1.732,8.938-15.58,16.664-15.58,16.664S5.071,25.85,3.34,16.913C1.633,8.106,6.654,3.507,11.844,3.507a7.861,7.861,0,0,1,7.075,3.538,7.861,7.861,0,0,1,7.075-3.538C31.184,3.507,36.206,8.106,34.5,16.913Z"
        transform="translate(-3 -3.491)"
      />
    </svg>
  )
}

export default AddedFavorite

import React from 'react'

const Pip3 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 18 18">
      <g data-name="Layer 2">
        <g data-name="invisible box">
          <path data-name="Rectangle 1659" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
        </g>
        <g data-name="icons Q2">
          <path
            data-name="Path 1170"
            d="M16.566 17.653H3.457V7.457h13.109zM17.294 6H2.728A.728.728 0 0 0 2 6.692v11.725a.728.728 0 0 0 .728.692h14.566a.728.728 0 0 0 .728-.692V6.692A.728.728 0 0 0 17.294 6z"
            transform="translate(-1.223 -3.815)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1171"
            d="M26.37 23.457v1.457h-2.913v-1.457h2.913M27.826 22H22v4.37h5.826z"
            transform="translate(-13.94 -13.989)"
            style={{ fill: color }}
          />
        </g>
      </g>
    </svg>
  )
}

export default Pip3

import React from 'react'

const Volume3 = ({ color = '#000', clickFunc = () => {}, volume = 100, isMute = false }) => {
  return (
    <>
      {isMute && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          onClick={clickFunc}
          viewBox="0 0 12.625 10.105"
        >
          <path
            d="m321.713 578.053 1.757-1.757a.433.433 0 1 0-.608-.608l-1.757 1.757-1.757-1.757a.433.433 0 1 0-.608.608l1.757 1.757-1.757 1.757a.433.433 0 1 0 .608.608l1.757-1.757 1.757 1.757a.433.433 0 1 0 .608-.608l-1.757-1.757zM316.895 573l-2.947 1.965v6.175l2.947 1.965a.842.842 0 0 0 .842-.842v-8.421a.842.842 0 0 0-.842-.842zM311 576.368v3.369a.842.842 0 0 0 .842.842h1.263v-5.053h-1.263a.842.842 0 0 0-.842.842z"
            transform="translate(-311 -573)"
            style={{ fillRule: 'evenodd', fill: color }}
          />
        </svg>
      )}
      {!isMute && volume > 49 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          onClick={clickFunc}
          viewBox="0 0 13.21 11.919"
        >
          <path
            d="M266.1 571.015v.875a5.164 5.164 0 0 1 0 10.169v.875a6.03 6.03 0 0 0 0-11.919zm-2.131.846-2.983 1.989v6.25l2.983 1.989a.853.853 0 0 0 .852-.852v-8.523a.853.853 0 0 0-.852-.852zm4.687 5.114a2.975 2.975 0 0 0-2.557-2.94v.852a2.131 2.131 0 0 1 0 4.175v.852a2.974 2.974 0 0 0 2.557-2.94zM258 575.27v3.409a.853.853 0 0 0 .852.852h1.278v-5.114h-1.278a.853.853 0 0 0-.852.852z"
            transform="translate(-258 -571.015)"
            style={{ fillRule: 'evenodd', fill: color }}
          />
        </svg>
      )}
      {!isMute && volume > 10 && volume <= 49 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          onClick={clickFunc}
          viewBox="0 0 10.653 10.227"
        >
          <path
            d="m263.966 571.861-2.983 1.989v6.25l2.983 1.989a.853.853 0 0 0 .852-.852v-8.523a.853.853 0 0 0-.852-.852zm4.687 5.114a2.975 2.975 0 0 0-2.557-2.94v.852a2.131 2.131 0 0 1 0 4.175v.852a2.974 2.974 0 0 0 2.557-2.94zM258 575.27v3.409a.853.853 0 0 0 .852.852h1.278v-5.114h-1.278a.853.853 0 0 0-.852.852z"
            transform="translate(-258 -571.861)"
            style={{ fillRule: 'evenodd', fill: color }}
          />
        </svg>
      )}
      {!isMute && volume <= 10 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          onClick={clickFunc}
          viewBox="0 0 6.818 10.227"
        >
          <path
            d="m263.966 571.861-2.983 1.989v6.25l2.983 1.989a.853.853 0 0 0 .852-.852v-8.523a.853.853 0 0 0-.852-.852zM258 575.27v3.409a.853.853 0 0 0 .852.852h1.278v-5.114h-1.278a.853.853 0 0 0-.852.852z"
            transform="translate(-258 -571.861)"
            style={{ fillRule: 'evenodd', fill: color }}
          />
        </svg>
      )}
    </>
  )
}

export default Volume3

import React, { useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import EditMenu from '../editMenu'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import PassimPlayer from '../rosemary/passimPlayer'

const ArticleImage = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage, VideoPlaceholder } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')

  const handleHoverItems = (name) => {
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }
  return (
    <div
      className={` ${isEdit && hoverState && 'component-hover-class'} relative `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="w-full flex justify-center ">
        <div className={`${config[data.size].styleClass} cursor-pointer`}>
          {isEdit || isEmpty(data?.player?.data) ? (
            <div className="w-full h-full relative flex justify-center items-center">
              <CustomImage
                imageUrl={getTemplateImage(data?.image_s3key)}
                imgClass="w-full h-full"
                loaderClass="w-full h-full"
                loaderStyle={{ background: '#5A5A5A50' }}
                imgAlt={'recent story Img'}
                errorPlaceholder={VideoPlaceholder}
                onClickRedirect={() => handleRedirect(data?.link)}
              />
            </div>
          ) : (
            <PassimPlayer
              data={data?.player}
              poster={getTemplateImage(data?.image_s3key)}
              onClickRedirect={() => handleRedirect(data?.link)}
            />
          )}
          {data?.heading ? (
            <p
              style={{
                color: getStyleValue('text_properties', 'color', '#1a57ff'),
                fontFamily: getStyleValue('text_properties', 'font', ''),
                textAlign: getStyleValue('text_properties', 'alignment', ''),
              }}
              className={` mb-0 mt-[15px] ${getStyleValue('text_properties', 'font_format', '')}`}
            >
              {data?.heading}
            </p>
          ) : null}
        </div>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(ArticleImage)

import React, { useContext, useEffect, useState } from 'react'
// import OTTlogo from '../../assets/ottdock_logo.png'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { UPDATE_USER_INFO, VALIDATE_SESSION, SEND_VERIFY_EMAIL } from '../../utils/apiUrls'
import { raiseToast } from '../../utils/helper'
import ChangePassword from '../../components/auth/changePassword'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { SECURITY_SESSION_ID } from '../../utils/constants'

const ProfilePage = ({ theme }) => {
  const { userObj, setLoader, isCustomTemplate, accountObj } = useContext(AppContext)
  const { t } = useTranslation()
  const [name, setName] = useState(userObj.name)
  const [email, setEmail] = useState(userObj.email_id)
  const [phone, setPhone] = useState(userObj.phone)
  const [user, setUser] = useState({})
  const [userSession, setUserSession] = useState({})
  const [accID, setAccID] = useState('')
  const [sessionId, setSessionId] = useState('')

  const [errorData, setErrorData] = useState({})

  const [showModal, setShowModal] = useState(false)

  const [{ data: updateUserRes, loading: updateUserLoading }, updateUserCall] = useAxios(
    {},
    { manual: true },
  )

  const [{ data: validateSession }, validateSessionCall] = useAxios(
    {
      url: VALIDATE_SESSION,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: sendVerifyEmailRes, loading: sendVerifyEmailLoading }, sendVerifyEmailCall] =
    useAxios(
      {
        url: SEND_VERIFY_EMAIL,
        method: 'post',
      },
      { manual: true },
    )

  const handleSave = async () => {
    updateUserCall({
      url: UPDATE_USER_INFO + '?user_id=' + userObj._id,
      method: 'put',
      data: {
        name,
        phone,
      },
    })
  }

  const sendVerifyEmail = async () => {
    sendVerifyEmailCall({
      headers: {
        account_id: accID,
        [SECURITY_SESSION_ID]: sessionId,
      },
      data: {
        email_id: email,
      },
    })
  }

  useEffect(() => {
    if (sendVerifyEmailRes) {
      if (sendVerifyEmailRes.success) {
        raiseToast('success', 'Mail for Email Verification sent please check your mailbox')
      } else {
        raiseToast('error', sendVerifyEmailRes.message)
      }
    }
  }, [sendVerifyEmailRes])

  useEffect(() => {
    const sessionId = localStorage.getItem(SECURITY_SESSION_ID)
    if (sessionId) {
      validateSessionCall()
    }
  }, [])

  useEffect(() => {
    if (validateSession && validateSession.success) {
      setUser(validateSession.data.user)
      setUserSession(validateSession.data.user_session)
    }
  }, [validateSession])

  useEffect(() => {
    setAccID(userSession?.session_data?.account_id)
    setSessionId(userSession?.session_id)
  }, [user, userSession])

  useEffect(() => {
    if (updateUserRes && updateUserRes.success) {
      raiseToast('success', t('profile_success_toast'))
    }
  }, [updateUserRes])

  useEffect(() => {
    setLoader(updateUserLoading || sendVerifyEmailLoading)
  }, [updateUserLoading, sendVerifyEmailLoading])

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }
  return (
    <div className="md:mb-0 mb-12 md:py-8">
      <div
        // style={
        //   isBrowser
        //     ? {
        //         backgroundImage: `url(${OTTlogo})`,
        //         backgroundPositionX: '-20%',
        //         backgroundSize: 'contain',
        //       }
        //     : {}
        // }
        className="bg-no-repeat md:bg-left md:bg-contain bg-cover bg-right flex justify-center"
      >
        <div
          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
          className="md:my-5 my-2  xl:w-2/5 w-8/12"
        >
          <div className="flex justify-center">
            <p className="md:text-2xl text-lg font-bold mt-10">{t('manage_profile')}</p>
          </div>

          <div className="py-2 md:text-xl">
            <div>
              <p>{`${t('name')} *`}</p>
              <input
                style={{
                  backgroundColor: isCustomTemplate ? theme.c10 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                type="text"
                name="name"
                value={name}
                onChange={(e) => handleChange(e, setName)}
                className=" border lg:text-lg rounded block w-full py-1 p-2.5 pl-2 focus:outline-none"
              />
            </div>
            <div className="mt-6">
              <div className="flex justify-between items-center">
                <p>{`${t('email')} *`}</p>
                {accountObj?.od_resource_allowances?.verify_email && (
                  <>
                    <p
                      className={`mr-auto ml-2 inline text-xs px-2 py-1 rounded-xl ${
                        user.is_email_verified ? 'bg-green-600' : 'bg-red-600'
                      }`}
                    >
                      {user.is_email_verified ? 'VERIFIED' : 'NOT VERIFIED'}
                    </p>
                    {!user.is_email_verified && (
                      <p
                        className={`cursor-pointer text-sm italic text-[#5fc1f2] `}
                        onClick={sendVerifyEmail}
                      >
                        {t('resend_verification_email')}
                      </p>
                    )}
                  </>
                )}
              </div>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => handleChange(e, setEmail)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c10 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className="bg-gray-900 border border-gray-600 text-white lg:text-lg rounded block w-full py-1 p-2.5 pl-2 focus:outline-none"
                disabled
              />
            </div>
            <div className="flex ">
              <p className="text-sm ">{t(`email_change`)}</p>
            </div>
            <div className="mt-6">
              <p>{`${t('phone')}`}</p>
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => handleChange(e, setPhone)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c10 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className=" border lg:text-lg rounded block w-full py-1 p-2.5 pl-2 focus:outline-none"
              />
            </div>
            <div className="mt-6">
              <p>{`${t('password')} *`}</p>
              <div
                //   style={{ border: "2px solid white" }}
                className="relative"
              >
                <input
                  type="password"
                  name="password"
                  placeholder="******** "
                  // value={phone}
                  // onChange={(e) => handleChange(e, setPhone)}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c10 : 'rgb(17,24,39)',
                    borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                    color: isCustomTemplate ? theme.c4 : '#fff',
                  }}
                  className=" border lg:text-lg rounded block w-full py-1 p-2.5 pl-2"
                  disabled
                />
                <button className="absolute right-0 top-0 mt-3 mr-2 focus:outline-none">
                  <svg
                    className="text-whit h-4 w-4 fill-current focus:outline-none"
                    viewBox="0 0 96 96"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title />
                    <g>
                      <path d="M82.24,22.2438A5.999,5.999,0,1,0,73.7562,13.76L69.455,18.0612A41.15,41.15,0,0,0,48,12.0022c-22.1588,0-35.6814,15.7022-46.9893,32.67a5.9842,5.9842,0,0,0,0,6.6558,110.6522,110.6522,0,0,0,15.8105,19.367L13.76,73.7562A5.999,5.999,0,1,0,22.2438,82.24ZM13.2677,48C25.3256,30.7921,35.2742,24.0015,48,24.0015a29.3537,29.3537,0,0,1,12.6716,2.8431l-9.5927,9.5926A11.1546,11.1546,0,0,0,48,36.0007,12.0112,12.0112,0,0,0,36.0007,48a11.1589,11.1589,0,0,0,.4365,3.0789L25.3014,62.2147A88.0132,88.0132,0,0,1,13.2677,48Z" />
                      <path d="M94.9893,44.6721c-2.4234-3.6363-4.9809-7.1751-7.6709-10.5528l-8.5461,8.5461c1.3007,1.6669,2.6131,3.4128,3.96,5.3346C71.1761,64.4924,61.5307,71.3423,49.5475,71.89L38.5714,82.8663A42.5472,42.5472,0,0,0,48,83.9978c22.1588,0,35.6814-15.7022,46.9893-32.67A5.9842,5.9842,0,0,0,94.9893,44.6721Z" />
                    </g>
                  </svg>
                </button>
              </div>
              <div
                style={{ color: isCustomTemplate ? theme.c5 : '#3686d6' }}
                className="md:text-lg flex justify-end mt-2 italic hover:underline cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                <p>{t('change_password')}</p>
              </div>
            </div>
            <div className="flex justify-center md:mt-10 mt-8 ">
              <button
                className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-6 py-1 rounded-full focus:outline-none text-white"
                onClick={handleSave}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal ? <ChangePassword setShowModal={setShowModal} /> : null}
    </div>
  )
}

export default withTheme(ProfilePage)

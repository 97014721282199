import React, { useContext, useEffect, useState } from 'react'
import { BiX } from 'react-icons/bi'
import { getImage, raiseToast } from '../../utils/helper'
import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import useAxios from 'axios-hooks'
import { ANALYTICS_API } from '../../utils/apiUrls'
import StreamComments from '../../components/comments/streamComments'
import { LG_SIZE, SM_SIZE } from '../../utils/constants'
import { useLocation } from 'react-router-dom'
import CustomVideoJs from '../../components/player/customVideoJs'
import { ContentContext } from '../../context/content'

const Player = (props) => {
  let PLAYER_STARTED = false
  const { contentInfo, handleClose, contentWatchUrl, resumeTime } = props
  const [error, setError] = useState(false)
  const playerRef = React.useRef(null)
  const { accountObj } = useContext(AppContext)
  const { updateContentResumeTimestamp } = useContext(ContentContext)
  const [source, setSource] = useState('WEB')
  const randomVal = Math.floor(1000 + Math.random() * 9000)
  const trackingId = `${contentInfo?.content_id + Date.now() + randomVal}`
  const location = useLocation()

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setSource('WEB')
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setSource('TABLET')
    } else {
      setSource('MOBILE')
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  let liveComment = contentInfo?.live_comments_applicable

  const [{ error: analyticsError }, analyticsEventCall] = useAxios(
    {
      url: ANALYTICS_API,
      method: 'post',
    },
    { manual: true, autoCancel: false },
  )

  useEffect(() => {
    console.error(analyticsError)
  }, [analyticsError])

  useEffect(() => {
    if (error) {
      handleError()
    }
  }, [error])

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5],
    controls: false,
    responsive: true,
    liveui: true,
    poster: getImage(accountObj?.cf_domain_name, contentInfo?.poster),
    // fluid: true,
    userActions: {
      hotkeys: true,
    },
    fill: true,
    sources: [
      {
        src: contentInfo.video_type === 'LIVE' ? contentInfo.stream.link : contentWatchUrl,
        type: 'application/x-mpegURL',
      },
    ],
    plugins: {
      qualityLevels: {},
      hlsQualitySelector: {},
    },
    html5: {
      hls: {
        overrideNative: true,
      },
    },
  }

  const isIFrame = () => location.pathname.includes('embed')

  const handleError = () => {
    if (!playerRef.current) {
      return
    }
    // playerRef.current.suppressNotSupportedError(true);
  }
  const handlePlayerReady = (player) => {
    playerRef.current = player
    let timeInterval
    let previousTime = 0
    let currentTime = 0
    let position = 0
    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
    })

    player.on('loadedmetadata', async () => {
      if (resumeTime > 0) {
        player.currentTime(resumeTime)
      }

      await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_START',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: `${player.videoHeight()}`,
        },
      })

      let playerStartedRes = await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_PLAY',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: `${player.videoHeight()}`,
        },
      })

      playerStartedRes?.data?.success ? (PLAYER_STARTED = true) : (PLAYER_STARTED = false)

      timeInterval = setInterval(async () => {
        if (PLAYER_STARTED) {
          updateContentResumeTimestamp(contentInfo?.content_id, player.currentTime())
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PING',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: `${player.videoHeight()}`,
            },
          })
        }
      }, 30000)
    })

    player.on('play', async () => {
      if (PLAYER_STARTED && !player.seeking()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PLAY',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
    })

    player.on('pause', async () => {
      if (PLAYER_STARTED && !player.seeking() && player.currentTime() < player.duration()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
      // clearInterval(timeInterval)
      // console.log('player is paused', player.textTracks())
    })

    player.on('timeupdate', function () {
      previousTime = currentTime
      currentTime = Math.floor(player.currentTime())

      // save 'position' so long as time is moving forward with each update
      if (previousTime < currentTime) {
        position = previousTime
        previousTime = currentTime
      }
    })

    player.on('seeked', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${position}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })

        if (!player.paused()) {
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PLAY',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: `${player.videoHeight()}`,
            },
          })
        }
      }
    })

    player.on('dispose', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_CLOSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }

      clearInterval(timeInterval)
    })

    player.on('ended', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_END',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: `${player.videoHeight()}`,
          },
        })
      }
      clearInterval(timeInterval)
    })

    player.on('error', () => {
      // console.log(player.error());
      let error = player.error()
      raiseToast('error', error.message)
      setError(true)
    })
  }

  return (
    <div className="flex flex-col xl:flex-row overflow-hidden">
      <div
        style={{ maxWidth: '100%' }}
        className={`player-container ${
          liveComment
            ? 'w-full xl:w-3/4 h-[50vh] xl:h-[80vh] md:mr-5 md:ml-6'
            : `w-full ${isIFrame() ? 'h-screen' : 'h-[80vh]'}`
        } `}
      >
        <div
          style={{
            zIndex: 40,
            backgroundColor: 'rgb(27, 36, 47,0.30)',
          }}
          className={` overlay grid grid-cols-12 items-center md:px-4 h-12 md:h-20  ${
            liveComment ? 'w-full xl:w-[72.5%] pr-6' : 'w-full'
          }`}
        >
          <div className="text-white col-span-2">
            {!isEmpty(contentInfo?.content_rating) ? (
              <div className="mx-2">
                <div className="flex items-center justify-start">
                  {contentInfo?.content_rating?.icon?.s3_key && (
                    <div className="mr-1">
                      <img
                        src={getImage(
                          accountObj?.cf_domain_name,
                          contentInfo?.content_rating?.icon?.s3_key,
                        )}
                        className="w-4 h-4 md:w-10 md:h-10 mx-1"
                        alt="Rating"
                      />
                    </div>
                  )}
                  <p className="text-xs md:text-lg font-semibold line-clamp-1">
                    {contentInfo?.content_rating?.name}
                  </p>
                </div>
                <div className="line-clamp-2 text-xs md:text-base">
                  {contentInfo?.content_rating?.description}
                </div>
              </div>
            ) : null}
          </div>
          <div className="text-white font-semibold text-sm md:text-xl col-span-8 text-center">
            {contentInfo?.title}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '50px',
            }}
            className={`col-span-2 ${liveComment && 'mr-6'}`}
          >
            <BiX
              color={'white'}
              className={'w-6 h-6 md:w-10 md:h-10 cursor-pointer'}
              onClick={handleClose}
            />
          </div>
        </div>
        <CustomVideoJs
          options={videoJsOptions}
          onReady={handlePlayerReady}
          controlBar={true}
          adSetData={[]}
        />
      </div>
      {liveComment && !isIFrame() && (
        <div className="w-full xl:w-1/4 md:mr-5">
          <StreamComments />
        </div>
      )}
    </div>
  )
}

export default Player

export default {
  name: 'DARK_INFRA_RED',
  c1: '#2A1B3D',
  c2: '#EEEEEE',
  c3: '#F64C72',
  c4: '#FFFFFF',
  c5: '#FF6700',
  c6: '#3D2A85',
  c7: '#FFFFFF',
  c8: '#f5345f',
  c9: '#583cbf',
  c10: '#452f60',
  c11: '#7B2639',
}

import React from 'react'

const PlayPause4 = ({
  isPlaying = false,
  primaryColor = '#323232',
  secondaryColor = '#323232',
}) => {
  return (
    <div className="w-full h-full">
      {isPlaying ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 22.295 17.399"
        >
          <path
            d="M3.323 23.825h16.36c1.975 0 2.968-.983 2.968-2.93V9.356c0-1.947-.992-2.93-2.968-2.93H3.323c-1.966 0-2.968.974-2.968 2.93V20.9c0 1.952 1.002 2.925 2.968 2.925zm.028-1.525a1.325 1.325 0 0 1-1.474-1.48V9.431a1.325 1.325 0 0 1 1.474-1.484h16.3a1.329 1.329 0 0 1 1.474 1.484V20.82a1.329 1.329 0 0 1-1.471 1.48zm5.756-3.648h.548a.817.817 0 0 0 .822-.888v-5.28a.82.82 0 0 0-.822-.9h-.548a.831.831 0 0 0-.869.9v5.283a.828.828 0 0 0 .869.888zm4.291 0h.548a.817.817 0 0 0 .822-.888v-5.28a.82.82 0 0 0-.822-.9H13.4a.831.831 0 0 0-.87.9v5.283a.828.828 0 0 0 .87.888z"
            transform="translate(-.355 -6.426)"
            style={{ fill: primaryColor }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-full h-full"
          viewBox="0 0 22.105 17.251"
        >
          <path
            d="M3.3 23.677h16.22c1.958 0 2.942-.975 2.942-2.9V9.331c0-1.93-.984-2.9-2.942-2.9H3.3c-1.949 0-2.942.965-2.942 2.9v11.441c-.003 1.94.991 2.905 2.942 2.905zm.028-1.509A1.314 1.314 0 0 1 1.864 20.7V9.406a1.314 1.314 0 0 1 1.462-1.472H19.49a1.318 1.318 0 0 1 1.462 1.471V20.7a1.318 1.318 0 0 1-1.462 1.471zM8.6 18.111c0 .431.478.618.89.375l5.01-2.961a.549.549 0 0 0 0-.946L9.492 11.6c-.384-.225-.89-.047-.89.375z"
            transform="translate(-.355 -6.426)"
            style={{ fill: primaryColor }}
          />
        </svg>
      )}
    </div>
  )
}

export default PlayPause4

import React from 'react'

const Language5 = ({ color = '#0c0c0c' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 14.364 14.364"
    >
      <g data-name={18101532361594444000}>
        <g data-name="Group 1186">
          <path
            data-name="Path 1164"
            d="M19.784 22.664H6.18a.359.359 0 0 1-.38-.38V8.68a.359.359 0 0 1 .38-.38h13.6a.359.359 0 0 1 .38.38v13.6a.359.359 0 0 1-.376.384zM6.56 21.9H19.4V9.06H6.56z"
            transform="translate(-5.8 -8.3)"
            style={{ fill: color }}
          />
          <g data-name="Group 1185">
            <path
              data-name="Path 1165"
              d="m5.852 8.094-.8 2.356h-.986l2.546-7.524H7.79l2.584 7.524H9.348l-.8-2.356zm2.47-.76L7.6 5.168c-.152-.494-.266-.95-.38-1.368h-.038c-.114.456-.228.912-.38 1.368L6.08 7.334z"
              style={{ fill: color }}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Language5

import React, { useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import EditMenu from '../editMenu'
import DeleteModal from '../../DeleteModal/DeleteModal'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const AsymmetricFlow = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { customNavigate, getTemplateImage, EditIcon } = useTemplateHook()
  const { data, id, config } = componentData
  const [hoverState, setHoverState] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedCardIndex, setSelectedCardIndex] = useState('')

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const deleteCard = (index) => {
    setSelectedCardIndex(index)
    setDeleteModal(true)
  }

  const handleModal = (cardInfo, type, cardIndex, isEdit) => {
    setModalState({
      keyID: id,
      modalData: cardInfo,
      type: type,
      cardIndex: cardIndex,
      isEdit,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const onDeleteCard = () => {
    let tempdata = data
    tempdata?.card_data.splice(selectedCardIndex, 1)
    modifyData(id, tempdata)
    setDeleteModal(false)
    setSelectedCardIndex('')
  }

  return (
    <div
      style={{ background: theme.c1 }}
      className={` w-full py-[1rem] px-1 relative  ${
        isEdit && hoverState && 'component-hover-class'
      }`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="flex justify-center">
        <h1
          style={{ color: theme.c2 }}
          className={` px-14 w-fit uppercase mb-2 mt-4 text-2xl sm:text-4xl font-bold relative ${
            isEdit && hoverState === 'HEADING' && 'component-hover-class'
          }`}
          onMouseOver={() => handleHoverItems('HEADING')}
          onMouseLeave={() => handleHoverItems('TOP')}
        >
          {data?.title}
          {hoverState === 'HEADING' && (
            <div
              className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 -right-3 flex justify-center items-center cursor-pointer"
              onClick={() => handleModal({ title: data?.title }, 'HEADING', 0, true)}
            >
              <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
            </div>
          )}
        </h1>
      </div>
      {/* <div className="my-1 flex justify-start w-full max-w-full h-auto"> */}
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {data?.card_data &&
          data?.card_data.map((cardInfo, index) => (
            <Card
              key={index}
              itemId={`index${index}`}
              index={index}
              cardInfo={cardInfo}
              config={config}
              handleRedirect={handleRedirect}
              isEdit={isEdit}
              hoverState={hoverState}
              theme={theme}
              handleHoverItems={handleHoverItems}
              handleModal={handleModal}
              deleteCard={deleteCard}
              getTemplateImage={getTemplateImage}
            />
          ))}
      </ScrollMenu>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}

      {deleteModal && (
        <DeleteModal
          openModal={deleteModal}
          title={`Are you sure you want to delete this Card ?`}
          confirmButtonText={`Yes`}
          onCancel={() => {
            setSelectedCardIndex('')
            setDeleteModal(false)
          }}
          onConfirm={onDeleteCard}
        />
      )}
    </div>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <button
      className={`${
        isFirstItemVisible ? 'opacity-0' : 'opacity-1'
      } absolute hidden lg:block left-0 top-1/3 z-10 border-0 bg-transparent`}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <img
        src="https://cdn.zeplin.io/6147943030515434bb60ac8f/assets/e3e361a8-053a-42b8-b192-59849f9c5285.svg"
        alt="prev"
        className="w-[54px] h-[54px] "
      />
    </button>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  return (
    <button
      className={`${
        isLastItemVisible ? 'opacity-0' : 'opacity-1'
      } absolute hidden lg:block  right-0 top-1/3 z-10 border-0 bg-transparent`}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <img
        src="https://cdn.zeplin.io/6147943030515434bb60ac8f/assets/46c57101-d722-4d1a-aa13-3f60e7fd5442.svg"
        alt="next"
        className="w-[54px] h-[54px] "
      />
    </button>
  )
}

function Card({
  index,
  cardInfo,
  config,
  handleRedirect,
  isEdit,
  hoverState,
  theme,
  handleHoverItems,
  handleModal,
  deleteCard,
  getTemplateImage,
}) {
  const { PlusIcon, EditIcon, TrashIcon, VideoPlaceholder } = useTemplateHook()
  return (
    <div
      key={index}
      className={`flex cursor-pointer ${!isEdit && index > 0 ? 'ml-3' : ''}`}
      onClick={() => handleRedirect(cardInfo.link)}
      tabIndex={0}
    >
      <div
        className={`mx-3 ${config[cardInfo.size].styleClass} ${
          isEdit && hoverState === `CARD${index}` && 'component-hover-class'
        } `}
        onMouseOver={() => handleHoverItems(`CARD${index}`)}
        onMouseLeave={() => handleHoverItems('TOP')}
      >
        {hoverState === `CARD${index}` && (
          <div className={`relative ${config[cardInfo.size].styleClass}`}>
            <div className={`mt-2 absolute w-full top-3 flex justify-center items-center`}>
              <span className={`text-[#2172eb] text-sm`}>
                {cardInfo.size === 'sm'
                  ? '382px X 597px'
                  : cardInfo.size === 'md'
                  ? '597px X 597px'
                  : '764px X 597px'}
              </span>
            </div>

            <div
              className={`absolute w-full top-3 ${
                cardInfo.size === 'sm' ? 'right-0' : 'right-4'
              } flex justify-end items-center`}
            >
              <div
                className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                onClick={() => handleModal(cardInfo, 'CARD', index, true)}
              >
                <img src={EditIcon} className="w-[17px] h-[17px]" alt="editicon" />
              </div>
              <div
                className="mx-[8px] h-[32px] w-[32px] rounded-full bg-[#2172eb] flex justify-center items-center cursor-pointer"
                onClick={() => deleteCard(index)}
              >
                <img src={TrashIcon} className="w-[17px] h-[17px]" alt="trashicon" />
              </div>
            </div>
          </div>
        )}
        <CustomImage
          imageUrl={getTemplateImage(cardInfo.image_s3key)}
          imgClass="sm:h-[477px] h-[356px] sm:mb-5 max-w-none w-full"
          loaderClass="sm:h-[477px] h-[356px] sm:mb-5"
          loaderStyle={{ background: '#5A5A5A50' }}
          imgAlt={'card img'}
          errorPlaceholder={VideoPlaceholder}
        />
        <p
          style={{ color: `${theme.c3}`, fontWeight: 500 }}
          className={`font-semibold text-lg sm:text-2xl mb-2`}
        >
          {cardInfo.heading}
        </p>
        <span
          style={{ color: `${theme.c5}`, borderBottom: `1.5px solid ${theme.c2}` }}
          className={` 2xl:text-base xl:text-sm text-xs font-normal py-0 sm:mb-5 inline-block`}
        >
          {cardInfo.sub_heading}
        </span>
        <p style={{ color: theme.c4 }} className={`text-sm sm:text-base`}>
          {cardInfo.description}
        </p>
      </div>
      <div
        className={`${
          isEdit ? '' : 'hidden'
        } rosemary-toptrending-add-button d-flex justify-content-center align-items-center h-[477px] py-10`}
      >
        <div style={{ border: '1px solid #2172eb' }} className="relative h-full">
          <div
            onClick={() => handleModal(cardInfo, 'CARD', index, false)}
            className="absolute h-[32px] w-[32px] rounded-full bg-[#2172eb] top-[45%] -left-4 flex justify-center items-center"
          >
            <img src={PlusIcon} className="w-[17px] h-[17px]" alt="plus icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(AsymmetricFlow)

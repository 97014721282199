import React from 'react'

const FullScreen6 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 11.96 11.96"
        >
          <path
            data-name="Path 1088"
            d="M11 17v-3.5h0a.5.5 0 0 0-.5-.5H7"
            transform="translate(-6.4 -5.64)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
          <path
            data-name="Path 1089"
            d="M13 7v3.5h0a.5.5 0 0 0 .5.5H17"
            transform="translate(-5.64 -6.4)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 11.959 11.959"
        >
          <path
            data-name="Path 1090"
            d="M8 12v4.707h0a.672.672 0 0 0 .672.672h4.707"
            transform="translate(-7.4 -6.021)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
          <path
            data-name="Path 1091"
            d="M17.379 13.379V8.672h0A.672.672 0 0 0 16.707 8H12"
            transform="translate(-6.021 -7.4)"
            style={{
              fill: 'none',
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.2px',
            }}
          />
        </svg>
      )}
    </div>
  )
}

export default FullScreen6

import React, { useEffect, useState } from 'react'
// function component
const AnimatedCard = ({ animation, digit }) => {
  return (
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  )
}

// function component
const StaticCard = ({ position, digit }) => {
  return (
    <div className={position}>
      <span>{digit}</span>
    </div>
  )
}

// function component
const FlipUnitContainer = ({ digit, shuffle, unit }) => {
  // assign digit values
  let currentDigit = digit
  let previousDigit = digit + 1

  // to prevent a negative value
  if (unit !== 'hours') {
    previousDigit = previousDigit === -1 ? 59 : previousDigit
  } else {
    previousDigit = previousDigit === -1 ? 24 : previousDigit
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`
  }

  // shuffle digits
  const digit1 = shuffle ? previousDigit : currentDigit
  const digit2 = !shuffle ? previousDigit : currentDigit

  // shuffle animations
  const animation1 = shuffle ? 'fold' : 'unfold'
  const animation2 = !shuffle ? 'fold' : 'unfold'

  return (
    <div className={'flipUnitContainer'}>
      <StaticCard position={'upperCard'} digit={currentDigit} />
      <StaticCard position={'lowerCard'} digit={previousDigit} />
      <AnimatedCard digit={digit1} animation={animation1} />
      <AnimatedCard digit={digit2} animation={animation2} />
      <p
        className={'text-center text-white text-xs md:text-sm'}
        style={{ textTransform: 'uppercase' }}
      >
        <b>{unit}</b>
      </p>
    </div>
  )
}

const FlipClock = (props) => {
  const [hourVal, setHourVal] = useState(0)
  const [minVal, setMinVal] = useState(0)
  const [secVal, setSecVal] = useState(0)
  const [shuffleHours, setShuffleHours] = useState(false)
  const [shuffleMins, setShuffleMins] = useState(false)
  const [shuffleSecs, setShuffleSecs] = useState(false)

  const { hours, minutes, seconds, days } = props

  useEffect(() => {
    if (secVal !== seconds) {
      setShuffleSecs((shuffleSecs) => !shuffleSecs)
      setSecVal(seconds)
    }
    if (minutes !== minVal) {
      setShuffleMins((shuffleMins) => !shuffleMins)
      setMinVal(minutes)
    }
    if (hours !== hourVal) {
      setShuffleHours((shuffleHours) => !shuffleHours)
      setHourVal(hours)
    }
  }, [hours, minutes, seconds])
  return (
    <div className={'flipClock'}>
      {days !== 0 && <FlipUnitContainer unit={'days'} digit={days} shuffle={false} />}
      <FlipUnitContainer unit={'hours'} digit={hourVal} shuffle={shuffleHours} />
      <FlipUnitContainer unit={'minutes'} digit={minVal} shuffle={shuffleMins} />
      <FlipUnitContainer unit={'seconds'} digit={secVal} shuffle={shuffleSecs} />
    </div>
  )
}
// class component
// class FlipClock extends React.Component {
//
//     // constructor(props) {
//     //     super(props);
//     //     this.state = {
//     //         hours: 0,
//     //         hoursShuffle: true,
//     //         minutes: 0,
//     //         minutesShuffle: true,
//     //         seconds: 0,
//     //         secondsShuffle: true
//     //     };
//     // }
//
//     // componentDidMount() {
//     //     this.timerID = setInterval(
//     //         () => this.updateTime(),
//     //         50
//     //     );
//     // }
//     //
//     // componentWillUnmount() {
//     //     clearInterval(this.timerID);
//     // }
//
//     // updateTime() {
//     //     // get new date
//     //     const time = new Date;
//     //     // set time units
//     //     const hours = time.getHours();
//     //     const minutes = time.getMinutes();
//     //     const seconds = time.getSeconds();
//     //     // on hour chanage, update hours and shuffle state
//     //     if( hours !== this.state.hours) {
//     //         const hoursShuffle = !this.state.hoursShuffle;
//     //         this.setState({
//     //             hours,
//     //             hoursShuffle
//     //         });
//     //     }
//     //     // on minute chanage, update minutes and shuffle state
//     //     if( minutes !== this.state.minutes) {
//     //         const minutesShuffle = !this.state.minutesShuffle;
//     //         this.setState({
//     //             minutes,
//     //             minutesShuffle
//     //         });
//     //     }
//     //     // on second chanage, update seconds and shuffle state
//     //     if( seconds !== this.state.seconds) {
//     //         const secondsShuffle = !this.state.secondsShuffle;
//     //         this.setState({
//     //             seconds,
//     //             secondsShuffle
//     //         });
//     //     }
//     // }
//
//     render() {
//
//         // state object destructuring
//         // const {
//         //     hours,
//         //     minutes,
//         //     seconds,
//         //     hoursShuffle,
//         //     minutesShuffle,
//         //     secondsShuffle
//         // } = this.state;
//
//
//     }
// }

export default FlipClock

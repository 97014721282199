import React from 'react'

const Resolution6 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.079 16.079"
    >
      <path
        d="M1 3.193a.731.731 0 0 1 .731-.731H9.04a.731.731 0 1 1 0 1.462H1.731A.731.731 0 0 1 1 3.193zm15.348-.731h-3.654v-.731a.731.731 0 1 0-1.462 0v2.923a.731.731 0 1 0 1.462 0v-.731h3.654a.731.731 0 1 0 0-1.462zM1.731 9.77h4.385a.731.731 0 0 0 0-1.462H1.731a.731.731 0 0 0 0 1.462zm14.617-1.461H9.77v-.731a.731.731 0 1 0-1.462 0V10.5a.731.731 0 1 0 1.462 0v-.73h6.578a.731.731 0 1 0 0-1.462zM1.731 15.617h9.5a.731.731 0 1 0 0-1.462h-9.5a.731.731 0 0 0 0 1.462zm14.617-1.462h-1.462v-.731a.731.731 0 1 0-1.462 0v2.923a.731.731 0 1 0 1.462 0v-.731h1.462a.731.731 0 1 0 0-1.462z"
        transform="translate(-1 -1)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Resolution6

import React from 'react'

const ShareIcon = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <g id="Group_775" data-name="Group 775" transform="translate(-553 -262)">
        <g
          id="_7935ec95c421cee6d86eb22ecd12b5bb"
          data-name="7935ec95c421cee6d86eb22ecd12b5bb"
          transform="translate(554.705 270.498)"
        >
          <path
            id="Path_879"
            data-name="Path 879"
            fill={color}
            d="M22.369,14.063a2.885,2.885,0,0,1-5.771,0c0-.052,0-.1.008-.155L10.894,11a2.884,2.884,0,1,1,.157-4.513l5.561-2.835c-.008-.086-.013-.173-.013-.261a2.889,2.889,0,1,1,1.024,2.2L12.053,8.429c.007.082.012.165.012.25a2.932,2.932,0,0,1-.037.439l5.516,2.812a2.882,2.882,0,0,1,4.824,2.133Z"
          />
        </g>
        <g id="Complete" transform="translate(552 261)">
          <g id="Circle" transform="translate(2 2)">
            <circle id="Circle-2" data-name="Circle" style={pathStyle} cx="16" cy="16" r="16" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShareIcon

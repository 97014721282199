import React from 'react'

const Playback3 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 17.618 13.657"
    >
      <path
        d="M10.809 5A8.809 8.809 0 0 0 3.18 18.214a.884.884 0 1 0 1.533-.881 6.977 6.977 0 0 1-.951-3.524 7.047 7.047 0 1 1 13.152 3.524.88.88 0 1 0 1.524.881A8.809 8.809 0 0 0 10.809 5zm2.5 5.074-1.365 1.357a2.563 2.563 0 0 0-1.136-.264 2.643 2.643 0 1 0 2.643 2.643 2.555 2.555 0 0 0-.264-1.128l1.365-1.357a.881.881 0 0 0-1.242-1.251zm-2.5 4.616a.881.881 0 1 1 .617-1.515.885.885 0 0 1-.617 1.515z"
        transform="translate(-2 -5)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Playback3

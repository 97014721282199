import React from 'react'

const Setting4 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 17.025 18.708">
      <g data-name="setting-svgrepo-com (3)">
        <path
          data-name="Path 1117"
          d="M3 8.147v4.976c0 1.828 0 1.828 1.725 2.993l4.744 2.743a2.85 2.85 0 0 0 2.587 0l4.744-2.742c1.725-1.164 1.725-1.164 1.725-2.984V8.147c0-1.82 0-1.82-1.725-2.984l-4.744-2.742a2.85 2.85 0 0 0-2.587 0L4.725 5.163C3 6.328 3 6.328 3 8.147z"
          transform="translate(-2.25 -1.286)"
          style={{
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
        />
        <path
          data-name="Path 1118"
          d="M11.587 14.175A2.587 2.587 0 1 0 9 11.587a2.587 2.587 0 0 0 2.587 2.588z"
          transform="translate(-3.075 -2.234)"
          style={{
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
        />
      </g>
    </svg>
  )
}

export default Setting4

import React from 'react'

const Pip1 = ({ color = '#323232' }) => {
  return (
    <svg
      data-name="svgviewer-output (1)"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-auto"
      viewBox="0 0 20 20"
    >
      <rect
        data-name="Rectangle 980"
        width={9}
        height={6}
        rx=".5"
        transform="translate(10 11)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 372"
        d="M9.88 9.737v2.254l-2.7-2.7-.889.889 2.7 2.7H6.737v1.257h4.4v-4.4z"
        transform="translate(-2.337 -3.451)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 373"
        d="M8.915 15.686H3.257A1.259 1.259 0 0 1 2 14.429V6.257A1.258 1.258 0 0 1 3.257 5h13.829a1.259 1.259 0 0 1 1.257 1.257v5.657h-1.257V6.257H3.257v8.172h5.658z"
        transform="translate(-.743 -1.857)"
        style={{ fill: color }}
      />
      <path data-name="<Transparent Rectangle>" style={{ fill: 'none' }} d="M0 0h20v20H0z" />
    </svg>
  )
}

export default Pip1

import React, { useContext, useEffect } from 'react'
import { getImage } from '../../utils/helper'
import { useNavigate } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { DETAIL_CONTENT } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import CustomImage from '../customImage/customImage'
import BannerPlaceholder from '../../assets/banner.jpg'

const NewCarousel = (props) => {
  const { data } = props
  const navigate = useNavigate()
  const { setLoader, accountObj, isBrowser } = useContext(AppContext)

  const getBannerImage = (slide) => getImage(accountObj?.cf_domain_name, slide.image_s3_key)

  const [{ data: contentDetail, loading: contentLoading }, callDetail] = useAxios(
    {
      url: `${DETAIL_CONTENT}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      if (contentDetail.data.video_type === 'PLAYLIST') {
        navigate(`/playlist/${contentDetail.data.content_id}`)
        return
      }
      if (contentDetail.data.video_type === 'MPVP') {
        navigate(`/parent-content/${contentDetail.data.content_id}`)
      } else {
        navigate(`/content/${contentDetail.data.content_id}`)
      }
    }
  }, [contentDetail])

  useEffect(() => {
    setLoader(contentLoading)
  }, [contentLoading])

  const handleClick = (slideNo) => {
    const slide = data[slideNo]
    if (slide.object_type === 'EXTERNAL_URL') {
      if (slide.url.includes('http')) {
        window.open(slide.url, '_blank')
      } else {
        window.open('https://' + slide.url, '_blank')
      }
    } else if (slide.object_type === 'CONTENT') {
      callDetail({
        url: `${DETAIL_CONTENT}${slide.object_id}`,
        method: 'get',
      })
    }
  }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      centerMode={isBrowser}
      centerSlidePercentage={70}
      showArrows={isBrowser}
      infiniteLoop={true}
      autoPlay={true}
      autoFocus={true}
      useKeyboardArrows={true}
      // selectedItem={parseInt(data.length / 2) ? data.length / 2 < data.length : 1}
      emulateTouch={true}
      onClickItem={handleClick}
    >
      {data.map((slide, index) => (
        <div
          key={index}
          className={'items-center bg-black h-full justify-center flex'}
          style={{ borderRadius: '0.62rem', marginRight: '2rem', marginLeft: '2rem' }}
        >
          <CustomImage
            imageUrl={getBannerImage(slide)}
            errorPlaceholder={BannerPlaceholder}
            imgClass="image"
            loaderClass="h-[7.5rem] md:h-44 xl:h-[18.7rem] 2xl:h-[26rem]"
            imgAlt={index}
          />
        </div>
      ))}
    </Carousel>
  )
}

export default NewCarousel

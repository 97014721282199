import React from 'react'

const Backward4 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.622"
      height="12.906"
      viewBox="0 0 22.622 12.906"
    >
      <g data-name="Group 1158">
        <path
          d="M20.839 11.765a.835.835 0 0 0 1.648.262 10.488 10.488 0 0 0 .134-1.652A10.393 10.393 0 0 0 13.892.133 10.5 10.5 0 0 0 12.239 0 10.382 10.382 0 0 0 1.868 9.918l-.428-.447a.835.835 0 0 0-1.21 1.151l1.876 1.964a.832.832 0 0 0 .194.152.836.836 0 0 0 1.121-.105l1.832-2.005A.836.836 0 0 0 4.016 9.5l-.477.522a8.706 8.706 0 0 1 8.7-8.349 8.876 8.876 0 0 1 1.392.106 8.718 8.718 0 0 1 7.316 8.593 8.862 8.862 0 0 1-.108 1.39z"
          style={{ fill: color }}
        />
        <path
          data-name="skip-10-seconds-icon"
          d="M12.718 12.238a2.7 2.7 0 0 1-.371-.966 7.72 7.72 0 0 1-.1-1.379 8.825 8.825 0 0 1 .1-1.467 2.691 2.691 0 0 1 .371-.966 1.533 1.533 0 0 1 .769-.611 3.6 3.6 0 0 1 1.272-.192 3.556 3.556 0 0 1 1.267.192 1.561 1.561 0 0 1 .77.611 2.586 2.586 0 0 1 .371.966 9.22 9.22 0 0 1 .1 1.472 7.981 7.981 0 0 1-.1 1.379 2.591 2.591 0 0 1-.371.961 1.56 1.56 0 0 1-.77.61 3.564 3.564 0 0 1-1.267.192 3.606 3.606 0 0 1-1.272-.192 1.532 1.532 0 0 1-.769-.61zm1.373-3.525v2.78h.394a2.414 2.414 0 0 0 .763-.091c.112-.061.169-.2.169-.418V8.2h-.441a2.315 2.315 0 0 0-.735.086c-.1.057-.15.2-.15.423zM11.2 6.657v6.334H9.072v-4l-1.295.529-.563-1.556 2.2-1.307H11.2z"
          transform="translate(0 -.204)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default Backward4

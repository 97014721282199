import React from 'react'

const Next4 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.87" height="17.87" viewBox="0 0 17.87 17.87">
      <path
        d="M9.935 18.87A8.935 8.935 0 1 0 1 9.935a8.935 8.935 0 0 0 8.935 8.935zm0-16.246a7.311 7.311 0 1 1-7.31 7.311 7.311 7.311 0 0 1 7.31-7.31zm1.625 7.311-4.874 3.249v-6.5zM14 13.184h-1.628v-6.5H14z"
        transform="translate(-1 -1)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Next4

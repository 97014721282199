import React, { useContext } from 'react'
import { AppContext } from '../../context/app'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { Twitter, Facebook, Mail, Whatsapp, Linkedin, Pinterest } from 'react-social-sharing'
import facebookLogo from '../../assets/facebook.svg'
import CopyIcon from '../../assets/copy-icon.svg'

const ShareModal = ({ theme, onClose, link, detailObj = {} }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(link)
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <>
      <div
        style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : '#0a0a0a90' }}
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none `}
      >
        <div className={`relative my-6 w-11/12 md:w-9/12 lg:w-1/2 xl:w-2/5  2xl:w-[36%]`}>
          <div
            style={{
              background: isCustomTemplate ? theme.c10 : '#1b242f',
              fontFamily: 'Arial',
              opacity: '0.96',
              boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.90)',
              border: '1px solid white',
              borderRadius: '4px',
            }}
            className="shadow-lg relative flex flex-col p-1 md:p-2"
          >
            <div className="relative w-full">
              <button
                className="absolute right-0 p-1 float-right xl:text-3xl leading-none font-semibold"
                onClick={onClose}
              >
                <span
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className={` h-6 w-6 text-xl block outline-none focus:outline-none`}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="flex px-2 md:px-10 mt-4">
              <h3 style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={``}>
                {t('share')}
              </h3>
            </div>
            <div className="w-full mt-4 md:mt-10 flex justify-center">
              <div className="flex justify-between  md:w-2/3">
                <Facebook buttonComponent={facebookLogo} small link={link} />
                <Twitter small link={link} />
                <Mail solid small link={link} />
                <Whatsapp small link={link} />
                <Linkedin solid small link={link} />
                <Pinterest small link={link} />
              </div>
            </div>
            <div className="pb-10 relative mt-4 md:mt-4 px-2 md:px-10 flex-auto">
              <h3 style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className={``}>
                {t('page_link')}
              </h3>
              <div className="relative flex justify-center ">
                <input
                  type="text"
                  value={link}
                  style={{
                    backgroundColor: isCustomTemplate ? theme.c7 : 'white',
                    borderColor: isCustomTemplate ? theme.c5 : '#707070',
                    color: '#1a1a1a',
                  }}
                  readOnly
                  className="mt-1 border text-sm rounded block w-full p-2 pl-2 focus:outline-none line-clamp-1"
                />
                <div
                  style={{ borderLeft: '2px solid #c0c0c0', height: '75%', background: 'white' }}
                  className="top-0 right-0 mt-2 absolute flex justify-center items-center"
                >
                  <img
                    className="cursor-pointer mx-3 "
                    alt="CopyIcon"
                    onClick={handleCopyClick}
                    src={CopyIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(ShareModal)

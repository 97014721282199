import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const CancelSubscriptionModal = ({ onCancel, cancelCallback, theme }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  return (
    <div
      style={{ backgroundColor: isCustomTemplate ? `${theme.c1}90` : 'rgb(17 ,24, 39,0.9)' }}
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-90"
    >
      <div className={`relative w-[94%] lg:w-[701px] my-2 mx-auto`}>
        <div
          style={{
            backgroundImage: 'linear-gradient(to right, #000 -14%, #00224a 55%)',

            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.60)',
          }}
          className="shadow-lg relative flex flex-col md:w-full p-5 rounded-xl"
        >
          <div
            style={{
              color: '#fff',
            }}
            className=" justify-center md:text-lg p-5"
          >
            <div
              style={{ color: '#fff', marginTop: '25px' }}
              className="flex flex-col justify-start"
            >
              <div
                style={{ color: '#fff' }}
                className="md:text-2xl lg:text-[28px] text-lg font-semibold flex justify-center"
              >
                <h4 className="text-center">{t('cancel_plan')}</h4>
              </div>
              <p className="italic mt-2 text-sm text-center">{t(`once_subscription_cancel`)}</p>
            </div>
            <div className="mt-2 flex flex-col lg:flex-row justify-center md:text-lg font-bold">
              <button
                style={{ backgroundColor: '#bbfa33', color: '#0d0d0d' }}
                className="mx-5 mt-6 md:mt-10 xl:mt-12 px-4 py-1 text-white rounded focus:outline-none lg:w-[231px]"
                onClick={onCancel}
              >
                {t('no')}
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#bbfa33',
                  border: '1px solid #bbfa33',
                }}
                className="mx-5 mt-6 md:mt-10 xl:mt-12 px-4 py-1 rounded focus:outline-none lg:w-[231px]"
                onClick={cancelCallback}
              >
                {t('yes')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(CancelSubscriptionModal)

import React from 'react'

const Playback2 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 17.513 15.468"
    >
      <path
        data-name="Path 1149"
        d="M6.343 17.657a8 8 0 1 1 11.314 0"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
        }}
        transform="translate(-3.243 -3.25)"
      />
      <path
        data-name="Path 1150"
        d="m12 12 4-2"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
        }}
        transform="translate(-3.243 -3.25)"
      />
    </svg>
  )
}

export default Playback2

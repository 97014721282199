import React from 'react'

const Forward6 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.688"
      height="22.652"
      viewBox="0 0 20.688 22.652"
    >
      <path
        d="M14.438 24.7a10.421 10.421 0 0 0 10.344-10.34 10.394 10.394 0 0 0-4.33-8.367.835.835 0 0 0-1.258.183.9.9 0 0 0 .284 1.217 8.588 8.588 0 1 1-7-1.43v1.46c0 .72.5.913 1.045.517l3.247-2.271a.639.639 0 0 0 0-1.136l-3.235-2.272c-.558-.406-1.055-.213-1.055.517v1.43a10.341 10.341 0 0 0 1.957 20.5zm2.454-6.075c1.683 0 2.779-1.592 2.779-4.026 0-2.454-1.1-4.067-2.779-4.067S14.1 12.149 14.1 14.6c0 2.438 1.109 4.03 2.792 4.03zm-5.292-.117a.64.64 0 0 0 .659-.7v-6.379a.717.717 0 0 0-.73-.811 1.428 1.428 0 0 0-.832.335L9.3 11.9a.673.673 0 0 0-.335.588.609.609 0 0 0 .588.619.733.733 0 0 0 .487-.183l.943-.69v5.578a.626.626 0 0 0 .617.696zm5.292-1.108c-.9 0-1.471-1.065-1.471-2.8 0-1.765.558-2.84 1.471-2.84s1.45 1.065 1.45 2.84c0 1.738-.557 2.8-1.45 2.8z"
        transform="translate(-4.094 -2.053)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Forward6

import React from 'react'

const FullScreen4 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 11.557 11.557"
        >
          <path
            data-name="Path 1073"
            d="M3 8.278C3 3.932 3.932 3 8.278 3s5.278.932 5.278 5.278-.932 5.278-5.278 5.278S3 12.625 3 8.278z"
            style={{ fill: 'none', stroke: color }}
            transform="translate(-2.5 -2.5)"
          />
          <path
            data-name="Path 1074"
            d="M9.346 15.346v-2.053h0A.293.293 0 0 0 9.053 13H7"
            transform="translate(-4.154 -6.635)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1075"
            d="M13 7v2.053h0a.293.293 0 0 0 .293.293h2.053"
            transform="translate(-6.635 -4.154)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 11.56 11.56"
        >
          <path
            data-name="Path 1079"
            d="M3 8.28C3 3.932 3.932 3 8.28 3s5.28.932 5.28 5.28-.932 5.28-5.28 5.28S3 12.628 3 8.28z"
            style={{ fill: 'none', stroke: color }}
            transform="translate(-2.5 -2.5)"
          />
          <path
            data-name="Path 1080"
            d="M8 12v3.5h0a.5.5 0 0 0 .5.5H12"
            transform="translate(-5.157 -7.283)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1081"
            d="M16 12V8.5h0a.5.5 0 0 0-.5-.5H12"
            transform="translate(-7.283 -5.157)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
        </svg>
      )}
    </div>
  )
}

export default FullScreen4

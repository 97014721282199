import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CgProfile } from 'react-icons/cg'
import logo from '../../../assets/basic-placehoder.jpg'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
// import SearchIconBlue from '../../../assets/search-icon-blue.svg'
// import SearchIconWhite from '../../../assets/search-icon-white.svg'
import AddSection from '../addSection'
import { withTheme } from 'theming'
import { useTranslation } from 'react-i18next'

const ATGHeader = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  theme,
  onAdd,
  onSwap,
}) => {
  const { t } = useTranslation()
  const { data, id } = componentData
  const { customNavigate, checkActiveClass, isLogin, userObj, logoutCall, getTemplateImage } =
    useTemplateHook()
  const [profileToggle, setProfileToggle] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openMobileSearch, setOpenMobileSearch] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [hoverState, setHoverState] = useState('')
  const [searchBarHover, setSearchBarHover] = useState(false)

  const handleSearchButton = () => {
    if (searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setOpenMobileSearch(false)
      setSearchText('')
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setSearchText('')
    }
  }

  const activeClass = 'atg-header-gradient'

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }
  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }
  const handleRedirect = (linkObj, title) => {
    customNavigate('INDIRECT', linkObj, isEdit, title)
  }

  return (
    <div
      className={`${
        !isEdit && `py-7  lg:py-9 xl:py-[40.5px] 2xl:py-[47px]  lg:h-28 h-20 xl:h-20 `
      }`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      <nav
        style={{ fontFamily: 'Arial', backgroundColor: `${theme.c1}99` }}
        className={`text-md ${isEdit ? 'relative' : 'fixed'} top-0 z-50 h-auto w-full ${
          isEdit && hoverState && 'component-hover-class'
        }`}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onEdit={handleModal}
            onDelete={() => onDelete(id)}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <div className="mx-auto px-2 py-3 md:py-3">
          <div className="flex justify-between ">
            <div className="flex space-x-10 ">
              <div
                className="self-center ml-2 md:ml-6"
                onClick={() => customNavigate('DIRECT', '/home', isEdit)}
              >
                <CustomImage
                  imageUrl={getTemplateImage(data?.logo_s3key)}
                  imgClass="2xl:w-[161px] 2xl:h-[70px] xl:w-[130px] xl:h-[57px] lg:w-[107px] lg:h-[46px] w-[50px] h-[22px] cursor-pointer"
                  imgStyle={{ backgroundColor: 'transparent' }}
                  loaderClass="2xl:w-[161px] 2xl:h-[70px] xl:w-[130px] xl:h-[57px] lg:w-[107px] lg:h-[46px] w-[50px] h-[22px]"
                  loaderStyle={{ background: '#5A5A5A50', hieght: '56px', width: '100px' }}
                  imgAlt={'Logo'}
                  errorPlaceholder={logo}
                />
              </div>
              {/* <!-- Primary Navbar items --> */}
            </div>
            <div
              className="hidden xl:flex justify-center items-center text-lg mr-auto ml-[20px]"
              // style={{ outline: '2px solid lime' }}
            >
              {data?.headings.map((heading, idx) => (
                <>
                  {heading?.text.length > 0 && (
                    <div
                      onClick={() => handleRedirect(heading?.link, heading.text)}
                      className="flex justify-center items-center"
                      key={idx}
                    >
                      <p
                        className={`${
                          checkActiveClass(heading?.link, isEdit) ? activeClass : ''
                        } md:px-4  font-semibold cursor-pointer decoration-[#e3e2e2] decoration-2 2xl:text-[0.94vw] text-[.85vw]`}
                        style={{ marginBlock: '0', fontWeight: '500', color: theme.c4 }}
                      >
                        {heading.text}
                      </p>
                    </div>
                  )}
                </>
              ))}
            </div>
            {/* <!-- Secondary Navbar items --> */}
            <div className="hidden xl:flex items-center space-x-10 mr-2 relative">
              <div
                className="group flex-shrink-0 group xl:h-11 lg:h-10 h-8 lg:w-9 hover:w-[200px]  transition-[500ms] xl:flex hidden text-transparent hover:text-white"
                style={{
                  position: 'absolute',
                  right: '100%',
                  borderBottom: searchBarHover ? `1px solid #5fc1f2` : '',
                  transition: '500ms',
                  color: theme.c4,
                  // overflow: 'hidden',
                  marginRight: '10px',
                  background: `${theme.c1}99`,
                }}
                onMouseEnter={() => setSearchBarHover(true)}
                onMouseLeave={() => setSearchBarHover(false)}
              >
                <input
                  type="text"
                  className="group-hover:block hidden bg-transparent flex-shrink-0 outline-none xl:px-4 px-2 xl:py-2.5 lg:py-1.5 py-[0] w-[88%] lg:text-base text-sm placeholder:italic"
                  placeholder={t('search_here')}
                  style={{
                    outline: 'none',
                    border: 'none',
                    color: theme.c4,
                    fontFamily: 'Arial',
                    fontWeight: 'normal',
                  }}
                  disabled={isEdit}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => handleSearch(e)}
                />
                <svg
                  style={{
                    transition: '500ms',
                    transform: searchBarHover === true ? 'translateX(-20%)' : '',
                  }}
                  className=" 2xl:w-9 xl:w-7 lg:w-6 w-5 m-auto flex-shrink-0"
                  id="search_black_24dp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                >
                  <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M0,0H42V42H0Z"
                    style={{ fill: `transparent` }}
                  />
                  <path
                    id="Path_59"
                    data-name="Path 59"
                    style={{ fill: theme.c4 }}
                    d="M24.875,22.25H23.493L23,21.778A11.392,11.392,0,1,0,21.778,23l.472.49v1.382L31,33.607,33.607,31Zm-10.5,0a7.875,7.875,0,1,1,7.875-7.875A7.864,7.864,0,0,1,14.375,22.25Z"
                    transform="translate(2.25 2.25)"
                  />
                </svg>
                {/* <img
                  src={searchBarHover === true ? SearchIconBlue : SearchIconWhite}
                  alt=""
                  className=" 2xl:w-9 xl:w-7 lg:w-6 w-5 m-auto flex-shrink-0"
                  style={{
                    transition: '500ms',
                    transform: searchBarHover === true ? 'translateX(-20%)' : '',
                  }}
                  onClick={handleSearchButton}
                /> */}
              </div>
              {!isLogin ? (
                <div className="flex justify-center">
                  <button
                    className="atg-register-gradient bg-gradient-to-r from-[#d20c83] to-[#0dedf5] text-white px-[16px] rounded-full  my-1 focus:outline-none"
                    style={{ border: 'none' }}
                    onClick={() =>
                      handleRedirect(data?.cta_buttons[1].link, data.cta_buttons[1].text)
                    }
                  >
                    {data?.cta_buttons[1].text}
                  </button>
                  <p
                    className="p-2 lg:px-4 md:mx-2 cursor-pointer font-bold text-md"
                    style={{ marginBlockEnd: 0, color: theme.c4 }}
                    onClick={() =>
                      handleRedirect(data?.cta_buttons[0].link, data.cta_buttons[0].text)
                    }
                  >
                    {data?.cta_buttons[0].text}
                  </p>
                </div>
              ) : (
                <div className="flex justify-center">
                  <div
                    className="md:pl-10 ml-1 flex justify-center items-center "
                    onClick={() => setProfileToggle(!profileToggle)}
                    onBlur={(e) => {
                      const currentTarget = e.currentTarget
                      // Give browser time to focus the next element
                      requestAnimationFrame(() => {
                        // Check if the new focused element is a child of the original container
                        if (!currentTarget.contains(document.activeElement)) {
                          setProfileToggle(false)
                        }
                      })
                    }}
                    tabIndex={0}
                  >
                    <div className="flex justify-center items-center">
                      <div className="relative">
                        <div className="flex justify-center items-center space-x-3 cursor-pointer">
                          {/* <div className="w-12 h-12"> */}
                          <CgProfile
                            style={{ color: theme.c4, opacity: '0.85' }}
                            className="w-8 h-8"
                          />
                          {/* </div> */}
                          <div className=" font-semibold dark:text-white text-md">
                            <div
                              style={{ color: theme.c4, opacity: '0.85' }}
                              className="flex cursor-pointer"
                            >
                              <span>{userObj?.name ? userObj?.name.split(' ')[0] : ''}</span>
                              <svg
                                className={
                                  profileToggle
                                    ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                    : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                                }
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {profileToggle ? (
                          <div
                            style={{
                              // backgroundColor: 'rgb(27, 36, 47,0.90)',
                              backgroundColor: `${theme.c1}99`,
                              borderColor: 'rgb(227, 226, 226,0.50)',
                              // opacity: "0.85",
                            }}
                            className="absolute right-0 z-20 w-48 px-2 py-3 shadow border mt-5"
                          >
                            <ul className="py-1" aria-labelledby="dropdown">
                              <li>
                                <Link to="/profile">
                                  <p
                                    className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    style={{ color: theme.c4 }}
                                  >
                                    {t('my_profile')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/watchlist">
                                  <p
                                    className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    style={{ color: theme.c4 }}
                                  >
                                    {t('my_watchlist')}
                                  </p>
                                </Link>
                              </li>
                              <li>
                                <Link to="/favourites">
                                  <p
                                    className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu"
                                    style={{ color: theme.c4 }}
                                  >
                                    {t('my_favorites')}
                                  </p>
                                </Link>
                              </li>
                              <li onClick={logoutCall}>
                                <p
                                  className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu"
                                  style={{ color: theme.c4 }}
                                >
                                  {t('sign_out')}
                                </p>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <!-- Mobile menu button --> */}
            <div className="xl:hidden flex items-center">
              <button
                className="outline-none mr-2 bg-transparent"
                style={{ border: 'none' }}
                onClick={() => {
                  setOpenMobileSearch((openMobileSearch) => !openMobileSearch)
                  setOpenMobileMenu(false)
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              <button
                className="outline-none mobile-menu-button bg-transparent"
                style={{ border: 'none' }}
                onClick={() => {
                  setOpenMobileMenu((openMobileMenu) => !openMobileMenu)
                  setOpenMobileSearch(false)
                }}
              >
                {!openMobileMenu ? (
                  <svg
                    className=" w-6 h-6 text-gray-500"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {openMobileSearch && (
          <div className="pt-2 relative mx-auto text-gray-600 flex ml-5 mb-3">
            <input
              className="border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none w-full"
              type="search"
              name="search"
              placeholder={'Search'}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="h-10 text-sm text-white rounded-md bg-red-500 hover:bg-red-600 px-5 mx-3"
              onClick={handleSearchButton}
            >
              {'Search'}
            </button>
          </div>
        )}

        {/* <!-- mobile menu --> */}
        <div
          className={openMobileMenu ? 'mobile-menu' : 'hidden mobile-menu bg-slate-50'}
          style={{ borderBottom: 'white' }}
        >
          <ul
            className="pl-4 pr-4 divide-y divide-stale-50"
            onClick={() => setOpenMobileMenu(false)}
            style={{ listStyle: 'none' }}
          >
            {!isLogin ? (
              <>
                <li>
                  <p
                    className="block text-sm px-[8px] py-[16px] text-white font-semibold"
                    onClick={() =>
                      handleRedirect(data?.cta_buttons[0].link, data.cta_buttons[0].text)
                    }
                  >
                    {data?.cta_buttons[0].text}
                  </p>
                </li>
                <li>
                  <p
                    className="block text-sm px-[8px] py-[16px] text-white font-semibold"
                    onClick={() =>
                      handleRedirect(data?.cta_buttons[1].link, data.cta_buttons[1].text)
                    }
                  >
                    {data?.cta_buttons[1].text}
                  </p>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/profile">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_profile')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/watchlist">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_watchlist')}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/favourites">
                    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                      {t('my_favorites')}
                    </p>
                  </Link>
                </li>
                <li onClick={logoutCall}>
                  <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">
                    {t('sign_out')}
                  </p>
                </li>
              </>
            )}
          </ul>
        </div>
        {!openMobileMenu && !openMobileSearch && (
          <div className="xl:hidden flex mb-1 pl-5 overflow-x-auto pb-2 justify-center no-scrollbar">
            {data?.headings.map((heading, idx) => (
              <>
                {heading?.text.length > 0 && (
                  <div onClick={() => handleRedirect(heading?.link, heading.text)} key={idx}>
                    <p
                      className={`pl-5 px-2 text-xs md:text-base text-white font-semibold  ${
                        checkActiveClass(heading?.link, isEdit) && activeClass
                      }`}
                      style={{ width: 'max-content' }}
                    >
                      {heading.text}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        )}
        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </nav>
    </div>
  )
}

export default withTheme(ATGHeader)

/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { AppContext } from './app'
import { FAVOURITE_URL, USER_RESUME_TIMESTAMP, WATCHLIST_URL } from '../utils/apiUrls'
import useAxios from 'axios-hooks'
import { raiseToast } from '../utils/helper'
import { useTranslation } from 'react-i18next'

const initialState = {
  inWatchList: () => {},
  inFavouriteList: () => {},
}
//create context

export const ContentContext = createContext(initialState)

//Provider Component

export const ContentProvider = ({ children }) => {
  const { t } = useTranslation()
  const [watchList, setWatchList] = useState([])
  const [resumeTimestamps, setResumeTimestamps] = useState({})
  const [favouriteList, setFavouriteList] = useState([])
  const [contentId, setContentId] = useState('')
  const { setLoader, isLogin, setOpenLoginModal, setModalType } = useContext(AppContext)
  const [isRequestDone, setIsRequestDone] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  useEffect(() => {
    if (!isLogin) {
      setWatchList([])
      setFavouriteList([])
      setResumeTimestamps({})
    } else {
      getWishList()
      getFavList()
      getResumeTimestamps()
    }
  }, [isLogin])

  const [{ data: resumeTimestampData }, getResumeTimestamps] = useAxios(
    {
      url: USER_RESUME_TIMESTAMP,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: wishListData, loading: wishListLoading }, getWishList] = useAxios(
    {
      url: WATCHLIST_URL,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: favListData, loading: favListLoading }, getFavList] = useAxios(
    {
      url: FAVOURITE_URL,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: favouriteData, loading: favouriteLoading }, favouriteOperations] = useAxios(
    {
      url: FAVOURITE_URL,
      method: 'put',
    },
    { manual: true },
  )
  const [{ data: watchlistData, loading: watchlistLoading }, watchlistOperations] = useAxios(
    {
      url: WATCHLIST_URL,
      method: 'put',
    },
    { manual: true },
  )

  useEffect(() => {
    if (resumeTimestampData && resumeTimestampData.success) {
      setResumeTimestamps(resumeTimestampData.data)
    }
  }, [resumeTimestampData])

  useEffect(() => {
    if (wishListData && wishListData.success) {
      setWatchList(wishListData.data.map((item) => item.content_id))
    }
  }, [wishListData])

  useEffect(() => {
    if (favListData && favListData.success) {
      setFavouriteList(favListData.data.map((item) => item.content_id))
    }
  }, [favListData])

  const addRemoveWatchList = (id) => {
    setIsRequestDone(false)
    if (isLogin) {
      setContentId(id)
      watchlistOperations({
        params: {
          content_id: id,
        },
      })
    } else {
      setModalType('login')
      setOpenLoginModal(true)
      // alertBox(
      //     'Alert',
      //     'Please login to create watchlist',
      //     {
      //         negativeText: 'Cancel',
      //         positiveText: 'Login',
      //         onPositiveClick: () => navigate(NavigationRouteNames.LOGIN)
      //     },
      // );
    }
  }

  const addRemoveFavouriteList = (id) => {
    setIsRequestDone(false)
    if (isLogin) {
      setContentId(id)
      favouriteOperations({
        params: {
          id: id,
        },
      })
    } else {
      setModalType('login')
      setOpenLoginModal(true)
      // alertBox(
      //     'Alert',
      //     'Please login to create favorite list',
      //     {
      //         negativeText: 'Cancel',
      //         positiveText: 'Login',
      //         onPositiveClick: () => navigate(NavigationRouteNames.LOGIN)
      //     },
      // );
    }
  }

  const isInWatchList = (id) => watchList.find((item) => item === id)

  const isInFavoriteList = (id) => favouriteList.find((item) => item === id)

  const getResumeTimestamp = (id) => {
    if (!isLogin || !resumeTimestamps.hasOwnProperty(`${id}`)) return null

    return resumeTimestamps[`${id}`]
  }
  const updateContentResumeTimestamp = (id, timestamp) => {
    // if (!isLogin || !resumeTimestamps.hasOwnProperty(`${id}`)) return
    if (!isLogin) return
    let tempResumeTime = resumeTimestamps
    tempResumeTime[`${id}`] = timestamp
    setResumeTimestamps({ ...tempResumeTime })
  }

  useEffect(() => {
    if (watchlistData && watchlistData.success) {
      const inWatchList = isInWatchList(contentId)
      raiseToast('success', inWatchList ? t('removed_watchlist_toast') : t('added_watchlist_toast'))
      setIsRequestDone(true)
      if (inWatchList) {
        setWatchList((watchList) => watchList.filter((item) => item !== contentId))
      } else {
        setWatchList((watchList) => [...watchList, contentId])
      }
      setContentId('')
    }
  }, [watchlistData])

  useEffect(() => {
    if (favouriteData && favouriteData.success) {
      const inFav = isInFavoriteList(contentId)
      raiseToast('success', inFav ? t('removed_favourites_toast') : t('added_favourites_toast'))
      setIsRequestDone(true)
      if (inFav) {
        setFavouriteList((favouriteList) => favouriteList.filter((item) => item !== contentId))
      } else {
        setFavouriteList((favouriteList) => [...favouriteList, contentId])
      }
      setContentId('')
    }
  }, [favouriteData])

  useEffect(() => {
    setLoader(favouriteLoading || watchlistLoading || wishListLoading || favListLoading)
  }, [watchlistLoading, favouriteLoading, favListLoading, wishListLoading])

  return (
    <ContentContext.Provider
      value={{
        addRemoveWatchList: addRemoveWatchList,
        isInWatchList: isInWatchList,
        isInFavoriteList: isInFavoriteList,
        addRemoveFavouriteList: addRemoveFavouriteList,
        isRequestDone: isRequestDone,
        setIsVideoPlaying: setIsVideoPlaying,
        isVideoPlaying: isVideoPlaying,
        resumeTimestamps,
        getResumeTimestamp,
        updateContentResumeTimestamp,
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}

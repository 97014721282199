import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleMementoSlides'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import './mementoSlides.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'

const MementoSlides = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage, FitnessPrev, FitnessNext } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({
    infinite: true,
    autoplay: data.auto_slide,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow transform={128} />,
    prevArrow: <SamplePrevArrow transform={128} />,
    dots: false,
    overflow: true,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          nextArrow: <SampleNextArrow transform={96} />,
          prevArrow: <SamplePrevArrow transform={96} />,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          nextArrow: <SampleNextArrow transform={64} />,
          prevArrow: <SamplePrevArrow transform={64} />,
        },
      },
      {
        breakpoint: 1279,
        settings: {
          arrows: false,
          dots: false,
        },
      },
    ],
  })

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  function SampleNextArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transparent`,
          width: '5.56%',
          height: '2.78vw',
          position: 'absolute',
          top: '40%',
          right: '0px',
          transform: `translateX(${transform}px) translateY(-50%)`,
          content: `url(${FitnessNext})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '5.56%',
          height: '2.78vw',
          position: 'absolute',
          top: '40%',
          left: '0px',
          transform: `translateX(-${transform}px) translateY(-50%)`,
          content: `url(${FitnessPrev})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }

  useEffect(() => {
    setSetting({
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: true,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      dots: false,
      overflow: true,
      responsive: [
        {
          breakpoint: 1536,
          settings: {
            nextArrow: <SampleNextArrow transform={96} />,
            prevArrow: <SamplePrevArrow transform={96} />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            nextArrow: <SampleNextArrow transform={64} />,
            prevArrow: <SamplePrevArrow transform={64} />,
          },
        },
        {
          breakpoint: 1279,
          settings: {
            arrows: false,
            dots: false,
          },
        },
      ],
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <div
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'}`}
      style={{ backgroundColor: theme.c1, color: theme.c3 }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className="2xl:py-14 lg:py-10 py-8">
        <h2 className="xl:text-3xl md:text-xl text-xs font-bold sm:mb-[2vw] mb-6 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[0.5rem] ">
          {data?.title}
        </h2>
        <div className="w-full sm:h-auto h-[127px] 2xl:px-32 xl:px-24 md:px-16 sm:px-10 px-[0.5rem] sm:pb-0 pb-6 galleries-section">
          <SliderWrapper>
            <Slider ref={sliderRef} {...setting}>
              {data?.slide_data.map((slide, idx) => (
                <div key={idx}>
                  <div
                    className="cursor-pointer w-full sm:h-[16.58vw] h-[73px] bg-white flex justify-start items-center sm:px-[5%] px-[8px]"
                    style={{ boxShadow: '3px 3px 6px 0 rgba(0, 0, 0, 0.4)', color: theme.c6 }}
                    onClick={() => handleRedirect(slide?.link)}
                  >
                    <CustomImage
                      imageUrl={getTemplateImage(slide?.image_s3key)}
                      imgClass="sm:w-[31%] w-[41%] rounded-3xl"
                      loaderClass="w-[41%] h-full rounded-3xl"
                      imgStyle={{
                        boxShadow: '6px 6px 6px 0 rgba(0, 0, 0, 0.4)',
                        border: '1px solid gray',
                      }}
                      loaderStyle={{ background: '#5A5A5A50' }}
                      imgAlt={'trainer'}
                    />
                    <div className="sm:ml-[2.3%] ml-[8px] sm:h-auto h-full md:self-start 2xl:py-9 xl:py-4 md:py-1 py-[0px] md:block flex flex-col justify-center gap-y-1">
                      <h3 className="2xl:text-xl xl:text-lg md:text-base sm:text-xs text-[10px] 2xl:mb-[4px] mb-[0px] font-bold">
                        {slide?.heading}
                      </h3>
                      <p className="xl:text-base sm:text-xs text-[10px] italic 2xl:mb-[20px] xl:mb-[16px] md:mb-[8px] mb-[0px]">
                        {slide?.sub_heading}
                      </p>
                      <p className="md:block hidden 2xl:text-sm xl:text-xs text-[10px]">
                        {slide?.description}
                        {slide?.description && (
                          <span className="font-semibold italic ml-1">See More →</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </SliderWrapper>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(MementoSlides)

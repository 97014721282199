import React from 'react'

const Playback6 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 16.943 13.657"
    >
      <path
        d="M1.875 222.968a6.61 6.61 0 0 0 1.34 3.231l-1.494 1.157A8.5 8.5 0 0 1 0 223.2zm14.653-3.468a8.422 8.422 0 0 1 .352 1.579 8.9 8.9 0 0 1 0 2.119 8.5 8.5 0 0 1-1.721 4.159l-1.494-1.157A6.61 6.61 0 0 0 15 222.968a7.036 7.036 0 0 0 0-1.66 6.6 6.6 0 0 0-.272-1.221zm-1.2-3.735 1.336 1.336-7.754 7.754-4.605-4.605 1.336-1.336 3.269 3.269zm-13.607 1.156 1.494 1.157a6.61 6.61 0 0 0-1.34 3.231L0 221.078a8.5 8.5 0 0 1 1.721-4.157zM7.38 213.7l.231 1.876a6.593 6.593 0 0 0-3.231 1.34l-1.158-1.494A8.492 8.492 0 0 1 7.38 213.7zm2.121 0a8.5 8.5 0 0 1 4.157 1.721l-1.158 1.493a6.59 6.59 0 0 0-3.23-1.34z"
        transform="translate(0 -213.699)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Playback6

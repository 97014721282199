import React from 'react'

const Language1 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      viewBox="0 0 15.818 15.364"
    >
      <path
        data-name="language-svgrepo-com (6)"
        d="M19.156 18.068 16.413 10.4a1.2 1.2 0 0 0-1.119-.816 1.223 1.223 0 0 0-1.128.835l-2.733 7.65a.688.688 0 0 0 1.293.468l.569-1.587h3.99l.569 1.587a.689.689 0 0 0 .651.459.667.667 0 0 0 .229-.037.687.687 0 0 0 .413-.881zm-5.357-2.5 1.5-4.21 1.5 4.21zM11.46 7.5c-2.073 3.275-3.944 5.256-6.219 6.577a.675.675 0 0 1-.339.092.684.684 0 0 1-.6-.339.677.677 0 0 1 .248-.936A15.989 15.989 0 0 0 9.828 7.51h-5.77a.688.688 0 1 1 0-1.376H7.5V4.318a.688.688 0 0 1 1.376 0v1.816h3.44a.688.688 0 0 1 0 1.376h-.862zm.037 6.668a.684.684 0 0 1-.349-.092 18.525 18.525 0 0 1-1.715-1.136.688.688 0 0 1 .826-1.1 15.8 15.8 0 0 0 1.587 1.046.689.689 0 0 1-.349 1.284z"
        transform="translate(-3.37 -3.63)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Language1

import styled from '@emotion/styled'

const MultiContentWrapper = styled('div')`
  .content-image::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to left, transparent 10%, ${(props) => props.theme.c1} 100%);
    margin-top: calc(70vw / -1.7777);
    height: calc(70vw / 1.7777);
    /* border: 1px solid red; */
    /* width: 100%; */
    content: '';
  }

  .description-color {
    color: ${(props) => props.theme.c4};
  }
  .description-see-more-color {
    color: ${(props) => props.theme.c3};
  }
`

export default MultiContentWrapper

import React, { useState } from 'react'

const NewAddedToFavorite = ({ color = '#fff', hoverColor = '#bbfa33' }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.573"
      height="15.377"
      viewBox="0 0 17.573 15.377"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <g data-name="Group 996">
        <path
          d="M6.26 2.385A5.607 5.607 0 0 1 9.887 3.8a5.6 5.6 0 0 1 3.626-1.417 5.353 5.353 0 0 1 5.16 5.517c0 3.917-2.865 6.124-5.166 7.9-.233.179-.452.348-.667.518a5.124 5.124 0 0 1-2.953 1.446 5.123 5.123 0 0 1-2.953-1.446c-.215-.169-.434-.338-.667-.518C3.965 14.024 1.1 11.817 1.1 7.9a5.677 5.677 0 0 1 1.494-3.878A4.966 4.966 0 0 1 6.26 2.385zM9.887 6.33l-.648-.674A4.212 4.212 0 0 0 6.26 4.185a3.185 3.185 0 0 0-2.345 1.061A3.883 3.883 0 0 0 2.9 7.9c0 3.031 2.27 4.78 4.465 6.472.237.182.46.355.683.53a4.143 4.143 0 0 0 1.839 1.06 4.144 4.144 0 0 0 1.839-1.06c.222-.175.446-.348.683-.53 2.2-1.691 4.465-3.44 4.465-6.472a3.555 3.555 0 0 0-3.36-3.715 4.211 4.211 0 0 0-2.978 1.472z"
          transform="translate(-1.1 -2.385)"
          style={{ fill: isHovered ? hoverColor : color }}
        />
        <path
          data-name="Path 913"
          d="m11.991 15.025 1.409-1.414 2.121 2.121 4.243-4.243 1.419 1.411-5.657 5.657z"
          transform="translate(-7.8 -7.745)"
          style={{ fill: isHovered ? hoverColor : color }}
        />
      </g>
    </svg>
  )
}

export default NewAddedToFavorite

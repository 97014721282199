import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaCaretDown } from 'react-icons/fa'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import { AppContext } from '../../context/app'

const Dropdown = ({ options, title, filterFunction, payload, filterKey, theme }) => {
  const { isCustomTemplate } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const handleChange = (e) => {
    setOpen(false)
    let { value, checked } = e.target
    if (checked) {
      payload[filterKey].push(value)
      filterFunction(payload)
      return
    }
    let tempPayload = payload

    tempPayload[filterKey] = payload[filterKey].filter((ele) => ele !== value)

    filterFunction(tempPayload)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [open])

  return (
    <div ref={ref}>
      <div
        style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
        onClick={() => setOpen(!open)}
        className="relative md:text-base md:mx-2 px-4 py-2 shadow rounded bg-transparent text-sm font-medium leading-none flex items-center justify-between cursor-pointer"
      >
        {title}
        <div>
          <div style={{ color: isCustomTemplate ? theme.c4 : '' }} className="text-white">
            <FaCaretDown />
          </div>
        </div>
      </div>
      {open && (
        <div
          style={{ backgroundColor: isCustomTemplate ? theme.c10 : '#25303e' }}
          className="absolute p-4  shadow rounded z-50 max-h-60 md:max-h-80 overflow-x-auto"
        >
          {!isEmpty(options) &&
            options.map((info, index) => (
              <div className="flex items-center justify-between" key={index}>
                <div className="flex items-center">
                  <div className=" flex items-center">
                    <div
                      style={{ borderColor: isCustomTemplate ? theme.c4 : '' }}
                      className="bg-gray-100 dark:bg-gray-800 border rounded-sm w-3 h-3 flex flex-shrink-0 border-gray-200 justify-center items-center relative py-1"
                    >
                      <input
                        type="checkbox"
                        className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                        checked={payload[filterKey]?.includes(info._id)}
                        value={info._id}
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="check-icon hidden bg-indigo-700 text-white rounded-sm">
                        <svg
                          className="icon icon-tabler icon-tabler-check"
                          xmlns="http://www.w3.org/2000/svg"
                          width={12}
                          height={12}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M5 12l5 5l10 -10" />
                        </svg>
                      </div>
                    </div>
                    <p
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                      className="text-md leading-normal ml-2 "
                    >
                      {info.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <style>
        {` .checkbox:checked + .check-icon {
        display: flex;
    }`}
      </style>
    </div>
  )
}

export default withTheme(Dropdown)

import React from 'react'

const PlayIcon = ({ color = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.608"
      height="18.296"
      viewBox="0 0 16.608 18.296"
    >
      <path
        d="M15.71 7.915 2.183.262C.983-.417 0 .269 0 1.787v14.721c0 1.518.983 2.2 2.183 1.526l13.527-7.65a1.3 1.3 0 0 0 0-2.469z"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default PlayIcon

import React, { useContext } from 'react'
import Signup from '../loginSignup/signup'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import EclipseSignUp from './eclipseSignUp'
import EliteEdgeSignUp from './eliteEdgeSignUp'
import SceneCenterSignUp from './sceneCenterSignUp'
import CrescendoSignUp from './crescendoSignUp'

const RegisterTemplate = () => {
  const { signUpTemplateData, signupTemplateLoaded } = useContext(TemplateContext)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      eclipseSignUp: <EclipseSignUp {...pageProps} />,
      eliteEdgeSignUp: <EliteEdgeSignUp {...pageProps} />,
      sceneCenterSignUp: <SceneCenterSignUp {...pageProps} />,
      playMakersSignUp: <SceneCenterSignUp {...pageProps} />,
      crescendoSignUp: <CrescendoSignUp {...pageProps} />,
    }

    return componentObj[key]
  }

  return (
    <div className="w-full h-full">
      {signupTemplateLoaded ? (
        !isEmpty(signUpTemplateData) ? (
          getPage(signUpTemplateData.template_key, signUpTemplateData.data)
        ) : (
          <Signup />
        )
      ) : null}
    </div>
  )
}

export default RegisterTemplate

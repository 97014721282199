import React from 'react'

const Backward5 = ({ color = '#323232' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.116"
      height="27.116"
      viewBox="0 0 27.116 27.116"
    >
      <path
        data-name="Path 1025"
        d="M4 15.006A10.037 10.037 0 1 0 14.037 4.969h-3.346V2.27L5.673 5.805l5.018 3.327v-2.49h3.346a8.364 8.364 0 1 1-8.364 8.364z"
        transform="translate(-.654 .141)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1026"
        d="M19.162 20.83a2.375 2.375 0 0 1-1.071-.226 2.041 2.041 0 0 1-.744-.644 2.986 2.986 0 0 1-.435-1.045 6.432 6.432 0 0 1-.142-1.405 6.549 6.549 0 0 1 .142-1.405 3.053 3.053 0 0 1 .435-1.045 2.041 2.041 0 0 1 .744-.644 2.375 2.375 0 0 1 1.071-.226 2.041 2.041 0 0 1 1.807.836 4.374 4.374 0 0 1 .585 2.451 4.374 4.374 0 0 1-.585 2.451 2.041 2.041 0 0 1-1.807.902zm0-1.02a.9.9 0 0 0 .836-.46 2.827 2.827 0 0 0 .309-1.263v-1.155a2.768 2.768 0 0 0-.243-1.255 1.029 1.029 0 0 0-1.723 0 2.768 2.768 0 0 0-.243 1.255v1.154a2.827 2.827 0 0 0 .243 1.263.887.887 0 0 0 .821.46z"
        transform="translate(-2.744 -2.228)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 1027"
        d="M10.563 20.735v-.987h1.673v-4.341l-1.556.836-.46-.887 1.94-1.087h1.221v5.436h1.489v1.029z"
        transform="translate(-1.672 -2.243)"
        style={{ fill: color }}
      />
      <path
        data-name="<Transparent Rectangle>"
        style={{ fill: 'none' }}
        d="M0 0h27.116v27.116H0z"
      />
    </svg>
  )
}

export default Backward5

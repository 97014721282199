import React from 'react'

const LoaderTile = ({ aspectRatio = '1.777', width = '100%', height = '100%' }) => {
  return (
    <div
      style={{ background: '#6b6b6b70', aspectRatio: aspectRatio, width: width, height: height }}
      className="rounded-lg animate-pulse"
    />
  )
}

export default LoaderTile

import React from 'react'

const AddFavorite = ({ color = '#fff', imgClass = '' }) => {
  const pathStyle = {
    fill: 'none',
    stroke: color,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  }
  return (
    <svg
      className={imgClass}
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      viewBox="0 0 34 34"
    >
      <g id="Group_773" data-name="Group 773" transform="translate(-694 -261)">
        <path
          id="love-svgrepo-com_1_"
          data-name="love-svgrepo-com (1)"
          fill={color}
          d="M18.134,9.94c-.832,4.294-7.486,8.007-7.486,8.007S4,14.234,3.163,9.94C2.343,5.708,4.756,3.5,7.249,3.5a3.777,3.777,0,0,1,3.4,1.7,3.777,3.777,0,0,1,3.4-1.7C16.542,3.5,18.954,5.708,18.134,9.94Z"
          transform="translate(700.351 268.281)"
        />
        <g id="Complete" transform="translate(693 260)">
          <g id="Circle" transform="translate(2 2)">
            <circle id="Circle-2" data-name="Circle" style={pathStyle} cx={16} cy={16} r={16} />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AddFavorite

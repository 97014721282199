import React from 'react'

const CrownIcon = ({ color = '#ffee00' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} viewBox="0 0 19 18">
      <g data-name="crown-svgrepo-com (1)">
        <path
          data-name="Path 894"
          d="m19.687 14.093.183-1.7a9.765 9.765 0 0 0 .112-1.889 1.5 1.5 0 0 1-1.118-.52 10.086 10.086 0 0 0-1.394 1.265c-.494.492-.742.739-1.017.777a.832.832 0 0 1-.45-.063c-.255-.112-.424-.417-.764-1.025L13.45 7.725c-.209-.376-.385-.69-.543-.943a2 2 0 0 1-1.815 0c-.158.253-.333.567-.543.943L8.76 10.934c-.34.609-.509.913-.764 1.025a.832.832 0 0 1-.45.063c-.276-.038-.523-.284-1.017-.777A10.086 10.086 0 0 0 5.136 9.98a1.5 1.5 0 0 1-1.118.52 9.764 9.764 0 0 0 .112 1.889l.183 1.7.089.849c.252 2.436.461 4.451 1.31 5.211.946.847 2.364.847 5.2.847h2.176c2.836 0 4.254 0 5.2-.847.849-.76 1.058-2.775 1.31-5.211l.089-.845z"
          style={{ fill: color, fillRule: 'evenodd' }}
          transform="translate(-2.5 -3)"
        />
        <path
          data-name="Path 895"
          d="M20 10.5a1.5 1.5 0 1 0-.018 0z"
          style={{ fill: color }}
          transform="translate(-2.5 -3)"
        />
        <path
          data-name="Path 896"
          d="M12 3a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          style={{ fill: color }}
          transform="translate(-2.5 -3)"
        />
        <path
          data-name="Path 897"
          d="M2.5 9A1.5 1.5 0 0 0 4 10.5h.018A1.5 1.5 0 1 0 2.5 9z"
          style={{ fill: color }}
          transform="translate(-2.5 -3)"
        />
        <path
          data-name="Path 898"
          d="M4.849 18.25c-.1-.45-.176-.955-.246-1.5H19.4c-.07.545-.148 1.05-.246 1.5z"
          style={{ fill: color }}
          transform="translate(-2.5 -3)"
        />
      </g>
    </svg>
  )
}

export default CrownIcon

import React, { useRef, useEffect } from 'react'
import useSize from './useSize'

function animateBars(analyser, canvas, canvasCtx, dataArray, bufferLength) {
  analyser.getByteFrequencyData(dataArray)

  canvasCtx.fillStyle = 'rgba(215,189,189,0.75)'

  const HEIGHT = canvas.height / 2

  var barWidth = Math.ceil(canvas.width / bufferLength) * 2.5
  let barHeight
  let x = 0

  for (var i = 0; i < bufferLength; i++) {
    barHeight = (dataArray[i] / 255) * HEIGHT
    const blueShade = Math.floor((dataArray[i] / 255) * 5) // generate a shade of blue based on the audio input
    const blueHex = ['#fb61ee', '#cd5afa', '#f550c1', 'rgba(230,65,216,0.75)', '#20232a'][blueShade] // use react logo blue shades
    canvasCtx.fillStyle = blueHex
    canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight)

    x += barWidth + 1
  }
}

const WaveForm = ({ analyzerData }) => {
  const canvasRef = useRef(null)
  const { dataArray, analyzer, bufferLength } = analyzerData
  const [width, height] = useSize()

  const draw = (dataArray, analyzer, bufferLength) => {
    const canvas = canvasRef.current
    if (!canvas || !analyzer) return
    const canvasCtx = canvas.getContext('2d')

    const animate = () => {
      requestAnimationFrame(animate)
      // eslint-disable-next-line no-self-assign
      canvas.width = canvas.width
      canvasCtx.translate(0, canvas.offsetHeight / 2 - 115)
      animateBars(analyzer, canvas, canvasCtx, dataArray, bufferLength)
    }

    animate()
  }

  useEffect(() => {
    draw(dataArray, analyzer, bufferLength)
  }, [dataArray, analyzer, bufferLength])

  return (
    <canvas
      style={{
        zIndex: '10',
        bottom: '-26px',
        position: 'absolute',
        background: '#00000090',
      }}
      ref={canvasRef}
      width={width}
      height={height / 3}
    />
  )
}

export default WaveForm

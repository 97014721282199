import React from 'react'

const FullScreen5 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 12.051 12.051"
        >
          <path
            data-name="Path 1070"
            d="M9.456 15.456v-2.149h0A.307.307 0 0 0 9.149 13H7"
            transform="translate(-4.044 -6.361)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1071"
            d="M13 7v2.149h0a.307.307 0 0 0 .307.307h2.149"
            transform="translate(-6.361 -4.044)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1072"
            d="M14.051 8.525A5.525 5.525 0 1 1 8.525 3a5.525 5.525 0 0 1 5.526 5.525z"
            style={{ fill: 'none', stroke: color }}
            transform="translate(-2.5 -2.5)"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 12.05 12.05"
        >
          <path
            data-name="Path 1076"
            d="M8 12v3.5h0a.5.5 0 0 0 .5.5H12"
            transform="translate(-4.982 -6.968)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1077"
            d="M16 12V8.5h0a.5.5 0 0 0-.5-.5H12"
            transform="translate(-6.968 -4.982)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              stroke: color,
            }}
          />
          <path
            data-name="Path 1078"
            d="M14.05 8.525A5.525 5.525 0 1 1 8.525 3a5.525 5.525 0 0 1 5.525 5.525z"
            style={{ fill: 'none', stroke: color }}
            transform="translate(-2.5 -2.5)"
          />
        </svg>
      )}
    </div>
  )
}

export default FullScreen5

import React from 'react'

const StarIcon = ({ color = '#fe0' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15">
      <path
        data-name="star-svgrepo-com (2)"
        d="M7.365 4.556C8.315 2.852 8.79 2 9.5 2s1.185.852 2.135 2.556l.246.444a2.751 2.751 0 0 0 .615.886 2.563 2.563 0 0 0 1 .338l.477.108c1.845.417 2.767.626 2.987 1.332s-.412 1.436-1.67 2.908l-.325.38a2.761 2.761 0 0 0-.617.885 2.881 2.881 0 0 0 0 1.095l.049.508c.19 1.962.285 2.943-.289 3.379s-1.438.038-3.165-.757l-.447-.206a2.559 2.559 0 0 0-1-.339 2.559 2.559 0 0 0-1 .339l-.447.206c-1.727.8-2.591 1.193-3.165.757S4.412 15.4 4.6 13.441l.049-.508a2.879 2.879 0 0 0 0-1.095 2.76 2.76 0 0 0-.616-.885l-.325-.38C2.452 9.1 1.824 8.366 2.043 7.661s1.142-.915 2.987-1.332l.477-.108a2.564 2.564 0 0 0 1-.338A2.752 2.752 0 0 0 7.119 5z"
        transform="translate(-2 -2)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default StarIcon

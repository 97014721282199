import React from 'react'
import { withTheme } from 'theming'
import StarIcon from '../../assets/imgComponents/starIcon'
import { useTranslation } from 'react-i18next'

const AggregateRatingReviewWidget = (props) => {
  const { t } = useTranslation()
  const { value, ratingCount, starColor, bigTextColor, smallTextColor } = props

  return (
    <>
      {ratingCount > 0 && (
        <div className={'flex items-center'}>
          <StarIcon color={starColor} />

          <div className="flex items-baseline ml-2">
            <p style={{ color: bigTextColor }} className={''}>
              {`${Number(value).toFixed(1).toLocaleString()} `}
            </p>
            <div className="flex items-center">
              <span style={{ color: smallTextColor }} className={'text-xs align-baseline ml-1'}>
                {` / 5`}
              </span>
              <div
                style={{ border: `0.5px solid ${smallTextColor}` }}
                className="ml-1 rounded-lg"
              />

              <span style={{ color: smallTextColor }} className={'text-xs ml-1'}>
                {ratingCount.toLocaleString()} {ratingCount === 1 ? t('review') : t('reviews')}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withTheme(AggregateRatingReviewWidget)

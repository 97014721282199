import React from 'react'

const ScreenBackIcon = ({ primaryColor = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 44.303 48.506">
      <g data-name="Group 1161" style={{ opacity: '.6' }}>
        <path
          d="M26.245 50.559A22.316 22.316 0 0 1 4.094 28.408a22.258 22.258 0 0 1 9.273-17.916 1.788 1.788 0 0 1 2.693.391 1.938 1.938 0 0 1-.608 2.606 18.391 18.391 0 1 0 14.985-3.062v3.127c0 1.542-1.064 1.954-2.237 1.108L21.25 9.8a1.368 1.368 0 0 1 0-2.432L28.178 2.5c1.194-.869 2.259-.456 2.259 1.108v3.061A22.314 22.314 0 0 1 48.4 28.408a22.3 22.3 0 0 1-22.155 22.151z"
          transform="translate(-4.094 -2.053)"
          style={{ fill: primaryColor }}
        />
        <path
          data-name="goforward-10-svgrepo-com"
          d="M26.853 28.932c3.757 0 6.2-3.554 6.2-8.986 0-5.478-2.445-9.076-6.2-9.076s-6.225 3.6-6.225 9.076c.001 5.433 2.472 8.986 6.225 8.986zm-11.815-.272a1.428 1.428 0 0 0 1.471-1.56V12.862a1.6 1.6 0 0 0-1.63-1.811 3.188 3.188 0 0 0-1.856.747L9.9 13.9a1.5 1.5 0 0 0-.747 1.313 1.359 1.359 0 0 0 1.312 1.387 1.636 1.636 0 0 0 1.086-.407l2.105-1.539V27.1a1.4 1.4 0 0 0 1.382 1.561zm11.815-2.467c-2.015 0-3.282-2.377-3.282-6.247 0-3.938 1.245-6.338 3.282-6.338 2.014 0 3.237 2.377 3.237 6.338 0 3.871-1.245 6.247-3.237 6.247z"
          transform="translate(1.047 7.04)"
          style={{ fill: primaryColor }}
        />
      </g>
    </svg>
  )
}

export default ScreenBackIcon

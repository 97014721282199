import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import PrevIcon from '../../assets/small-prev-white-icon.svg'
import NextIcon from '../../assets/small-next-white-icon.svg'
import { useTranslation } from 'react-i18next'

const ChannelDateSlider = ({ selectedDate, onChange }) => {
  let scrl = useRef(null)
  const [scrollX, setscrollX] = useState(0)
  const [dates, setDates] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    getDates()
  }, [])

  const getDateLabel = (date) => {
    let today = moment().format('ddd, D MMM')
    let yesterday = moment().subtract(1, 'd').format('ddd, D MMM')
    if (date === today) {
      return t('today')
    }
    if (date === yesterday) {
      return t('yesterday')
    }

    return date
  }

  const getDates = () => {
    let dateArr = []
    for (let i = 0; i < 7; i++) {
      let date = moment().subtract(i, 'd')
      dateArr.push({
        label: getDateLabel(date.format('ddd, D MMM')),
        value: date.format(),
      })
    }

    setDates([...dateArr])
  }

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)
  }

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
  }

  return (
    <div className="flex items-center justify-center py-2 lg:py-0">
      <button className={` z-10 border-0 bg-transparent px-0`} onClick={() => slide(-100)}>
        <img
          src={PrevIcon}
          alt="prev"
          style={{
            width: '45px',
          }}
        />
      </button>

      <ul className="slider-ul" ref={scrl} onScroll={scrollCheck}>
        {dates.map((info, i) => (
          <li
            className={` ${
              selectedDate === info.value ? 'slider-li-selected' : 'slider-li'
            } cursor-pointer`}
            key={i}
            onClick={() => onChange(info.value)}
          >
            {info.label}
          </li>
        ))}
      </ul>
      <button className={` z-10 border-0 bg-transparent px-0`} onClick={() => slide(+100)}>
        <img
          src={NextIcon}
          alt="next"
          style={{
            width: '45px',
          }}
        />
      </button>
    </div>
  )
}

export default ChannelDateSlider

import React from 'react'

const Pip4 = ({ color = '#323232' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-auto" viewBox="0 0 16.933 13.17">
      <path
        d="M35.365 44h-13.8A1.57 1.57 0 0 0 20 45.568V55.6a1.57 1.57 0 0 0 1.568 1.568h13.8a1.57 1.57 0 0 0 1.565-1.568V45.568A1.57 1.57 0 0 0 35.365 44zm-13.484 1.881h13.17v3.763h-5.33a1.57 1.57 0 0 0-1.568 1.568v4.076h-6.272zm8.153 9.407v-3.762h5.017v3.763z"
        transform="translate(-20 -44)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Pip4
